import React, { useState, useContext } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { InputBase, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { StoreContext } from '../Hooks/store';
import L from 'leaflet';
import { notify } from '../Toast';
import { useTrackInteractions } from '../../utils/trackInteractions';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(3),
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(2),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}));

const GeocoderSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const { mapInstance: [mapInst], user: [user] } = useContext(StoreContext);
  const [currentMarker, setCurrentMarker] = useState(null);
  
  // Initialize tracking functions
  const { trackGeolocationSearch, trackComponentView } = useTrackInteractions();
  
  // Track component view when mounted
  React.useEffect(() => {
    trackComponentView('GeocoderSearch', { 
      viewType: 'search_bar',
      timestamp: Date.now()
    });
  }, [trackComponentView]);

  const handleSearch = async (e) => {
    // Track the search action
    trackGeolocationSearch(searchValue, {}, {
      userId: user?.id,
      timestamp: Date.now()
    });
    e.preventDefault();
    if (!searchValue.trim() || !mapInst) return;

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchValue)}`
      );
      const data = await response.json();

      if (data && data.length > 0) {
        // Track successful search with results
        trackGeolocationSearch(searchValue, {
          count: data.length,
          firstResult: {
            name: data[0].display_name,
            lat: data[0].lat,
            lon: data[0].lon
          }
        }, {
          userId: user?.id,
          timestamp: Date.now(),
          success: true
        });
        const { lat, lon, display_name } = data[0];
        const center = [parseFloat(lat), parseFloat(lon)];

        // Remove previous marker if it exists
        if (currentMarker) {
          currentMarker.remove();
        }

        // Set view to the new location
        mapInst.setView(center, 16);

        // Add new marker
        const marker = L.marker(center, {
          icon: L.icon({
            iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          })
        })
          .addTo(mapInst)
          .bindPopup(display_name)
          .openPopup();

        setCurrentMarker(marker);
        setTimeout(() => {
          setSearchValue('');
        }, 300);
      } else {
        notify('Location not found', 'error');
        
        // Track failed search with no results
        trackGeolocationSearch(searchValue, {
          count: 0
        }, {
          userId: user?.id,
          timestamp: Date.now(),
          success: false,
          reason: 'no_results'
        });
      }
    } catch (error) {
      console.error('Geocoding error:', error);
      notify('Error searching for location', 'error');
      
      // Track failed search with error
      trackGeolocationSearch(searchValue, {
        count: 0
      }, {
        userId: user?.id,
        timestamp: Date.now(),
        success: false,
        reason: 'api_error',
        error: error.message
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSearch}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search for a location..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
    </Box>
  );
};

export default GeocoderSearch;
