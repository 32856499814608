import { useState, useEffect, useContext } from 'react';
import { StoreContext } from './store';
import { useAuth } from './useAuth';
import { 
  getActiveBreakNotifications, 
  checkBreakDue,
  createBreakNotification
} from '../../api/timetrack';

/**
 * Custom hook to handle break notifications globally
 * This hook can be used in any component to check for and manage break notifications
 */
export const useBreakNotifications = () => {
  const [activeNotifications, setActiveNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const { user } = useAuth();
  const userId = user?.id;
  
  // Get clock state from store
  const storeContextValue = useContext(StoreContext);
  const { activitiesclockedin: [clockedIn] } = storeContextValue;

  // Function to check for active notifications
  const checkForActiveNotifications = async () => {
    if (!userId || !clockedIn) {
      setActiveNotifications([]);
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // First check for active notifications in the database
      const notifications = await getActiveBreakNotifications(userId);
      
      if (notifications && notifications.length > 0) {
        setActiveNotifications(notifications);
        return;
      }
      
      // If no active notifications, check if a break is due
      const userState = 'WA'; // Default to Washington state
      const breakDue = await checkBreakDue(userId, userState);
      
      if (breakDue) {
        // Create a new notification
        const newNotification = await createBreakNotification({
          userId,
          breakType: breakDue.breakType,
          scheduledBreakTime: breakDue.scheduledBreakTime,
          state: userState
        });
        
        if (newNotification) {
          setActiveNotifications([newNotification]);
        }
      } else {
        setActiveNotifications([]);
      }
    } catch (err) {
      console.error('Error checking for break notifications:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Check for active notifications when the component mounts
  // and when the user or clock state changes
  useEffect(() => {
    if (!userId || !clockedIn) {
      setActiveNotifications([]);
      return;
    }
    
    // Check immediately
    checkForActiveNotifications();
    
    // Set up interval to check every minute
    const intervalId = setInterval(checkForActiveNotifications, 60000);
    
    return () => clearInterval(intervalId);
  }, [userId, clockedIn]);

  return {
    activeNotifications,
    loading,
    error,
    refreshNotifications: checkForActiveNotifications
  };
};
