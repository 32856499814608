import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';

/**
 * Create a new saved location entry.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @param {string} name - The name of the location.
 * @param {string} address - The address of the location.
 * @param {string} description - A description of the location.
 * @param {string} defaultIcon - The Material-UI icon name for the location.
 * @param {number[]} coords - The geographic coordinates of the location [lat, lng].
 * @returns {Promise} - A promise that resolves when the location is created.
 */
export async function createSavedLocation(
  userId,
  name,
  address,
  description,
  defaultIcon,
  coords
) {
  const locationData = {
    id: uuidv4(), // Generate a UUID for the primary key
    user_id: userId, // Owner of the location
    name,
    address,
    description,
    default_icon: defaultIcon,
    coords: { lat: coords[0], lng: coords[1] }, // Store coordinates as JSONB
    utc_saved: Date.now(), // Current time in milliseconds
    created_by: userId, // Track the creator
    updated_by: userId, // Track the user who created the location
  };

  const { data, error } = await supabase
    .from('saved_locations')
    .insert(locationData)
    .select(); // Return the inserted row

  if (error) throw error;
  return data[0]; // Return the inserted row
}

/**
 * Fetch saved locations for a specific user.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @returns {Promise} - A promise that resolves with the saved locations.
 */
export async function getSavedLocations(userId) {
  const { data, error } = await supabase
    .from('saved_locations')
    .select('*')
    .eq('user_id', userId) // Filter by user_id
    .is('deleted_at', null) // Exclude soft-deleted locations
    .order('utc_saved', { ascending: false }); // Order by saved time (most recent first)

  if (error) throw error;
  return data;
}

/**
 * Fetch all saved locations (including those created by other users).
 * @returns {Promise} - A promise that resolves with all saved locations.
 */
export async function getAllSavedLocations() {
  const { data, error } = await supabase
    .from('saved_locations')
    .select('*')
    .is('deleted_at', null) // Exclude soft-deleted locations
    .order('utc_saved', { ascending: false }); // Order by saved time (most recent first)

  if (error) throw error;
  return data;
}

/**
 * Update a saved location.
 * @param {string} locationId - The ID of the location (UUID).
 * @param {Object} updatedData - The updated location data.
 * @param {string} updatedBy - The ID of the user updating the location.
 * @returns {Promise} - A promise that resolves when the location is updated.
 */
export async function updateSavedLocation(locationId, updatedData, updatedBy) {
  const { data, error } = await supabase
    .from('saved_locations')
    .update({
      ...updatedData,
      updated_by: updatedBy, // Track the user who updated the location
    })
    .eq('id', locationId)
    .select(); // Return the updated row

  if (error) throw error;
  return data[0]; // Return the updated row
}

/**
 * Soft delete a saved location.
 * @param {string} locationId - The ID of the location (UUID).
 * @param {string} userId - The ID of the user deleting the location.
 * @param {string} deletionReason - The reason for deletion.
 * @returns {Promise} - A promise that resolves when the location is soft-deleted.
 */
export async function softDeleteLocation(locationId, userId, deletionReason) {
  const { data, error } = await supabase
    .from('saved_locations')
    .update({
      deleted_at: Date.now(), // Timestamp for soft deletion
      deletion_reason: deletionReason, // Reason for deletion
      updated_by: userId, // Track the user who performed the deletion
    })
    .eq('id', locationId)
    .select(); // Return the updated row

  if (error) throw error;
  return data[0]; // Return the updated row
}

/**
 * Add a comment to a saved location.
 * @param {string} savedLocationId - The ID of the saved location.
 * @param {string} userId - The ID of the user adding the comment.
 * @param {string} comment - The comment text.
 * @returns {Promise} - A promise that resolves when the comment is added.
 */
export async function addComment(savedLocationId, userId, comment) {
  const { data, error } = await supabase
    .from('saved_location_comments')
    .insert({
      saved_location_id: savedLocationId,
      user_id: userId,
      comment,
      utc_created: Date.now(), // Use timestamp (bigint) instead of ISO string
    })
    .select() // Add this to return the inserted row
    .single(); // Add this to get the first row

  if (error) throw error;
  return data;
}

/**
 * Fetch comments for a saved location, including user details.
 * @param {string} savedLocationId - The ID of the saved location.
 * @returns {Promise} - A promise that resolves with the comments and user details.
 */
export async function getComments(savedLocationId) {
  try {
    // Step 1: Fetch comments from the saved_location_comments table
    const { data: comments, error: commentsError } = await supabase
      .from('saved_location_comments')
      .select('id, comment, utc_created, user_id')
      .eq('saved_location_id', savedLocationId)
      .order('utc_created', { ascending: true });

    if (commentsError) throw commentsError;

    // Step 2: Fetch user details for all unique user IDs in the comments
    const userIds = [...new Set(comments.map((comment) => comment.user_id))]; // Get unique user IDs
    const { data: users, error: usersError } = await supabase
      .from('active_users')
      .select('id, email, full_name')
      .in('id', userIds); // Fetch details for all users in the comments

    if (usersError) throw usersError;

    // Step 3: Map user details to comments
    const userMap = users.reduce((map, user) => {
      map[user.id] = user;
      return map;
    }, {});

    const commentsWithUsers = comments.map((comment) => ({
      id: comment.id,
      comment: comment.comment,
      utc_created: comment.utc_created,
      user_id: comment.user_id,
      user: userMap[comment.user_id] || null, // Attach user details or null if not found
    }));

    return commentsWithUsers;
  } catch (error) {
    console.error("Error fetching comments:", error);
    throw error;
  }
}

/**
 * Update a comment.
 * @param {string} commentId - The ID of the comment.
 * @param {string} userId - The ID of the user updating the comment.
 * @param {string} comment - The updated comment text.
 * @returns {Promise} - A promise that resolves when the comment is updated.
 */
export async function updateComment(commentId, userId, comment) {
  const { data, error } = await supabase
    .from('saved_location_comments')
    .update({ comment, utc_updated: Date.now() })
    .eq('id', commentId)
    .eq('user_id', userId); // Ensure the user can only update their own comments

  if (error) throw error;
  return data;
}

/**
 * Delete a comment.
 * @param {string} commentId - The ID of the comment.
 * @param {string} userId - The ID of the user deleting the comment.
 * @returns {Promise} - A promise that resolves when the comment is deleted.
 */
export async function deleteComment(commentId, userId) {
  const { data, error } = await supabase
    .from('saved_location_comments')
    .delete()
    .eq('id', commentId)
    .eq('user_id', userId); // Ensure the user can only delete their own comments

  if (error) throw error;
  return data;
}

/**
 * Fetch the count of comments for a saved location.
 * @param {string} savedLocationId - The ID of the saved location.
 * @returns {Promise<number>} - A promise that resolves with the count of comments.
 */
export async function getCommentCount(savedLocationId) {
  try {
    const { count, error } = await supabase
      .from('saved_location_comments')
      .select('*', { count: 'exact', head: true }) // Use count: 'exact' to get the count
      .eq('saved_location_id', savedLocationId); // Filter by location ID

    if (error) throw error;
    return count || 0; // Return 0 if no comments exist
  } catch (error) {
    console.error("Error fetching comment count:", error);
    throw error;
  }
}