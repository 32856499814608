import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line
} from 'recharts';

// Colors for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57'];

const InteractionSummary = ({ data }) => {
  if (!data) return null;

  // Format data for pie chart
  const interactionTypeData = data.mostCommonInteractions.map((item, index) => ({
    name: item.type,
    value: item.count,
    color: COLORS[index % COLORS.length]
  }));

  // Format data for component bar chart
  const componentData = data.mostActiveComponents.map((item, index) => ({
    name: item.component.length > 15 ? item.component.substring(0, 15) + '...' : item.component,
    count: item.count,
    fullName: item.component
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Interaction Summary
      </Typography>
      
      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Total Interactions
              </Typography>
              <Typography variant="h3">
                {data.totalInteractions.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Unique Interaction Types
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.interactionsByType).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Active Components
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.interactionsByComponent).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Active Users
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.interactionsByUser).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Charts */}
      <Grid container spacing={3}>
        {/* Interaction Types Pie Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Interaction Types Distribution
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <PieChart>
                <Pie
                  data={interactionTypeData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {interactionTypeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Most Active Components Bar Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Most Active Components
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={componentData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip 
                  formatter={(value, name, props) => [value.toLocaleString(), 'Interactions']}
                  labelFormatter={(value, props) => props.payload && props.payload.length > 0 ? props.payload[0].fullName : value}
                />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" name="Interactions" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Interactions Over Time Line Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Interactions Over Time
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <LineChart
                data={data.interactionsByDayArray}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="count" 
                  stroke="#8884d8" 
                  name="Interactions" 
                  activeDot={{ r: 8 }} 
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Most Active Users */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Most Active Users
            </Typography>
            <List>
              {data.mostActiveUsers.map((user, index) => (
                <React.Fragment key={user.userId}>
                  <ListItem>
                    <ListItemText 
                      primary={`User ID: ${user.userId}`} 
                      secondary={`${user.count.toLocaleString()} interactions`} 
                    />
                  </ListItem>
                  {index < data.mostActiveUsers.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        
        {/* Most Common Interactions */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Most Common Interactions
            </Typography>
            <List>
              {data.mostCommonInteractions.map((interaction, index) => (
                <React.Fragment key={interaction.type}>
                  <ListItem>
                    <ListItemText 
                      primary={interaction.type} 
                      secondary={`${interaction.count.toLocaleString()} occurrences`} 
                    />
                  </ListItem>
                  {index < data.mostCommonInteractions.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InteractionSummary;
