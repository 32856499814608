import React from 'react';
import { 
  Box, 
  Button, 
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText,
  Divider,
  Tooltip
} from '@mui/material';
import { 
  Print as PrintIcon, 
  FileDownload as FileDownloadIcon,
  TableChart as CsvIcon
} from '@mui/icons-material';

const InteractionReportExport = ({ 
  reportRef, 
  tabValue,
  summary,
  pageViewStats,
  buttonClickStats,
  formSubmissionStats,
  mapInteractionStats
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  // Handle menu open/close
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle printing using browser's print functionality
  const handlePrint = () => {
    // Use window.print() as a fallback since useReactToPrint is causing issues
    window.print();
    handleClose();
  };

  // Get the current report title based on tab value
  const getReportTitle = () => {
    switch (tabValue) {
      case 0: return 'Summary';
      case 1: return 'Page Views';
      case 2: return 'Button Clicks';
      case 3: return 'Form Submissions';
      case 4: return 'Map Interactions';
      default: return 'User Interactions';
    }
  };

  // Get the current report data based on tab value
  const getCurrentReportData = () => {
    switch (tabValue) {
      case 0: return summary;
      case 1: return pageViewStats;
      case 2: return buttonClickStats;
      case 3: return formSubmissionStats;
      case 4: return mapInteractionStats;
      default: return summary;
    }
  };

  // Export to CSV
  const exportToCSV = () => {
    const data = getCurrentReportData();
    if (!data) return;
    
    let csvContent = '';
    const reportTitle = getReportTitle();
    
    // Create CSV content based on the current tab
    if (tabValue === 0) { // Summary
      // Header
      csvContent = 'Category,Type,Count\n';
      
      // Interaction types
      if (data.mostCommonInteractions) {
        data.mostCommonInteractions.forEach(item => {
          csvContent += `Interaction Type,${item.type},${item.count}\n`;
        });
      }
      
      // Components
      if (data.mostActiveComponents) {
        data.mostActiveComponents.forEach(item => {
          csvContent += `Component,${item.component},${item.count}\n`;
        });
      }
      
      // Users
      if (data.mostActiveUsers) {
        data.mostActiveUsers.forEach(item => {
          csvContent += `User,${item.userId},${item.count}\n`;
        });
      }
      
      // Daily data
      if (data.interactionsByDayArray) {
        csvContent += '\nDate,Interactions\n';
        data.interactionsByDayArray.forEach(item => {
          csvContent += `${item.date},${item.count}\n`;
        });
      }
    } else if (tabValue === 1 && data.mostViewedPages) { // Page Views
      // Header
      csvContent = 'Page,Views\n';
      
      // Page views
      data.mostViewedPages.forEach(item => {
        csvContent += `${item.page},${item.count}\n`;
      });
      
      // Daily data
      if (data.pageViewsByDayArray) {
        csvContent += '\nDate,Views\n';
        data.pageViewsByDayArray.forEach(item => {
          csvContent += `${item.date},${item.count}\n`;
        });
      }
    } else if (tabValue === 2 && data.mostClickedButtons) { // Button Clicks
      // Header
      csvContent = 'Button,Clicks\n';
      
      // Button clicks
      data.mostClickedButtons.forEach(item => {
        csvContent += `${item.buttonId},${item.count}\n`;
      });
      
      // Component clicks
      if (data.buttonClicksByComponent) {
        csvContent += '\nComponent,Clicks\n';
        Object.entries(data.buttonClicksByComponent).forEach(([component, count]) => {
          csvContent += `${component},${count}\n`;
        });
      }
      
      // Daily data
      if (data.buttonClicksByDayArray) {
        csvContent += '\nDate,Clicks\n';
        data.buttonClicksByDayArray.forEach(item => {
          csvContent += `${item.date},${item.count}\n`;
        });
      }
    } else if (tabValue === 3 && data.mostSubmittedForms) { // Form Submissions
      // Header
      csvContent = 'Form,Submissions\n';
      
      // Form submissions
      data.mostSubmittedForms.forEach(item => {
        csvContent += `${item.formId},${item.count}\n`;
      });
      
      // Success/failure
      if (data.formSubmissionSuccess) {
        csvContent += '\nStatus,Count\n';
        csvContent += `Successful,${data.formSubmissionSuccess.successful}\n`;
        csvContent += `Failed,${data.formSubmissionSuccess.failed}\n`;
      }
      
      // Daily data
      if (data.formSubmissionsByDayArray) {
        csvContent += '\nDate,Submissions\n';
        data.formSubmissionsByDayArray.forEach(item => {
          csvContent += `${item.date},${item.count}\n`;
        });
      }
    } else if (tabValue === 4 && data.mapInteractionsByType) { // Map Interactions
      // Header
      csvContent = 'Interaction Type,Count\n';
      
      // Interaction types
      Object.entries(data.mapInteractionsByType).forEach(([type, count]) => {
        csvContent += `${type},${count}\n`;
      });
      
      // Map layers
      if (data.mostActiveMapLayers) {
        csvContent += '\nLayer,Interactions\n';
        data.mostActiveMapLayers.forEach(item => {
          csvContent += `${item.layer},${item.count}\n`;
        });
      }
      
      // Daily data
      if (data.mapInteractionsByDayArray) {
        csvContent += '\nDate,Interactions\n';
        data.mapInteractionsByDayArray.forEach(item => {
          csvContent += `${item.date},${item.count}\n`;
        });
      }
    }
    
    // Create and download the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${reportTitle.replace(/\s+/g, '_')}_Report.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    handleClose();
  };

  // Export to JSON
  const exportToJSON = () => {
    const data = getCurrentReportData();
    if (!data) return;
    
    const reportTitle = getReportTitle();
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${reportTitle.replace(/\s+/g, '_')}_Report.json`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    handleClose();
  };

  return (
    <Box>
      <Tooltip title="Export or print report">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          onClick={handleClick}
        >
          Export / Print
        </Button>
      </Tooltip>
      
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handlePrint}>
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Print Report</ListItemText>
        </MenuItem>
        
        <Divider />
        
        <MenuItem onClick={exportToCSV}>
          <ListItemIcon>
            <CsvIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export as CSV</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={exportToJSON}>
          <ListItemIcon>
            <FileDownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export as JSON</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default InteractionReportExport;
