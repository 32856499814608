import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@mui/material';

const CreateTodoList = ({
  isCreating,
  newListName,
  onNameChange,
  onCreateList,
  onCancel,
  onStartCreating,
  classes,
}) => (
  <div className={classes.createListContainer}>
    {isCreating ? (
      <>
        <TextField
          autoFocus
          placeholder="New List Name"
          value={newListName}
          onChange={(e) => onNameChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onCreateList();
            }
          }}
        />
        <Button onClick={onCreateList}>Create</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </>
    ) : (
      <Button onClick={onStartCreating}>Create New List</Button>
    )}
  </div>
);

CreateTodoList.propTypes = {
  isCreating: PropTypes.bool.isRequired,
  newListName: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onCreateList: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onStartCreating: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CreateTodoList; 