import React from 'react';
import { List, ListItem, ListItemText, Typography, Chip, Box, Tooltip, Divider, Paper } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import TimerIcon from '@mui/icons-material/Timer';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Helper function to format timestamp
const formatTime = (timestamp) => {
  if (!timestamp) return 'N/A';
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

// Helper function to format duration
const formatDuration = (durationMs) => {
  if (!durationMs) return '';
  
  const seconds = Math.floor(durationMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  
  if (hours > 0) {
    return `${hours}h ${minutes % 60}m`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s`;
  } else {
    return `${seconds}s`;
  }
};

// Helper function to parse work sessions from JSON string
const parseWorkSessions = (workSessionsJson) => {
  if (!workSessionsJson) return [];
  
  try {
    // Handle the case where workSessionsJson might already be an array
    if (Array.isArray(workSessionsJson)) {
      return workSessionsJson;
    }
    
    // Try to parse the JSON string
    const parsed = JSON.parse(workSessionsJson);
    
    // Ensure the result is an array
    if (Array.isArray(parsed)) {
      return parsed;
    } else {
      // Always return an empty array for non-array values
      // but log an error for debugging purposes
      console.error('Work sessions is not an array:', parsed);
      return [];
    }
  } catch (error) {
    console.error('Error parsing work sessions:', error);
    return [];
  }
};

// Helper function to get event color
const getEventColor = (eventName) => {
  switch(eventName) {
    case 'Clocked In':
      return '#8CC63F';
    case 'Clocked Out':
      return '#666666';
    case 'Started Break':
      return '#FBB03B';
    case 'Ended Break':
      return '#00416e';
    default:
      return '#666666';
  }
};

export default function TimeCardEntries({ timecards }) {
  // Sort timecards by event_date in descending order (newest first)
  const sortedTimecards = [...timecards].sort((a, b) => b.event_date - a.event_date);
  
  return (
    <Box sx={{ 
      border: '1px solid #e0e0e0', 
      borderRadius: '4px',
      backgroundColor: '#f9f9f9',
      padding: '8px',
      maxHeight: '300px', 
      overflow: 'auto'
    }}>
      <Typography variant="subtitle2" sx={{ 
        fontWeight: 'bold', 
        padding: '8px 16px',
        borderBottom: '1px solid #e0e0e0',
        color: '#00416e'
      }}>
        Activity
      </Typography>
      
      {sortedTimecards.length === 0 ? (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          padding: '30px 0',
          color: '#666'
        }}>
          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
            No timecard entries for this date
          </Typography>
        </Box>
      ) : (
        <List sx={{ padding: 0 }}>
          {sortedTimecards.map((tc, index) => (
            <Paper
              key={index}
              elevation={0}
              sx={{ 
                mb: 1,
                borderLeft: `4px solid ${getEventColor(tc.event_name)}`,
                backgroundColor: tc.needs_review ? 'rgba(255, 0, 0, 0.05)' : 'white',
                borderRadius: '4px',
                overflow: 'hidden'
              }}
            >
              <ListItem 
                divider={false}
                sx={{ 
                  flexDirection: 'column', 
                  alignItems: 'flex-start',
                  padding: '12px'
                }}
              >
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1" component="span" sx={{ fontWeight: 'bold' }}>
                      {tc.event_name}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, color: '#666' }} />
                    <Typography variant="body2" component="span" color="textSecondary">
                      {formatTime(tc.utc_start)}
                    </Typography>
                  </Box>
                </Box>
                
                {/* Display duration information */}
                {tc.event_duration_ms > 0 && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" color="textSecondary">
                      {tc.is_break ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <PauseCircleOutlineIcon fontSize="small" sx={{ mr: 0.5 }} />
                          Break duration: {formatDuration(tc.event_duration_ms)}
                        </Box>
                      ) : tc.event_name === 'Clocked Out' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TimerIcon fontSize="small" sx={{ mr: 0.5 }} />
                          Total work time: {formatDuration(tc.event_duration_ms)}
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TimerIcon fontSize="small" sx={{ mr: 0.5 }} />
                          Duration: {formatDuration(tc.event_duration_ms)}
                        </Box>
                      )}
                    </Typography>
                  </Box>
                )}
                
                {/* Display work time before break */}
                {tc.event_name === 'Started Break' && tc.work_time_before_break > 0 && (
                  <Typography variant="body2" color="textSecondary">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TimerIcon fontSize="small" sx={{ mr: 0.5 }} />
                      Work time before break: {formatDuration(tc.work_time_before_break)}
                    </Box>
                  </Typography>
                )}
                
                {/* Display total work time for clock out events */}
                {tc.event_name === 'Clocked Out' && tc.total_work_time > 0 && (
                  <Typography variant="body2" color="textSecondary">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TimerIcon fontSize="small" sx={{ mr: 0.5 }} />
                      Total work time (excluding breaks): {formatDuration(tc.total_work_time)}
                    </Box>
                  </Typography>
                )}
                
                {/* Display work sessions if available */}
                {tc.work_sessions && tc.event_name === 'Clocked Out' && (
                  <Box sx={{ mt: 1, width: '100%' }}>
                    <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                      Work Sessions:
                    </Typography>
                    {(() => {
                      const sessions = parseWorkSessions(tc.work_sessions);
                      if (!Array.isArray(sessions) || sessions.length === 0) {
                        return (
                          <Typography variant="body2" color="textSecondary" sx={{ ml: 2 }}>
                            No work sessions recorded
                          </Typography>
                        );
                      }
                      return sessions.map((session, idx) => (
                        <Typography key={idx} variant="body2" color="textSecondary" sx={{ ml: 2 }}>
                          Session {idx + 1}: {formatDuration(session?.duration || 0)}
                        </Typography>
                      ));
                    })()}
                  </Box>
                )}
                
                {/* Location information */}
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {tc.latitude && tc.longitude ? (
                    <Tooltip title={tc.address || "Address not available"} arrow>
                      <Chip 
                        icon={<LocationOnIcon fontSize="small" />} 
                        label={tc.address ? "Location: " + tc.address.substring(0, 20) + (tc.address.length > 20 ? "..." : "") : "Location recorded"} 
                        size="small" 
                        color="success" 
                        variant="outlined"
                        sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      />
                    </Tooltip>
                  ) : (
                    <Chip 
                      icon={<LocationOffIcon fontSize="small" />} 
                      label="No location data" 
                      size="small" 
                      color="error" 
                      variant="outlined"
                    />
                  )}
                </Box>
              </ListItem>
            </Paper>
          ))}
        </List>
      )}
    </Box>
  );
}
