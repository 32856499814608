import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line
} from 'recharts';

// Colors for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57'];

const FormSubmissionsReport = ({ data }) => {
  if (!data) return null;

  // Format data for form submissions bar chart
  const formSubmissionsData = data.mostSubmittedForms.map((item, index) => ({
    name: item.formId.length > 20 ? item.formId.substring(0, 20) + '...' : item.formId,
    count: item.count,
    fullName: item.formId
  }));

  // Format data for success/failure pie chart
  const successData = [
    { name: 'Successful', value: data.formSubmissionSuccess.successful, color: '#00C49F' },
    { name: 'Failed', value: data.formSubmissionSuccess.failed, color: '#FF8042' }
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Form Submissions Analysis
      </Typography>
      
      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Total Form Submissions
              </Typography>
              <Typography variant="h3">
                {data.totalFormSubmissions.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Unique Forms Submitted
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.formSubmissionsByForm).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Success Rate
              </Typography>
              <Typography variant="h3">
                {data.formSubmissionSuccess.successful > 0 ? 
                  `${Math.round((data.formSubmissionSuccess.successful / data.totalFormSubmissions) * 100)}%` : 
                  '0%'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Charts */}
      <Grid container spacing={3}>
        {/* Most Submitted Forms Bar Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Most Submitted Forms
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={formSubmissionsData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip 
                  formatter={(value, name, props) => [value.toLocaleString(), 'Submissions']}
                  labelFormatter={(value, props) => props.payload && props.payload.length > 0 ? props.payload[0].fullName : value}
                />
                <Legend />
                <Bar dataKey="count" fill="#FFBB28" name="Form Submissions" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Success/Failure Pie Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Form Submission Success Rate
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <PieChart>
                <Pie
                  data={successData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {successData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Form Submissions Over Time Line Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Form Submissions Over Time
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <LineChart
                data={data.formSubmissionsByDayArray}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="count" 
                  stroke="#FFBB28" 
                  name="Form Submissions" 
                  activeDot={{ r: 8 }} 
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Most Submitted Forms List */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Most Submitted Forms
            </Typography>
            <List>
              {data.mostSubmittedForms.map((form, index) => (
                <React.Fragment key={form.formId}>
                  <ListItem>
                    <ListItemText 
                      primary={form.formId} 
                      secondary={`${form.count.toLocaleString()} submissions`} 
                    />
                  </ListItem>
                  {index < data.mostSubmittedForms.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        
        {/* Form Submission Insights */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Form Submission Insights
            </Typography>
            <Typography variant="body1" paragraph>
              Form submissions represent completed user actions that often indicate conversions or important
              data collection points in the application.
            </Typography>
            <Typography variant="body1" paragraph>
              The success rate of form submissions is a critical metric for understanding user experience.
              High failure rates may indicate usability issues or technical problems that need to be addressed.
            </Typography>
            <Typography variant="body1">
              Consider the following insights:
            </Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Form Optimization" 
                  secondary="Identify forms with high failure rates and optimize them for better user experience." 
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText 
                  primary="Conversion Analysis" 
                  secondary="Track form submission trends to understand conversion patterns over time." 
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText 
                  primary="User Friction Points" 
                  secondary="Analyze which forms are submitted less frequently to identify potential user friction points." 
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormSubmissionsReport;
