import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  smallerText: {
    fontSize: '6px !important',
    color: 'grey !important',
    fontWeight: 'lighter !important',
  },
  switchRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    cursor: 'default !important',
  },
  createListContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  actionButtons: {
    display: 'flex',
    gap: '8px',
    marginRight: '80px',
  },
  listNameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  archivedItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '&::before': {
      content: '"Archived"',
      position: 'absolute',
      right: '16px',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '0.75rem',
      color: 'rgba(0, 0, 0, 0.6)',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      padding: '2px 6px',
      borderRadius: '4px',
    },
  },
})); 