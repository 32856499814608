import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line
} from 'recharts';

// Colors for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57'];

const MapInteractionsReport = ({ data }) => {
  if (!data) return null;

  // Format data for interaction types pie chart
  const interactionTypeData = Object.entries(data.mapInteractionsByType)
    .map(([type, count], index) => ({
      name: type,
      value: count,
      color: COLORS[index % COLORS.length]
    }));

  // Format data for map layers bar chart
  const mapLayersData = data.mostActiveMapLayers.map((item, index) => ({
    name: item.layer.length > 20 ? item.layer.substring(0, 20) + '...' : item.layer,
    count: item.count,
    fullName: item.layer
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Map Interactions Analysis
      </Typography>
      
      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Total Map Interactions
              </Typography>
              <Typography variant="h3">
                {data.totalMapInteractions.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Interaction Types
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.mapInteractionsByType).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Active Map Layers
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.mapInteractionsByLayer).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Charts */}
      <Grid container spacing={3}>
        {/* Interaction Types Pie Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Map Interaction Types
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <PieChart>
                <Pie
                  data={interactionTypeData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {interactionTypeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Map Interactions Over Time Line Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Map Interactions Over Time
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <LineChart
                data={data.mapInteractionsByDayArray}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="count" 
                  stroke="#8884d8" 
                  name="Map Interactions" 
                  activeDot={{ r: 8 }} 
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Most Active Map Layers Bar Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Most Active Map Layers
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={mapLayersData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip 
                  formatter={(value, name, props) => [value.toLocaleString(), 'Interactions']}
                  labelFormatter={(value, props) => props.payload && props.payload.length > 0 ? props.payload[0].fullName : value}
                />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" name="Layer Interactions" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Most Active Map Layers List */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Most Active Map Layers
            </Typography>
            <List>
              {data.mostActiveMapLayers.map((layer, index) => (
                <React.Fragment key={layer.layer}>
                  <ListItem>
                    <ListItemText 
                      primary={layer.layer} 
                      secondary={`${layer.count.toLocaleString()} interactions`} 
                    />
                  </ListItem>
                  {index < data.mostActiveMapLayers.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        
        {/* Map Interaction Insights */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Map Interaction Insights
            </Typography>
            <Typography variant="body1" paragraph>
              Map interactions provide valuable insights into how users engage with geographic data
              and spatial features in the application.
            </Typography>
            <Typography variant="body1" paragraph>
              Understanding which map layers receive the most attention can help prioritize data quality
              and visual presentation for those layers.
            </Typography>
            <Typography variant="body1">
              Consider the following insights:
            </Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Layer Visibility" 
                  secondary="Ensure the most frequently accessed layers are visible by default or easily accessible." 
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText 
                  primary="Data Quality Focus" 
                  secondary="Prioritize data quality and updates for the most actively used map layers." 
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText 
                  primary="User Interaction Patterns" 
                  secondary="Analyze how users interact with the map (pan, zoom, click) to optimize the map interface." 
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MapInteractionsReport;
