// Simple event system for TimeTrack status updates
const listeners = new Set();

// Status constants
export const STATUS = {
  CLOCKED_IN: '#8CC63F',  // Green
  CLOCKED_OUT: '#FF1D25', // Red
  ON_BREAK: '#FBB03B',    // Orange
  DEFAULT: '#231f20'      // Black
};

// Subscribe to status changes
export const subscribeToStatusChange = (callback) => {
  listeners.add(callback);
  return () => {
    listeners.delete(callback);
  };
};

// Publish status changes
export const publishStatusChange = (status) => {
  listeners.forEach(callback => callback(status));
};
