import { useState, useEffect, useCallback } from 'react';
import { 
  createTimeTrackEntry, 
  getTimeTrackEntries, 
  updateTimeTrackEntry, 
  deleteTimeTrackEntry, 
  syncOfflineTimeTrackEntries,
  isOnline,
  createOfflineBreakEndEntry,
  getTimeTrackSettings,
  getMostRecentClockInTimestamp
} from '../../api/timetrack';
import useCompressedStickyState from './useCompressedStickyState';

/**
 * Custom hook for managing time tracking data with offline support.
 * @param {string} userId - The ID of the user.
 * @returns {Object} - The time tracking state and functions.
 */
export function useTimeTrack(userId) {
  //console.log('useTimeTrack hook initialized with userId:', userId);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [syncStatus, setSyncStatus] = useState('idle'); // 'idle', 'syncing', 'success', 'error'
  const [currentDate, setCurrentDate] = useState(new Date());
  
  // Force loading to false after 5 seconds to prevent infinite loading
  useEffect(() => {
    //console.log('Setting up loading timeout');
    const timeout = setTimeout(() => {
      if (loading) {
        //console.log('Forcing loading to false after timeout');
        setLoading(false);
      }
    }, 5000);
    
    return () => clearTimeout(timeout);
  }, []); // Empty dependency array to run only once
  
  // Use compressed local storage for caching time entries
  const [cachedEntries, setCachedEntries] = useCompressedStickyState([], `timetrack_entries_${userId}`);
  
  // Use local storage for tracking clock in/out state
  const [clockState, setClockState] = useCompressedStickyState({
    clockedIn: false,
    clockInTime: null,
    lastBreakTime: null,
    lastMealTime: null,
    shiftDuration: 0,
    workSessions: []
  }, `timetrack_clock_state_${userId}`);
  
  // Check for backup clock state on initialization only
  useEffect(() => {
    if (!userId) return;
    
    try {
      // Check if there's a backup clock state in localStorage
      const backupClockState = localStorage.getItem(`timetrack_clock_state_backup_${userId}`);
      
      if (backupClockState) {
        const parsedBackupState = JSON.parse(backupClockState);
        
        // Only restore from backup on initial load, not on every clockState change
        // This prevents infinite loops with the entries-based state update
        console.log('Checking backup clock state on initialization');
        if (parsedBackupState.clockedIn) {
          console.log('Restoring clock state from backup', parsedBackupState);
          setClockState(parsedBackupState);
        }
      }
    } catch (err) {
      console.error('Error checking backup clock state:', err);
    }
    // Only run this effect once on initialization, not when clockState changes
  }, [userId, setClockState]); // Removed clockState.clockedIn from dependencies
  
  // Always ensure the backup clock state is up to date
  useEffect(() => {
    if (!userId) return;
    
    // If clocked in, always keep the backup up to date
    if (clockState.clockedIn) {
      //console.log('Updating clock state backup');
      localStorage.setItem(`timetrack_clock_state_backup_${userId}`, JSON.stringify(clockState));
    } else {
      // If not clocked in, remove the backup
      localStorage.removeItem(`timetrack_clock_state_backup_${userId}`);
    }
  }, [userId, clockState]);
  
  // Fetch time track entries for the current date
  const fetchEntries = useCallback(async () => {
    if (!userId) {
      setLoading(false); // Exit loading state if no user ID
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Calculate start and end of the current date
      const startOfDay = new Date(currentDate);
      startOfDay.setHours(0, 0, 0, 0);
      
      const endOfDay = new Date(currentDate);
      endOfDay.setHours(23, 59, 59, 999);
      
      // Check if we're in offline mode or if the table exists
      if (!isOnline()) {
        console.log('Device is offline, using cached entries');
        // Use cached entries if available, but filter by date
        if (cachedEntries.length > 0) {
          // Filter cached entries to only include those for the current date
          const filteredEntries = cachedEntries.filter(entry => {
            const entryDate = new Date(entry.timestamp);
            return (
              entryDate.getDate() === startOfDay.getDate() &&
              entryDate.getMonth() === startOfDay.getMonth() &&
              entryDate.getFullYear() === startOfDay.getFullYear()
            );
          });
          console.log('Filtered cached entries for date:', startOfDay, filteredEntries);
          setEntries(filteredEntries);
        } else {
          setEntries([]);
        }
        setLoading(false); // Exit loading state immediately in offline mode
      } else {
        try {
          const fetchedEntries = await getTimeTrackEntries(userId, {
            startDate: startOfDay.getTime(),
            endDate: endOfDay.getTime()
          });
          
          setEntries(fetchedEntries);
          setCachedEntries(prev => {
            // Merge with existing cached entries to ensure we don't lose offline entries
            const existingEntries = prev.filter(entry => {
              const entryDate = new Date(entry.timestamp);
              return !(
                entryDate.getDate() === startOfDay.getDate() &&
                entryDate.getMonth() === startOfDay.getMonth() &&
                entryDate.getFullYear() === startOfDay.getFullYear()
              );
            });
            return [...existingEntries, ...fetchedEntries];
          });
          setLoading(false); // Exit loading state after successful fetch
        } catch (fetchError) {
          console.error('Error fetching time track entries:', fetchError);
          
          // If the error is related to the table not existing, we can still use the component in offline mode
          if (fetchError.message && (
              fetchError.message.includes('relation "time_track" does not exist') || 
              fetchError.message.includes('does not exist in schema')
            )) {
            console.log('time_track table does not exist, using offline mode');
            // Use cached entries if available, but filter by date
            if (cachedEntries.length > 0) {
              // Filter cached entries to only include those for the current date
              const filteredEntries = cachedEntries.filter(entry => {
                const entryDate = new Date(entry.timestamp);
                return (
                  entryDate.getDate() === startOfDay.getDate() &&
                  entryDate.getMonth() === startOfDay.getMonth() &&
                  entryDate.getFullYear() === startOfDay.getFullYear()
                );
              });
              console.log('Filtered cached entries for date (error case):', startOfDay, filteredEntries);
              setEntries(filteredEntries);
            } else {
              setEntries([]);
            }
            setLoading(false); // Exit loading state immediately if table doesn't exist
          } else {
            // For other errors, set the error state
            setError(fetchError);
            setLoading(false); // Exit loading state
          }
        }
      }
    } catch (err) {
      console.error('Unexpected error in fetchEntries:', err);
      setError(err);
      
      // Use cached entries if available, but filter by date
      if (cachedEntries.length > 0) {
        // Calculate start and end of the current date
        const startOfDay = new Date(currentDate);
        startOfDay.setHours(0, 0, 0, 0);
        
        // Filter cached entries to only include those for the current date
        const filteredEntries = cachedEntries.filter(entry => {
          const entryDate = new Date(entry.timestamp);
          return (
            entryDate.getDate() === startOfDay.getDate() &&
            entryDate.getMonth() === startOfDay.getMonth() &&
            entryDate.getFullYear() === startOfDay.getFullYear()
          );
        });
        console.log('Filtered cached entries for date (unexpected error case):', startOfDay, filteredEntries);
        setEntries(filteredEntries);
      } else {
        setEntries([]);
      }
      setLoading(false); // Exit loading state
    }
  }, [userId, currentDate, setCachedEntries, cachedEntries]);
  
  // Sync offline entries when the device comes online
  const syncOfflineEntries = useCallback(async () => {
    if (!userId) {
      console.error('Cannot sync offline entries: userId is undefined');
      setSyncStatus('error');
      setTimeout(() => setSyncStatus('idle'), 3000);
      return;
    }
    
    if (!isOnline()) {
      console.error('Cannot sync offline entries: device is offline');
      setSyncStatus('error');
      setTimeout(() => setSyncStatus('idle'), 3000);
      return;
    }
    
    console.log('Starting sync of offline entries for user:', userId);
    setSyncStatus('syncing');
    
    try {
      // Get all offline entries from localStorage
      const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
      console.log('Found offline entries:', offlineEntries.length);
      
      // Filter entries for this user
      const userOfflineEntries = offlineEntries.filter(entry => entry.user_id === userId);
      console.log('Offline entries for this user:', userOfflineEntries.length);
      
      if (userOfflineEntries.length === 0) {
        console.log('No offline entries to sync');
        setSyncStatus('success');
        setTimeout(() => setSyncStatus('idle'), 3000);
        return;
      }
      
      // Use the SDK function to sync entries
      const syncedEntries = await syncOfflineTimeTrackEntries(userId);
      console.log('Synced entries:', syncedEntries.length);
      
      if (syncedEntries.length > 0) {
        console.log('Successfully synced entries:', syncedEntries);
        
        // Refresh entries after syncing
        await fetchEntries();
        setSyncStatus('success');
        
        // Reset sync status after a delay
        setTimeout(() => {
          setSyncStatus('idle');
        }, 3000);
      } else {
        console.log('No entries were synced');
        setSyncStatus('idle');
      }
    } catch (err) {
      console.error('Error syncing offline entries:', err);
      setSyncStatus('error');
      
      // Reset sync status after a delay
      setTimeout(() => {
        setSyncStatus('idle');
      }, 3000);
    }
  }, [userId, fetchEntries]);
  
  // Listen for online/offline events
  useEffect(() => {
    const handleOnline = () => {
      console.log('Device is online, syncing offline entries...');
      syncOfflineEntries();
    };
    
    const handleOffline = () => {
      console.log('Device is offline');
      // If the user is clocked in, make sure we have a backup of the clock state
      if (clockState.clockedIn) {
        localStorage.setItem(`timetrack_clock_state_backup_${userId}`, JSON.stringify(clockState));
      }
    };
    
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    
    // Check online status immediately
    if (!navigator.onLine && clockState.clockedIn) {
      console.log('Device is offline on initialization, backing up clock state');
      localStorage.setItem(`timetrack_clock_state_backup_${userId}`, JSON.stringify(clockState));
    }
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [syncOfflineEntries, clockState, userId]);
  
  // Check for breaks that should have ended while the app was closed
  useEffect(() => {
    if (!userId) return;
    
    // Function to check for pending breaks
    const checkPendingBreaks = () => {
      const now = new Date().getTime();
      const breakKeys = Object.keys(localStorage).filter(key => key.startsWith(`break_${userId}_`));
      
      breakKeys.forEach(key => {
        try {
          const breakInfo = JSON.parse(localStorage.getItem(key));
          
          // If the break should have ended by now
          if (breakInfo && breakInfo.endTime < now) {
            console.log('Found break that should have ended:', breakInfo);
            
            // Create a break-end entry
            const offlineEndEntry = createOfflineBreakEndEntry(
              userId,
              breakInfo.breakType,
              breakInfo.locationData
            );
            
            // Add the entry to the UI only if it belongs to the current date
            const entryDate = new Date(offlineEndEntry.timestamp);
            const currentDateStart = new Date(currentDate);
            currentDateStart.setHours(0, 0, 0, 0);
            const currentDateEnd = new Date(currentDate);
            currentDateEnd.setHours(23, 59, 59, 999);
            
            if (entryDate >= currentDateStart && entryDate <= currentDateEnd) {
              setEntries(prev => [offlineEndEntry, ...prev]);
            }
            
            // Remove the break info from localStorage
            localStorage.removeItem(key);
            
            // Clear any timeout that might still be active
            const timeoutKey = key.replace('break_', 'breakTimeout_');
            const timeoutId = localStorage.getItem(timeoutKey);
            if (timeoutId) {
              clearTimeout(parseInt(timeoutId));
              localStorage.removeItem(timeoutKey);
            }
          }
        } catch (err) {
          console.error('Error processing pending break:', err);
        }
      });
    };
    
    // Check immediately when the component mounts
    checkPendingBreaks();
    
    // Also check when the app comes online
    const handleOnline = () => {
      checkPendingBreaks();
    };
    
    window.addEventListener('online', handleOnline);
    
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, [userId, currentDate]);
  
  // Fetch entries when the component mounts or when the date changes
  useEffect(() => {
    //console.log('Fetching entries...');
    
    // Use a local variable to track if the component is still mounted
    let isMounted = true;
    
    // Wrap fetchEntries in an async function
    const doFetchEntries = async () => {
      try {
        await fetchEntries();
      } catch (error) {
        console.error('Error fetching entries:', error);
      } finally {
        // Only update state if the component is still mounted
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    
    doFetchEntries();
    
    // Force loading to false after 3 seconds as a fallback
    const timeout = setTimeout(() => {
      if (isMounted) {
        console.log('Forcing loading to false after fetch timeout');
        setLoading(false);
      }
    }, 3000);
    
    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
  }, [currentDate, userId]); // Only depend on currentDate and userId, not fetchEntries
  
  // Update clock state based on entries when they change
  useEffect(() => {
    if (loading) return;
    
    // Track if we've already processed this set of entries to prevent multiple updates
    const entriesKey = entries.map(e => `${e.id || e.local_id}-${e.timestamp}`).join('|');
    const processedEntriesKey = `processed_entries_${userId}`;
    const lastProcessedEntries = localStorage.getItem(processedEntriesKey);
    
    // Skip processing if we've already processed these exact entries
    if (lastProcessedEntries === entriesKey) {
      return;
    }
    
    // If there are no entries but we have a saved clock state that shows clocked in,
    // we should preserve that state (especially important for offline mode)
    if (entries.length === 0) {
      if (clockState.clockedIn) {
        console.log('No entries but clock state shows clocked in, preserving state');
        // Mark as processed
        localStorage.setItem(processedEntriesKey, entriesKey);
        return;
      }
    }
    
    // Sort entries by timestamp in descending order (newest first)
    const sortedEntries = [...entries].sort((a, b) => b.timestamp - a.timestamp);
    
    // Find the most recent clock-in, clock-out, or break entry
    const lastClockInEntry = sortedEntries.find(entry => entry.status === 'clock-in');
    const lastClockOutEntry = sortedEntries.find(entry => entry.status === 'clock-out');
    const lastBreakEntry = sortedEntries.find(entry => entry.status === 'break');
    const lastBreakEndEntry = sortedEntries.find(entry => entry.status === 'break-end');
    
    // Determine if the user is currently clocked in
    const isCurrentlyClocked = lastClockInEntry && (!lastClockOutEntry || lastClockInEntry.timestamp > lastClockOutEntry.timestamp);
    
    // If the user is clocked in, update the clock state
    if (isCurrentlyClocked) {
      const now = new Date().getTime();
      const clockInTime = lastClockInEntry.timestamp;
      
      // Calculate shift duration in minutes
      let shiftDuration = Math.floor((now - clockInTime) / (60 * 1000));
      
      // Adjust for breaks if any
      if (lastBreakEntry && lastBreakEndEntry && lastBreakEntry.timestamp > lastClockInEntry.timestamp) {
        const breakDuration = lastBreakEntry.duration || 0;
        shiftDuration -= breakDuration;
      }
      
      // Update clock state
      setClockState(prev => ({
        ...prev,
        clockedIn: true,
        clockInTime,
        shiftDuration: Math.max(0, shiftDuration) // Ensure non-negative duration
      }));
    } else if (!isCurrentlyClocked && clockState.clockedIn) {
      // This is a special case for when entries show the user is not clocked in,
      // but the saved clock state shows they are. This can happen when:
      // 1. The user clocks in while offline
      // 2. The dialog is closed
      // 3. The dialog is reopened, but the offline entries aren't properly loaded
      
      // Check if we're offline - if so, we should trust the clock state over the entries
      if (!isOnline()) {
        console.log('Offline mode: Preserving clocked-in state from localStorage');
        // Keep the existing clock state
      } else {
        // We're online, so the entries should be accurate
        // But first check if there's a backup that might be more recent than our entries
        const backupClockState = localStorage.getItem(`timetrack_clock_state_backup_${userId}`);
        
        if (backupClockState) {
          try {
            const parsedBackupState = JSON.parse(backupClockState);
            const backupTimestamp = parsedBackupState.clockInTime;
            
            // If the backup is more recent than our last entry, trust the backup
            const mostRecentEntryTimestamp = sortedEntries.length > 0 ? sortedEntries[0].timestamp : 0;
            
            if (backupTimestamp > mostRecentEntryTimestamp) {
              console.log('Backup is more recent than entries, preserving backup state');
              // Mark as processed to prevent infinite loop
              localStorage.setItem(processedEntriesKey, entriesKey);
              return;
            }
            
            // If we get here, the entries are more recent, so remove the backup
            console.log('Entries are more recent than backup, removing backup');
            localStorage.removeItem(`timetrack_clock_state_backup_${userId}`);
          } catch (err) {
            console.error('Error parsing backup clock state:', err);
            // Remove corrupted backup
            localStorage.removeItem(`timetrack_clock_state_backup_${userId}`);
          }
        }
        
        console.log('Online mode: Updating clock state to match entries (not clocked in)');
        setClockState(prev => ({
          ...prev,
          clockedIn: false,
          clockInTime: null,
          lastBreakTime: null,
          lastMealTime: null,
          shiftDuration: 0
        }));
      }
    }
    
    // Mark these entries as processed
    localStorage.setItem(processedEntriesKey, entriesKey);
  }, [entries, loading, setClockState, clockState.clockedIn, userId]);
  
  // Clock in function
  const clockIn = async (locationData = null) => {
    if (clockState.clockedIn) return;
    
    const now = new Date();
    const timeEntry = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    
    const newClockState = {
      clockedIn: true,
      clockInTime: now.getTime(),
      lastBreakTime: null,
      lastMealTime: null,
      shiftDuration: 0,
      workSessions: [{
        start: now.getTime(),
        end: null,
        duration: 0
      }]
    };
    
    // Update clock state immediately to ensure it's saved even if the component unmounts
    setClockState(newClockState);
    
    // Store a backup of the clock state in localStorage without compression
    // This provides a fallback in case the compressed state gets corrupted
    localStorage.setItem(`timetrack_clock_state_backup_${userId}`, JSON.stringify(newClockState));
    
    try {
      // Get device ID from localStorage if available
      const deviceInfo = localStorage.getItem('deviceid');
      const deviceid = deviceInfo ? JSON.parse(deviceInfo) : null;
      
      const entry = await createTimeTrackEntry({
        userId,
        status: 'clock-in',
        timestamp: now.getTime(),
        timeEntry,
        workSessions: newClockState.workSessions,
        latitude: locationData?.latitude,
        longitude: locationData?.longitude,
        address: locationData?.address, // Include address for storage in the database
        device_id: deviceid?.dvc_hash // Include device ID for tracking which device was used
      });
      
      // Update entries state
      setEntries(prev => [entry, ...prev]);
      
      // Also update cached entries to ensure they're available when offline
      setCachedEntries(prev => {
        const newEntries = [entry, ...prev];
        return newEntries;
      });
    } catch (err) {
      console.error('Error clocking in:', err);
      setError(err);
      
      // Still update the UI even if the API call fails
      const offlineEntry = {
        local_id: `local_${Date.now()}`,
        user_id: userId,
        status: 'clock-in',
        timestamp: now.getTime(),
        time_entry: timeEntry,
        work_sessions: JSON.stringify(newClockState.workSessions),
        latitude: locationData?.latitude,
        longitude: locationData?.longitude,
        address: locationData?.address, // Include address for storage in the database
        synced: false
      };
      
      // Update entries state
      setEntries(prev => [offlineEntry, ...prev]);
      
      // Also update cached entries to ensure they're available when offline
      setCachedEntries(prev => {
        const newEntries = [offlineEntry, ...prev];
        return newEntries;
      });
    }
  };
  
  // Clock out function
  const clockOut = async (isAuto = false, locationData = null) => {
    if (!clockState.clockedIn) return;
    
    const now = new Date();
    const timeEntry = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    
    // Check if there are any active breaks and end them first
    const endActiveBreaks = async () => {
      // Get all entries since clock-in to find active breaks
      try {
        const startDate = new Date(clockState.clockInTime);
        startDate.setHours(0, 0, 0, 0);
        
        const endDate = new Date(now);
        endDate.setHours(23, 59, 59, 999);
        
        // Get all entries between clock-in time and now
        const allEntries = await getTimeTrackEntries(userId, {
          startDate: startDate.getTime(),
          endDate: endDate.getTime()
        });
        
        // First, sort entries by timestamp to ensure proper order
        const sortedEntries = [...allEntries].sort((a, b) => a.timestamp - b.timestamp);
        
        // Filter entries to only include those after clock-in
        const entriesAfterClockIn = sortedEntries.filter(entry => entry.timestamp >= clockState.clockInTime);
        
        // Track active breaks
        const activeBreaks = [];
        
        // Process entries in chronological order to find active breaks
        for (let i = 0; i < entriesAfterClockIn.length; i++) {
          const entry = entriesAfterClockIn[i];
          
          if (entry.status === 'break') {
            // Start a new break
            activeBreaks.push({
              id: entry.id || entry.local_id,
              start: entry.timestamp,
              type: entry.break_type
            });
          } else if (entry.status === 'break-end') {
            // Find the corresponding break start
            // First try to match by break_id if available
            let matchingBreakIndex = -1;
            if (entry.break_id) {
              matchingBreakIndex = activeBreaks.findIndex(b => b.id === entry.break_id);
            }
            
            // If no match by ID, try to match by type
            if (matchingBreakIndex === -1) {
              matchingBreakIndex = activeBreaks.findIndex(b => b.type === entry.break_type);
            }
            
            if (matchingBreakIndex !== -1) {
              // Remove this break from active breaks
              activeBreaks.splice(matchingBreakIndex, 1);
            }
          }
        }
        
        // End any active breaks
        for (const activeBreak of activeBreaks) {
          console.log(`Ending active break of type ${activeBreak.type} before clock-out`);
          
          // Get device ID from localStorage if available
          const deviceInfo = localStorage.getItem('deviceid');
          const deviceid = deviceInfo ? JSON.parse(deviceInfo) : null;
          
          try {
            // Create a break-end entry
            const breakEndEntry = await createTimeTrackEntry({
              userId,
              status: 'break-end',
              timestamp: now.getTime() - 1000, // 1 second before clock-out
              timeEntry,
              breakType: activeBreak.type,
              break_id: activeBreak.id,
              latitude: locationData?.latitude,
              longitude: locationData?.longitude,
              address: locationData?.address,
              device_id: deviceid?.dvc_hash,
              notes: 'Automatically ended due to clock-out'
            });
            
            // Update entries state
            setEntries(prev => [breakEndEntry, ...prev]);
            
            // Also update cached entries
            setCachedEntries(prev => {
              const newEntries = [breakEndEntry, ...prev];
              return newEntries;
            });
            
            // Remove any break info from localStorage
            const breakKeys = Object.keys(localStorage).filter(key => 
              key.startsWith(`break_${userId}_`) && 
              localStorage.getItem(key).includes(activeBreak.id)
            );
            
            breakKeys.forEach(key => {
              localStorage.removeItem(key);
              
              // Also remove any associated timeout
              const timeoutKey = key.replace('break_', 'breakTimeout_');
              const timeoutId = localStorage.getItem(timeoutKey);
              if (timeoutId) {
                clearTimeout(parseInt(timeoutId));
                localStorage.removeItem(timeoutKey);
              }
            });
          } catch (err) {
            console.error('Error ending active break before clock-out:', err);
            
            // Create offline break-end entry if API call fails
            const offlineBreakEndEntry = createOfflineBreakEndEntry(
              userId,
              activeBreak.type,
              locationData,
              'Automatically ended due to clock-out',
              activeBreak.id
            );
            
            // Update entries state
            setEntries(prev => [offlineBreakEndEntry, ...prev]);
            
            // Also update cached entries
            setCachedEntries(prev => {
              const newEntries = [offlineBreakEndEntry, ...prev];
              return newEntries;
            });
          }
        }
      } catch (error) {
        console.error('Error ending active breaks before clock-out:', error);
      }
    };
    
    // End any active breaks before clocking out
    await endActiveBreaks();
    
    // Get all entries since clock-in to calculate total work time excluding unpaid breaks
    let totalWorkTime = 0;
    let unpaidBreakTime = 0;
    
    try {
      // Get all entries since clock-in
      const startDate = new Date(clockState.clockInTime);
      startDate.setHours(0, 0, 0, 0);
      
      const endDate = new Date(now);
      endDate.setHours(23, 59, 59, 999);
      
      // Get all entries between clock-in time and now
      const allEntries = await getTimeTrackEntries(userId, {
        startDate: startDate.getTime(),
        endDate: endDate.getTime()
      });
      
      // Use the calculateWorkTime function from timetrack.js
      const calculateWorkTimeSinceClockIn = (clockInTime, currentTime, entries) => {
        if (!clockInTime) return 0;
        
        // Calculate total elapsed time since clock-in
        const totalElapsedMs = currentTime - clockInTime;
        
        // Find all break periods
        const breakPeriods = [];
        
        // First, sort entries by timestamp to ensure proper order
        const sortedEntries = [...entries].sort((a, b) => a.timestamp - b.timestamp);
        
        // Filter entries to only include those after clock-in
        const entriesAfterClockIn = sortedEntries.filter(entry => entry.timestamp >= clockInTime);
        
        // Track active breaks
        let activeBreaks = [];
        
        // Process entries in chronological order
        for (let i = 0; i < entriesAfterClockIn.length; i++) {
          const entry = entriesAfterClockIn[i];
          
          if (entry.status === 'break') {
            // Start a new break
            activeBreaks.push({
              id: entry.id || entry.local_id,
              start: entry.timestamp,
              type: entry.break_type
            });
          } else if (entry.status === 'break-end') {
            // Find the corresponding break start
            // First try to match by break_id if available
            let matchingBreakIndex = -1;
            if (entry.break_id) {
              matchingBreakIndex = activeBreaks.findIndex(b => b.id === entry.break_id);
            }
            
            // If no match by ID, try to match by type
            if (matchingBreakIndex === -1) {
              matchingBreakIndex = activeBreaks.findIndex(b => b.type === entry.break_type);
            }
            
            if (matchingBreakIndex !== -1) {
              const matchingBreak = activeBreaks[matchingBreakIndex];
              
              // Calculate break duration
              const breakDuration = entry.timestamp - matchingBreak.start;
              
              // Determine if break is paid (simplified logic)
              const isPaid = matchingBreak.type === 'rest';
              
              // Add to break periods
              breakPeriods.push({
                start: matchingBreak.start,
                end: entry.timestamp,
                duration: breakDuration,
                type: matchingBreak.type,
                paid: isPaid
              });
              
              // Remove this break from active breaks
              activeBreaks.splice(matchingBreakIndex, 1);
            }
          }
        }
        
        // Handle any still-active breaks
        for (const activeBreak of activeBreaks) {
          const activeDuration = currentTime - activeBreak.start;
          
          // Determine if break is paid (simplified logic)
          const isPaid = activeBreak.type === 'rest';
          
          breakPeriods.push({
            start: activeBreak.start,
            end: currentTime,
            duration: activeDuration,
            type: activeBreak.type,
            active: true,
            paid: isPaid
          });
        }
        
        // Calculate total unpaid break time (only subtract unpaid breaks)
        const totalUnpaidBreakTimeMs = breakPeriods
          .filter(period => !period.paid) // Only include unpaid breaks
          .reduce((total, period) => total + period.duration, 0);
        
        // Calculate work time (total elapsed time minus unpaid break time)
        const workTimeMs = totalElapsedMs - totalUnpaidBreakTimeMs;
        
        return Math.max(0, workTimeMs); // Ensure non-negative duration
      };
      
      // Calculate total work time excluding unpaid breaks
      totalWorkTime = calculateWorkTimeSinceClockIn(clockState.clockInTime, now.getTime(), allEntries);
      
      // Calculate unpaid break time
      const totalElapsedTime = now.getTime() - clockState.clockInTime;
      unpaidBreakTime = totalElapsedTime - totalWorkTime;
      
      console.log(`Total elapsed time: ${Math.floor(totalElapsedTime / (60 * 1000))} minutes`);
      console.log(`Total work time (excluding unpaid breaks): ${Math.floor(totalWorkTime / (60 * 1000))} minutes`);
      console.log(`Total unpaid break time: ${Math.floor(unpaidBreakTime / (60 * 1000))} minutes`);
    } catch (error) {
      console.error('Error calculating total work time:', error);
      // Fallback to using shiftDuration if there's an error
      totalWorkTime = clockState.shiftDuration * 60 * 1000; // Convert minutes to milliseconds
    }
    
    // Calculate overtime minutes based on settings
    let overtimeMinutes = 0;
    try {
      // Get time track settings
      const settings = await getTimeTrackSettings(userId);
      
      if (settings) {
        // Use regular_work_hours if available, otherwise calculate from start/end times
        if (settings.regular_work_hours) {
          // Convert regular work hours to minutes
          const regularWorkMinutes = settings.regular_work_hours * 60;
          
          // Calculate overtime (actual - regular work hours)
          const actualWorkMinutes = Math.floor(totalWorkTime / (60 * 1000));
          overtimeMinutes = Math.max(0, actualWorkMinutes - regularWorkMinutes);
          console.log(`Regular work hours: ${settings.regular_work_hours} hours (${regularWorkMinutes} minutes), Actual duration: ${actualWorkMinutes} minutes, Overtime: ${overtimeMinutes} minutes`);
        } else {
          // Fallback to calculating from default start and end times
          const defaultStartTime = settings.default_start_time;
          const defaultEndTime = settings.default_end_time;
          
          // Calculate planned work duration in minutes
          const startParts = defaultStartTime.split(':').map(Number);
          const endParts = defaultEndTime.split(':').map(Number);
          
          const startMinutes = startParts[0] * 60 + startParts[1];
          const endMinutes = endParts[0] * 60 + endParts[1];
          
          // Calculate planned work duration in minutes
          let plannedDuration = endMinutes - startMinutes;
          if (plannedDuration < 0) {
            plannedDuration += 24 * 60; // Add 24 hours if end time is on the next day
          }
          
          // Calculate overtime (actual - planned)
          const actualWorkMinutes = Math.floor(totalWorkTime / (60 * 1000));
          overtimeMinutes = Math.max(0, actualWorkMinutes - plannedDuration);
          console.log(`Planned duration: ${plannedDuration} minutes, Actual duration: ${actualWorkMinutes} minutes, Overtime: ${overtimeMinutes} minutes`);
        }
      }
    } catch (error) {
      console.error('Error calculating overtime:', error);
    }
    
    // Update the last work session
    const updatedWorkSessions = [...clockState.workSessions];
    if (updatedWorkSessions.length > 0) {
      const lastSession = updatedWorkSessions[updatedWorkSessions.length - 1];
      if (lastSession && !lastSession.end) {
        lastSession.end = now.getTime();
        lastSession.duration = lastSession.end - lastSession.start;
      }
    }
    
    const newClockState = {
      clockedIn: false,
      clockInTime: null,
      lastBreakTime: null,
      lastMealTime: null,
      shiftDuration: 0,
      workSessions: []
    };
    
    // Update clock state immediately
    setClockState(newClockState);
    
    // Remove the backup clock state from localStorage
    localStorage.removeItem(`timetrack_clock_state_backup_${userId}`);
    
    try {
      // Get device ID from localStorage if available
      const deviceInfo = localStorage.getItem('deviceid');
      const deviceid = deviceInfo ? JSON.parse(deviceInfo) : null;
      
      const entry = await createTimeTrackEntry({
        userId,
        status: 'clock-out',
        timestamp: now.getTime(),
        timeEntry,
        totalWorkTime,
        workSessions: updatedWorkSessions,
        latitude: locationData?.latitude,
        longitude: locationData?.longitude,
        address: locationData?.address, // Include address for storage in the database
        overtime_minutes: overtimeMinutes, // Include overtime minutes
        device_id: deviceid?.dvc_hash // Include device ID for tracking which device was used
      });
      
      // Update entries state
      setEntries(prev => [entry, ...prev]);
      
      // Also update cached entries to ensure they're available when offline
      setCachedEntries(prev => {
        const newEntries = [entry, ...prev];
        return newEntries;
      });
    } catch (err) {
      console.error('Error clocking out:', err);
      setError(err);
      
      // Still update the UI even if the API call fails
      const offlineEntry = {
        local_id: `local_${Date.now()}`,
        user_id: userId,
        status: isAuto ? 'auto-clock-out' : 'clock-out',
        timestamp: now.getTime(),
        time_entry: timeEntry,
        total_work_time: totalWorkTime,
        work_sessions: JSON.stringify(updatedWorkSessions),
        latitude: locationData?.latitude,
        longitude: locationData?.longitude,
        address: locationData?.address, // Include address for storage in the database
        overtime_minutes: overtimeMinutes, // Include overtime minutes
        synced: false
      };
      
      // Update entries state
      setEntries(prev => [offlineEntry, ...prev]);
      
      // Also update cached entries to ensure they're available when offline
      setCachedEntries(prev => {
        const newEntries = [offlineEntry, ...prev];
        return newEntries;
      });
    }
  };
  
  // Take break function
  const takeBreak = async (breakType, duration, locationData = null, state = 'WA', projectId = null) => {
    if (!clockState.clockedIn) return;
    
    const now = new Date();
    const timeEntry = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    
    // Calculate work time before break
    const workTimeBeforeBreak = clockState.lastBreakTime 
      ? now.getTime() - clockState.lastBreakTime 
      : now.getTime() - clockState.clockInTime;
    
    // Update the last work session
    const updatedWorkSessions = [...clockState.workSessions];
    if (updatedWorkSessions.length > 0) {
      const lastSession = updatedWorkSessions[updatedWorkSessions.length - 1];
      if (lastSession && !lastSession.end) {
        lastSession.end = now.getTime();
        lastSession.duration = lastSession.end - lastSession.start;
      }
    }
    
    // Add a new work session that will start after the break
    updatedWorkSessions.push({
      start: now.getTime() + (duration * 60 * 1000), // Start after break
      end: null,
      duration: 0
    });
    
    const newClockState = {
      ...clockState,
      lastBreakTime: breakType === 'break' || breakType === 'rest' ? now.getTime() : clockState.lastBreakTime,
      lastMealTime: breakType === 'meal' || breakType === 'lunch' ? now.getTime() : clockState.lastMealTime,
      workSessions: updatedWorkSessions
    };
    
    // Update clock state immediately
    setClockState(newClockState);
    
    // Update the backup clock state in localStorage
    if (clockState.clockedIn) {
      localStorage.setItem(`timetrack_clock_state_backup_${userId}`, JSON.stringify(newClockState));
    }
    
    try {
      // Get device ID from localStorage if available
      const deviceInfo = localStorage.getItem('deviceid');
      const deviceid = deviceInfo ? JSON.parse(deviceInfo) : null;
      
      const entry = await createTimeTrackEntry({
        userId,
        status: 'break',
        timestamp: now.getTime(),
        timeEntry,
        duration,
        breakType,
        workTimeBeforeBreak,
        workSessions: updatedWorkSessions,
        latitude: locationData?.latitude,
        longitude: locationData?.longitude,
        address: locationData?.address, // Include address for storage in the database
        state, // Include state for break rule compliance
        projectId, // Include project ID if provided
        device_id: deviceid?.dvc_hash // Include device ID for tracking which device was used
      });
      
      // Update entries state
      setEntries(prev => [entry, ...prev]);
      
      // Also update cached entries to ensure they're available when offline
      setCachedEntries(prev => {
        const newEntries = [entry, ...prev];
        return newEntries;
      });
      
      // Store break info in localStorage to handle auto-end even if app is closed
      const breakInfo = {
        userId,
        breakType,
        startTime: now.getTime(),
        duration,
        locationData,
        endTime: now.getTime() + (duration * 60 * 1000)
      };
      localStorage.setItem(`break_${userId}_${now.getTime()}`, JSON.stringify(breakInfo));
      
      // Schedule the end of break entry
      const breakEndTimeoutId = setTimeout(async () => {
        const endTime = new Date();
        const endTimeEntry = endTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
        
        try {
          // Check if we're online
          if (isOnline()) {
            // Get device ID from localStorage if available
            const deviceInfo = localStorage.getItem('deviceid');
            const deviceid = deviceInfo ? JSON.parse(deviceInfo) : null;
            
            const endEntry = await createTimeTrackEntry({
              userId,
              status: 'break-end',
              timestamp: endTime.getTime(),
              timeEntry: endTimeEntry,
              breakType,
              break_id: entry.id, // Add the break ID to link this end entry to the start entry
              latitude: locationData?.latitude,
              longitude: locationData?.longitude,
              address: locationData?.address, // Include address for storage in the database
              device_id: deviceid?.dvc_hash // Include device ID for tracking which device was used
            });
            
            // Only add to entries if the entry belongs to the current date
            const entryDate = new Date(endEntry.timestamp);
            const currentDateStart = new Date(currentDate);
            currentDateStart.setHours(0, 0, 0, 0);
            const currentDateEnd = new Date(currentDate);
            currentDateEnd.setHours(23, 59, 59, 999);
            
            if (entryDate >= currentDateStart && entryDate <= currentDateEnd) {
              setEntries(prev => [endEntry, ...prev]);
              
              // Also update cached entries
              setCachedEntries(prev => {
                const newEntries = [endEntry, ...prev];
                return newEntries;
              });
            }
          } else {
            // If offline, use the createOfflineBreakEndEntry function
            const offlineEndEntry = createOfflineBreakEndEntry(
              userId, 
              breakType, 
              locationData,
              null,
              entry.id // Pass the break ID to link this end entry to the start entry
            );
            
            // Only add to entries if the entry belongs to the current date
            const entryDate = new Date(offlineEndEntry.timestamp);
            const currentDateStart = new Date(currentDate);
            currentDateStart.setHours(0, 0, 0, 0);
            const currentDateEnd = new Date(currentDate);
            currentDateEnd.setHours(23, 59, 59, 999);
            
            if (entryDate >= currentDateStart && entryDate <= currentDateEnd) {
              setEntries(prev => [offlineEndEntry, ...prev]);
              
              // Also update cached entries
              setCachedEntries(prev => {
                const newEntries = [offlineEndEntry, ...prev];
                return newEntries;
              });
            }
          }
          
          // Remove the break info from localStorage
          localStorage.removeItem(`break_${userId}_${now.getTime()}`);
        } catch (err) {
          console.error('Error ending break:', err);
          
          // Still update the UI even if the API call fails
          const offlineEndEntry = createOfflineBreakEndEntry(
            userId, 
            breakType, 
            locationData,
            null,
            entry.id // Pass the break ID to link this end entry to the start entry
          );
          
          // Only add to entries if the entry belongs to the current date
          const entryDate = new Date(offlineEndEntry.timestamp);
          const currentDateStart = new Date(currentDate);
          currentDateStart.setHours(0, 0, 0, 0);
          const currentDateEnd = new Date(currentDate);
          currentDateEnd.setHours(23, 59, 59, 999);
          
          if (entryDate >= currentDateStart && entryDate <= currentDateEnd) {
            setEntries(prev => [offlineEndEntry, ...prev]);
            
            // Also update cached entries
            setCachedEntries(prev => {
              const newEntries = [offlineEndEntry, ...prev];
              return newEntries;
            });
          }
        }
      }, duration * 60 * 1000);
      
      // Store the timeout ID so we can clear it if needed
      localStorage.setItem(`breakTimeout_${userId}_${now.getTime()}`, breakEndTimeoutId.toString());
    } catch (err) {
      console.error('Error taking break:', err);
      setError(err);
      
      // Still update the UI even if the API call fails
      const offlineEntry = {
        local_id: `local_${Date.now()}`,
        user_id: userId,
        status: 'break',
        timestamp: now.getTime(),
        time_entry: timeEntry,
        duration,
        break_type: breakType,
        work_time_before_break: workTimeBeforeBreak,
        work_sessions: JSON.stringify(updatedWorkSessions),
        latitude: locationData?.latitude,
        longitude: locationData?.longitude,
        address: locationData?.address, // Include address for storage in the database
        synced: false
      };
      
      // Update entries state
      setEntries(prev => [offlineEntry, ...prev]);
      
      // Also update cached entries to ensure they're available when offline
      setCachedEntries(prev => {
        const newEntries = [offlineEntry, ...prev];
        return newEntries;
      });
      
      // Store break info in localStorage to handle auto-end even if app is closed
      const breakInfo = {
        userId,
        breakType,
        startTime: now.getTime(),
        duration,
        locationData,
        endTime: now.getTime() + (duration * 60 * 1000)
      };
      localStorage.setItem(`break_${userId}_${now.getTime()}`, JSON.stringify(breakInfo));
      
      // Schedule the end of break entry
      const breakEndTimeoutId = setTimeout(() => {
        const offlineEndEntry = createOfflineBreakEndEntry(
          userId, 
          breakType, 
          locationData,
          null,
          offlineEntry.local_id // Pass the break ID to link this end entry to the start entry
        );
        
        // Only add to entries if the entry belongs to the current date
        const entryDate = new Date(offlineEndEntry.timestamp);
        const currentDateStart = new Date(currentDate);
        currentDateStart.setHours(0, 0, 0, 0);
        const currentDateEnd = new Date(currentDate);
        currentDateEnd.setHours(23, 59, 59, 999);
        
        if (entryDate >= currentDateStart && entryDate <= currentDateEnd) {
          setEntries(prev => [offlineEndEntry, ...prev]);
          
          // Also update cached entries
          setCachedEntries(prev => {
            const newEntries = [offlineEndEntry, ...prev];
            return newEntries;
          });
        }
        
        // Remove the break info from localStorage
        localStorage.removeItem(`break_${userId}_${now.getTime()}`);
      }, duration * 60 * 1000);
      
      // Store the timeout ID so we can clear it if needed
      localStorage.setItem(`breakTimeout_${userId}_${now.getTime()}`, breakEndTimeoutId.toString());
    }
  };
  
  // Update shift duration
  const updateShiftDuration = useCallback((duration) => {
    setClockState(prev => ({
      ...prev,
      shiftDuration: duration
    }));
  }, [setClockState]);
  
  // Navigate to a different date with debouncing to prevent excessive API calls
  const navigateDate = useCallback((direction) => {
    console.log(`navigateDate called with direction: ${direction}`);
    
    // Use a debounce mechanism to prevent multiple rapid calls
    if (window._dateNavigationTimeout) {
      clearTimeout(window._dateNavigationTimeout);
    }
    
    // Set loading state immediately
    setLoading(prev => ({ ...prev, dateNav: true }));
    
    // Create a new date object immediately to avoid stale state
    const newDate = new Date(currentDate);
    
    // Support both 'forward'/'back' and any other value (treated as backward)
    const increment = direction === 'forward' ? 1 : -1;
    newDate.setDate(newDate.getDate() + increment);
    
    console.log(`Current date: ${currentDate.toLocaleDateString()}, New date: ${newDate.toLocaleDateString()}`);
    
    // Store the current clock state before changing the date
    const currentClockState = { ...clockState };
    
    // Update the date immediately
    setCurrentDate(newDate);
    
    // Use a short timeout to reset loading state and preserve clock state
    window._dateNavigationTimeout = setTimeout(() => {
      // Restore the original clock state to prevent date navigation from affecting it
      setClockState(currentClockState);
      
      // Reset loading state
      setLoading(prev => ({ ...prev, dateNav: false }));
    }, 300);
  }, [currentDate, setLoading, setCurrentDate, clockState, setClockState]);
  
  return {
    entries,
    setEntries, // Expose setEntries to allow direct updates to the entries state
    loading,
    error,
    syncStatus,
    setSyncStatus, // Expose setSyncStatus to allow manual control of sync status
    currentDate,
    clockState,
    clockIn,
    clockOut,
    takeBreak,
    updateShiftDuration,
    navigateDate,
    syncOfflineEntries,
    refreshEntries: fetchEntries,
    setCurrentDate // Expose setCurrentDate to allow direct date setting
  };
}
