import { supabase } from '../lib/supabase';

/**
 * Fetch user interaction data for reporting
 * @param {Object} filters - Filters to apply to the query
 * @param {string} filters.startDate - Start date for filtering (ISO string)
 * @param {string} filters.endDate - End date for filtering (ISO string)
 * @param {string[]} filters.interactionTypes - Array of interaction types to filter by
 * @param {string[]} filters.userIds - Array of user IDs to filter by
 * @param {string[]} filters.components - Array of component names to filter by
 * @param {number} page - Page number for pagination
 * @param {number} pageSize - Number of items per page
 * @returns {Promise<Object>} - A promise that resolves to the interaction data
 */
export async function fetchUserInteractionData(filters = {}, page = 1, pageSize = 100) {
  try {
    const {
      startDate,
      endDate,
      interactionTypes = [],
      userIds = [],
      components = []
    } = filters;

    // Start building the query
    let query = supabase
      .from('user_interaction')
      .select('*');

    // Apply date filters if provided
    if (startDate) {
      const startTimestamp = new Date(startDate).getTime();
      query = query.gte('added_at', startTimestamp);
    }

    if (endDate) {
      const endTimestamp = new Date(endDate).getTime();
      query = query.lte('added_at', endTimestamp);
    }

    // Apply user ID filter if provided
    if (userIds.length > 0) {
      query = query.in('user_id', userIds);
    }

    // Apply pagination
    const from = (page - 1) * pageSize;
    const to = from + pageSize - 1;
    query = query.range(from, to);

    // Execute the query
    const { data, error, count } = await query;

    if (error) throw error;

    // Post-process the results to apply filters that can't be done at the database level
    let filteredData = data;

    // Filter by interaction type if provided
    if (interactionTypes.length > 0) {
      filteredData = filteredData.filter(item => 
        interactionTypes.includes(item.interaction_data.type)
      );
    }

    // Filter by component if provided
    if (components.length > 0) {
      filteredData = filteredData.filter(item => 
        components.includes(item.interaction_data.data?.componentName)
      );
    }

    return {
      data: filteredData,
      count: filteredData.length,
      page,
      pageSize
    };
  } catch (error) {
    console.error('Error fetching user interaction data:', error);
    throw error;
  }
}

/**
 * Get summary statistics for user interactions
 * @param {Object} filters - Filters to apply to the query
 * @returns {Promise<Object>} - A promise that resolves to the summary statistics
 */
export async function getUserInteractionSummary(filters = {}) {
  try {
    const interactionData = await fetchUserInteractionData(filters, 1, 10000);
    
    // Initialize summary object
    const summary = {
      totalInteractions: interactionData.data.length,
      interactionsByType: {},
      interactionsByComponent: {},
      interactionsByUser: {},
      interactionsByDay: {},
      mostActiveUsers: [],
      mostActiveComponents: [],
      mostCommonInteractions: []
    };

    // Process the data to generate summary statistics
    interactionData.data.forEach(interaction => {
      const interactionType = interaction.interaction_data.type;
      const componentName = interaction.interaction_data.data?.componentName || 'unknown';
      const userId = interaction.user_id;
      const timestamp = interaction.added_at;
      const date = new Date(timestamp).toISOString().split('T')[0]; // YYYY-MM-DD

      // Count by interaction type
      summary.interactionsByType[interactionType] = (summary.interactionsByType[interactionType] || 0) + 1;

      // Count by component
      summary.interactionsByComponent[componentName] = (summary.interactionsByComponent[componentName] || 0) + 1;

      // Count by user
      summary.interactionsByUser[userId] = (summary.interactionsByUser[userId] || 0) + 1;

      // Count by day
      summary.interactionsByDay[date] = (summary.interactionsByDay[date] || 0) + 1;
    });

    // Convert to arrays for easier sorting and limiting
    summary.mostActiveUsers = Object.entries(summary.interactionsByUser)
      .map(([userId, count]) => ({ userId, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    summary.mostActiveComponents = Object.entries(summary.interactionsByComponent)
      .map(([component, count]) => ({ component, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    summary.mostCommonInteractions = Object.entries(summary.interactionsByType)
      .map(([type, count]) => ({ type, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    // Convert objects to arrays for charting
    summary.interactionsByDayArray = Object.entries(summary.interactionsByDay)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return summary;
  } catch (error) {
    console.error('Error generating user interaction summary:', error);
    throw error;
  }
}

/**
 * Get detailed statistics for page views
 * @param {Object} filters - Filters to apply to the query
 * @returns {Promise<Object>} - A promise that resolves to the page view statistics
 */
export async function getPageViewStatistics(filters = {}) {
  try {
    // Modify filters to only include page_view interaction type
    const pageViewFilters = {
      ...filters,
      interactionTypes: ['page_view']
    };

    const interactionData = await fetchUserInteractionData(pageViewFilters, 1, 10000);
    
    // Initialize statistics object
    const statistics = {
      totalPageViews: interactionData.data.length,
      pageViewsByPage: {},
      pageViewsByUser: {},
      pageViewsByDay: {},
      mostViewedPages: [],
      averageTimeOnPage: {}
    };

    // Process the data to generate statistics
    interactionData.data.forEach(interaction => {
      const page = interaction.interaction_data.data?.page || 'unknown';
      const userId = interaction.user_id;
      const timestamp = interaction.added_at;
      const date = new Date(timestamp).toISOString().split('T')[0]; // YYYY-MM-DD

      // Count by page
      statistics.pageViewsByPage[page] = (statistics.pageViewsByPage[page] || 0) + 1;

      // Count by user
      statistics.pageViewsByUser[userId] = (statistics.pageViewsByUser[userId] || 0) + 1;

      // Count by day
      statistics.pageViewsByDay[date] = (statistics.pageViewsByDay[date] || 0) + 1;
    });

    // Convert to arrays for easier sorting and limiting
    statistics.mostViewedPages = Object.entries(statistics.pageViewsByPage)
      .map(([page, count]) => ({ page, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    // Convert objects to arrays for charting
    statistics.pageViewsByDayArray = Object.entries(statistics.pageViewsByDay)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return statistics;
  } catch (error) {
    console.error('Error generating page view statistics:', error);
    throw error;
  }
}

/**
 * Get detailed statistics for button clicks
 * @param {Object} filters - Filters to apply to the query
 * @returns {Promise<Object>} - A promise that resolves to the button click statistics
 */
export async function getButtonClickStatistics(filters = {}) {
  try {
    // Modify filters to only include button_click interaction type
    const buttonClickFilters = {
      ...filters,
      interactionTypes: ['button_click']
    };

    const interactionData = await fetchUserInteractionData(buttonClickFilters, 1, 10000);
    
    // Initialize statistics object
    const statistics = {
      totalButtonClicks: interactionData.data.length,
      buttonClicksByButton: {},
      buttonClicksByComponent: {},
      buttonClicksByUser: {},
      buttonClicksByDay: {},
      mostClickedButtons: []
    };

    // Process the data to generate statistics
    interactionData.data.forEach(interaction => {
      const buttonId = interaction.interaction_data.data?.buttonId || 'unknown';
      const componentName = interaction.interaction_data.data?.componentName || 'unknown';
      const userId = interaction.user_id;
      const timestamp = interaction.added_at;
      const date = new Date(timestamp).toISOString().split('T')[0]; // YYYY-MM-DD

      // Count by button
      statistics.buttonClicksByButton[buttonId] = (statistics.buttonClicksByButton[buttonId] || 0) + 1;

      // Count by component
      statistics.buttonClicksByComponent[componentName] = (statistics.buttonClicksByComponent[componentName] || 0) + 1;

      // Count by user
      statistics.buttonClicksByUser[userId] = (statistics.buttonClicksByUser[userId] || 0) + 1;

      // Count by day
      statistics.buttonClicksByDay[date] = (statistics.buttonClicksByDay[date] || 0) + 1;
    });

    // Convert to arrays for easier sorting and limiting
    statistics.mostClickedButtons = Object.entries(statistics.buttonClicksByButton)
      .map(([buttonId, count]) => ({ buttonId, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    // Convert objects to arrays for charting
    statistics.buttonClicksByDayArray = Object.entries(statistics.buttonClicksByDay)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return statistics;
  } catch (error) {
    console.error('Error generating button click statistics:', error);
    throw error;
  }
}

/**
 * Get detailed statistics for form submissions
 * @param {Object} filters - Filters to apply to the query
 * @returns {Promise<Object>} - A promise that resolves to the form submission statistics
 */
export async function getFormSubmissionStatistics(filters = {}) {
  try {
    // Modify filters to only include form_submit interaction type
    const formSubmitFilters = {
      ...filters,
      interactionTypes: ['form_submit']
    };

    const interactionData = await fetchUserInteractionData(formSubmitFilters, 1, 10000);
    
    // Initialize statistics object
    const statistics = {
      totalFormSubmissions: interactionData.data.length,
      formSubmissionsByForm: {},
      formSubmissionsByUser: {},
      formSubmissionsByDay: {},
      mostSubmittedForms: [],
      formSubmissionSuccess: {
        successful: 0,
        failed: 0
      }
    };

    // Process the data to generate statistics
    interactionData.data.forEach(interaction => {
      const formId = interaction.interaction_data.data?.formId || 'unknown';
      const userId = interaction.user_id;
      const timestamp = interaction.added_at;
      const date = new Date(timestamp).toISOString().split('T')[0]; // YYYY-MM-DD
      const success = interaction.interaction_data.data?.success || false;

      // Count by form
      statistics.formSubmissionsByForm[formId] = (statistics.formSubmissionsByForm[formId] || 0) + 1;

      // Count by user
      statistics.formSubmissionsByUser[userId] = (statistics.formSubmissionsByUser[userId] || 0) + 1;

      // Count by day
      statistics.formSubmissionsByDay[date] = (statistics.formSubmissionsByDay[date] || 0) + 1;

      // Count success/failure
      if (success) {
        statistics.formSubmissionSuccess.successful++;
      } else {
        statistics.formSubmissionSuccess.failed++;
      }
    });

    // Convert to arrays for easier sorting and limiting
    statistics.mostSubmittedForms = Object.entries(statistics.formSubmissionsByForm)
      .map(([formId, count]) => ({ formId, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    // Convert objects to arrays for charting
    statistics.formSubmissionsByDayArray = Object.entries(statistics.formSubmissionsByDay)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return statistics;
  } catch (error) {
    console.error('Error generating form submission statistics:', error);
    throw error;
  }
}

/**
 * Get detailed statistics for map interactions
 * @param {Object} filters - Filters to apply to the query
 * @returns {Promise<Object>} - A promise that resolves to the map interaction statistics
 */
export async function getMapInteractionStatistics(filters = {}) {
  try {
    // Modify filters to only include map-related interaction types
    const mapInteractionFilters = {
      ...filters,
      interactionTypes: ['map_pan', 'map_zoom', 'map_click', 'map_layer_toggle']
    };

    const interactionData = await fetchUserInteractionData(mapInteractionFilters, 1, 10000);
    
    // Initialize statistics object
    const statistics = {
      totalMapInteractions: interactionData.data.length,
      mapInteractionsByType: {},
      mapInteractionsByLayer: {},
      mapInteractionsByUser: {},
      mapInteractionsByDay: {},
      mostActiveMapLayers: []
    };

    // Process the data to generate statistics
    interactionData.data.forEach(interaction => {
      const interactionType = interaction.interaction_data.type;
      const layer = interaction.interaction_data.data?.layer || 'unknown';
      const userId = interaction.user_id;
      const timestamp = interaction.added_at;
      const date = new Date(timestamp).toISOString().split('T')[0]; // YYYY-MM-DD

      // Count by interaction type
      statistics.mapInteractionsByType[interactionType] = (statistics.mapInteractionsByType[interactionType] || 0) + 1;

      // Count by layer (if applicable)
      if (interactionType === 'map_layer_toggle' || interactionType === 'map_click') {
        statistics.mapInteractionsByLayer[layer] = (statistics.mapInteractionsByLayer[layer] || 0) + 1;
      }

      // Count by user
      statistics.mapInteractionsByUser[userId] = (statistics.mapInteractionsByUser[userId] || 0) + 1;

      // Count by day
      statistics.mapInteractionsByDay[date] = (statistics.mapInteractionsByDay[date] || 0) + 1;
    });

    // Convert to arrays for easier sorting and limiting
    statistics.mostActiveMapLayers = Object.entries(statistics.mapInteractionsByLayer)
      .map(([layer, count]) => ({ layer, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    // Convert objects to arrays for charting
    statistics.mapInteractionsByDayArray = Object.entries(statistics.mapInteractionsByDay)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return statistics;
  } catch (error) {
    console.error('Error generating map interaction statistics:', error);
    throw error;
  }
}

/**
 * Get a list of all unique interaction types
 * @returns {Promise<string[]>} - A promise that resolves to an array of interaction types
 */
export async function getInteractionTypes() {
  try {
    const { data, error } = await supabase
      .from('user_interaction')
      .select('interaction_data');

    if (error) throw error;

    // Extract unique interaction types
    const types = new Set();
    data.forEach(item => {
      if (item.interaction_data && item.interaction_data.type) {
        types.add(item.interaction_data.type);
      }
    });

    return Array.from(types);
  } catch (error) {
    console.error('Error fetching interaction types:', error);
    throw error;
  }
}

/**
 * Get a list of all unique component names
 * @returns {Promise<string[]>} - A promise that resolves to an array of component names
 */
export async function getComponentNames() {
  try {
    const { data, error } = await supabase
      .from('user_interaction')
      .select('interaction_data');

    if (error) throw error;

    // Extract unique component names
    const components = new Set();
    data.forEach(item => {
      if (item.interaction_data && 
          item.interaction_data.data && 
          item.interaction_data.data.componentName) {
        components.add(item.interaction_data.data.componentName);
      }
    });

    return Array.from(components);
  } catch (error) {
    console.error('Error fetching component names:', error);
    throw error;
  }
}

/**
 * Get a list of all users who have interactions
 * @returns {Promise<Object[]>} - A promise that resolves to an array of user objects
 */
export async function getUsersWithInteractions() {
  try {
    // Get all user_ids from interactions
    const { data, error } = await supabase
      .from('user_interaction')
      .select('user_id');

    if (error) throw error;

    // Extract unique user IDs using a Set
    const uniqueUserIds = [...new Set(data.map(item => item.user_id))];

    // If there are no user IDs, return an empty array
    if (uniqueUserIds.length === 0) {
      return [];
    }

    // Since we're having issues with the users table, let's just return basic user objects
    // This is a fallback solution that will work even if we can't access the users table
    return uniqueUserIds.map(id => ({ 
      id, 
      email: id === '00000000-0000-0000-0000-000000000000' ? 'Anonymous' : `User ${id.substring(0, 8)}` 
    }));
  } catch (error) {
    console.error('Error fetching users with interactions:', error);
    // Return an empty array instead of throwing to prevent dashboard from breaking
    return [];
  }
}
