import React, { useEffect } from 'react';
import useStickyState from '../Hooks/useStickyState';
import useCompressedStickyState from './useCompressedStickyState';
import { v4 as uuidv4 } from 'uuid';
import { hashCode } from '../Utils/hashCode';
import { supabase } from '../../lib/supabase'; // Import Supabase client

export const StoreContext = React.createContext(null);

export default ({ children }) => {
  const dt_formatted_string = new Date(Date.now()).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const todo_header_default_id = '';//uuidv4();
  const todo_header_default_text = 'To-do ' + dt_formatted_string;

  const [dialogContent, setDialogContent] = React.useState({});
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [sharing, setSharing] = React.useState([]);
  const [help, setHelp] = React.useState([]);

  const [statuscolorTimecard, setStatuscolorTimecard] = useStickyState('', 'statuscolor');
  const [loadedmodal, setLoadedmodal] = useStickyState('', 'loadedmodal' + hashCode('loadedmodal'));
  const [donecustom, setDonecustom] = useStickyState([], 'donecustom');
  const [doneselect, setDoneselect] = useStickyState([], 'doneselect');
  const [savedactivities, setsavedactivities] = useStickyState([], 'savedactivities');
  const [savedhashes, setSavedhashes] = useStickyState([], 'savedhashes');
  const [todos, setTodos] = useStickyState([], 'todos');
  const [todoHeader, setTodoHeader] = useStickyState(todo_header_default_text, 'todolistheader');
  const [todolistid, settodolistid] = useStickyState(todo_header_default_id, 'todolistid');
  const [showaddactivity, setShowaddactivity] = useStickyState(false, 'showaddactivity');
  const [clockedin, setClockedin] = useStickyState(false, 'clockedin');
  const [sessionid, setSessionid] = useStickyState('', 'sessionid');
  const [deviceid, setDeviceid] = useStickyState('', 'deviceid');
  const [locationid, setLocationid] = useStickyState('', 'locationid');
  const [userlocation, setuserlocation] = useStickyState('', 'userlocation');
  const [userid, setUserid] = useStickyState('', 'userid');
  const [dirtytodos, setdirtytodos] = useStickyState('', 'dirtytodos');
  const [tcsessionid, setTcsessionid] = useStickyState('', 'tcsessionid');
  const [kvtodolistnames, setKvtodolistnames] = useStickyState([], 'kvtodolistnames');
  const [sessiondata, setsessiondata] = useStickyState({}, 'sessiondata');
  const [interactiondata, setinteractiondata] = useCompressedStickyState({}, 'interactiondata');
  const [showarchived, setShowarchived] = useStickyState(true, 'showarchived');
  const [todolists, settodolists] = useStickyState([], 'todolists');
  const [assignments, setAssignments] = useStickyState([], 'assignments');

  // Add geolocation state
  const [geolocation, setGeolocation] = React.useState({ lat: 0, lon: 0, now: 0 });

  // Add authenticated user state
  const [user, setUser] = React.useState(null);

  // Add map instance state
  const [mapInstance, setMapInstance] = React.useState(null);
  
  // Add TimeTrack mounted state
  const [isTimeTrackMounted, setIsTimeTrackMounted] = React.useState(false);
  
  // Add break notification state
  const [pendingBreakType, setPendingBreakType] = React.useState(null);
  
  // Add global clock state tracking
  const [globalClockState, setGlobalClockState] = React.useState({
    clockedIn: clockedin,
    clockInTime: null
  });
  
  // Update global clock state when clockedin changes
  useEffect(() => {
    setGlobalClockState(prev => ({
      ...prev,
      clockedIn: clockedin
    }));
  }, [clockedin]);

  // Listen for timetrack:takeBreak events
  useEffect(() => {
    const handleTakeBreakEvent = (event) => {
      console.log('Received timetrack:takeBreak event:', event.detail);
      // Store the break type to be used when TimeTrack is mounted
      setPendingBreakType(event.detail.breakType);
      
      // Show a notification to the user
      createNotificationDialog(
        'Break Notification',
        `<div style="opacity: 0.95;">
           <div style="margin-bottom: 12px;">A ${event.detail.breakType} break is due. Please open TimeTrack to take your break.</div>
         </div>`
      );
    };
    
    window.addEventListener('timetrack:takeBreak', handleTakeBreakEvent);
    
    return () => {
      window.removeEventListener('timetrack:takeBreak', handleTakeBreakEvent);
    };
  }, []);
  
  // Function to create styled notification dialog
  const createNotificationDialog = (headerText, bodyContent) => {
    const dialog = document.createElement('div');
    dialog.style.position = 'fixed';
    dialog.style.top = '20px';
    dialog.style.left = '50%';
    dialog.style.transform = 'translateX(-50%)';
    dialog.style.zIndex = '9999';
    dialog.style.minWidth = '300px';
    dialog.style.maxWidth = '500px';
    dialog.style.borderRadius = '8px';
    dialog.style.overflow = 'hidden';
    dialog.style.boxShadow = '0 4px 20px rgba(0,0,0,0.25)';
    dialog.style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
    dialog.style.fontSize = '14px';
    dialog.style.lineHeight = '1.5';
    dialog.style.opacity = '0';
    dialog.style.transition = 'opacity 0.3s ease';

    let countdownInterval;
    let secondsLeft = 10;

    const closeDialog = () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
      dialog.style.opacity = '0';
      dialog.addEventListener('transitionend', () => {
        dialog.remove();
      }, { once: true });
    };

    dialog.innerHTML = `
      <div style="background: #FBB03B; color: white; padding: 16px 24px; font-weight: 500; font-size: 16px; letter-spacing: 0.15px; display: flex; justify-content: space-between; align-items: center;">
        <span>${headerText}</span>
        <button style="background: none; border: none; color: white; cursor: pointer; padding: 0; font-size: 20px; line-height: 1; opacity: 0.8; transition: opacity 0.2s ease;" onmouseover="this.style.opacity=1" onmouseout="this.style.opacity=0.8">&times;</button>
      </div>
      <div style="background: #666673; color: white; padding: 20px 24px;">
        ${bodyContent}
        <div style="margin-top: 16px; font-size: 13px; opacity: 0.9; text-align: right; font-family: monospace;">
          Closing in <span id="countdown" style="font-weight: bold;">${secondsLeft}</span> seconds
        </div>
      </div>
    `;

    // Set up close button click handler
    const closeButton = dialog.querySelector('button');
    closeButton.addEventListener('click', closeDialog);

    // Set up countdown timer
    const countdownElement = dialog.querySelector('#countdown');
    countdownInterval = setInterval(() => {
      secondsLeft--;
      if (countdownElement) {
        countdownElement.textContent = secondsLeft;
      }
      if (secondsLeft <= 0) {
        clearInterval(countdownInterval);
        closeDialog();
      }
    }, 1000);

    // Fade in the dialog
    requestAnimationFrame(() => {
      dialog.style.opacity = '1';
    });

    document.body.appendChild(dialog);

    return dialog;
  };

  // Function to get address from coordinates
  const getAddressFromCoords = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      return data.display_name;
    } catch (error) {
      console.error('Error getting address:', error);
      return 'Address not found';
    }
  };

  // Function to request geolocation
  const requestGeolocationFunc = () => {
    console.log('in store.requestGeolocationFunc '); // Debugging
    if (navigator.geolocation) {
      // Show loading notification
      const loadingDialog = createNotificationDialog(
        'Current Location',
        `<div style="opacity: 0.95;">
           <div style="margin-bottom: 12px;">Getting your location...</div>
           <div style="margin-bottom: 12px; display: flex; justify-content: center; align-items: center;">
             <svg width="24" height="24" viewBox="0 0 24 24" style="animation: spin 1s linear infinite;">
               <style>
                 @keyframes spin {
                   0% { transform: rotate(0deg); }
                   100% { transform: rotate(360deg); }
                 }
               </style>
               <path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" fill="white"/>
             </svg>
           </div>
           <div style="font-family: monospace; font-size: 13px; text-align: center;">Please wait while we determine your location</div>
         </div>`
      );

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const location = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            now: Date.now(),
          };
          setGeolocation(location); // Update shared geolocation state

          // Get address and show notification
          const address = await getAddressFromCoords(location.lat, location.lon);
          
          // Remove loading dialog after we have all the data
          loadingDialog.remove();
          createNotificationDialog(
            'Current Location',
            `<div style="margin-bottom: 12px; opacity: 0.95;">${address}</div>
             <div style="opacity: 0.95; font-family: monospace; font-size: 13px;">
               Coordinates: ${location.lat.toFixed(6)}, ${location.lon.toFixed(6)}
             </div>`
          );
        },
        (error) => {
          loadingDialog.remove();
          createNotificationDialog(
            'Location Error',
            `<div style="opacity: 0.95;">
               ${error.code === 1 ? 'Please allow location access to use this feature.' :
                 error.code === 2 ? 'Unable to determine your location. Please check your device settings.' :
                 'An error occurred while getting your location.'}
             </div>`
          );

          console.error('Error fetching geolocation:', error);
        }
      );
    } else {
      createNotificationDialog(
        'Browser Support Error',
        `<div style="opacity: 0.95;">
           Geolocation is not supported by your browser. Please try using a modern browser with location services enabled.
         </div>`
      );

      console.error('Geolocation is not supported by this browser.');
    }
  };

  // Listen for Supabase authentication state changes
  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (session?.user) {
        // User is authenticated, save the user object to the store
        setUser(session.user);
        setUserid(session.user.id); // Save the user ID to the sticky state
      } else {
        // User is not authenticated, set the user to null
        setUser(null);
        setUserid(''); // Clear the user ID from the sticky state
      }
    });

    // Cleanup subscription on unmount
    return () => subscription.unsubscribe();
  }, []);

  const store = {
    share: [sharing, setSharing],
    help: [help, setHelp],
    modaldialog: [loadedmodal, setLoadedmodal],
    mapdialog: [dialogContent, setDialogContent],
    mapdialogopen: [dialogOpen, setDialogOpen],
    activitiescustom: [donecustom, setDonecustom],
    activitiesselected: [doneselect, setDoneselect],
    hashlistsaved: [savedhashes, setSavedhashes],
    todolistheader: [todoHeader, setTodoHeader],
    todolistheaderid: [todolistid, settodolistid],
    todolistsaved: [todos, setTodos],
    tododirtycheck: [dirtytodos, setdirtytodos],
    activitiessaved: [savedactivities, setsavedactivities],
    activitiesclockedin: [clockedin, setClockedin],
    pendingBreakType: [pendingBreakType, setPendingBreakType],
    globalClockState: [globalClockState, setGlobalClockState],
    thisdeviceid: [deviceid, setDeviceid],
    thislocationid: [locationid, setLocationid],
    currentuserlocation: [userlocation, setuserlocation],
    thissessionid: [sessionid, setSessionid],
    mysessiondata: [sessiondata, setsessiondata],
    myinteractions: [interactiondata, setinteractiondata],
    mytodolistnames: [kvtodolistnames, setKvtodolistnames],
    thisuserid: [userid, setUserid],
    timecardsessionid: [tcsessionid, setTcsessionid],
    activitiesaddvisible: [showaddactivity, setShowaddactivity],
    timecard: [statuscolorTimecard, setStatuscolorTimecard],
    showarchiveflag: [showarchived, setShowarchived],
    mytodolists: [todolists, settodolists],
    assignments: [assignments, setAssignments],
    geolocation: [geolocation, setGeolocation], // Add geolocation to the store
    askGeolocation: [requestGeolocationFunc], // Add requestGeolocationFunc to the store
    user: [user, setUser], // Add authenticated user to the store
    mapInstance: [mapInstance, setMapInstance], // Add map instance to the store
    isTimeTrackMounted: [isTimeTrackMounted, setIsTimeTrackMounted], // Add TimeTrack mounted state to the store
  };

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
