import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Container, Dialog, DialogTitle, DialogContent, Divider, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { notify } from '../Toast';
import CurrentDate from '../Utils/CurrentDate';
import TimeCardDialog from './TimeCardDialog';
import { StoreContext } from '../Hooks/store';
import { useTimeCards } from '../Hooks/useTimeCards';
import { reverseGeocode } from '../../utils/geocoding';
import { useTrackInteractions } from '../../utils/trackInteractions';

export default function TimeCard({ open, onClose }) {
  // Initialize tracking functions
  const { trackComponentView, trackTimeCardAction } = useTrackInteractions();
  
  // Track component view when mounted
  useEffect(() => {
    if (open) {
      trackComponentView('TimeCard', { 
        viewType: 'dialog',
        timestamp: Date.now()
      });
    }
  }, [open, trackComponentView]);
  const store = useContext(StoreContext) || {};
  const [user] = store?.user || [null]; // Get the authenticated user from the store
  const userId = user?.id; // Use the authenticated user's ID
  const [statuscolorTimecard, setStatuscolorTimecard] = store?.timecard || ['#FF1D25', () => {}]; // Get from store
  // #231f20
  const { timecards, loading, error, createTimeCard } = useTimeCards(userId);

  // Get the stored clockedin state from the store
  const [storedClockedin, setStoredClockedin] = store?.activitiesclockedin || [false, () => {}];

  const [clockedin, setClockedin] = useState(storedClockedin);
  const [showingHistory, setShowingHistory] = useState(false);
  const [workbreak, setWorkbreak] = useState('Break');
  const [clockbtnlabel, setClockbtnlabel] = useState(storedClockedin ? 'Clock Out' : 'Clock In');
  const [status, setStatus] = useState('Clocked Out');
  const [clockinstart, setClockinstart] = useState(0);
  const [breakstart, setBreakstart] = useState(0);
  const [onBreak, setOnBreak] = useState(false);
  const [tctime, setTctime] = useState(0);
  const [tcdate, setTcdate] = useState(new Date().toLocaleDateString());
  const [selectedDate, setSelectedDate] = useState(new Date()); // Store the actual date object
  const [latlon, setLatlon] = useState({ lat: null, lon: null, now: 0 });
  const [locationPermission, setLocationPermission] = useState(null);
  const [fwdnavdisabled, setFwdnavdisabled] = useState(true);
  const [filteredTimecards, setFilteredTimecards] = useState([]);
  const [isCurrentDate, setIsCurrentDate] = useState(true);
  
  // New state variables for tracking work time
  const [totalWorkTime, setTotalWorkTime] = useState(0); // Total work time excluding breaks
  const [workSessions, setWorkSessions] = useState([]); // Array to store work sessions
  const [lastWorkTime, setLastWorkTime] = useState(0); // Time worked before break

  // Get user's location and request permission if needed
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatlon({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
              now: Date.now()
            });
            setLocationPermission('granted');
          },
          (error) => {
            console.error('Error getting location:', error);
            setLocationPermission('denied');
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setLocationPermission('unsupported');
      }
    };

    getLocation();
    // Set up interval to update location every minute when clocked in
    let locationInterval;
    if (clockedin) {
      locationInterval = setInterval(getLocation, 60000); // Update every minute
    }

    return () => {
      if (locationInterval) clearInterval(locationInterval);
    };
  }, [clockedin]);

  // Initialize state based on the most recent timecard entry when component mounts or timecards change
  useEffect(() => {
    if (timecards && timecards.length > 0) {
      // Sort timecards by event_date in descending order to get the most recent one
      const sortedTimecards = [...timecards].sort((a, b) => b.event_date - a.event_date);
      const lastEvent = sortedTimecards[0];
      
      if (lastEvent) {
        // Set the initial state based on the last event
        if (lastEvent.event_name === 'Clocked In') {
          setClockedin(true);
          setStatus('Clocked In');
          setClockbtnlabel('Clock Out');
          setWorkbreak('Go on Break');
          setClockinstart(lastEvent.utc_start);
          setStoredClockedin(true);
          setStatuscolorTimecard('#8CC63F'); // Green for clocked in
        } else if (lastEvent.event_name === 'Started Break') {
          setClockedin(true);
          setOnBreak(true);
          setStatus('On Break');
          setClockbtnlabel('Clock Out');
          setWorkbreak('End Break');
          setClockinstart(lastEvent.utc_start);
          setBreakstart(lastEvent.utc_start);
          setStoredClockedin(true);
          setStatuscolorTimecard('#FBB03B'); // Orange for break
        } else if (lastEvent.event_name === 'Ended Break') {
          setClockedin(true);
          setOnBreak(false);
          setStatus('Clocked In');
          setClockbtnlabel('Clock Out');
          setWorkbreak('Go on Break');
          setStoredClockedin(true);
          setStatuscolorTimecard('#8CC63F'); // Green for clocked in
        } else if (lastEvent.event_name === 'Clocked Out') {
          setClockedin(false);
          setOnBreak(false);
          setStatus('Clocked Out');
          setClockbtnlabel('Clock In');
          setWorkbreak('Break');
          setClockinstart(0);
          setBreakstart(0);
          setStoredClockedin(false);
          setStatuscolorTimecard('#231f20'); // Black for clocked out
        }
      }
    }
  }, [timecards, setStoredClockedin, setStatuscolorTimecard]);

  // Update status color based on current status
  useEffect(() => {
    if (clockedin) {
      if (onBreak) {
        setStatuscolorTimecard('#FBB03B'); // Orange for break (as specified)
      } else {
        setStatuscolorTimecard('#8CC63F'); // Green for clocked in (as specified)
      }
    } else {
      setStatuscolorTimecard('#231f20'); // Black for clocked out
    }
  }, [clockedin, onBreak, setStatuscolorTimecard]);

  // Filter timecards based on selected date
  useEffect(() => {
    if (timecards && timecards.length > 0) {
      // Check if selected date is today
      const today = new Date();
      const isToday = selectedDate.getDate() === today.getDate() &&
                      selectedDate.getMonth() === today.getMonth() &&
                      selectedDate.getFullYear() === today.getFullYear();
      
      setIsCurrentDate(isToday);
      
      // Filter timecards for the selected date
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);
      
      const filtered = timecards.filter(tc => {
        const eventDate = new Date(tc.event_date);
        return eventDate >= startOfDay && eventDate <= endOfDay;
      });
      
      setFilteredTimecards(filtered);
      
      // Disable forward navigation if we're already on the current date
      setFwdnavdisabled(isToday);
    } else {
      setFilteredTimecards([]);
    }
  }, [timecards, selectedDate]);
  
  // Handle navigation to previous day
  const oneDayBack = useCallback(() => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
    setTcdate(newDate.toLocaleDateString());
    setShowingHistory(true);
  }, [selectedDate]);
  
  // Handle navigation to next day
  const oneDayForward = useCallback(() => {
    const today = new Date();
    
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    
    // Allow navigating up to the current date (including today)
    const todayWithoutTime = new Date(today);
    todayWithoutTime.setHours(0, 0, 0, 0);
    
    const newDateWithoutTime = new Date(newDate);
    newDateWithoutTime.setHours(0, 0, 0, 0);
    
    // Don't allow navigating past the current date
    if (newDateWithoutTime <= todayWithoutTime) {
      setSelectedDate(newDate);
      setTcdate(newDate.toLocaleDateString());
      
      // Check if the new date is today
      const isToday = newDate.getDate() === today.getDate() &&
                      newDate.getMonth() === today.getMonth() &&
                      newDate.getFullYear() === today.getFullYear();
      
      setIsCurrentDate(isToday);
      setFwdnavdisabled(isToday);
      
      if (isToday) {
        setShowingHistory(false);
      }
    }
  }, [selectedDate]);

  // Calculate elapsed time for the current session
  const calculateElapsedTime = (start, end) => {
    if (!start) return 0;
    const endTime = end || Date.now();
    return endTime - start;
  };

  const getCurrentLocation = async () => {
    const rightnow = Date.now();
    let address = null;
    
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          });
        });
        
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        
        setLatlon({
          lat,
          lon,
          now: rightnow
        });
        
        // Get address from coordinates
        try {
          address = await reverseGeocode(lat, lon);
          console.log('Address from geocoding:', address);
        } catch (error) {
          console.error('Error getting address:', error);
          address = 'Address not available';
        }
        
        setLocationPermission('granted');
      } catch (error) {
        console.error('Error getting location:', error);
        setLocationPermission('denied');
      }
    } else {
      console.error('Geolocation is not supported by this browser.');
      setLocationPermission('unsupported');
    }
    
    return { lat: latlon.lat, lon: latlon.lon, address, permission: locationPermission };
  };

  const handleClockin = useCallback(async () => {
    // Track the clock-in/out action
    trackTimeCardAction(
      !clockedin ? 'clock_in' : 'clock_out',
      {
        timestamp: Date.now(),
        totalWorkTime: clockedin ? totalWorkTime : 0,
        workSessions: clockedin ? workSessions : []
      },
      {
        userId,
        location: {
          lat: latlon.lat,
          lon: latlon.lon,
          permission: locationPermission
        }
      }
    );
    
    const rightnow = Date.now();
    let event_name, event_duration_ms;
    
    // Get current location
    const { lat, lon, address, permission } = await getCurrentLocation();

    if (!clockedin) {
      // Clocking in
      event_name = 'Clocked In';
      setClockedin(true);
      setClockinstart(rightnow);
      setStatus('Clocked In');
      setClockbtnlabel('Clock Out');
      setWorkbreak('Go on Break');
      event_duration_ms = 0; // No duration for clock in event
      setStoredClockedin(true);
      
      // Reset work session tracking for new clock-in
      setWorkSessions([]);
      setTotalWorkTime(0);
      setLastWorkTime(0);
      
      // Store clock-in time in local storage
      try {
        const clockInData = {
          userId,
          timestamp: rightnow,
          type: 'clock_in'
        };
        localStorage.setItem(`clockIn_${userId}_${rightnow}`, JSON.stringify(clockInData));
        console.log('Clock-in time saved to local storage:', rightnow);
      } catch (error) {
        console.error('Error saving clock-in time to local storage:', error);
      }
    } else {
      // Clocking out - calculate total time worked including current session
      let finalWorkTime = totalWorkTime;
      
      // If not on break, add the current work session time
      if (!onBreak) {
        const currentSessionTime = calculateElapsedTime(clockinstart);
        finalWorkTime += currentSessionTime;
        
        // Add the current work session to the array
        setWorkSessions(prev => [...prev, {
          start: clockinstart,
          end: rightnow,
          duration: currentSessionTime
        }]);
      }
      
      event_name = 'Clocked Out';
      event_duration_ms = finalWorkTime; // Total time worked including all sessions
      setClockedin(false);
      setStatus('Clocked Out');
      setClockbtnlabel('Clock In');
      setClockinstart(0);
      setStoredClockedin(false);
      
      // Store the final work time in local storage
      try {
        const workTimeData = {
          userId,
          totalTimeWorked: finalWorkTime,
          timestamp: rightnow,
          workSessions: workSessions,
          type: 'clock_out'
        };
        localStorage.setItem(`workTime_${userId}_${rightnow}`, JSON.stringify(workTimeData));
        console.log('Total work time saved to local storage:', finalWorkTime);
      } catch (error) {
        console.error('Error saving total work time to local storage:', error);
      }
      
      // Reset break state if needed
      if (onBreak) {
        setOnBreak(false);
        setWorkbreak('Break');
      }
      
      // Reset work tracking state
      setTotalWorkTime(0);
      setWorkSessions([]);
      setLastWorkTime(0);
    }

      // Create a time card entry with core fields
      // Additional metadata will be stored in local storage until the database schema is updated
      await createTimeCard({
        event_name,
        event_date: rightnow,
        utc_start: !clockedin ? rightnow : clockinstart, // When clocking in, start time is now; when clocking out, start time is when we clocked in
        utc_end: rightnow,
        event_duration_ms,
        latitude: latlon.lat,
        longitude: latlon.lon,
        location_permission: locationPermission,
        // These fields might not exist in the database yet, but we'll include them for future use
        address: address,
        total_work_time: clockedin ? totalWorkTime : 0,
        work_sessions: clockedin && Array.isArray(workSessions) ? 
          JSON.stringify(workSessions) : JSON.stringify([]),
        is_break: false
      });

    notify(`You are ${event_name}`);
    onClose();
  }, [clockedin, createTimeCard, onClose, latlon, clockinstart, locationPermission, onBreak, setStoredClockedin, totalWorkTime, workSessions, userId]);

  const handleBreak = useCallback(async () => {
    // Track the break action
    trackTimeCardAction(
      !onBreak ? 'break_start' : 'break_end',
      {
        timestamp: Date.now(),
        breakDuration: onBreak ? calculateElapsedTime(breakstart) : 0,
        workTimeBeforeBreak: !onBreak ? calculateElapsedTime(clockinstart) : 0
      },
      {
        userId,
        location: {
          lat: latlon.lat,
          lon: latlon.lon,
          permission: locationPermission
        }
      }
    );
    
    const rightnow = Date.now();
    let event_name, event_duration_ms;
    
    // Get current location
    const { lat, lon, address, permission } = await getCurrentLocation();

    if (!onBreak) {
      // Starting break - calculate time worked up to this point
      const timeWorkedBeforeBreak = calculateElapsedTime(clockinstart, rightnow);
      setLastWorkTime(timeWorkedBeforeBreak);
      
      // Store the work time in local storage
      try {
        const workTimeData = {
          userId,
          timeWorked: timeWorkedBeforeBreak,
          timestamp: rightnow,
          type: 'break_start'
        };
        localStorage.setItem(`workTime_${userId}_${rightnow}`, JSON.stringify(workTimeData));
        console.log('Work time before break saved to local storage:', timeWorkedBeforeBreak);
      } catch (error) {
        console.error('Error saving work time to local storage:', error);
      }
      
      event_name = 'Started Break';
      setOnBreak(true);
      setWorkbreak('End Break');
      setStatus('On Break');
      setBreakstart(rightnow);
      
      // Add the current work session to the array
      setWorkSessions(prev => [...prev, {
        start: clockinstart,
        end: rightnow,
        duration: timeWorkedBeforeBreak
      }]);
      
      // Update total work time
      setTotalWorkTime(prev => prev + timeWorkedBeforeBreak);
      
      event_duration_ms = timeWorkedBeforeBreak; // Store the time worked before break
    } else {
      // Ending break
      event_name = 'Ended Break';
      event_duration_ms = calculateElapsedTime(breakstart);
      setOnBreak(false);
      setWorkbreak('Break');
      setStatus('Clocked In');
      setBreakstart(0);
      
      // Reset clock-in start to now (after break)
      setClockinstart(rightnow);
    }

    // Create a time card entry with core fields
    // Additional metadata will be stored in local storage until the database schema is updated
    await createTimeCard({
      event_name,
      event_date: rightnow,
      utc_start: !onBreak ? rightnow : breakstart, // When starting break, start time is now; when ending break, start time is when break started
      utc_end: rightnow,
      event_duration_ms,
      latitude: latlon.lat,
      longitude: latlon.lon,
      location_permission: locationPermission,
      // These fields might not exist in the database yet, but we'll include them for future use
      address: address,
      work_time_before_break: !onBreak ? lastWorkTime : 0,
      total_work_time: totalWorkTime,
      work_sessions: Array.isArray(workSessions) ? 
        JSON.stringify(workSessions) : JSON.stringify([]),
      is_break: true
    });

    notify(`You have ${event_name}`);
  }, [onBreak, createTimeCard, breakstart, latlon, locationPermission, clockinstart, userId, lastWorkTime, totalWorkTime]);

  // Show a loading indicator while fetching data
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show an error message if there's an error
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '11pt',
            backgroundColor: '#00416e',
            color: '#fff',
            textAlign: 'center',
            position: 'relative',
            padding: '12px 24px',
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: (theme) => theme.spacing(1),
              top: (theme) => theme.spacing(1),
              color: '#ffffff',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: '#fff',
            minWidth: '320px',
            marginLeft: '-5px',
          }}
        >
          <TimeCardDialog
            open={open}
            onClose={onClose}
            tcdate={tcdate}
            statuscolorTimecard={statuscolorTimecard}
            status={status}
            clockbtnlabel={clockbtnlabel}
            workbreak={workbreak}
            showingHistory={showingHistory}
            clockedin={clockedin}
            handleClockin={handleClockin}
            handleBreak={handleBreak}
            timecards={filteredTimecards}
            oneDayBack={oneDayBack}
            oneDayForward={oneDayForward}
            fwdnavdisabled={fwdnavdisabled}
            isCurrentDate={isCurrentDate}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}
