import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  TextField, 
  Autocomplete, 
  Button, 
  Typography,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const InteractionFilters = ({ 
  filters, 
  onFilterChange, 
  interactionTypes = [], 
  componentNames = [], 
  users = [] 
}) => {
  // Local state for filter values
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const [selectedInteractionTypes, setSelectedInteractionTypes] = useState(filters.interactionTypes || []);
  const [selectedUsers, setSelectedUsers] = useState(filters.userIds || []);
  const [selectedComponents, setSelectedComponents] = useState(filters.components || []);

  // Apply filters when the Apply button is clicked
  const handleApplyFilters = () => {
    onFilterChange({
      startDate,
      endDate,
      interactionTypes: selectedInteractionTypes,
      userIds: selectedUsers,
      components: selectedComponents
    });
  };

  // Reset all filters
  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedInteractionTypes([]);
    setSelectedUsers([]);
    setSelectedComponents([]);
    
    onFilterChange({
      startDate: null,
      endDate: null,
      interactionTypes: [],
      userIds: [],
      components: []
    });
  };

  // Format user display for autocomplete
  const formatUserOption = (user) => {
    if (!user) return '';
    if (user.id === '00000000-0000-0000-0000-000000000000') return 'Anonymous';
    return user.email || user.first_name ? 
      `${user.first_name || ''} ${user.last_name || ''} (${user.email || 'No email'})`.trim() : 
      user.id;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" gutterBottom>
        Filter Reports
      </Typography>
      
      <Grid container spacing={3}>
        {/* Date Range Filters */}
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              maxDate={endDate || undefined}
            />
          </LocalizationProvider>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={startDate || undefined}
            />
          </LocalizationProvider>
        </Grid>
        
        {/* Interaction Type Filter */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="interaction-type-label">Interaction Types</InputLabel>
            <Select
              labelId="interaction-type-label"
              id="interaction-type-select"
              multiple
              value={selectedInteractionTypes}
              onChange={(e) => setSelectedInteractionTypes(e.target.value)}
              input={<OutlinedInput id="select-interaction-types" label="Interaction Types" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {interactionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
        {/* User Filter */}
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            id="user-filter"
            options={users}
            getOptionLabel={formatUserOption}
            value={users.filter(user => selectedUsers.includes(user.id))}
            onChange={(event, newValue) => {
              setSelectedUsers(newValue.map(user => user.id));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by User"
                placeholder="Select users"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip 
                  label={formatUserOption(option)} 
                  {...getTagProps({ index })} 
                />
              ))
            }
          />
        </Grid>
        
        {/* Component Filter */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="component-label">Components</InputLabel>
            <Select
              labelId="component-label"
              id="component-select"
              multiple
              value={selectedComponents}
              onChange={(e) => setSelectedComponents(e.target.value)}
              input={<OutlinedInput id="select-components" label="Components" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {componentNames.map((component) => (
                <MenuItem key={component} value={component}>
                  {component}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
        {/* Filter Action Buttons */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button 
              variant="outlined" 
              onClick={handleResetFilters}
            >
              Reset Filters
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleApplyFilters}
            >
              Apply Filters
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InteractionFilters;
