import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  CircularProgress, 
  Alert,
  Tabs,
  Tab,
  Divider
} from '@mui/material';
import InteractionFilters from './InteractionFilters';
import InteractionSummary from './InteractionSummary';
import PageViewsReport from './PageViewsReport';
import ButtonClicksReport from './ButtonClicksReport';
import FormSubmissionsReport from './FormSubmissionsReport';
import MapInteractionsReport from './MapInteractionsReport';
import InteractionReportExport from './InteractionReportExport';
import { 
  getUserInteractionSummary, 
  getPageViewStatistics,
  getButtonClickStatistics,
  getFormSubmissionStatistics,
  getMapInteractionStatistics,
  getInteractionTypes,
  getComponentNames,
  getUsersWithInteractions
} from '../../api/userInteractionReports';

// Tab panel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`interaction-tabpanel-${index}`}
      aria-labelledby={`interaction-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Helper function for tab accessibility
function a11yProps(index) {
  return {
    id: `interaction-tab-${index}`,
    'aria-controls': `interaction-tabpanel-${index}`,
  };
}

const InteractionDashboard = () => {
  // State for filters
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    interactionTypes: [],
    userIds: [],
    components: []
  });

  // State for data
  const [summary, setSummary] = useState(null);
  const [pageViewStats, setPageViewStats] = useState(null);
  const [buttonClickStats, setButtonClickStats] = useState(null);
  const [formSubmissionStats, setFormSubmissionStats] = useState(null);
  const [mapInteractionStats, setMapInteractionStats] = useState(null);
  
  // State for metadata
  const [interactionTypes, setInteractionTypes] = useState([]);
  const [componentNames, setComponentNames] = useState([]);
  const [users, setUsers] = useState([]);
  
  // State for UI
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [reportRef, setReportRef] = useState(null);

  // Load metadata on mount
  useEffect(() => {
    const loadMetadata = async () => {
      try {
        const [types, components, usersList] = await Promise.all([
          getInteractionTypes(),
          getComponentNames(),
          getUsersWithInteractions()
        ]);
        
        setInteractionTypes(types);
        setComponentNames(components);
        setUsers(usersList);
      } catch (err) {
        console.error('Error loading metadata:', err);
        setError('Failed to load filter options. Please try again later.');
      }
    };
    
    loadMetadata();
  }, []);

  // Load data when filters change
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const [
          summaryData, 
          pageViews, 
          buttonClicks, 
          formSubmissions, 
          mapInteractions
        ] = await Promise.all([
          getUserInteractionSummary(filters),
          getPageViewStatistics(filters),
          getButtonClickStatistics(filters),
          getFormSubmissionStatistics(filters),
          getMapInteractionStatistics(filters)
        ]);
        
        setSummary(summaryData);
        setPageViewStats(pageViews);
        setButtonClickStats(buttonClicks);
        setFormSubmissionStats(formSubmissions);
        setMapInteractionStats(mapInteractions);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load interaction data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    loadData();
  }, [filters]);

  // Handle filter changes
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  // Handle tab changes
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        User Interaction Analytics
      </Typography>
      
      <Paper sx={{ p: 2, mb: 3 }}>
        <InteractionFilters 
          filters={filters}
          onFilterChange={handleFilterChange}
          interactionTypes={interactionTypes}
          componentNames={componentNames}
          users={users}
        />
      </Paper>
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box ref={setReportRef}>
          <Paper sx={{ mb: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs 
                value={tabValue} 
                onChange={handleTabChange} 
                aria-label="interaction report tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Summary" {...a11yProps(0)} />
                <Tab label="Page Views" {...a11yProps(1)} />
                <Tab label="Button Clicks" {...a11yProps(2)} />
                <Tab label="Form Submissions" {...a11yProps(3)} />
                <Tab label="Map Interactions" {...a11yProps(4)} />
              </Tabs>
            </Box>
            
            <TabPanel value={tabValue} index={0}>
              {summary && <InteractionSummary data={summary} />}
            </TabPanel>
            
            <TabPanel value={tabValue} index={1}>
              {pageViewStats && <PageViewsReport data={pageViewStats} />}
            </TabPanel>
            
            <TabPanel value={tabValue} index={2}>
              {buttonClickStats && <ButtonClicksReport data={buttonClickStats} />}
            </TabPanel>
            
            <TabPanel value={tabValue} index={3}>
              {formSubmissionStats && <FormSubmissionsReport data={formSubmissionStats} />}
            </TabPanel>
            
            <TabPanel value={tabValue} index={4}>
              {mapInteractionStats && <MapInteractionsReport data={mapInteractionStats} />}
            </TabPanel>
          </Paper>
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <InteractionReportExport 
              reportRef={reportRef} 
              tabValue={tabValue}
              summary={summary}
              pageViewStats={pageViewStats}
              buttonClickStats={buttonClickStats}
              formSubmissionStats={formSubmissionStats}
              mapInteractionStats={mapInteractionStats}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InteractionDashboard;
