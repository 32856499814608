import React from 'react';
import { Button, styled } from "@mui/material";

const ClockinButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontWeight: '700',
  fontSize: '12pt',
  lineHeight: '1.2',
  color: '#666666',
  border: 'solid 1px',
  borderColor: '#666666',
  backgroundColor: '#FFF',
  textTransform: 'none',
  borderRadius: '4px',
  padding: '10px 16px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': { 
    backgroundColor: '#8CC63F', 
    color: '#FFF',
    borderColor: '#8CC63F',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)'
  },
  '&:disabled': { 
    backgroundColor: '#fff', 
    color: '#b3b3b3',
    borderColor: '#e0e0e0'
  },
  minWidth: '140px',
}));

export default ClockinButton;
