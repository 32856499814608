import React, { useEffect, useRef, useState } from 'react';
import { StoreContext } from '../Hooks/store';
import { saveUserInteractionData, saveUserSessionData } from '../../api/userInteractions';
import { useAuth } from '../Hooks/useAuth';
import { 
  isTrackingEnabled, 
  getCachedTrackingConfig, 
  setCachedTrackingConfig 
} from '../../api/trackingConfig';

/**
 * Custom hook for tracking user interactions
 * This hook provides functions to track user interactions and save them to both the store and Supabase
 * @returns {Object} Object containing trackInteraction and clearSessionData functions
 */
function useUserInteractionTracker() {
  // Get the store context
  const store = React.useContext(StoreContext);
  const { user } = useAuth();
  
  // Keep track of pending interactions to batch save
  const pendingInteractions = useRef([]);
  const batchSaveTimeout = useRef(null);

  // Throw an error if the store is not available
  if (!store) {
    throw new Error('useUserInteractionTracker must be used within a StoreProvider');
  }

  // Destructure the necessary values from the store
  const { myinteractions, thissessionid, thisuserid } = store;
  const [interactiondata, setinteractiondata] = myinteractions || [null, () => {}]; // Provide a fallback
  const [sessionid] = thissessionid || [null]; // Provide a fallback
  const [userid] = thisuserid || [null]; // Provide a fallback

  // Function to save interactions to Supabase
  const saveInteractionsToSupabase = async () => {
    if (pendingInteractions.current.length === 0) return;
    
    try {
      // Create a copy of the pending interactions
      const interactionsToSave = [...pendingInteractions.current];
      
      // Clear the pending interactions
      pendingInteractions.current = [];
      
      // Create the interaction data object
      const interactionDataObj = {
        interactions: interactionsToSave
      };
      
      // Get the user ID from the authenticated user or the store
      const userId = user?.id || userid;
      
      // Save to Supabase
      await saveUserInteractionData(interactionDataObj, userId, sessionid);
      
      //console.log(`Saved ${interactionsToSave.length} interactions to Supabase`);
    } catch (error) {
      console.error('Error saving interactions to Supabase:', error);
    }
  };

  // State to track whether tracking is enabled
  const [trackingEnabled, setTrackingEnabled] = useState(true);
  const [trackingConfig, setTrackingConfig] = useState(null);
  
  // Load tracking configuration on mount
  useEffect(() => {
    const loadTrackingConfig = async () => {
      try {
        // Check if we have cached config
        const cachedConfig = getCachedTrackingConfig();
        if (cachedConfig) {
          setTrackingConfig(cachedConfig);
          return;
        }
        
        // Otherwise, fetch from Supabase
        const isEnabled = await isTrackingEnabled('*', '*');
        setTrackingEnabled(isEnabled);
        
        // Cache the result
        setCachedTrackingConfig({ isEnabled });
      } catch (error) {
        console.error('Error loading tracking config:', error);
        // Default to enabled if there's an error
        setTrackingEnabled(true);
      }
    };
    
    loadTrackingConfig();
  }, []);
  
  // Function to check if tracking is enabled for a specific component and event type
  const isEnabled = async (componentName, eventType) => {
    if (!trackingEnabled) return false;
    
    try {
      // Check if we have cached config
      if (trackingConfig) {
        // TODO: Implement more sophisticated caching logic
        return trackingConfig.isEnabled;
      }
      
      // Otherwise, fetch from Supabase
      try {
        return await isTrackingEnabled(componentName, eventType);
      } catch (apiError) {
        console.error('API error checking if tracking is enabled:', apiError);
        return true; // Default to enabled if there's an API error
      }
    } catch (error) {
      console.error('Error checking if tracking is enabled:', error);
      return true; // Default to enabled if there's an error
    }
  };

  // Function to track user interactions
  const trackInteraction = async (interactionType, additionalData = {}) => {
    try {
      // Check if tracking is enabled for this interaction
      const componentName = additionalData.componentName || 'unknown';
      const enabled = await isEnabled(componentName, interactionType);
      if (!enabled) {
        console.log(`Tracking disabled for ${componentName}:${interactionType}`);
        return;
      }
    } catch (error) {
      console.error('Error checking tracking status:', error);
      // Continue with tracking as default behavior if checking fails
    }
    const newInteraction = {
      type: interactionType,
      sessionHash: sessionid ?? additionalData.sessionHash,
      timestamp: Date.now(),
      data: additionalData,
    };

    // Update the interaction data in the store
    setinteractiondata((prevInteractionData) => ({
      ...prevInteractionData,
      interactions: [...(prevInteractionData.interactions || []), newInteraction],
    }));
    
    // Add to pending interactions
    pendingInteractions.current.push(newInteraction);
    
    // Schedule a batch save if not already scheduled
    if (batchSaveTimeout.current === null) {
      batchSaveTimeout.current = setTimeout(() => {
        saveInteractionsToSupabase();
        batchSaveTimeout.current = null;
      }, 5000); // Save every 5 seconds
    }
    
    // If it's an important interaction or we have enough pending interactions, save immediately
    if (interactionType === 'login' || 
        interactionType === 'logout' || 
        interactionType === 'form_submit' ||
        pendingInteractions.current.length >= 10) {
      clearTimeout(batchSaveTimeout.current);
      batchSaveTimeout.current = null;
      saveInteractionsToSupabase();
    }
  };

  // Function to clear session data
  const clearSessionData = () => {
    // Save any pending interactions before clearing
    if (pendingInteractions.current.length > 0) {
      saveInteractionsToSupabase();
    }
    
    // Clear the timeout if it exists
    if (batchSaveTimeout.current !== null) {
      clearTimeout(batchSaveTimeout.current);
      batchSaveTimeout.current = null;
    }
    
    // Clear local storage
    localStorage.removeItem('sessiondata');
    localStorage.removeItem('interactiondata');
    localStorage.removeItem('sessionid');
    
    // Clear the store
    setinteractiondata({ interactions: [] });
  };
  
  // Save pending interactions when the component unmounts
  useEffect(() => {
    return () => {
      if (pendingInteractions.current.length > 0) {
        saveInteractionsToSupabase();
      }
      
      if (batchSaveTimeout.current !== null) {
        clearTimeout(batchSaveTimeout.current);
      }
    };
  }, []);

  // Return the functions for external use
  return {
    trackInteraction,
    clearSessionData,
  };
}

export default useUserInteractionTracker;
