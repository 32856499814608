import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  Box, 
  Typography,
  CircularProgress,
  Alert,
  Chip,
  useTheme,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { useTimeTrackContext } from '../context/TimeTrackContext';
import '../styles.css';
import { stateConfigs } from './stateConfigs';
import StateRadioButton from './StateRadioButton';
import { checkBreakNotificationStatus } from '../../../api/timetrack';
import { useAuth } from '../../Hooks/useAuth';
import { supabase } from '../../../lib/supabase';

const BreakSchedule = ({ open, onClose }) => {
  const { user } = useAuth();
  const userId = user?.id;
  const [breakStatuses, setBreakStatuses] = useState({});
  const [breaksWithHashes, setBreaksWithHashes] = useState([]);
  const { 
    clockState,
    error,
    loading,
    selectedState,
    setSelectedState,
    formatTime,
    isCurrentDateToday,
    loadBreakRules
  } = useTimeTrackContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleStateChange = (event) => {
    const newState = event.target.value;
    console.log('State changed to:', newState);
    setSelectedState(newState);
    loadBreakRules(newState);
  };
  
  // Load break notification statuses when the component mounts or when the selected state changes
  useEffect(() => {
    if (!userId || !open || !loadBreakRules) return;
    
    const loadBreakStatuses = async () => {
      try {
        // Get break rules from the database
        const breakRulesData = await loadBreakRules(selectedState);
        
        

        // Function to process break rules data
        const processBreakRules = (rulesData) => {
          // Format the break rules data to match the stateConfigs shape
          const stateConfig = {
            title: `${selectedState} Break Requirements`,
            breaks: rulesData.map(rule => {
              const timeText = `After ${rule.min_work_hours} hour${rule.min_work_hours !== 1 ? 's' : ''}`;
              const breakType = rule.rule_type.charAt(0).toUpperCase() + rule.rule_type.slice(1); // Capitalize
              const duration = `${rule.duration_minutes} min`;
              return [timeText, breakType, duration, rule.paid, rule.min_work_hours];
            }),
            notes: [
              `Break requirements are based on ${selectedState} state labor laws.`,
              'Paid breaks mean you continue to be paid during the break period.',
              'Unpaid breaks are not included in your paid work time.',
              'Breaks should be taken at appropriate intervals as shown above.'
            ]
          };
          
          
          const statuses = {};
          const breaksWithHashesArray = stateConfig.breaks.map(breakInfo => {
            const [timeText, breakType, duration, isPaid, hoursFromStart] = breakInfo;
            const durationMinutes = parseInt(duration.replace(' min', ''));
            
            // Generate session hash matching timetrack.js implementation
            // IMPORTANT: Order must match exactly with timetrack.js
            const hashInput = `${userId}-${breakType.toLowerCase()}-${durationMinutes}-${isPaid ? 'paid' : 'unpaid'}-${hoursFromStart}-${selectedState}`;
            const breakHash = hashInput.split('').reduce((a,b) => {
              a = ((a << 5) - a) + b.charCodeAt(0);
              return a & a;
            }, 0).toString(16).substring(0, 8);
            
            console.log(`[DEBUG] Break hash for ${breakType} (${durationMinutes} min): Input=${hashInput}, Hash=${breakHash}`);
            console.log(`[DEBUG] Hash components: userId=${userId}, breakType=${breakType.toLowerCase()}, durationMinutes=${durationMinutes}, isPaid=${isPaid ? 'paid' : 'unpaid'}, hoursFromStart=${hoursFromStart}, state=${selectedState}`);
            
            return [...breakInfo, breakHash];
          });
          
          // Check break statuses for each break type using the hash
          const checkStatuses = async () => {
            for (const breakInfo of stateConfig.breaks) {
              const [, breakType, duration, isPaid, hoursFromStart] = breakInfo;
              const durationMinutes = parseInt(duration.replace(' min', ''));
              const breakTypeKey = breakType.toLowerCase();
              
              // Generate the hash for this break
              const hashInput = `${userId}-${breakTypeKey}-${durationMinutes}-${isPaid ? 'paid' : 'unpaid'}-${hoursFromStart}-${selectedState}`;
              const breakHash = hashInput.split('').reduce((a,b) => {
                a = ((a << 5) - a) + b.charCodeAt(0);
                return a & a;
              }, 0).toString(16).substring(0, 8);
              
              try {
                // Check status using the hash
                const { data: notifications, error } = await supabase
                  .from('break_notifications')
                  .select('*')
                  .eq('user_id', userId)
                  .eq('session_hash', breakHash)
                  .order('created_at', { ascending: false })
                  .limit(1);
                
                if (error) {
                  console.error(`Error checking break notifications for hash ${breakHash}:`, error);
                  continue;
                }
                
                if (notifications && notifications.length > 0) {
                  const notification = notifications[0];
                  statuses[breakHash] = {
                    dismissed: notification.dismissed,
                    dismissedTime: notification.dismissed_time,
                    breakTaken: notification.break_taken,
                    breakTakenTime: notification.break_taken_time,
                    dismissedPermanently: notification.dismissed_permanently,
                    deviceId: notification.device_id
                  };
                } else {
                  // Fallback to the API method if no direct match found
                  const status = await checkBreakNotificationStatus(userId, breakTypeKey, durationMinutes);
                  if (status) {
                    statuses[`${breakType}-${durationMinutes}`] = status;
                  }
                }
              } catch (error) {
                console.error(`Error checking break status for ${breakType}:`, error);
              }
            }
            
            setBreakStatuses(statuses);
            setBreaksWithHashes(breaksWithHashesArray);
          };
          
          checkStatuses();
        };
          
        // Check if we have valid data - handle both array and undefined cases
        if (!breakRulesData) {
          console.log(`No break rules found for state: ${selectedState}`);
          
          // Try to get the rules directly from the API instead
          try {
            const { getBreakRules } = await import('../../../api/timetrack');
            const directRules = await getBreakRules(selectedState);
            if (!directRules || directRules.length === 0) {
              console.log('No break rules found even with direct API call');
              setBreakStatuses({});
              setBreaksWithHashes([]);
              return;
            }
            
            // Use the directly fetched rules instead
            processBreakRules(directRules);
            return;
          } catch (directError) {
            console.error('Error fetching break rules directly:', directError);
            setBreakStatuses({});
            setBreaksWithHashes([]);
            return;
          }
        }
        
        if (Array.isArray(breakRulesData) && breakRulesData.length === 0) {
          console.log(`No break rules found for state: ${selectedState}`);
          setBreakStatuses({});
          setBreaksWithHashes([]);
          return;
        }
        
        
        
        // Now process the break rules data after the function is defined
        processBreakRules(breakRulesData);
      } catch (error) {
        console.error('Error loading break rules:', error);
        setBreakStatuses({});
        setBreaksWithHashes([]);
      }
    };
    
    loadBreakStatuses();
  }, [userId, selectedState, open, loadBreakRules]);

  const renderBreakScheduleContent = () => {
    if (loading.breakSchedule) {
      return (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: 300,
          gap: 2 
        }}>
          <CircularProgress />
          <Typography color="textSecondary">
            Loading break requirements...
          </Typography>
        </Box>
      );
    }

    const getNextBreakTime = (hoursFromStart) => {
      if (!clockState.clockedIn || !isCurrentDateToday()) return null;
      return new Date(clockState.clockInTime + (hoursFromStart * 60 * 60 * 1000));
    };

    const renderBreakRow = (timeText, breakType, duration, isPaid, hoursFromStart, breakHash) => {
      const nextBreakTime = getNextBreakTime(hoursFromStart);
      const isBreakDue = nextBreakTime && nextBreakTime < new Date();
      
      // Parse duration to get minutes
      const durationMinutes = parseInt(duration.replace(' min', ''));
      
      // Get break status from state using the hash
      const breakStatus = breakStatuses[breakHash] || breakStatuses[`${breakType}-${durationMinutes}`];
      
      // Determine status chip to display
      let statusChip = null;
      
      if (breakStatus) {
        if (breakStatus.breakTaken) {
          // Break was taken
          const takenTime = new Date(breakStatus.breakTakenTime);
          statusChip = (
            <Tooltip title={`Break taken at ${formatTime(takenTime)}`}>
              <Chip 
                label={`Taken at ${formatTime(takenTime)}`}
                size="small" 
                color="success"
                sx={{ ml: 1, height: 20 }}
              />
            </Tooltip>
          );
        } else if (breakStatus.dismissed) {
          // Break was dismissed
          statusChip = (
            <Chip 
              label="User Dismissed" 
              size="small" 
              color="default"
              sx={{ ml: 1, height: 20 }}
            />
          );
        }
      } else if (isBreakDue) {
        // Break is due but not dismissed or taken
        statusChip = (
          <Chip 
            label="Due" 
            size="small" 
            color="warning"
            sx={{ ml: 1, height: 20 }}
          />
        );
      }

      return (
        <Box 
          className="break-schedule-row" 
          sx={{
            bgcolor: isBreakDue && !breakStatus ? 'rgba(251, 176, 59, 0.1)' : 'inherit',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box className="break-schedule-cell">
            <span className="break-schedule-cell-content">
              {timeText}
              {nextBreakTime && !breakStatus && (
                <>
                  <br />
                  <small style={{ 
                    color: isBreakDue ? '#ff9800' : '#0080BB',
                    fontWeight: isBreakDue ? 600 : 400
                  }}>
                    {isBreakDue ? 'Break Due: ' : 'Next Break: '}
                    {formatTime(nextBreakTime)}
                  </small>
                </>
              )}
            </span>
          </Box>

          <Box className="break-schedule-cell">
            <span className="break-schedule-cell-content">
              {breakType}
              {statusChip}
            </span>
          </Box>
          <Box className="break-schedule-cell">{duration}</Box>
          <Box className="break-schedule-cell">
            <Chip 
              label={isPaid ? 'Paid' : 'Unpaid'} 
              size="small"
              color={isPaid ? 'success' : 'default'}
              sx={{ height: 20 }}
            />
          </Box>
          <Box className="break-schedule-cell">
            <Tooltip title="Unique break identifier">
              <Chip 
                label={breakHash} 
                size="small"
                color="info"
                sx={{ 
                  height: 20,
                  fontFamily: 'monospace',
                  fontSize: '0.7rem'
                }}
              />
            </Tooltip>
          </Box>          
        </Box>
      );
    };

    const renderStateContent = () => (
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h6" sx={{ 
            color: '#00416e', 
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            gap: 1 
          }}>
            <span>{selectedState} Break Requirements</span>
            {clockState.clockedIn && isCurrentDateToday() && (
              <Chip 
                label="Active Shift" 
                size="small" 
                color="success"
                sx={{ height: 24 }}
              />
            )}
          </Typography>

          {clockState.clockedIn && (
            <Typography variant="body2" color="textSecondary">
              Shift started: {formatTime(new Date(clockState.clockInTime))}
            </Typography>
          )}
        </Box>
        
        <Box className="break-schedule-table" sx={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 1,
          overflow: 'hidden'
        }}>
          <Box className="break-schedule-header" sx={{ bgcolor: '#f5f5f5' }}>
            <Box className="break-schedule-cell">Time</Box>
            <Box className="break-schedule-cell">Break Type</Box>
            <Box className="break-schedule-cell">Duration</Box>
            <Box className="break-schedule-cell">Status</Box>
            <Box className="break-schedule-cell">Break ID</Box>
          </Box>

          {breaksWithHashes.map((breakInfo, index) => (
            <React.Fragment key={index}>
              {renderBreakRow(...breakInfo)}
            </React.Fragment>
          ))}
        </Box>

        <Box sx={{ 
          mt: 3, 
          display: 'flex', 
          justifyContent: 'center'
        }}>
          <button 
            onClick={onClose}
            style={{
              padding: '10px 20px',
              backgroundColor: '#00416e',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              fontSize: '16px',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              width: isMobile ? '100%' : 'auto'
            }}
          >
            Close
          </button>
        </Box>
        
        <Box sx={{ 
          mt: 4, 
          p: 2, 
          backgroundColor: '#f9f9f9', 
          borderRadius: 1,
          border: '1px solid rgba(0, 0, 0, 0.08)'
        }}>
          <Typography variant="h6" sx={{ 
            mb: 1, 
            color: '#00416e', 
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <InfoIcon fontSize="small" />
            Important Notes:
          </Typography>
          <ul style={{ marginBottom: 0 }}>
            <li>Break requirements are based on {selectedState} state labor laws.</li>
            <li>Paid breaks mean you continue to be paid during the break period.</li>
            <li>Unpaid breaks are not included in your paid work time.</li>
            <li>Breaks should be taken at appropriate intervals as shown above.</li>
          </ul>
        </Box>
        

      </Box>
    );

    return breaksWithHashes.length > 0 ? renderStateContent() : (
      <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>
        No break requirements found for {selectedState}. Please select a different state.
      </Typography>
    );
  };

  // This useEffect dependency array was incorrectly placed
  useEffect(() => {
    // Add any effect logic here if needed
  }, [
    userId, selectedState, clockState, formatTime, isCurrentDateToday,
    breakStatuses, breaksWithHashes, loading, loadBreakRules
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          minHeight: isMobile ? '100vh' : 'auto'
        }
      }}
    >
      <DialogTitle
        sx={{
          bgcolor: '#00416e',
          color: 'white',
          padding: '16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h6" component="div">
          Break Schedule Information
        </Typography>
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'medium' }}>
            Select State:
          </Typography>
          
          <Box 
            sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: 1,
              justifyContent: { xs: 'center', sm: 'flex-start' }
            }}
          >
            {loading.stateSelect ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2 }}>
                <CircularProgress size={20} />
                <span>Loading states...</span>
              </Box>
            ) : (
              <>
                <StateRadioButton 
                  state="WA" 
                  label="Washington" 
                  selectedState={selectedState} 
                  onChange={handleStateChange} 
                />
                <StateRadioButton 
                  state="OR" 
                  label="Oregon" 
                  selectedState={selectedState} 
                  onChange={handleStateChange} 
                />
                <StateRadioButton 
                  state="NV" 
                  label="Nevada" 
                  selectedState={selectedState} 
                  onChange={handleStateChange} 
                />
                <StateRadioButton 
                  state="US" 
                  label="Federal (Default)" 
                  selectedState={selectedState} 
                  onChange={handleStateChange} 
                />
              </>
            )}
          </Box>
        </Box>

        {renderBreakScheduleContent()}
      </DialogContent>
    </Dialog>
  );
};

export default BreakSchedule;
