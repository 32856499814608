import React, { useEffect, useRef, memo, useState } from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { iconMap, createCustomIcon } from '../mapUtils';

const MarkerLayer = memo(({ 
  markers, 
  map, 
  onMarkerClick, 
  handleEditLocation, 
  handleDeleteLocation,
  getCommentCount 
}) => {
  const layerRef = useRef(null);

  useEffect(() => {
    if (!map) return;

    // Create a new layer group for markers if it doesn't exist
    if (!layerRef.current) {
      layerRef.current = L.layerGroup().addTo(map);
    }

    // Batch marker updates
    const updateMarkers = async () => {
      // Clear existing markers
      layerRef.current.clearLayers();

      // Create markers in batch
      const markerPromises = markers
        .filter(location => {
          // Validate coordinates
          if (!location?.coords?.lat || !location?.coords?.lng) {
            console.warn('Invalid coordinates for location:', location);
            return false;
          }
          return true;
        })
        .map(async (location) => {
          const IconComponent = iconMap[location.icon] || iconMap.PlaceIcon;
          const customIcon = createCustomIcon(IconComponent, location.color || '#3f51b5');
          const marker = L.marker([location.coords.lat, location.coords.lng], { icon: customIcon });

          // Get comment count asynchronously
          let commentCount = 0;
          try {
            commentCount = await getCommentCount(location.id);
          } catch (error) {
            console.error("Error fetching comment count:", error);
          }

          // Create popup content
          const popupContent = document.createElement('div');
          popupContent.innerHTML = `
            <div style="padding: 10px;">
              <strong>${location.name || 'Unnamed Location'}</strong>
              <p>${location.address || 'No address'}</p>
              <p>${location.description || 'No description'}</p>
              <p><strong>Coordinates:</strong> ${location.coords.lat.toFixed(6)}, ${location.coords.lng.toFixed(6)}</p>
              <p><strong>Comments:</strong> ${commentCount}</p>
              <button class="edit-button" style="padding: 10px; background-color: #4CAF50; color: white; border: none; cursor: pointer; margin-right: 10px;">
                Edit Location
              </button>
              <button class="delete-button" style="padding: 10px; background-color: #ff4d4d; color: white; border: none; cursor: pointer;">
                Delete Location
              </button>
            </div>
          `;

          // Add event listeners
          const editButton = popupContent.querySelector('.edit-button');
          const deleteButton = popupContent.querySelector('.delete-button');
          
          editButton.addEventListener('click', () => handleEditLocation(location.id));
          deleteButton.addEventListener('click', () => handleDeleteLocation(location.id));

          marker.bindPopup(popupContent);
          marker.on('click', () => onMarkerClick(location));

          return marker;
        });

      // Wait for all markers to be created
      const createdMarkers = await Promise.all(markerPromises);
      
      // Add all markers to the layer group at once
      layerRef.current.addLayer(L.layerGroup(createdMarkers));
    };

    updateMarkers();

    // Cleanup
    return () => {
      if (layerRef.current) {
        layerRef.current.clearLayers();
      }
      // if (geoJSONLayerRef.current) {
      //   geoJSONLayerRef.current.clearLayers();
      // }
    };
  }, [map, markers, onMarkerClick, handleEditLocation, handleDeleteLocation, getCommentCount]);
  // }, [map, markers, onMarkerClick, handleEditLocation, handleDeleteLocation, getCommentCount, seattle1GeoJSON, seattle2GeoJSON]);

  return null;
});

MarkerLayer.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    coords: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    name: PropTypes.string,
    address: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  map: PropTypes.object,
  onMarkerClick: PropTypes.func.isRequired,
  handleEditLocation: PropTypes.func.isRequired,
  handleDeleteLocation: PropTypes.func.isRequired,
  getCommentCount: PropTypes.func.isRequired,
};

export default MarkerLayer;
