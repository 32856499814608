import React from 'react';
import { ReactComponent as BackArrow } from './back_arrow.svg';
import { ReactComponent as ForwardArrow } from './fwd_arrow.svg';
import { Box, Typography, Paper } from '@mui/material';

export default function TimeCardHeader({ tcdate, oneDayBack, oneDayForward, fwdnavdisabled }) {
  return (
    <Paper 
      elevation={0}
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        padding: '10px 16px',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        border: '1px solid #e0e0e0'
      }}
    >
      <BackArrow 
        onClick={oneDayBack} 
        width={30} 
        height={30} 
        style={{ 
          cursor: 'pointer', 
          fill: '#00416e' 
        }} 
      />
      <Typography 
        variant="h6" 
        sx={{ 
          color: '#00416e', 
          fontWeight: 'bold',
          fontFamily: 'Montserrat'
        }}
      >
        {tcdate}
      </Typography>
      <ForwardArrow 
        disabled={fwdnavdisabled} 
        onClick={oneDayForward} 
        width={30} 
        height={30} 
        style={{ 
          cursor: fwdnavdisabled ? 'default' : 'pointer',
          fill: '#00416e',
          opacity: fwdnavdisabled ? 0.5 : 1
        }} 
      />
    </Paper>
  );
}
