import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';
import { hashCode } from '../components/Utils/hashCode';
import { z } from 'zod';

// Cache for frequently accessed data
const todoListCache = new Map();
const todoListObjCache = new Map();

// Constants
const TODO_LIST_TABLE = 'todo_list';
const TODO_TABLE = 'todo';
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes in milliseconds

// Zod Schemas
const todoListSchema = z.object({
  userId: z.string().uuid(),
  listName: z.string().min(1, 'List name is required'),
  archived: z.boolean().default(false),
});

const todoItemSchema = z.object({
  userId: z.string().uuid(),
  listId: z.string().uuid(),
  description: z.string().min(1, 'Description is required'),
  completed: z.boolean().default(false),
});

/**
 * Helper function to check if a cache entry is expired.
 * @param {number} timestamp - The timestamp when the cache entry was created.
 * @returns {boolean} - True if the cache entry is expired, otherwise false.
 */
const isCacheExpired = (timestamp) => {
  return Date.now() - timestamp > CACHE_TTL;
};

/**
 * Helper function to clear expired cache entries.
 */
const clearExpiredCacheEntries = () => {
  const now = Date.now();

  // Clear expired entries in todoListCache
  for (const [key, { timestamp }] of todoListCache.entries()) {
    if (isCacheExpired(timestamp)) {
      todoListCache.delete(key);
    }
  }

  // Clear expired entries in todoListObjCache
  for (const [key, { timestamp }] of todoListObjCache.entries()) {
    if (isCacheExpired(timestamp)) {
      todoListObjCache.delete(key);
    }
  }
};

// Periodically clear expired cache entries
setInterval(clearExpiredCacheEntries, CACHE_TTL);

/**
 * Helper function for updating todo list fields.
 * @param {string} listId - The ID of the todo list (UUID).
 * @param {string} field - The field to update (e.g., 'list_name', 'archived').
 * @param {any} value - The new value for the field.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
const updateTodoListField = async (listId, field, value) => {
  try {
    const { error } = await supabase
      .from(TODO_LIST_TABLE)
      .update({ [field]: value })
      .eq('id', listId);

    if (error) throw new Error(`Failed to update ${field}: ${error.message}`);

    // Invalidate cache for this list
    todoListCache.clear();
  } catch (error) {
    console.error(`Error in updateTodoListField (${field}):`, error);
    throw new Error(`Failed to update ${field}. Please try again.`);
  }
};

/**
 * Create a new todo list.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @param {string} listName - The name of the todo list.
 * @param {boolean} [archived=false] - Whether the list is archived.
 * @returns {Promise<{ id: string, user_id: string, list_name: string, archived: boolean, created_at: string, updated_at: string }>} - A promise that resolves with the newly created todo list.
 */
export async function createTodoList(userId, listName, archived = false) {
  // Validate input
  const validation = todoListSchema.safeParse({ userId, listName, archived });
  if (!validation.success) {
    throw new Error(`Validation error: ${validation.error.errors[0].message}`);
  }

  try {
    const hash = hashCode(userId, listName, archived);

    if (todoListObjCache.has(hash)) {
      const cachedListId = todoListObjCache.get(hash).data;
      console.log('Returning cached list ID:', cachedListId);
      return { id: cachedListId };
    }

    const todoList = {
      id: uuidv4(),
      user_id: userId,
      list_name: listName,
      archived,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    const { data, error } = await supabase
      .from(TODO_LIST_TABLE)
      .insert([todoList])
      .select()
      .single();

    if (error) throw new Error(`Failed to create todo list: ${error.message}`);
    if (!data) throw new Error('No data returned from the server');

    // Cache the new list with a timestamp
    todoListObjCache.set(hash, { data: data.id, timestamp: Date.now() });
    console.log('New list created and cached:', data.id);

    // Invalidate the user's todo list cache
    todoListCache.delete(userId);

    return data;
  } catch (error) {
    console.error('Error in createTodoList:', error);
    throw new Error('Failed to create todo list. Please try again.');
  }
}

/**
 * Fetch todo lists for a specific user, sorted by created_at in descending order.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @returns {Promise<Array<{ id: string, user_id: string, list_name: string, archived: boolean, created_at: string, updated_at: string }>>} - A promise that resolves with the todo lists.
 */
export async function getTodoLists(userId) {
  if (todoListCache.has(userId)) {
    const { data, timestamp } = todoListCache.get(userId);
    if (!isCacheExpired(timestamp)) {
      return data; // Return cached data if not expired
    }
  }

  try {
    const { data, error } = await supabase
      .from(TODO_LIST_TABLE)
      .select('*')
      .eq('user_id', userId)
      .order('updated_at', { ascending: false }); // Sort by created_at in descending order

    if (error) throw new Error(`Failed to fetch todo lists: ${error.message}`);

    // Cache the result with a timestamp
    todoListCache.set(userId, { data, timestamp: Date.now() });
    return data;
  } catch (error) {
    console.error('Error in getTodoLists:', error);
    throw new Error('Failed to fetch todo lists. Please try again.');
  }
}

/**
 * Update the name of a todo list.
 * @param {string} listId - The ID of the todo list (UUID).
 * @param {string} listName - The new name of the todo list.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
export const updateTodoListName = async (listId, listName) => {
  await updateTodoListField(listId, 'list_name', listName);
};

/**
 * Archive a todo list.
 * @param {string} listId - The ID of the todo list (UUID).
 * @returns {Promise<void>} - A promise that resolves when the list is archived.
 */
export const archiveTodoList = async (listId) => {
  await updateTodoListField(listId, 'archived', true);
};

/**
 * Delete a todo list.
 * @param {string} listId - The ID of the todo list (UUID).
 * @returns {Promise<void>} - A promise that resolves when the list is deleted.
 */
export async function deleteTodoList(listId) {
  try {
    const { error } = await supabase
      .from(TODO_LIST_TABLE)
      .delete()
      .eq('id', listId);

    if (error) throw new Error(`Failed to delete todo list: ${error.message}`);

    // Clear cache after deletion
    todoListCache.clear();
    todoListObjCache.clear();
  } catch (error) {
    console.error('Error in deleteTodoList:', error);
    throw new Error('Failed to delete todo list. Please try again.');
  }
}

/**
 * Check if a todo list exists in the database.
 * @param {string} listId - The ID of the todo list (UUID).
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the list exists, otherwise `false`.
 */
export async function checkIfListExists(listId) {
  try {
    const { data, error } = await supabase
      .from(TODO_LIST_TABLE)
      .select('id')
      .eq('id', listId)
      .maybeSingle();

    if (error) throw new Error(`Failed to check if list exists: ${error.message}`);
    return !!data;
  } catch (error) {
    console.error('Error in checkIfListExists:', error);
    return false;
  }
}

/**
 * Create a new todo item.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @param {string} listId - The ID of the todo list (UUID).
 * @param {string} description - The description of the todo item.
 * @param {boolean} [completed=false] - Whether the todo item is completed.
 * @returns {Promise<{ id: string, user_id: string, list_id: string, description: string, completed: boolean, created_at: string, updated_at: string }>} - A promise that resolves with the newly created todo item.
 */
export async function createTodoItem(userId, listId, description, completed = false) {
  try {
    const todoItem = {
      id: uuidv4(),
      user_id: userId,
      list_id: listId,
      description,
      completed,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    const { data, error } = await supabase
      .from(TODO_TABLE)
      .insert([todoItem])
      .select()
      .single();

    if (error) throw new Error(`Failed to create todo item: ${error.message}`);
    if (!data) throw new Error('No data returned from the server');

    return data;
  } catch (error) {
    console.error('api: Error in createTodoItem:', error);
    throw new Error('Failed to create todo item. Please try again.');
  }
}

/**
 * Fetch todo items for a specific list.
 * @param {string} listId - The ID of the todo list (UUID).
 * @returns {Promise<Array<{ id: string, user_id: string, list_id: string, description: string, completed: boolean, created_at: string, updated_at: string }>>} - A promise that resolves with the todo items.
 */
export async function getTodoItems(listId) {
  try {
    const { data, error } = await supabase
      .from(TODO_TABLE)
      .select('*')
      .eq('list_id', listId)
      .order('updated_at', { ascending: false });

    if (error) throw new Error(`Failed to fetch todo items: ${error.message}`);
    return data;
  } catch (error) {
    console.error('Error in getTodoItems:', error);
    throw new Error('Failed to fetch todo items. Please try again.');
  }
}

/**
 * Update the description of a todo item.
 * @param {string} todoId - The ID of the todo item (UUID).
 * @param {string} description - The new description of the todo item.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
export async function updateTodoItemDescription(todoId, description) {
  try {
    const { error } = await supabase
      .from(TODO_TABLE)
      .update({ description, updated_at: new Date().toISOString() })
      .eq('id', todoId);

    if (error) throw new Error(`Failed to update todo item description: ${error.message}`);
  } catch (error) {
    console.error('Error in updateTodoItemDescription:', error);
    throw new Error('Failed to update todo item description. Please try again.');
  }
}

/**
 * Toggle the completion status of a todo item.
 * @param {string} todoId - The ID of the todo item (UUID).
 * @param {boolean} completed - Whether the todo item is completed.
 * @param {string} [timeSpentText] - Optional text describing time spent on the todo item.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
export async function toggleTodoItemCompletion(todoId, completed, timeSpentText = null) {
  try {
    const now = new Date().toISOString();
    const updateData = { 
      completed, 
      updated_at: now
    };
    
    // If the todo is being completed, set the ended_at timestamp
    if (completed) {
      updateData.ended_at = now;
    }
    
    // Only add time_spent_text if it's provided
    if (timeSpentText) {
      updateData.time_spent_text = timeSpentText;
    }
    
    const { error } = await supabase
      .from(TODO_TABLE)
      .update(updateData)
      .eq('id', todoId);

    if (error) throw new Error(`Failed to toggle todo item completion: ${error.message}`);
  } catch (error) {
    console.error('Error in toggleTodoItemCompletion:', error);
    throw new Error('Failed to toggle todo item completion. Please try again.');
  }
}

/**
 * Update the time spent text of a todo item.
 * @param {string} todoId - The ID of the todo item (UUID).
 * @param {string} timeSpentText - The text describing time spent on the todo item.
 * @param {string} [endedAt] - Optional ISO timestamp when the todo item was ended.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
export async function updateTodoItemTimeSpent(todoId, timeSpentText, endedAt = null) {
  try {
    const now = new Date().toISOString();
    const updateData = { 
      time_spent_text: timeSpentText,
      updated_at: now
    };
    
    // Add ended_at if provided, otherwise use current time
    updateData.ended_at = endedAt || now;
    
    const { error } = await supabase
      .from(TODO_TABLE)
      .update(updateData)
      .eq('id', todoId);

    if (error) throw new Error(`Failed to update todo item time spent: ${error.message}`);
  } catch (error) {
    console.error('Error in updateTodoItemTimeSpent:', error);
    throw new Error('Failed to update todo item time spent. Please try again.');
  }
}

/**
 * Add a time spent history record for a todo item.
 * @param {string} todoId - The ID of the todo item (UUID).
 * @param {string} userId - The ID of the user (UUID).
 * @param {number} timeSpentMs - The time spent in milliseconds.
 * @param {string} startedAt - The ISO timestamp when the timer was started.
 * @param {string} endedAt - The ISO timestamp when the timer was stopped.
 * @returns {Promise<void>} - A promise that resolves when the history record is created.
 */
export async function addTodoTimeSpentHistory(todoId, userId, timeSpentMs, startedAt, endedAt) {
  try {
    const historyRecord = {
      todo_id: todoId,
      user_id: userId,
      time_spent_ms: timeSpentMs,
      started_at: startedAt,
      ended_at: endedAt,
      created_at: new Date().toISOString()
    };
    
    const { error } = await supabase
      .from('todo_timespent_history')
      .insert([historyRecord]);

    if (error) throw new Error(`Failed to add todo time spent history: ${error.message}`);
  } catch (error) {
    console.error('Error in addTodoTimeSpentHistory:', error);
    throw new Error('Failed to add todo time spent history. Please try again.');
  }
}

/**
 * Get the total time spent on a todo item.
 * @param {string} todoId - The ID of the todo item (UUID).
 * @returns {Promise<number>} - A promise that resolves with the total time spent in milliseconds.
 */
export async function getTotalTimeSpentOnTodo(todoId) {
  try {
    const { data, error } = await supabase
      .from('todo_timespent_history')
      .select('time_spent_ms')
      .eq('todo_id', todoId);

    if (error) throw new Error(`Failed to get total time spent: ${error.message}`);
    
    // Sum up all time_spent_ms values
    const totalTimeSpent = data.reduce((total, record) => total + record.time_spent_ms, 0);
    return totalTimeSpent;
  } catch (error) {
    console.error('Error in getTotalTimeSpentOnTodo:', error);
    throw new Error('Failed to get total time spent. Please try again.');
  }
}

/**
 * Update the started_at timestamp of a todo item.
 * @param {string} todoId - The ID of the todo item (UUID).
 * @param {string} startedAt - The ISO timestamp when the todo item was started.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
export async function updateTodoItemStartTime(todoId, startedAt) {
  try {
    const { error } = await supabase
      .from(TODO_TABLE)
      .update({ 
        started_at: startedAt,
        updated_at: new Date().toISOString() 
      })
      .eq('id', todoId);

    if (error) throw new Error(`Failed to update todo item start time: ${error.message}`);
  } catch (error) {
    console.error('Error in updateTodoItemStartTime:', error);
    throw new Error('Failed to update todo item start time. Please try again.');
  }
}

/**
 * Delete a todo item.
 * @param {string} todoId - The ID of the todo item (UUID).
 * @returns {Promise<void>} - A promise that resolves when the todo item is deleted.
 */
export async function deleteTodoItem(todoId) {
  try {
    const { error } = await supabase
      .from(TODO_TABLE)
      .delete()
      .eq('id', todoId);

    if (error) throw new Error(`Failed to delete todo item: ${error.message}`);
  } catch (error) {
    console.error('Error in deleteTodoItem:', error);
    throw new Error('Failed to delete todo item. Please try again.');
  }
}
