import React, { useEffect } from 'react';
import '@fontsource/roboto';
import { Paper, Avatar, Button, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, Outlet } from 'react-router-dom';
import { withRouter } from '../withRouter';
import { useAuth } from '../Hooks/useAuth'; // Import the custom hook
import { PrimarySearchAppBar, StickyFooter } from '../Layouts';
import Map from '../Map';
import StoreProvider from '../Hooks/store';

const Main = styled('main')(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  maxWidth: 600,
  [theme.breakpoints.up(400 + theme.spacing(3))]: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(4),
  backgroundColor: theme.palette.secondary.main,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

function Dashboard(props) {
  const navigate = useNavigate();
  const { user, loading, logout } = useAuth(); // Use the custom hook

  useEffect(() => {
    if (!loading && !user) {
      // Redirect to login if the user is not authenticated
      alert('Please login first');
      navigate('/login', { replace: true });
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading spinner while checking auth state
  }

  return (
    <StoreProvider>
      <CssBaseline />
      <PrimarySearchAppBar />
      {/* <Notifications /> */}
      <main>
        <Outlet />
      </main>
      <Map width="100vw" height="95vh" /> {/* Pass width and height as props */}
      <StickyFooter />
    </StoreProvider>
  );
}

export default withRouter(Dashboard);
