import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { StoreContext } from '../Hooks/store';
import TimeTrackHeader from './components/TimeTrackHeader';
import TimeTrackControls from './components/TimeTrackControls';
import DateNavigation from './components/DateNavigation';
import TimeEntriesList from './components/TimeEntriesList';
import BreakNotification from './components/BreakNotification';
import { TimeTrackProvider, useTimeTrackContext } from './context/TimeTrackContext';
import { trackTimeTrackAutoBreakEnd } from '../../utils/trackInteractions';
import { createTimeTrackEntry } from '../../api/timetrack';
import './styles.css';

// Inner component to access context
const TimeTrackContent = ({ onClose }) => {
  // Get the pending break type from store
  const storeContextValue = useContext(StoreContext);
  const { pendingBreakType: [pendingBreakType, setPendingBreakType] } = storeContextValue;
  const { 
    showBreakNotification, 
    breakNotification,
    handleDismissBreakNotification,
    handleTakeBreakFromNotification,
    userId,
    entries,
    setEntries,
    refreshEntries,
    handleBreak
  } = useTimeTrackContext();
  
  // Keep track of breaks we've already refreshed
  const [refreshedBreakIds, setRefreshedBreakIds] = useState(new Set());
  
  // Handle pending break type when component mounts
  useEffect(() => {
    if (pendingBreakType) {
      console.log('Taking pending break:', pendingBreakType);
      handleBreak(pendingBreakType);
      setPendingBreakType(null);
    }
  }, [pendingBreakType, handleBreak, setPendingBreakType]);
  
  // Periodically refresh entries to show completed breaks and automatically end breaks when time is up
  useEffect(() => {
    if (!userId) return;
    
    // Function to check for active breaks that should have ended
    const checkAndRefreshEntries = async () => {
      // Find the most recent break entry
      const activeBreakEntry = entries.find(entry => 
        entry.status === 'break' && 
        !entries.some(e => e.status === 'break-end' && e.timestamp > entry.timestamp)
      );
      
      if (activeBreakEntry) {
        // Skip if we've already refreshed this break
        const breakId = activeBreakEntry.id || activeBreakEntry.local_id;
        if (refreshedBreakIds.has(breakId)) {
          return;
        }
        
        const breakStartTime = activeBreakEntry.timestamp;
        const breakDuration = activeBreakEntry.duration * 60 * 1000; // Convert minutes to milliseconds
        const breakEndTime = breakStartTime + breakDuration;
        const now = new Date().getTime();
        
        // If the break should have ended by now, automatically end it
        if (now > breakEndTime) {
          console.log('Break time is up, automatically ending break...');
          
          // Track automatic break end
          trackTimeTrackAutoBreakEnd(
            activeBreakEntry.break_type,
            activeBreakEntry.duration,
            { userId }
          );
          
          // Mark this break as refreshed
          setRefreshedBreakIds(prev => {
            const newSet = new Set(prev);
            newSet.add(breakId);
            return newSet;
          });
          
          // Get the last known location or use default values
          const locationData = {
            latitude: activeBreakEntry.latitude || 0,
            longitude: activeBreakEntry.longitude || 0,
            address: activeBreakEntry.address || 'Unknown location'
          };
          
          try {
            // Create a break-end entry with auto-end information
            const now = new Date();
            const timeEntry = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
            
            // Create early end info for automatic break ending
            const earlyEndInfo = JSON.stringify({
              reason: 'Break automatically ended after scheduled duration',
              originalDuration: activeBreakEntry.duration,
              actualDuration: activeBreakEntry.duration, // Full duration since it completed normally
              autoEnded: true
            });
            
            // Make sure the break start entry is in the entries list
            const breakStartExists = entries.some(e => 
              e.status === 'break' && 
              e.id === activeBreakEntry.id
            );
            
            if (!breakStartExists) {
              // Create a break start entry if it doesn't exist
              console.log("Creating missing break start entry for auto-ended break");
              const breakStartEntry = {
                ...activeBreakEntry,
                time_entry: new Date(activeBreakEntry.timestamp).toLocaleTimeString('en-US', { 
                  hour: 'numeric', 
                  minute: '2-digit', 
                  hour12: true 
                })
              };
              
              // Add the break start entry to the entries list
              setEntries(prev => {
                // Check if it already exists to avoid duplicates
                if (!prev.some(e => e.id === breakStartEntry.id || e.local_id === breakStartEntry.local_id)) {
                  return [...prev, breakStartEntry].sort((a, b) => b.timestamp - a.timestamp);
                }
                return prev;
              });
            }
            
            const breakEndEntry = await createTimeTrackEntry({
              userId,
              status: 'break-end',
              timestamp: now.getTime(),
              timeEntry,
              breakType: activeBreakEntry.break_type,
              latitude: locationData.latitude,
              longitude: locationData.longitude,
              address: locationData.address,
              early_end_info: earlyEndInfo
            });
            
            console.log("Auto break end entry created:", breakEndEntry);
            
            // Directly update the entries state with the new break-end entry
            setEntries(prev => {
              // Add the new break-end entry to the beginning of the array
              return [breakEndEntry, ...prev];
            });
            
            console.log("Entries updated with auto break-end entry");
            
            // Also refresh entries from the server to ensure everything is in sync
            console.log("Refreshing entries after auto-ending break...");
            await refreshEntries();
          } catch (error) {
            console.error('Error automatically ending break:', error);
            // Still refresh entries to show any other changes
            await refreshEntries();
          }
        }
      }
    };
    
    // Check immediately
    checkAndRefreshEntries();
    
    // Set up interval to check every 15 seconds to be more responsive
    const intervalId = setInterval(checkAndRefreshEntries, 15000);
    
    return () => clearInterval(intervalId);
  }, [userId, entries, refreshEntries, refreshedBreakIds, setEntries]);

  return (
    <Fragment>
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="time-track-dialog-title"
      >
        <TimeTrackHeader onClose={onClose} />
        
        <DialogContent sx={{ p: 3 }}>
          <TimeTrackControls />
          <DateNavigation />
          <TimeEntriesList />
        </DialogContent>
      </Dialog>

      {/* Break notification */}
      {showBreakNotification && breakNotification && (
        <BreakNotification
          notification={breakNotification}
          onDismiss={() => handleDismissBreakNotification(breakNotification)}
          onTakeBreak={() => handleTakeBreakFromNotification(breakNotification)}
        />
      )}
    </Fragment>
  );
};

// Wrapper component that provides context
const TimeTrack = ({ open, onClose }) => {
  if (!open) return null;
  
  return (
    <TimeTrackProvider>
      <TimeTrackContent onClose={onClose} />
    </TimeTrackProvider>
  );
};

export default TimeTrack;
