import React from 'react';
import { Button } from '@mui/material';

const StateRadioButton = ({ state, label, selectedState, onChange }) => {
  const isSelected = selectedState === state;
  
  const handleClick = () => {
    onChange({ target: { value: state } });
  };
  
  return (
    <Button
      variant={isSelected ? "contained" : "outlined"}
      color={isSelected ? "primary" : "inherit"}
      onClick={handleClick}
      sx={{
        minWidth: '120px',
        borderRadius: '20px',
        textTransform: 'none',
        fontWeight: isSelected ? 600 : 400,
        boxShadow: isSelected ? 1 : 0,
        '&:hover': {
          boxShadow: 1
        }
      }}
    >
      {label}
    </Button>
  );
};

export default StateRadioButton;
