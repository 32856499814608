import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItemButton,
  ListItemText,
  TextField,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import EditIcon from '@mui/icons-material/Edit';

const TodoListItem = ({
  todolist,
  todoListCounts,
  onSelectTodoList,
  handleArchiveTodoList,
  handleDeleteConfirmation,
  handleEditListName,
  handleSaveListName,
  editingListId,
  editedListName,
  setEditedListName,
  classes,
  settodolistid,
  setTodoHeader,
}) => {
  const { total, completed } = todoListCounts[todolist.id] || { total: 0, completed: 0 };

  return (
    <ListItemButton
      key={todolist.id}
      onClick={() => {
        settodolistid(todolist.id);
        setTodoHeader(todolist.list_name);
        if (typeof onSelectTodoList === 'function') {
          onSelectTodoList(todolist.id, todolist.list_name);
        }
      }}
      className={todolist.archived ? classes.archivedItem : ''}
      sx={{ position: 'relative' }}
    >
      {editingListId === todolist.id ? (
        <TextField
          autoFocus
          value={editedListName}
          onChange={(e) => setEditedListName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSaveListName(todolist.id);
            }
          }}
        />
      ) : (
        <ListItemText
          primary={`${todolist.list_name} (${completed}/${total})`}
        />
      )}
      <div className={classes.actionButtons}>
        {editingListId === todolist.id ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleSaveListName(todolist.id);
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEditListName(todolist.id, todolist.list_name);
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleArchiveTodoList(todolist.id, todolist.archived);
          }}
        >
          {todolist.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteConfirmation(todolist.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </ListItemButton>
  );
};

TodoListItem.propTypes = {
  todolist: PropTypes.shape({
    id: PropTypes.number.isRequired,
    list_name: PropTypes.string.isRequired,
    archived: PropTypes.bool.isRequired,
  }).isRequired,
  todoListCounts: PropTypes.object,
  onSelectTodoList: PropTypes.func,
  handleArchiveTodoList: PropTypes.func,
  handleDeleteConfirmation: PropTypes.func,
  handleEditListName: PropTypes.func,
  handleSaveListName: PropTypes.func,
  editingListId: PropTypes.number,
  editedListName: PropTypes.string,
  setEditedListName: PropTypes.func,
  classes: PropTypes.object.isRequired,
  settodolistid: PropTypes.func,
  setTodoHeader: PropTypes.func,
};

TodoListItem.defaultProps = {
  onSelectTodoList: () => {},
  todoListCounts: {},
  handleArchiveTodoList: () => {},
  handleDeleteConfirmation: () => {},
  handleEditListName: () => {},
  handleSaveListName: () => {},
  editingListId: null,
  editedListName: '',
  setEditedListName: () => {},
  settodolistid: () => {},
  setTodoHeader: () => {},
};

export default TodoListItem; 