// src/components/App/index.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import StoreProvider from '../Hooks/store';
import { TimeTrackProvider} from '../TimeTrack/context/TimeTrackContext';
import HomePage from '../HomePage';
import Auth from '../Auth';
import Dashboard from '../Dashboard';
import Assignments from '../Assignment';
import AdminPage from '../Admin/AdminPage';
import ProtectedRoute from './ProtectedRoute';
import VerifyEmail from '../VerifyEmail';
import AlertNotification from '../AlertNotification';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#00416e',
      dark: '#006db3',
      contrastText: '#ffffff',
    },
    action: {
      disabled: '#b3b3b3',
      contrastText: '#ffffff',
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StoreProvider>
        <Router>
          {/* Global AlertNotification component wrapped in TimeTrackProvider */}
          <ProtectedRoute>
            <TimeTrackProvider>
              <AlertNotification />
            </TimeTrackProvider>
          </ProtectedRoute>
          <Routes>
            {/* Auth Routes - These don't need TimeTrackProvider */}
            <Route path="/auth/*" element={<Auth />} />
            <Route path="/verify-email" element={<VerifyEmail />} />

            {/* Protected Routes - Wrap these with TimeTrackProvider */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <TimeTrackProvider>
                    <HomePage />
                  </TimeTrackProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <TimeTrackProvider>
                    <Dashboard />
                  </TimeTrackProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/assignments"
              element={
                <ProtectedRoute>
                  <TimeTrackProvider>
                    <Assignments />
                  </TimeTrackProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/assignments/:id"
              element={
                <ProtectedRoute>
                  <TimeTrackProvider>
                    <Assignments />
                  </TimeTrackProvider>
                </ProtectedRoute>
              }
            />

            {/* Admin Route */}
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <TimeTrackProvider>
                    <AdminPage />
                  </TimeTrackProvider>
                </ProtectedRoute>
              }
            />

            {/* Fallback Route */}
            <Route path="*" element={<h1>404 - Not Found</h1>} />
          </Routes>
        </Router>
      </StoreProvider>
    </ThemeProvider>
  );
}
