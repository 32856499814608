import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import {
  addComment,
  getComments,
  updateComment,
  deleteComment,
  softDeleteLocation,
} from "../../api/savedLocations";
import { useAuth } from "../Hooks/useAuth";
import { reverseGeocode } from "../../utils/geocoding";
import { supabase } from "../../lib/supabase";
//import CommentSection from './components/CommentSection';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  correctAddressButton: {
    marginTop: theme.spacing(1),
  },
  commentsSection: {
    marginTop: theme.spacing(3),
  },
  commentItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

// Snackbar Alert component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LocationManagementDialog = ({
  open,
  onClose,
  onSaveLocation,
  onUpdateLocation,
  onDeleteLocation,
  isSaving,
  initialCoordinates,
  initialLocationId = "",
  initialName = "",
  initialAddress = "",
  initialDescription = "",
  mode = "save",
  fetchSavedLocations, // to refrsh the saved locations layer on the map after saving comment
}) => {
  const { user } = useAuth();
  const classes = useStyles();
  
  const [name, setName] = useState(initialName);
  const [locationId, setLocationId] = useState(initialLocationId);
  const [address, setAddress] = useState(initialAddress);
  const [description, setDescription] = useState(initialDescription);
  const [isCorrectingAddress, setIsCorrectingAddress] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentAuthors, setCommentAuthors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Snackbar handlers
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Fetch user details for a comment author
  const fetchUserDetails = async (userId) => {
    try {
      const { data, error } = await supabase
        .from("active_users")
        .select("id, email, full_name")
        .eq("id", userId)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  };

  // Fetch comments and their authors
  const fetchComments = async () => {
    if (!initialLocationId.trim()) {
      console.error("Invalid saved location ID:", initialLocationId);
      return;
    }

    try {
      setIsLoading(true);
      const comments = await getComments(initialLocationId);
      setComments(comments || []);

      // Fetch user details for each comment
      const authors = {};
      for (const comment of comments) {
        if (comment.user_id === user.id) {
          // Use the current authenticated user's details for their own comments
          authors[comment.user_id] = {
            email: user.email,
            full_name: user.user_metadata?.full_name,
          };
        } else {
          const userDetails = await fetchUserDetails(comment.user_id);
          if (userDetails) {
            authors[comment.user_id] = userDetails;
          }
        }
      }
      setCommentAuthors(authors);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setComments([]);
      handleSnackbarOpen("Failed to fetch comments. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Reset form and fetch comments when the dialog opens or mode changes
  useEffect(() => {
    if (open) {
      setName(initialName);
      setLocationId(initialLocationId);
      setAddress(initialAddress);
      setDescription(initialDescription);

      if (mode !== "save" && initialLocationId) {
        fetchComments();
      }
    }
  }, [open, mode, initialName, initialAddress, initialLocationId, initialDescription, initialCoordinates]);
 
  /*
  // Validate comment
  const validateComment = (comment) => {
    if (!comment.trim()) {
      return "Comment cannot be empty.";
    }
    if (comment.length > 500) {
      return "Comment cannot exceed 500 characters.";
    }
    return null; // No errors
  };


  // Handle adding a new comment
  const handleAddComment = async (commentText) => {
    const validationError = validateComment(commentText);
    if (validationError) {
      handleSnackbarOpen(validationError, "error");
      return;
    }
  
    if (!commentText.trim() || !initialLocationId.trim() || !user?.id) {
      handleSnackbarOpen("Invalid comment, location ID, or user ID.", "error");
      return;
    }
  
    try {
      setIsLoading(true);
      const response = await addComment(initialLocationId, user.id, commentText);
      
      const newCommentObj = {
        id: response?.id || Date.now(),
        comment: commentText,
        user_id: user.id,
        utc_created: response?.utc_created || Date.now(),
        location_id: initialLocationId,
      };
      
      setComments(prevComments => [...prevComments, newCommentObj]);
      setCommentAuthors(prev => ({
        ...prev,
        [user.id]: {
          email: user.email,
          full_name: user.user_metadata?.full_name,
        }
      }));
      
      handleSnackbarOpen("Comment added successfully!", "success");
      //fetchSavedLocations(); // Update marker popup
    } catch (error) {
      console.error("Error adding comment:", error);
      handleSnackbarOpen("Failed to add comment. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle updating a comment
  const handleUpdateComment = async (commentId, commentUserId) => {
    if (commentUserId !== user.id) {
      handleSnackbarOpen("You can only update your own comments.", "error");
      return;
    }
  
    const commentToUpdate = comments.find((c) => c.id === commentId);
    const updatedComment = prompt("Edit comment:", commentToUpdate?.comment);
    if (!updatedComment) return;
  
    try {
      setIsLoading(true);
      await updateComment(commentId, user.id, updatedComment);
      
      // Update the comment in local state
      setComments(prevComments => 
        prevComments.map(comment => 
          comment.id === commentId 
            ? { ...comment, comment: updatedComment }
            : comment
        )
      );
      
      handleSnackbarOpen("Comment updated successfully!", "success");
      //fetchSavedLocations(); // Update marker popup
    } catch (error) {
      console.error("Error updating comment:", error);
      handleSnackbarOpen("Failed to update comment. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle deleting a comment
  const handleDeleteComment = async (commentId, commentUserId) => {
    if (commentUserId !== user.id) {
      handleSnackbarOpen("You can only delete your own comments.", "error");
      return;
    }
  
    try {
      setIsLoading(true);
      await deleteComment(commentId, user.id);
      
      // Remove the comment from local state
      setComments(prevComments => 
        prevComments.filter(comment => comment.id !== commentId)
      );
      
      handleSnackbarOpen("Comment deleted successfully!", "success");
      //fetchSavedLocations(); // Update marker popup
    } catch (error) {
      console.error("Error deleting comment:", error);
      handleSnackbarOpen("Failed to delete comment. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };
  */

  // Handle save or update location
  const handleSave = async () => {
    if (!name || !address) {
      handleSnackbarOpen("Name and address are required.", "error");
      return;
    }

    try {
      setIsLoading(true);
      if (mode === "save") {
        await onSaveLocation(name, address, description, initialCoordinates);
      } else if (mode === "edit") {
        await onUpdateLocation(name, address, description);
      }
      handleSnackbarOpen("Location saved successfully!", "success");
      onClose();
    } catch (error) {
      console.error("Error saving location:", error);
      handleSnackbarOpen("Failed to save location. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle delete location
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await softDeleteLocation(initialLocationId, user.id, "User deleted the location");
      onDeleteLocation();
      onClose();
      handleSnackbarOpen("Location deleted successfully!", "success");
    } catch (error) {
      console.error("Error deleting location:", error);
      handleSnackbarOpen("Failed to delete location. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle correct address
  const handleCorrectAddress = async () => {
    setIsCorrectingAddress(true);
    try {
      const correctedAddress = await reverseGeocode(initialCoordinates.lat, initialCoordinates.lng);
      setAddress(correctedAddress);
      handleSnackbarOpen("Address corrected successfully!", "success");
    } catch (error) {
      console.error("Error correcting address:", error);
      handleSnackbarOpen("Failed to correct address. Please try again.", "error");
    } finally {
      setIsCorrectingAddress(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle className={classes.dialogTitle}>
          {mode === "save" && "Save Location"}
          {mode === "edit" && "Edit Location"}
          {mode === "delete" && "Delete Location"}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {isLoading && (
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              <CircularProgress />
            </div>
          )}
          {mode !== "delete" && (
            <>
              <TextField
                label="Name"
                fullWidth
                margin="normal"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className={classes.inputField}
              />
              <TextField
                label="Address"
                fullWidth
                margin="normal"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
                className={classes.inputField}
              />
              <Button
                onClick={handleCorrectAddress}
                color="primary"
                disabled={isCorrectingAddress}
                className={classes.correctAddressButton}
              >
                {isCorrectingAddress ? "Correcting Address..." : "Correct Address"}
              </Button>
              <TextField
                label="Description"
                fullWidth
                margin="normal"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                className={classes.inputField}
              />

              {/* Comments Section
              {mode !== "save" && (
                <CommentSection
                  comments={comments}
                  commentAuthors={commentAuthors}
                  currentUserId={user.id}
                  onAddComment={handleAddComment}
                  onUpdateComment={handleUpdateComment}
                  onDeleteComment={handleDeleteComment}
                  isLoading={isLoading}
                  classes={classes}
                />
              )} */}
            </>
          )}
          {mode === "delete" && (
            <Typography variant="body1">
              Are you sure you want to delete this location?
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {mode === "delete" ? (
            <>
              <Button onClick={onClose} color="secondary" disabled={isLoading}>
                Cancel
              </Button>
              <Button onClick={handleDelete} color="error" disabled={isLoading}>
                Delete
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} color="secondary" disabled={isLoading}>
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                color="primary"
                disabled={isLoading || !name || !address}
              >
                {isLoading ? "Saving..." : "Save"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default React.memo(LocationManagementDialog);