import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Button,
  IconButton,
  Slide
} from '@mui/material';
import {
  Close as CloseIcon,
  Coffee as RestIcon,
  Restaurant as MealIcon
} from '@mui/icons-material';
import { useTimeTrackContext } from '../context/TimeTrackContext';

const BreakNotification = ({ 
  notification, 
  onDismiss, 
  onTakeBreak 
}) => {
  const { formatTime } = useTimeTrackContext();
  
  // Get the appropriate icon based on break type
  const getBreakIcon = () => {
    switch (notification.breakType) {
      case 'rest':
        return <RestIcon fontSize="large" color="warning" />;
      case 'meal':
      case 'lunch':
        return <MealIcon fontSize="large" color="info" />;
      default:
        return <RestIcon fontSize="large" color="primary" />;
    }
  };
  
  // Get the break type display name
  const getBreakTypeName = () => {
    switch (notification.breakType) {
      case 'rest':
        return 'Rest Break';
      case 'meal':
        return 'Meal Break';
      case 'lunch':
        return 'Lunch Break';
      default:
        return notification.breakType.charAt(0).toUpperCase() + notification.breakType.slice(1) + ' Break';
    }
  };
  
  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Paper 
        elevation={4}
        sx={{
          position: 'fixed',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'calc(100% - 32px)',
          maxWidth: 500,
          zIndex: 1300,
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            bgcolor: notification.breakType === 'rest' ? '#FBB03B' : '#0080BB',
            color: 'white',
            p: 1.5
          }}
        >
          <Box sx={{ mr: 1.5 }}>
            {getBreakIcon()}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" fontWeight="medium">
              {getBreakTypeName()} Due
            </Typography>
            <Typography variant="body2">
              Scheduled for {formatTime(notification.scheduledBreakTime)}
            </Typography>
          </Box>
          <IconButton 
            size="small" 
            onClick={onDismiss}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        
        <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            It's time for your {notification.durationMinutes}-minute {getBreakTypeName().toLowerCase()}.
            Taking regular breaks helps maintain productivity and is required by labor laws.
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              variant="outlined" 
              color="inherit"
              onClick={onDismiss}
              sx={{ mr: 1 }}
            >
              Dismiss
            </Button>
            <Button 
              variant="contained" 
              color={notification.breakType === 'rest' ? 'warning' : 'info'}
              onClick={onTakeBreak}
              sx={{ 
                bgcolor: notification.breakType === 'rest' ? '#FBB03B' : '#0080BB',
                '&:hover': {
                  bgcolor: notification.breakType === 'rest' ? '#e69c35' : '#006da1'
                }
              }}
            >
              Take Break Now
            </Button>
          </Box>
        </Box>
      </Paper>
    </Slide>
  );
};

export default BreakNotification;
