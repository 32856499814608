import React, { memo } from 'react';
import { Box, Typography, Chip, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useTimeTrackContext } from '../context/TimeTrackContext';
import '../styles.css';

const TimeEntryItem = memo(({ entry, prevEntry }) => {
  const { getStatusColor, formatTime } = useTimeTrackContext();
  
  const statusColor = getStatusColor(entry.status);
  const entryTime = new Date(entry.timestamp);
  
  // Calculate duration if this is a break end entry
  const getDuration = () => {
    if (entry.status === 'break-end' && prevEntry && prevEntry.status === 'break') {
      const duration = entry.timestamp - prevEntry.timestamp;
      const minutes = Math.round(duration / (1000 * 60));
      return `${minutes}m`;
    }
    return null;
  };

  return (
    <Box className="time-entry">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Chip 
          label={entry.status.replace(/-/g, ' ').toUpperCase()} 
          size="small"
          sx={{ 
            bgcolor: statusColor,
            color: 'white',
            minWidth: 100
          }} 
        />
        {getDuration() && (
          <Typography variant="body2" color="textSecondary">
            Duration: {getDuration()}
          </Typography>
        )}
        {entry.syncing && (
          <CircularProgress size={16} sx={{ ml: 'auto' }} />
        )}
        {!entry.syncing && !entry.synced && (
          <WifiOffIcon 
            fontSize="small" 
            sx={{ 
              ml: 'auto',
              color: 'warning.main',
              opacity: 0.8
            }} 
          />
        )}
        {!entry.syncing && entry.synced && (
          <WifiIcon 
            fontSize="small" 
            sx={{ 
              ml: 'auto',
              color: 'success.main',
              opacity: 0.8
            }} 
          />
        )}
      </Box>
      <Typography>
        {formatTime(entryTime)}
      </Typography>
    </Box>
  );
}, (prevProps, nextProps) => {
  // Only re-render if relevant props change
  return prevProps.entry.id === nextProps.entry.id && 
         prevProps.entry.timestamp === nextProps.entry.timestamp &&
         prevProps.entry.synced === nextProps.entry.synced &&
         prevProps.entry.syncing === nextProps.entry.syncing;
});

TimeEntryItem.propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.string,
    local_id: PropTypes.string,
    status: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    synced: PropTypes.bool,
    syncing: PropTypes.bool
  }).isRequired,
  prevEntry: PropTypes.object
};

TimeEntryItem.displayName = 'TimeEntryItem';

export default TimeEntryItem; 