import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-lasso';
import { renderToString } from 'react-dom/server';
import SchoolIcon from '@mui/icons-material/School';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PlaceIcon from '@mui/icons-material/Place';
import HomeIcon from '@mui/icons-material/Home';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import tippy from 'tippy.js';
import { SinglePropertyContent, MultiplePropertiesContent } from './PropertyDialogContent';

// Map of icon names to their corresponding Material-UI icons
export const iconMap = {
  SchoolIcon: SchoolIcon,
  LocalGroceryStoreIcon: LocalGroceryStoreIcon,
  LocalPoliceIcon: LocalPoliceIcon,
  PlaceIcon: PlaceIcon,
  HomeIcon: HomeIcon,
  LocalHospitalIcon: LocalHospitalIcon,
  LocalHotelIcon: LocalHotelIcon,
  LocalGasStationIcon: LocalGasStationIcon,
  LocalPharmacyIcon: LocalPharmacyIcon,
  LocalPostOfficeIcon: LocalPostOfficeIcon,
};

/**
 * Creates a custom Leaflet icon using a Material-UI icon.
 */
export const createCustomIcon = (IconComponent, color = 'red') => {
  return L.divIcon({
    html: renderToString(<IconComponent style={{ fontSize: '24px', color }} />),
    iconSize: [24, 24], // Size of the icon
    className: 'custom-icon', // Custom class for styling
  });
};

/**
 * Creates a date range slider control for filtering property data by date
 * @param {Object} map - The Leaflet map instance
 * @param {Function} onFilterChange - Callback function when filter changes
 * @param {Object} initialFilter - Initial filter values (optional)
 * @returns {Object} The created control
 */
export const createDateRangeSliderControl = (map, onFilterChange, initialFilter = null) => {
  const dateRangeControl = L.control({ position: 'topright' });
  
  dateRangeControl.onAdd = function () {
    // Create container
    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control date-range-control');
    container.style.backgroundColor = 'white';
    container.style.padding = '10px';
    container.style.width = '250px';
    container.style.borderRadius = '4px';
    container.style.boxShadow = '0 1px 5px rgba(0,0,0,0.4)';
    
    // Create header
    const header = document.createElement('div');
    header.style.display = 'flex';
    header.style.justifyContent = 'space-between';
    header.style.alignItems = 'center';
    header.style.marginBottom = '10px';
    
    const title = document.createElement('h4');
    title.textContent = 'Filter by Date';
    title.style.margin = '0';
    
    const toggleButton = document.createElement('button');
    toggleButton.innerHTML = '&#x2715;'; // X symbol
    toggleButton.style.border = 'none';
    toggleButton.style.background = 'none';
    toggleButton.style.cursor = 'pointer';
    toggleButton.style.fontSize = '16px';
    
    header.appendChild(title);
    header.appendChild(toggleButton);
    
    // Create date inputs
    const startDateContainer = document.createElement('div');
    startDateContainer.style.marginBottom = '10px';
    
    const startDateLabel = document.createElement('label');
    startDateLabel.textContent = 'Start Date:';
    startDateLabel.style.display = 'block';
    startDateLabel.style.marginBottom = '5px';
    
    const startDateInput = document.createElement('input');
    startDateInput.type = 'date';
    startDateInput.style.width = '100%';
    startDateInput.style.padding = '5px';
    startDateInput.style.boxSizing = 'border-box';
    
    startDateContainer.appendChild(startDateLabel);
    startDateContainer.appendChild(startDateInput);
    
    const endDateContainer = document.createElement('div');
    endDateContainer.style.marginBottom = '10px';
    
    const endDateLabel = document.createElement('label');
    endDateLabel.textContent = 'End Date:';
    endDateLabel.style.display = 'block';
    endDateLabel.style.marginBottom = '5px';
    
    const endDateInput = document.createElement('input');
    endDateInput.type = 'date';
    endDateInput.style.width = '100%';
    endDateInput.style.padding = '5px';
    endDateInput.style.boxSizing = 'border-box';
    
    endDateContainer.appendChild(endDateLabel);
    endDateContainer.appendChild(endDateInput);
    
    // Create buttons
    const buttonsContainer = document.createElement('div');
    buttonsContainer.style.display = 'flex';
    buttonsContainer.style.gap = '10px';
    
    const applyButton = document.createElement('button');
    applyButton.textContent = 'Apply';
    applyButton.style.flex = '1';
    applyButton.style.padding = '8px';
    applyButton.style.backgroundColor = '#2196f3';
    applyButton.style.color = 'white';
    applyButton.style.border = 'none';
    applyButton.style.borderRadius = '4px';
    applyButton.style.cursor = 'pointer';
    
    const clearButton = document.createElement('button');
    clearButton.textContent = 'Clear';
    clearButton.style.flex = '1';
    clearButton.style.padding = '8px';
    clearButton.style.backgroundColor = '#f44336';
    clearButton.style.color = 'white';
    clearButton.style.border = 'none';
    clearButton.style.borderRadius = '4px';
    clearButton.style.cursor = 'pointer';
    
    buttonsContainer.appendChild(applyButton);
    buttonsContainer.appendChild(clearButton);
    
    // Add all elements to container
    container.appendChild(header);
    container.appendChild(startDateContainer);
    container.appendChild(endDateContainer);
    container.appendChild(buttonsContainer);
    
    // Set default date range (last 3 months)
    const end = new Date();
    const start = new Date();
    start.setMonth(start.getMonth() - 3);
    
    const formatDate = (date) => {
      return date.toISOString().split('T')[0];
    };
    
    startDateInput.value = formatDate(start);
    endDateInput.value = formatDate(end);
    
    // Initialize with initialFilter if provided
    if (initialFilter) {
      startDateInput.value = initialFilter.startDate;
      endDateInput.value = initialFilter.endDate;
    }
    
    // Set up event handlers
    let isVisible = true;
    
    toggleButton.addEventListener('click', () => {
      if (isVisible) {
        startDateContainer.style.display = 'none';
        endDateContainer.style.display = 'none';
        buttonsContainer.style.display = 'none';
        isVisible = false;
        toggleButton.innerHTML = '&#x25BC;'; // Down arrow
      } else {
        startDateContainer.style.display = 'block';
        endDateContainer.style.display = 'block';
        buttonsContainer.style.display = 'flex';
        isVisible = true;
        toggleButton.innerHTML = '&#x2715;'; // X symbol
      }
    });
    
    applyButton.addEventListener('click', () => {
      if (startDateInput.value && endDateInput.value) {
        onFilterChange({
          startDate: startDateInput.value,
          endDate: endDateInput.value
        });
        
        // Change button color to indicate active filter
        applyButton.style.backgroundColor = '#4CAF50';
      }
    });
    
    clearButton.addEventListener('click', () => {
      onFilterChange(null);
      
      // Reset button color
      applyButton.style.backgroundColor = '#2196f3';
    });
    
    // Prevent map click events from propagating
    L.DomEvent.disableClickPropagation(container);
    L.DomEvent.disableScrollPropagation(container);
    
    return container;
  };
  
  return dateRangeControl.addTo(map);
};

/**
 * Creates a refresh control for refreshing property data
 * @param {Object} map - The Leaflet map instance
 * @param {Function} refreshCallback - Callback function to refresh property data
 * @returns {Object} The created control
 */
export const createRefreshControl = (map, refreshCallback) => {
  const refreshControl = L.control({ position: 'topright' });
  refreshControl.onAdd = function () {
    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control custom-control');
    const button = L.DomUtil.create('button', '', container);
    button.innerHTML = '<span style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;"><svg viewBox="0 0 24 24" width="18" height="18"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" fill="currentColor"></path></svg></span>';
    button.title = 'Refresh property data';
    button.onclick = refreshCallback;
    L.DomEvent.disableClickPropagation(button);
    
    tippy(button, {
      content: 'Refresh property data',
      placement: 'bottom',
      arrow: true,
    });

    return container;
  };
  
  return refreshControl.addTo(map);
};

// Create a lasso control that works with all visible layers
export const createLassoControl = (map) => {
  const LassoControl = L.Control.extend({
    options: {
      position: 'topright'
    },

    onAdd: function(map) {
      const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
      const button = L.DomUtil.create('a', 'leaflet-control-lasso', container);
      button.href = '#';
      button.title = 'Draw Area (Click to add points- to complete area selection, double-click or click near the starting point).';

      L.DomEvent
        .addListener(button, 'click', L.DomEvent.stopPropagation)
        .addListener(button, 'click', L.DomEvent.preventDefault)
        .addListener(button, 'click', () => {
          if (button.classList.contains('active')) {
            button.classList.remove('active');
            map.dragging.enable();
            map.boxZoom.enable();
          } else {
            button.classList.add('active');
            map.dragging.disable();
            map.boxZoom.disable();
            this._enableAreaSelect(map);
          }
        });

        tippy(button, {
          content: 'Draw Area (Click to add points- to complete area selection, double-click or click near the starting point).',
          placement: 'bottom',
          arrow: true,
        });

      return container;
    },

    _enableAreaSelect: function(map) {
      let points = [];
      let polygon = null;
      let isDrawing = false;

      // Disable map double-click zoom while drawing
      if (map.doubleClickZoom) {
        map.doubleClickZoom.disable();
      }

      const cleanup = () => {
        map.dragging.enable();
        map.boxZoom.enable();
        if (map.doubleClickZoom) {
          map.doubleClickZoom.enable();
        }
        map.off('click', onClick);
        map.off('mousemove', onMouseMove);
        map.off('dblclick', onDoubleClick);
        if (polygon) {
          map.removeLayer(polygon);
        }
        points = [];
        isDrawing = false;
        const buttons = document.querySelectorAll('.leaflet-control-lasso');
        buttons.forEach(button => button.classList.remove('active'));
      };

      function updatePolygon(currentPoint) {
        const tempPoints = [...points];
        if (currentPoint) {
          tempPoints.push(currentPoint);
        }
        
        if (polygon) {
          map.removeLayer(polygon);
        }

        if (tempPoints.length > 1) {
          polygon = L.polygon(tempPoints, {
            color: '#00c8ff',
            weight: 3,
            opacity: 0.8,
            fillColor: '#00c8ff',
            fillOpacity: 0.1,
            dashArray: '5, 10',
            lineCap: 'round'
          }).addTo(map);
        }
      }

      function isNearFirstPoint(point) {
        if (points.length < 3) return false;
        const firstPoint = points[0];
        const distance = point.distanceTo(firstPoint);
        // Increase threshold to make it easier to close the lasso
        const threshold = 50 / map.getZoomScale(map.getZoom());
        return distance < threshold;
      }

      function findMarkersInPolygon() {
        const selectedLayers = [];
        const polygonLatLngs = polygon.getLatLngs()[0];

        // Function to check if a point is inside polygon
        function isPointInPolygon(point, vs) {
          var x = point.lng, y = point.lat;
          var inside = false;
          for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            var xi = vs[i].lng, yi = vs[i].lat;
            var xj = vs[j].lng, yj = vs[j].lat;
            var intersect = ((yi > y) != (yj > y))
              && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
          }
          return inside;
        }

        // Check all visible layers for markers
        map.eachLayer((layer) => {
          if (layer instanceof L.MarkerClusterGroup) {
            layer.eachLayer((marker) => {
              if (marker.feature && isPointInPolygon(marker.getLatLng(), polygonLatLngs)) {
                selectedLayers.push(marker);
              }
            });
          }
        });

        return selectedLayers;
      }

      function completeSelection() {
        if (points.length >= 3) {
          if (points[points.length - 1] !== points[0]) {
            points.push(points[0]);
            updatePolygon();
          }

          const selectedLayers = findMarkersInPolygon();
          const selectedFeatures = selectedLayers
            .filter(layer => layer.feature)
            .map(layer => layer.feature);

          map.fire('lasso.finished', { 
            layers: selectedLayers,
            features: selectedFeatures 
          });
        }

        cleanup();
      }

      function onClick(e) {
        if (!isDrawing) {
          isDrawing = true;
          points = [e.latlng];
          updatePolygon();
        } else if (points.length >= 3 && isNearFirstPoint(e.latlng)) {
          points.push(points[0]); // Close with exact starting point
          updatePolygon();
          completeSelection();
        } else {
          points.push(e.latlng);
          updatePolygon();
          
          // Auto-complete if we have enough points and are near the start
          if (points.length >= 3 && isNearFirstPoint(e.latlng)) {
            points[points.length - 1] = points[0]; // Snap to starting point
            updatePolygon();
            completeSelection();
          }
        }
      }

      function onMouseMove(e) {
        if (!isDrawing || points.length === 0) return;
        
        // Show visual feedback when near starting point
        if (points.length >= 3 && isNearFirstPoint(e.latlng)) {
          updatePolygon(points[0]); // Show preview snapped to start point
        } else {
          updatePolygon(e.latlng);
        }
      }


      function onDoubleClick(e) {
        L.DomEvent.stop(e);
        
        if (!isDrawing) return;
        
        // Immediately complete the selection on double click
        if (points.length >= 2) {
          // Check if near starting point
          if (isNearFirstPoint(e.latlng)) {
            points.push(points[0]); // Close with exact starting point
          } else {
            points.push(e.latlng);
            points.push(points[0]);
          }
          updatePolygon();
          completeSelection();
        }
      }

      map.on('click', onClick);
      map.on('mousemove', onMouseMove);
      map.on('dblclick', onDoubleClick);
    }
  });

  return new LassoControl().addTo(map);
};

/**
 * Creates a property GeoJSON layer with consistent styling and popups
 * @param {Object} geoJsonData - The GeoJSON data to display
 * @param {Object} mapRef - Reference to the map instance
 * @param {Object} dateFilter - Optional date filter with startDate and endDate
 */
export const createPropertyLayer = (geoJsonData, mapRef, dateFilter = null) => {
  // Create a function to show multiple properties in dialog
  const showMultipleProperties = (features) => {
    if (!features.length) return;
    
    // Check if this is a building permit by looking for Permit Type property
    const isPermit = 'Permit Type' in features[0].properties;
    
    // Get the center of the selected features
    const bounds = L.latLngBounds(features.map(f => {
      const coords = f.geometry.coordinates;
      return L.latLng(coords[1], coords[0]);
    }));
    
    // Get the StoreContext from the window object
    const storeContext = window.document.getElementById('root')?.__STORE_CONTEXT__;
    const { setDialogContent, setDialogOpen } = storeContext;
    
    // Set the dialog content and open the dialog
    setDialogContent({
      name: `Selected ${isPermit ? 'Building Permits' : 'Real Estate Transactions'} (${features.length})`,
      address: '',//`Location: ${bounds.getCenter().lat.toFixed(6)}, ${bounds.getCenter().lng.toFixed(6)}`,
      description: <MultiplePropertiesContent features={features} />
    });
    setDialogOpen(true);
  };

  // Create a new MarkerClusterGroup with custom options
  let geoJsonLayer; // Declare here so we can access it in lasso handlers
  const markers = L.markerClusterGroup({
    maxClusterRadius: 50,
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: true,
    zoomToBoundsOnClick: true,
    disableClusteringAtZoom: 19,
    // Custom styling for clusters
    iconCreateFunction: function(cluster) {
      const childCount = cluster.getChildCount();
      const values = cluster.getAllChildMarkers().map(marker => 
        marker.feature.properties['Property Value']
      );
      const avgValue = values.reduce((a, b) => a + b, 0) / values.length;
      
      // Get color based on average property value
      const getColor = (value) => {
        if (value >= 5000000) return '#4A0082';
        if (value >= 4000000) return '#800080';
        if (value >= 3000000) return '#FF0000';
        if (value >= 2000000) return '#FF4500';
        if (value >= 1000000) return '#FF8C00';
        return '#FFD700';
      };

      const color = getColor(avgValue);
      
      return L.divIcon({
        html: `<div style="background-color: ${color}"><span>${childCount}</span></div>`,
        className: 'marker-cluster',
        iconSize: L.point(40, 40)
      });
    }
  });

  // Create the GeoJSON layer with the same options as before
  geoJsonLayer = L.geoJSON(geoJsonData, {
    filter: function (feature) {
      if (!feature.type || !feature.geometry || !feature.geometry.coordinates) {
        console.error('Invalid feature:', feature);
        return false;
      }
      
      // Apply date filter if provided
      if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
        // Check for both 'Recording Date' (older format) and 'recording_date' (newer format)
        const recordingDate = feature.properties['Recording Date'] || feature.properties['recording_date'];
        if (!recordingDate) return false;
        
        // Parse dates for comparison
        const featureDate = new Date(recordingDate);
        const startDate = new Date(dateFilter.startDate);
        const endDate = new Date(dateFilter.endDate);
        
        // Set time to midnight for consistent comparison
        featureDate.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999); // End of day
        
        // Compare dates
        return featureDate >= startDate && featureDate <= endDate;
      }
      
      return true;
    },
    coordsToLatLng: function (coords) {
      const lng = parseFloat(coords[0]);
      const lat = parseFloat(coords[1]);
      if (isNaN(lat) || isNaN(lng)) {
        console.error('Invalid coordinates:', coords);
        return null;
      }
      return L.latLng(lat, lng);
    },
    pointToLayer: function (feature, latlng) {
      const value = feature.properties['Property Value'];
      
      // Function to get color based on property value ranges
      const getColor = (value) => {
        if (value >= 5000000) return '#4A0082'; // Deep purple for highest values
        if (value >= 4000000) return '#800080'; // Purple
        if (value >= 3000000) return '#FF0000'; // Red
        if (value >= 2000000) return '#FF4500'; // Orange-red
        if (value >= 1000000) return '#FF8C00'; // Dark orange
        return '#FFD700';                       // Yellow for lowest values
      };

      return L.circleMarker(latlng, {
        radius: 7,
        fillColor: getColor(value),
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8
      });
    },
    onEachFeature: function (feature, layer) {
      const props = feature.properties;

      // Check if this is a building permit
      const isPermit = 'Permit Type' in props;
      const address = isPermit ? props['Site Address'] : props['Property Address'];
      const city = isPermit ? props['Site City'] : props['Property City'];
      const state = isPermit ? props['Site State'] : props['Property State'];
      const zip = isPermit ? props['Site ZIP Code'] : props['Property ZIP Code'];

      // Create a click handler for the layer
      layer.on('click', () => {
        // Format the property details for the dialog
        const fullAddress = `${address}, ${city}, ${state} ${zip}`;
        
        // Get the StoreContext from the window object
        const storeContext = window.document.getElementById('root')?.__STORE_CONTEXT__;
        const { setDialogContent, setDialogOpen } = storeContext;
        
        // Set the dialog content and open the dialog
        setDialogContent({
          name: isPermit ? `Building Permit` : `Real Estate Transaction`,
          address: fullAddress,
          description: <SinglePropertyContent feature={feature} />
        });
        setDialogOpen(true);
      });
    }
  });

  // Add the GeoJSON layer to the cluster group
  markers.addLayer(geoJsonLayer);
  
  // Add custom CSS for cluster and lasso styling
  const style = document.createElement('style');
  style.textContent = `
    /* Lasso control styles */
    .leaflet-lasso-active {
      cursor: crosshair !important;
    }
    .leaflet-lasso-control {
      width: 30px;
      height: 30px;
      background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=") center center no-repeat;
      background-size: 22px;
      border: 2px solid rgba(0, 0, 0, 0.2);
      background-clip: padding-box;
      border-radius: 4px;
      cursor: pointer;
    }
    .leaflet-lasso-control.active {
      background-color: #e3f2fd;
      border-color: #2196f3;
    }
    /* Add hover effect */
    .leaflet-lasso-control:hover {
      background-color: #f5f5f5;
    }
    .leaflet-lasso-control.active:hover {
      background-color: #bbdefb;
    }
    
    /* Marker cluster styles */
    .marker-cluster {
      background-clip: padding-box;
      border-radius: 20px;
    }
    .marker-cluster div {
      width: 40px;
      height: 40px;
      margin-left: 0;
      margin-top: 0;
      text-align: center;
      border-radius: 20px;
      font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .marker-cluster span {
      color: #fff;
      font-weight: bold;
      text-shadow: 0 0 1px #000;
    }
  `;
  document.head.appendChild(style);

  // Setup lasso handlers
  mapRef.current.on('lasso.finished', (event) => {
    if (event.features && event.features.length > 0) {
      showMultipleProperties(event.features);
    }
  });

  // Return the markers cluster group without adding to map
  return markers;
};
