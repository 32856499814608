import React, { useState, useEffect } from 'react';
import { StoreContext } from '../Hooks/store';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';
import {
  Typography,
  Snackbar,
  Switch, 
  FormControlLabel,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { createTodoList, archiveTodoList, deleteTodoList, updateTodoListName } from '../../api/todo';
import PropTypes from 'prop-types';
import TodoListItem from './components/TodoListItem';
import { useStyles } from './styles/todoListStyles';
import { useTodoListState } from './hooks/useTodoListState';
import DeleteConfirmationDialog from './components/DeleteConfirmationDialog';
import CreateTodoList from './components/CreateTodoList';

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ArchivedTodolists({ onSelectTodoList, onCreateTodoList }) {
  const {
    todolistheader,
    todolistheaderid,
    showarchiveflag,
    mytodolists,
    thisuserid,
  } = React.useContext(StoreContext);

  // Initialize the user interaction tracker
  const { trackInteraction } = useUserInteractionTracker();

  const classes = useStyles();

  const [showarchived, setShowarchived] = showarchiveflag;
  const [userid] = thisuserid;
  const [todolists, settodolists] = mytodolists;
  const [, settodolistid] = todolistheaderid;
  const [, setTodoHeader] = todolistheader;

  // State for creating a new todo list
  const [newListName, setNewListName] = useState('');
  const [isCreatingList, setIsCreatingList] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // Delete confirmation dialog state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);

  // Edit list name state
  const [editingListId, setEditingListId] = useState(null);
  const [editedListName, setEditedListName] = useState('');

  // State for showing/hiding archived lists
  const [showArchivedLists, setShowArchivedLists] = useState(
    localStorage.getItem('showArchivedLists') === 'true' || false
  );

  // Use custom hook for todo list state management
  const { todoListCounts, mostRecentLists, groupedLists } = useTodoListState(todolists);

  // Track page_view when the component mounts (only once)
  useEffect(() => {
    if (userid) {
      trackInteraction('page_view', {
        componentName: 'ArchivedTodolists',
        timestamp: Date.now(),
        userId: userid
      });
    }
  }, []); // Empty dependency array means this runs once on mount

  // Toggle archived lists visibility
  const handleToggleArchivedLists = () => {
    setShowArchivedLists((prev) => {
      const newValue = !prev;
      localStorage.setItem('showArchivedLists', newValue);
      return newValue;
    });
  };

  // Show Snackbar function
  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handle creating a new todo list
  const handleCreateTodoList = async () => {
    if (newListName.trim() === '') {
      showSnackbar('List name cannot be empty', 'error');
      return;
    }

    try {
      const newList = await createTodoList(userid, newListName);
      
      // Track the create action
      trackInteraction('todolist_create', {
        componentName: 'ArchivedTodolists',
        timestamp: Date.now(),
        userId: userid
      });
      
      if (typeof onCreateTodoList === 'function') {
        onCreateTodoList(newList);
      }
      setNewListName('');
      setIsCreatingList(false);
      showSnackbar('New list created!', 'success');
    } catch (error) {
      console.error('Error creating todo list:', error);
      showSnackbar('Error creating todo list', 'error');
    }
  };

  // Handle archiving/unarchiving a todo list
  const handleArchiveTodoList = async (listId, isArchived) => {
    try {
      await archiveTodoList(listId, !isArchived);
      
      // Find the list name for tracking
      const listName = todolists.find(list => list.id === listId)?.list_name || '';
      
      // Track the archive/unarchive action
      trackInteraction(isArchived ? 'todolist_unarchive' : 'todolist_archive', {
        componentName: 'ArchivedTodolists',
        timestamp: Date.now(),
        userId: userid
      });
      
      const updatedLists = todolists.map((list) =>
        list.id === listId ? { ...list, archived: !isArchived } : list
      );
      settodolists(updatedLists);
      showSnackbar(`List ${isArchived ? 'unarchived' : 'archived'}!`, 'success');
    } catch (error) {
      console.error('Error archiving/unarchiving list:', error);
      showSnackbar('Error archiving/unarchiving list', 'error');
    }
  };

  // Handle deleting a todo list
  const handleDeleteTodoList = async () => {
    if (!listToDelete) return;

    try {
      // Find the list name for tracking before deletion
      const listToDeleteInfo = todolists.find(list => list.id === listToDelete);
      
      await deleteTodoList(listToDelete);
      
      // Track the delete action
      trackInteraction('todolist_delete', {
        componentName: 'ArchivedTodolists',
        timestamp: Date.now(),
        userId: userid
      });
      
      const updatedLists = todolists.filter((list) => list.id !== listToDelete);
      settodolists(updatedLists);
      showSnackbar('List deleted!', 'success');
    } catch (error) {
      console.error('Error deleting list:', error);
      showSnackbar('Error deleting list', 'error');
    } finally {
      setDeleteDialogOpen(false);
      setListToDelete(null);
    }
  };

  // Handle editing a todo list name
  const handleEditListName = (listId, currentName) => {
    setEditingListId(listId);
    setEditedListName(currentName);
  };

  // Handle saving the edited list name
  const handleSaveListName = async (listId) => {
    if (editedListName.trim() === '') {
      showSnackbar('List name cannot be empty', 'error');
      return;
    }

    try {
      // Find the original list name for tracking
      const originalListName = todolists.find(list => list.id === listId)?.list_name || '';
      
      await updateTodoListName(listId, editedListName);
      
      // Track the edit action
      trackInteraction('todolist_edit', {
        componentName: 'ArchivedTodolists',
        timestamp: Date.now(),
        userId: userid
      });
      
      const updatedLists = todolists.map((list) =>
        list.id === listId ? { ...list, list_name: editedListName } : list
      );
      settodolists(updatedLists);
      setEditingListId(null);
      setEditedListName('');
      showSnackbar('List name updated!', 'success');
    } catch (error) {
      console.error('Error updating list name:', error);
      showSnackbar('Error updating list name', 'error');
    }
  };

  return (
    <div style={{ height: 300, width: '100%' }}>
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setListToDelete(null);
        }}
        onConfirm={handleDeleteTodoList}
      />

      {/* Toggle for showing/hiding archived lists */}
      <FormControlLabel
        control={
          <Switch
            checked={showArchivedLists}
            onChange={handleToggleArchivedLists}
            color="primary"
          />
        }
        label={showArchivedLists ? 'Hide Archived Lists' : 'Show Archived Lists'}
      />

      {/* Display a message when archived lists are hidden */}
      {!showArchivedLists && (
        <Typography variant="body2" color="textSecondary">
          {todolists.filter((list) => list.archived).length} archived lists hidden
        </Typography>
      )}

      <h2>All To-Do Lists</h2>

      {/* Create New Todo List */}
      <CreateTodoList
        isCreating={isCreatingList}
        newListName={newListName}
        onNameChange={setNewListName}
        onCreateList={handleCreateTodoList}
        onCancel={() => setIsCreatingList(false)}
        onStartCreating={() => setIsCreatingList(true)}
        classes={classes}
      />

      {/* List of Todo Lists */}
      <div>
        {/* Today */}
        {groupedLists.today.length > 0 && (
          <>
            <Typography variant="h6">Today</Typography>
            {groupedLists.today.map((todolist) => (
              <TodoListItem
                key={todolist.id}
                todolist={todolist}
                todoListCounts={todoListCounts}
                onSelectTodoList={onSelectTodoList}
                handleArchiveTodoList={handleArchiveTodoList}
                handleDeleteConfirmation={(id) => {
                  setListToDelete(id);
                  setDeleteDialogOpen(true);
                }}
                handleEditListName={handleEditListName}
                handleSaveListName={handleSaveListName}
                editingListId={editingListId}
                editedListName={editedListName}
                setEditedListName={setEditedListName}
                classes={classes}
                settodolistid={settodolistid}
                setTodoHeader={setTodoHeader}
              />
            ))}
          </>
        )}

        {/* Yesterday */}
        {groupedLists.yesterday.length > 0 && (
          <>
            <Typography variant="h6">Yesterday</Typography>
            {groupedLists.yesterday.map((todolist) => (
              <TodoListItem
                key={todolist.id}
                todolist={todolist}
                todoListCounts={todoListCounts}
                onSelectTodoList={onSelectTodoList}
                handleArchiveTodoList={handleArchiveTodoList}
                handleDeleteConfirmation={(id) => {
                  setListToDelete(id);
                  setDeleteDialogOpen(true);
                }}
                handleEditListName={handleEditListName}
                handleSaveListName={handleSaveListName}
                editingListId={editingListId}
                editedListName={editedListName}
                setEditedListName={setEditedListName}
                classes={classes}
                settodolistid={settodolistid}
                setTodoHeader={setTodoHeader}
              />
            ))}
          </>
        )}

        {/* Previous 7 Days */}
        {groupedLists.previous7Days.length > 0 && (
          <>
            <Typography variant="h6">Previous 7 Days</Typography>
            {groupedLists.previous7Days.map((todolist) => (
              <TodoListItem
                key={todolist.id}
                todolist={todolist}
                todoListCounts={todoListCounts}
                onSelectTodoList={onSelectTodoList}
                handleArchiveTodoList={handleArchiveTodoList}
                handleDeleteConfirmation={(id) => {
                  setListToDelete(id);
                  setDeleteDialogOpen(true);
                }}
                handleEditListName={handleEditListName}
                handleSaveListName={handleSaveListName}
                editingListId={editingListId}
                editedListName={editedListName}
                setEditedListName={setEditedListName}
                classes={classes}
                settodolistid={settodolistid}
                setTodoHeader={setTodoHeader}
              />
            ))}
          </>
        )}

        {/* Previous 30 Days */}
        {groupedLists.previous30Days.length > 0 && (
          <>
            <Typography variant="h6">Previous 30 Days</Typography>
            {groupedLists.previous30Days.map((todolist) => (
              <TodoListItem
                key={todolist.id}
                todolist={todolist}
                todoListCounts={todoListCounts}
                onSelectTodoList={onSelectTodoList}
                handleArchiveTodoList={handleArchiveTodoList}
                handleDeleteConfirmation={(id) => {
                  setListToDelete(id);
                  setDeleteDialogOpen(true);
                }}
                handleEditListName={handleEditListName}
                handleSaveListName={handleSaveListName}
                editingListId={editingListId}
                editedListName={editedListName}
                setEditedListName={setEditedListName}
                classes={classes}
                settodolistid={settodolistid}
                setTodoHeader={setTodoHeader}
              />
            ))}
          </>
        )}

        {/* By Month (Older than 30 Days) */}
        {Object.entries(groupedLists.byMonth).map(([monthYear, lists]) => (
          <div key={monthYear}>
            <Typography variant="h6">{monthYear}</Typography>
            {lists.map((todolist) => (
              <TodoListItem
                key={todolist.id}
                todolist={todolist}
                todoListCounts={todoListCounts}
                onSelectTodoList={onSelectTodoList}
                handleArchiveTodoList={handleArchiveTodoList}
                handleDeleteConfirmation={(id) => {
                  setListToDelete(id);
                  setDeleteDialogOpen(true);
                }}
                handleEditListName={handleEditListName}
                handleSaveListName={handleSaveListName}
                editingListId={editingListId}
                editedListName={editedListName}
                setEditedListName={setEditedListName}
                classes={classes}
                settodolistid={settodolistid}
                setTodoHeader={setTodoHeader}
              />
            ))}
          </div>
        ))}

        {/* Archived Lists */}
        {showArchivedLists && (
          <>
            <Typography variant="h6">Archived</Typography>
            {groupedLists.archived.map((todolist) => (
              <TodoListItem
                key={todolist.id}
                todolist={todolist}
                todoListCounts={todoListCounts}
                onSelectTodoList={onSelectTodoList}
                handleArchiveTodoList={handleArchiveTodoList}
                handleDeleteConfirmation={(id) => {
                  setListToDelete(id);
                  setDeleteDialogOpen(true);
                }}
                handleEditListName={handleEditListName}
                handleSaveListName={handleSaveListName}
                editingListId={editingListId}
                editedListName={editedListName}
                setEditedListName={setEditedListName}
                classes={classes}
                settodolistid={settodolistid}
                setTodoHeader={setTodoHeader}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

ArchivedTodolists.propTypes = {
  onSelectTodoList: PropTypes.func,
  onCreateTodoList: PropTypes.func,
};

ArchivedTodolists.defaultProps = {
  onSelectTodoList: () => {},
  onCreateTodoList: () => {},
};
