import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
  alpha,
  Fade,
  Grow
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CommentIcon from '@mui/icons-material/Comment';

import { getComments, addComment, updateComment, deleteComment } from '../../api/savedLocations';
import CommentSection from './components/CommentSection';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 20,
    maxWidth: 500,
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.005)'
    }
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(3),
    background: '#00416E',
    color: '#fff',
    position: 'relative'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3, 3),
    background: theme.palette.mode === 'dark' 
      ? alpha(theme.palette.primary.main, 0.05)
      : alpha(theme.palette.primary.light, 0.05)
  }
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: 12,
  background: theme.palette.mode === 'dark' 
    ? alpha(theme.palette.primary.main, 0.05)
    : alpha(theme.palette.primary.light, 0.05),
  transition: 'transform 0.2s ease, background-color 0.2s ease',
  '&:hover': {
    transform: 'translateX(4px)',
    background: theme.palette.mode === 'dark' 
      ? alpha(theme.palette.primary.main, 0.08)
      : alpha(theme.palette.primary.light, 0.08)
  },
  '& .icon': {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    transition: 'transform 0.2s ease',
  },
  '&:hover .icon': {
    transform: 'scale(1.1)'
  },
  '& .value': {
    color: theme.palette.text.primary,
    fontWeight: 500
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: theme.spacing(1.2, 3),
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '0.95rem',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.primary.main,
  }
}));

const LocationPopupDialog = ({
  open,
  onClose,
  location,
  commentCount,
  onEdit,
  onDelete,
  userId,
  onDataChange
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentAuthors, setCommentAuthors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadComments = useCallback(async () => {
    if (!location) return;
    
    try {
      setLoading(true);
      const fetchedComments = await getComments(location.id);
      setComments(fetchedComments);
      
      const authors = {};
      fetchedComments.forEach(comment => {
        if (comment.user) {
          authors[comment.user.id] = comment.user;
        }
      });
      setCommentAuthors(authors);
    } catch (error) {
      console.error('Error loading comments:', error);
    } finally {
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (open && location) {
      loadComments();
    }
  }, [open, location, loadComments]);

  useEffect(() => {
    if (open) {
      onDataChange?.();
    }
  }, [open, onDataChange]);

  const handleAddComment = async (commentText) => {
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      const newComment = await addComment(location.id, userId, commentText);
      setComments(prev => [...prev, newComment]);
      await loadComments();
      onDataChange?.();
    } catch (error) {
      console.error('Error adding comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateComment = async (commentId, userId, newText) => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      await updateComment(commentId, userId, newText);
      setComments(prev => prev.map(comment => 
        comment.id === commentId ? { ...comment, comment: newText } : comment
      ));
      onDataChange?.();
    } catch (error) {
      console.error('Error updating comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteComment = async (commentId, userId) => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      await deleteComment(commentId, userId);
      setComments(prev => prev.filter(comment => comment.id !== commentId));
      onDataChange?.();
    } catch (error) {
      console.error('Error deleting comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const commentStyles = {
    commentsSection: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      background: theme.palette.mode === 'dark' 
        ? alpha(theme.palette.primary.main, 0.05)
        : alpha(theme.palette.primary.light, 0.05),
      borderRadius: 16,
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.mode === 'dark'
          ? alpha(theme.palette.common.white, 0.2)
          : alpha(theme.palette.common.black, 0.2),
        borderRadius: '4px',
        '&:hover': {
          background: theme.palette.mode === 'dark'
            ? alpha(theme.palette.common.white, 0.3)
            : alpha(theme.palette.common.black, 0.3),
        }
      }
    },
    commentItem: {
      marginBottom: theme.spacing(1.5),
      padding: theme.spacing(2),
      borderRadius: 12,
      background: theme.palette.background.paper,
      boxShadow: `0 2px 8px ${alpha(theme.palette.common.black, 0.05)}`,
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.08)}`,
        background: theme.palette.mode === 'dark'
          ? alpha(theme.palette.primary.main, 0.1)
          : alpha(theme.palette.primary.light, 0.1),
      }
    }
  };

  if (!location) return null;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      TransitionComponent={Grow}
      transitionDuration={300}
    >
      <DialogTitle>
        <Box sx={{ pr: 4 }}>
          <Typography variant="h5" component="div" sx={{ 
            fontWeight: 600,
            mb: 1,
            color: 'inherit'
          }}>
            {location.name}
          </Typography>
          <Fade in timeout={500}>
            <Typography variant="body2" sx={{ 
              color: 'inherit',
              opacity: 0.8,
              display: 'flex',
              alignItems: 'center',
              gap: 0.5
            }}>
              <LocationOnIcon fontSize="small" />
              {location.address}
            </Typography>
          </Fade>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: '#fff',
            transition: 'all 1.5s ease',
            '&:hover': {
              transform: 'rotate(90deg)',
              color: '#666673'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          height: fullScreen ? 'calc(100vh - 56px)' : '70vh',
          overflow: 'hidden',
          position: 'relative',
          p: '0 !important'
        }}
      >
        <Box sx={{ 
          flex: 1,
          overflowY: 'auto',
          px: 3,
          pb: { xs: 20, sm: 12 },
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.mode === 'dark'
              ? alpha(theme.palette.common.white, 0.2)
              : alpha(theme.palette.common.black, 0.2),
            borderRadius: '4px',
            '&:hover': {
              background: theme.palette.mode === 'dark'
                ? alpha(theme.palette.common.white, 0.3)
                : alpha(theme.palette.common.black, 0.3)
            }
          }
        }}>
          <Fade in timeout={600}>
            <Typography 
              variant="body1" 
              sx={{ 
                mt: 2,
                mb: 3,
                lineHeight: 1.6,
                color: theme.palette.text.primary,
                background: theme.palette.mode === 'dark'
                  ? alpha(theme.palette.primary.main, 0.05)
                  : alpha(theme.palette.primary.light, 0.05),
                p: 2.5,
                borderRadius: 3,
                transition: 'background-color 0.2s ease',
                '&:hover': {
                  background: theme.palette.mode === 'dark'
                    ? alpha(theme.palette.primary.main, 0.08)
                    : alpha(theme.palette.primary.light, 0.08),
                }
              }}
            >
              {location.description}
            </Typography>
          </Fade>

          <Grow in timeout={700}>
            <DetailRow>
              <LocationOnIcon className="icon" />
              <Typography variant="body2" className="value">
                {location.coords.lat.toFixed(6)}, {location.coords.lng.toFixed(6)}
              </Typography>
            </DetailRow>
          </Grow>

          <CommentSection
            comments={comments}
            commentAuthors={commentAuthors}
            currentUserId={userId}
            onAddComment={handleAddComment}
            onUpdateComment={handleUpdateComment}
            onDeleteComment={handleDeleteComment}
            isLoading={isSubmitting}
            classes={commentStyles}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ 
        display: fullScreen ? 'none' : 'flex',
        borderTop: '1px solid',
        borderColor: 'divider'
      }}>
        <ActionButton
          onClick={() => onEdit(location.id)}
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
        >
          Edit Location
        </ActionButton>
        <ActionButton
          onClick={() => onDelete(location.id)}
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
        >
          Delete Location
        </ActionButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default LocationPopupDialog;
