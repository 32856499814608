import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import {
  getAllTrackingConfigs,
  updateGlobalTrackingConfig,
  updateComponentTrackingConfig,
  deleteTrackingConfig,
  clearCachedTrackingConfig
} from '../../api/trackingConfig';
import { useAuth } from '../Hooks/useAuth';

const componentOptions = [
  { value: '*', label: 'All Components' },
  { value: 'TimeCard', label: 'Time Card' },
  { value: 'Map', label: 'Map' },
  { value: 'GeocoderSearch', label: 'Geocoder Search' },
  { value: 'TodoList', label: 'Todo List' },
  { value: 'Assignment', label: 'Assignment' }
];

const eventTypeOptions = [
  { value: '*', label: 'All Events' },
  { value: 'component_view', label: 'Component View' },
  { value: 'button_click', label: 'Button Click' },
  { value: 'menu_click', label: 'Menu Click' },
  { value: 'form_submit', label: 'Form Submit' },
  { value: 'map_interaction', label: 'Map Interaction' },
  { value: 'map_layer_toggle', label: 'Map Layer Toggle' },
  { value: 'geolocation_search', label: 'Geolocation Search' },
  { value: 'timecard_action', label: 'TimeCard Action' },
  { value: 'todo_action', label: 'Todo Action' },
  { value: 'assignment_action', label: 'Assignment Action' },
  { value: 'page_view', label: 'Page View' }
];

const TrackingConfigPanel = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [globalConfig, setGlobalConfig] = useState({ is_enabled: true });
  const [componentConfigs, setComponentConfigs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newConfig, setNewConfig] = useState({
    component_name: '*',
    event_type: '*',
    is_enabled: true
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Load tracking configurations
  useEffect(() => {
    const loadConfigs = async () => {
      try {
        setLoading(true);
        const configs = await getAllTrackingConfigs();
        setGlobalConfig(configs.globalConfig);
        setComponentConfigs(configs.componentConfigs);
      } catch (error) {
        console.error('Error loading tracking configs:', error);
        setSnackbar({
          open: true,
          message: 'Error loading tracking configurations',
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };

    loadConfigs();
  }, []);

  // Handle global tracking toggle
  const handleGlobalToggle = async (event) => {
    const isEnabled = event.target.checked;
    try {
      setGlobalConfig({ ...globalConfig, is_enabled: isEnabled });
      await updateGlobalTrackingConfig(isEnabled);
      clearCachedTrackingConfig();
      setSnackbar({
        open: true,
        message: `Global tracking ${isEnabled ? 'enabled' : 'disabled'}`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating global tracking config:', error);
      setSnackbar({
        open: true,
        message: 'Error updating global tracking configuration',
        severity: 'error'
      });
      // Revert UI state
      setGlobalConfig({ ...globalConfig, is_enabled: !isEnabled });
    }
  };

  // Handle component tracking toggle
  const handleComponentToggle = async (id, isEnabled) => {
    try {
      // Update UI optimistically
      setComponentConfigs(
        componentConfigs.map(config =>
          config.id === id ? { ...config, is_enabled: !isEnabled } : config
        )
      );

      // Find the config to update
      const configToUpdate = componentConfigs.find(config => config.id === id);
      if (!configToUpdate) return;

      // Update in database
      await updateComponentTrackingConfig(
        configToUpdate.component_name,
        configToUpdate.event_type,
        !isEnabled
      );
      
      clearCachedTrackingConfig();
      
      setSnackbar({
        open: true,
        message: `Tracking for ${configToUpdate.component_name}:${configToUpdate.event_type} ${!isEnabled ? 'enabled' : 'disabled'}`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating component tracking config:', error);
      setSnackbar({
        open: true,
        message: 'Error updating component tracking configuration',
        severity: 'error'
      });
      
      // Revert UI state
      setComponentConfigs(
        componentConfigs.map(config =>
          config.id === id ? { ...config, is_enabled: isEnabled } : config
        )
      );
    }
  };

  // Handle delete tracking config
  const handleDeleteConfig = async (id) => {
    try {
      // Update UI optimistically
      setComponentConfigs(componentConfigs.filter(config => config.id !== id));

      // Delete from database
      await deleteTrackingConfig(id);
      clearCachedTrackingConfig();
      
      setSnackbar({
        open: true,
        message: 'Tracking configuration deleted',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting tracking config:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting tracking configuration',
        severity: 'error'
      });
      
      // Reload configs to revert UI state
      const configs = await getAllTrackingConfigs();
      setComponentConfigs(configs.componentConfigs);
    }
  };

  // Handle add new config
  const handleAddConfig = async () => {
    try {
      await updateComponentTrackingConfig(
        newConfig.component_name,
        newConfig.event_type,
        newConfig.is_enabled
      );
      clearCachedTrackingConfig();
      
      // Reload configs
      const configs = await getAllTrackingConfigs();
      setComponentConfigs(configs.componentConfigs);
      
      setSnackbar({
        open: true,
        message: 'Tracking configuration added',
        severity: 'success'
      });
      
      setOpenDialog(false);
    } catch (error) {
      console.error('Error adding tracking config:', error);
      setSnackbar({
        open: true,
        message: 'Error adding tracking configuration',
        severity: 'error'
      });
    }
  };

  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        User Interaction Tracking Configuration
      </Typography>

      {/* Global Tracking Toggle */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <FormControlLabel
          control={
            <Switch
              checked={globalConfig.is_enabled}
              onChange={handleGlobalToggle}
              color="primary"
            />
          }
          label={
            <Typography variant="h6">
              {globalConfig.is_enabled ? 'Tracking Enabled' : 'Tracking Disabled'}
            </Typography>
          }
        />
        <Typography variant="body2" color="text.secondary">
          This setting controls whether user interaction tracking is enabled globally.
          When disabled, no interactions will be tracked regardless of component-specific settings.
        </Typography>
      </Paper>

      {/* Component Tracking Table */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5">Component Tracking Settings</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Add Configuration
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Component</TableCell>
                <TableCell>Event Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {componentConfigs.map((config) => (
                <TableRow key={config.id}>
                  <TableCell>{config.component_name}</TableCell>
                  <TableCell>{config.event_type}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={config.is_enabled}
                          onChange={() => handleComponentToggle(config.id, config.is_enabled)}
                          color="primary"
                          disabled={!globalConfig.is_enabled}
                        />
                      }
                      label={config.is_enabled ? 'Enabled' : 'Disabled'}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => handleDeleteConfig(config.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {componentConfigs.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No component tracking configurations found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Add Configuration Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Tracking Configuration</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, minWidth: 400 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Component</InputLabel>
              <Select
                value={newConfig.component_name}
                label="Component"
                onChange={(e) => setNewConfig({ ...newConfig, component_name: e.target.value })}
              >
                {componentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Event Type</InputLabel>
              <Select
                value={newConfig.event_type}
                label="Event Type"
                onChange={(e) => setNewConfig({ ...newConfig, event_type: e.target.value })}
              >
                {eventTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={newConfig.is_enabled}
                  onChange={(e) => setNewConfig({ ...newConfig, is_enabled: e.target.checked })}
                  color="primary"
                />
              }
              label={newConfig.is_enabled ? 'Enabled' : 'Disabled'}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddConfig} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TrackingConfigPanel;
