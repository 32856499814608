import React, { useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Box,
  Divider,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { clearPropertyDataCache, fetchAndCachePropertyData } from '../../api/propertyData';
import { useAuth } from '../Hooks/useAuth';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker'; // Import the tracker hook
import RefreshIcon from '@mui/icons-material/Refresh';

const ValueText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: '#2b8a3e',
}));

const PropertyRow = ({ label, value, isPrice = false }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
    <Typography variant="body2">{label}</Typography>
    {isPrice ? (
      <ValueText variant="body1">${value.toLocaleString()}</ValueText>
    ) : (
      <Typography variant="body2">{value || 'N/A'}</Typography>
    )}
  </Box>
);

export const PropertySummary = ({ property }) => {
  const { user } = useAuth();
  const { trackInteraction } = useUserInteractionTracker(); // Initialize the tracker
  
  // Track page view when component mounts
  useEffect(() => {
    trackInteraction('page_view', { 
      sessionHash: user?.id, 
      pageName: 'PropertyDialogContent',
      userId: user?.id,
      url: window.location.href,
      timestamp: Date.now()
    });
  }, []);
  const isPermit = 'Permit Type' in property.properties;
  const props = property.properties;
  
  // Debug: Log the properties to see what's available
  console.log('Property data:', props);
  console.dir(props, { depth: null });
  
  const handleRefreshData = async () => {
    if (!user) {
      alert("You must be logged in to refresh property data.");
      return;
    }
    
    try {
      clearPropertyDataCache(user.id);
      await fetchAndCachePropertyData(user.id);
      alert("Property data refreshed successfully! Please click on the property again to see updated data.");
    } catch (error) {
      console.error("Error refreshing property data:", error);
      alert("Failed to refresh property data.");
    }
  };
  
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button 
          variant="outlined" 
          size="small" 
          startIcon={<RefreshIcon />}
          onClick={handleRefreshData}
        >
          Refresh Data
        </Button>
      </Box>
      <PropertyRow 
        label="Value" 
        value={props['Property Value']} 
        isPrice={true} 
      />
      
      {isPermit ? (
        <>
          <PropertyRow label="Permit Type" value={props['Permit Type']} />
          <PropertyRow label="Contractor" value={props['Contractor Name']} />
          <PropertyRow label="Use Type" value={props['Use Type']} />
          {props['Square Feet'] && <PropertyRow label="Square Feet" value={props['Square Feet']} />}
          {props['Additional Information'] && <PropertyRow label="Additional Information" value={props['Additional Information']} />}
        </>
      ) : (
        <>
        <PropertyRow 
          label="Homeowner Type" 
          value={
            props['Homeowner Type'] === null
              ? 'N/A'
              : props['Homeowner Type'] === 'C'
              ? 'Commercial'
              : props['Homeowner Type'] === 'R'
              ? 'Residential'
              : 'N/A'
          } 
        />
        {props['Buyer Name'] && <PropertyRow label="Buyer Name" value={props['Buyer Name']} />}
        {props['Seller Name'] && <PropertyRow label="Seller Name" value={props['Seller Name']} />}
        </>
      )}
      
      <PropertyRow label="Description" value={props['Property Description']} />
      <PropertyRow 
        label="Recording Date" 
        value={
          (props['Recording Date'] || props['recording_date'])
            ? new Date(props['Recording Date'] || props['recording_date']).toLocaleDateString('en-US', { 
                weekday: 'short', 
                year: 'numeric', 
                month: 'short', 
                day: 'numeric' 
              })
            : 'N/A'
        } 
      />
    </Box>
  );
};

export const MultiplePropertiesSummary = ({ features }) => {
  const totalValue = features.reduce((sum, feature) => sum + feature.properties['Property Value'], 0);
  const avgValue = totalValue / features.length;
  const isPermit = 'Permit Type' in features[0].properties;
  
  return (
    <Box sx={{ mb: 2 }}>
      <PropertyRow 
        label="Total Value" 
        value={totalValue} 
        isPrice={true} 
      />
      <PropertyRow 
        label="Average Value" 
        value={Math.round(avgValue)} 
        isPrice={true} 
      />
      
      {isPermit && (
        <>
          <PropertyRow 
            label="Permit Types" 
            value={[...new Set(features.map(f => f.properties['Permit Type']))].join(', ')} 
          />
          <PropertyRow 
            label="Use Types" 
            value={[...new Set(features.map(f => f.properties['Use Type']))].join(', ')} 
          />
        </>
      )}
    </Box>
  );
};

export const PropertiesTable = ({ features }) => {
  const isPermit = 'Permit Type' in features[0].properties;
  
  // Debug: Log the first feature to see what properties are available
  console.log('First feature in table:', features[0].properties);
  
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>Value</TableCell>
            {!isPermit && (
              <>
                {features[0].properties['Buyer Name'] && <TableCell>Buyer</TableCell>}
                {features[0].properties['Seller Name'] && <TableCell>Seller</TableCell>}
              </>
            )}
            {isPermit && features[0].properties['Square Feet'] && <TableCell>Square Feet</TableCell>}
            <TableCell>
              {isPermit ? 'Use Type, Description' : 'Description'}
            </TableCell>
            <TableCell>Recording Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature, index) => (
            <TableRow key={index}>
              <TableCell>
                {feature.properties[isPermit ? 'Site Address' : 'Property Address']}
              </TableCell>
              <TableCell>${feature.properties['Property Value'].toLocaleString()}</TableCell>
              {!isPermit && (
                <>
                  {feature.properties['Buyer Name'] && <TableCell>{feature.properties['Buyer Name'] || 'N/A'}</TableCell>}
                  {feature.properties['Seller Name'] && <TableCell>{feature.properties['Seller Name'] || 'N/A'}</TableCell>}
                </>
              )}
              {isPermit && feature.properties['Square Feet'] && <TableCell>{feature.properties['Square Feet'] || 'N/A'}</TableCell>}
              <TableCell>
                {isPermit 
                  ? `${feature.properties['Use Type'] || ''} - ${feature.properties['Property Description'] || ''}`
                  : `${feature.properties['Property Description'] || 'N/A'}`
                }
              </TableCell>
              <TableCell>
                {(feature.properties['Recording Date'] || feature.properties['recording_date'])
                  ? new Date(feature.properties['Recording Date'] || feature.properties['recording_date']).toLocaleDateString('en-US', { 
                      year: 'numeric', 
                      month: 'short', 
                      day: 'numeric' 
                    })
                  : 'N/A'
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SinglePropertyContent = ({ feature }) => {
  return <PropertySummary property={feature} />;
};

export const MultiplePropertiesContent = ({ features }) => {
  const { user } = useAuth();
  const { trackInteraction } = useUserInteractionTracker();
  
  // Track page view when component mounts
  useEffect(() => {
    trackInteraction('page_view', { 
      sessionHash: user?.id, 
      pageName: 'PropertyDialogContent',
      userId: user?.id,
      url: window.location.href,
      timestamp: Date.now()
    });
  }, []);
  
  return (
    <>
      <MultiplePropertiesSummary features={features} />
      <Divider sx={{ my: 2 }} />
      <PropertiesTable features={features} />
    </>
  );
};
