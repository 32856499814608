import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  Paper, 
  Container,
  Alert,
  CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Hooks/useAuth';
import { isUserAdmin } from '../../api/trackingConfig';
import TrackingConfigPanel from './TrackingConfigPanel';
import UserRoleManagement from './UserRoleManagement';
import InteractionDashboard from './InteractionDashboard';

// Tab panel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Helper function for tab accessibility
function a11yProps(index) {
  return {
    id: `admin-tab-${index}`,
    'aria-controls': `admin-tabpanel-${index}`,
  };
}

const AdminPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  // Check if user is an admin
  useEffect(() => {
    const checkAdmin = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        console.log(`AdminPage: Checking if user ${user.id} is an admin`);

        // Force admin to true for testing
        //setIsAdmin(true);
        //console.log('AdminPage: Forcing admin to true for testing');
        
        // Uncomment this to use the actual admin check
        const admin = await isUserAdmin(user.id);
        console.log(`AdminPage: User ${user.id} is admin: ${admin}`);
        setIsAdmin(admin); // temporarily set to true for testing
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      checkAdmin();
    } else {
      setLoading(false);
    }
  }, [user]);

  // Debug user object
  useEffect(() => {
    console.log('AdminPage: User object:', user);
    console.log('AdminPage: Loading state:', loading);
    console.log('AdminPage: isAdmin state:', isAdmin);
  }, [user, loading, isAdmin]);

  // Redirect non-admin users
  useEffect(() => {
    if (!loading) {
      // If user is not logged in, redirect to login
      if (!user) {
        console.log('AdminPage: User not logged in, redirecting to login');
        console.log('AdminPage: User object is:', user);
        // navigate('/auth/login');
        return;
      }
      
      // If user is logged in but not an admin, show error message (handled in render)
      if (!isAdmin) {
        console.log('AdminPage: User is not an admin');
      }
    }
  }, [isAdmin, loading, navigate, user]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isAdmin && user) {
    console.log(isAdmin, user);
    console.log('AdminPage: User is not an admin, showing error message');
    console.log('AdminPage: User object is:', user);
    console.log('AdminPage: isAdmin state is:', isAdmin);
    console.log('AdminPage: loading state is:', loading);
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="error">
          You do not have permission to access the admin panel. Please contact an administrator.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            aria-label="admin tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Dashboard" {...a11yProps(0)} />
            <Tab label="User Tracking" {...a11yProps(1)} />
            <Tab label="User Management" {...a11yProps(2)} />
            <Tab label="System Settings" {...a11yProps(3)} />
          </Tabs>
        </Box>
        
        <TabPanel value={tabValue} index={0}>
          <InteractionDashboard />
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          <TrackingConfigPanel />
        </TabPanel>
        
        <TabPanel value={tabValue} index={2}>
          <UserRoleManagement />
        </TabPanel>
        
        <TabPanel value={tabValue} index={3}>
          <Typography variant="h4" gutterBottom>
            System Settings
          </Typography>
          <Typography paragraph>
            System settings functionality will be implemented here.
          </Typography>
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default AdminPage;
