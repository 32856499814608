import { supabase } from '../lib/supabase';

/**
 * Save user device information to the database
 * @param {string} userId - User ID
 * @param {Object} deviceInfo - Device information object with dvc_hash and dvc_json
 * @returns {Promise<Object>} Saved device information
 */
export async function saveUserDevice(userId, deviceInfo) {
  if (!userId || !deviceInfo || !deviceInfo.dvc_hash) {
    console.error('Invalid parameters for saveUserDevice:', { userId, deviceInfo });
    throw new Error('Invalid parameters for saveUserDevice');
  }

  try {
    // Check if the device already exists for this user
    const { data: existingDevice, error: fetchError } = await supabase
      .from('user_device')
      .select('*')
      .eq('user_id', userId)
      .eq('device_id', deviceInfo.dvc_hash)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') { // PGRST116 is "no rows returned" error
      console.error('Error fetching existing device:', fetchError);
      throw fetchError;
    }

    // If the device exists, update it
    if (existingDevice) {
      // TODO: Future enhancement: Increment counter for device usage possibly storing in ClickHouse. Part of user interaction tracking/analytics.
      const { data, error } = await supabase
        .from('user_device')
        .update({
          device_info_json: {
            dvc_hash: deviceInfo.dvc_hash,
            dvc_json: deviceInfo.dvc_json
          }
          // Let the database trigger handle the updated_at column
        })
        .eq('id', existingDevice.id)
        .select()
        .single();

      if (error) {
        console.error('Error updating device information:', error);
        throw error;
      }

      console.log('Device information updated:', data);
      return data;
    }

    // If the device doesn't exist, insert it
    const { data, error } = await supabase
      .from('user_device')
      .insert({
        user_id: userId,
        device_id: deviceInfo.dvc_hash,
        device_info_json: {
          dvc_hash: deviceInfo.dvc_hash,
          dvc_json: deviceInfo.dvc_json
        }
      })
      .select()
      .single();

    if (error) {
      console.error('Error inserting device information:', error);
      throw error;
    }

    console.log('Device information saved:', data);
    return data;
  } catch (error) {
    console.error('Error in saveUserDevice:', error);
    throw error;
  }
}

/**
 * Get user devices
 * @param {string} userId - User ID
 * @returns {Promise<Array>} User devices
 */
export async function getUserDevices(userId) {
  if (!userId) {
    console.error('Invalid userId for getUserDevices:', userId);
    throw new Error('Invalid userId for getUserDevices');
  }

  try {
    const { data, error } = await supabase
      .from('user_device')
      .select('*')
      .eq('user_id', userId)
      .order('updated_at', { ascending: false });

    if (error) {
      console.error('Error fetching user devices:', error);
      throw error;
    }

    return data || [];
  } catch (error) {
    console.error('Error in getUserDevices:', error);
    throw error;
  }
}

/**
 * Get user device by ID
 * @param {string} userId - User ID
 * @param {number} deviceId - Device ID
 * @returns {Promise<Object>} User device
 */
export async function getUserDeviceById(userId, deviceId) {
  if (!userId || !deviceId) {
    console.error('Invalid parameters for getUserDeviceById:', { userId, deviceId });
    throw new Error('Invalid parameters for getUserDeviceById');
  }

  try {
    const { data, error } = await supabase
      .from('user_device')
      .select('*')
      .eq('user_id', userId)
      .eq('device_id', deviceId)
      .single();

    if (error) {
      console.error('Error fetching user device:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in getUserDeviceById:', error);
    throw error;
  }
}

/**
 * Delete user device
 * @param {string} userId - User ID
 * @param {number} deviceId - Device ID
 * @returns {Promise<void>}
 */
export async function deleteUserDevice(userId, deviceId) {
  if (!userId || !deviceId) {
    console.error('Invalid parameters for deleteUserDevice:', { userId, deviceId });
    throw new Error('Invalid parameters for deleteUserDevice');
  }

  try {
    const { error } = await supabase
      .from('user_device')
      .delete()
      .eq('user_id', userId)
      .eq('device_id', deviceId);

    if (error) {
      console.error('Error deleting user device:', error);
      throw error;
    }

    console.log('Device deleted successfully');
  } catch (error) {
    console.error('Error in deleteUserDevice:', error);
    throw error;
  }
}
