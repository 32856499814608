import useUserInteractionTracker from '../components/Hooks/useUserInteractionTracker';

/**
 * Utility functions for tracking user interactions across the application
 * This file provides standardized tracking functions for various components and interactions
 */

// Create a singleton instance of the tracker
let trackInteractionFunc = null;
let clearSessionDataFunc = null;

/**
 * Initialize the interaction tracker
 * This should be called in a component that has access to the StoreContext
 * @param {Function} trackInteraction - The trackInteraction function from useUserInteractionTracker
 * @param {Function} clearSessionData - The clearSessionData function from useUserInteractionTracker
 */
export const initializeTracker = (trackInteraction, clearSessionData) => {
  trackInteractionFunc = trackInteraction;
  clearSessionDataFunc = clearSessionData;
};

/**
 * Get the current timestamp
 * @returns {number} - Current timestamp in milliseconds
 */
const getTimestamp = () => Date.now();

/**
 * Get the current URL
 * @returns {string} - Current URL
 */
const getCurrentUrl = () => window.location.href;

/**
 * Track a component view
 * @param {string} componentName - Name of the component being viewed
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackComponentView = (componentName, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('component_view', {
    componentName,
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a button click
 * @param {string} buttonName - Name of the button being clicked
 * @param {string} componentName - Name of the component containing the button
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackButtonClick = (buttonName, componentName, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('button_click', {
    buttonName,
    componentName,
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a menu click
 * @param {string} menuItem - Name of the menu item being clicked
 * @param {string} menuName - Name of the menu
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackMenuClick = (menuItem, menuName, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('menu_click', {
    menuItem,
    menuName,
    componentName: menuName, // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a form submission
 * @param {string} formName - Name of the form being submitted
 * @param {Object} formData - Data being submitted (sensitive data should be excluded)
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackFormSubmit = (formName, formData = {}, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  // Remove sensitive data from formData
  const sanitizedFormData = { ...formData };
  delete sanitizedFormData.password;
  delete sanitizedFormData.token;
  delete sanitizedFormData.secret;

  trackInteractionFunc('form_submit', {
    formName,
    formData: sanitizedFormData,
    componentName: additionalData.componentName || formName, // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a map interaction
 * @param {string} interactionType - Type of map interaction (e.g., 'click', 'zoom', 'pan')
 * @param {Object} coordinates - Coordinates of the interaction
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackMapInteraction = (interactionType, coordinates, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('map_interaction', {
    interactionType,
    coordinates,
    componentName: 'Map', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a map layer toggle
 * @param {string} layerName - Name of the layer being toggled
 * @param {boolean} isVisible - Whether the layer is now visible
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackMapLayerToggle = (layerName, isVisible, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('map_layer_toggle', {
    layerName,
    isVisible,
    componentName: 'Map', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a geolocation search
 * @param {string} searchQuery - The search query
 * @param {Object} searchResults - The search results (count or summary)
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackGeolocationSearch = (searchQuery, searchResults = {}, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('geolocation_search', {
    searchQuery,
    resultsCount: searchResults.count || 0,
    componentName: 'GeocoderSearch', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a TimeCard action
 * @param {string} action - The action being performed (e.g., 'clock_in', 'clock_out', 'break_start', 'break_end')
 * @param {Object} timeData - Time-related data
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackTimeCardAction = (action, timeData = {}, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('timecard_action', {
    action,
    timeData,
    componentName: 'TimeCard', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a TimeTrack action
 * @param {string} action - The action being performed (e.g., 'clock_in', 'clock_out', 'break_start', 'break_end')
 * @param {Object} timeData - Time-related data
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackTimeTrackAction = (action, timeData = {}, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('timetrack_action', {
    action,
    timeData,
    componentName: 'TimeTrack', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a TimeTrack form submission
 * @param {string} formType - Type of form being submitted (e.g., 'clock_in', 'clock_out', 'break')
 * @param {Object} formData - Data being submitted (sensitive data should be excluded)
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackTimeTrackFormSubmit = (formType, formData = {}, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  // Remove sensitive data from formData
  const sanitizedFormData = { ...formData };
  delete sanitizedFormData.password;
  delete sanitizedFormData.token;
  delete sanitizedFormData.secret;

  trackInteractionFunc('form_submit', {
    formName: `TimeTrack_${formType}`,
    formData: sanitizedFormData,
    componentName: 'TimeTrack', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a TimeTrack automatic break end event
 * @param {string} breakType - Type of break that ended (e.g., 'meal', 'rest')
 * @param {number} duration - Duration of the break in minutes
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackTimeTrackAutoBreakEnd = (breakType, duration, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('timetrack_auto_break_end', {
    breakType,
    duration,
    componentName: 'TimeTrack', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a TimeTrack page view
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackTimeTrackPageView = (additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('page_view', {
    pageName: 'TimeTrack',
    componentName: 'TimeTrack', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a Todo action
 * @param {string} action - The action being performed (e.g., 'add', 'complete', 'delete')
 * @param {string} todoId - ID of the todo item
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackTodoAction = (action, todoId, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('todo_action', {
    action,
    todoId,
    componentName: 'TodoList', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};



/**
 * Track an Assignment action
 * @param {string} action - The action being performed
 * @param {string} assignmentId - ID of the assignment
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackAssignmentAction = (action, assignmentId, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('assignment_action', {
    action,
    assignmentId,
    componentName: 'Assignment', // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Track a page view
 * @param {string} pageName - Name of the page being viewed
 * @param {string} userId - ID of the user
 * @param {Object} additionalData - Additional data to include with the interaction
 */
export const trackPageView = (pageName, userId, additionalData = {}) => {
  if (!trackInteractionFunc) {
    console.warn('Interaction tracker not initialized');
    return;
  }

  trackInteractionFunc('page_view', {
    pageName,
    userId,
    componentName: pageName, // Add componentName for tracking config
    url: getCurrentUrl(),
    timestamp: getTimestamp(),
    ...additionalData
  });
};

/**
 * Use this hook in your component to get tracking functions
 * @returns {Object} - Object containing tracking functions
 */
export const useTrackInteractions = () => {
  const { trackInteraction, clearSessionData } = useUserInteractionTracker();
  
  // Initialize the tracker if it hasn't been initialized yet
  if (!trackInteractionFunc) {
    initializeTracker(trackInteraction, clearSessionData);
  }
  
  return {
    trackComponentView,
    trackButtonClick,
    trackMenuClick,
    trackFormSubmit,
    trackMapInteraction,
    trackMapLayerToggle,
    trackGeolocationSearch,
    trackTimeCardAction,
    trackTimeTrackAction,
    trackTimeTrackFormSubmit,
    trackTimeTrackAutoBreakEnd,
    trackTimeTrackPageView,
    trackTodoAction,
    trackAssignmentAction,
    trackPageView,
    clearSessionData
  };
};

export default useTrackInteractions;
