import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const CommentSection = memo(({ 
  comments,
  commentAuthors,
  currentUserId,
  onAddComment,
  onUpdateComment,
  onDeleteComment,
  isLoading,
  classes
}) => {
  const [newComment, setNewComment] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [localLoading, setLocalLoading] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!newComment.trim() || localLoading) return;

    try {
      setLocalLoading(true);
      await onAddComment(newComment.trim());
      setNewComment('');
    } finally {
      setLocalLoading(false);
    }
  }, [newComment, localLoading, onAddComment]);

  const handleStartEdit = useCallback((comment) => {
    setEditingCommentId(comment.id);
    setEditedCommentText(comment.comment);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setEditingCommentId(null);
    setEditedCommentText('');
  }, []);

  const handleSaveEdit = useCallback(async (commentId, userId) => {
    if (!editedCommentText.trim() || localLoading) return;

    try {
      setLocalLoading(true);
      await onUpdateComment(commentId, userId, editedCommentText.trim());
      setEditingCommentId(null);
      setEditedCommentText('');
    } finally {
      setLocalLoading(false);
    }
  }, [editedCommentText, localLoading, onUpdateComment]);

  const handleDelete = useCallback(async (commentId, userId) => {
    if (localLoading) return;

    try {
      setLocalLoading(true);
      await onDeleteComment(commentId, userId);
    } finally {
      setLocalLoading(false);
    }
  }, [localLoading, onDeleteComment]);

  const isDisabled = isLoading || localLoading;

  return (
    <Box className={classes.commentsSection}>
      <Typography variant="h6" sx={{ mb: 2, px: 2, pt: 2 }}>Comments</Typography>
      
      {/* Comments List */}
      <Box sx={{ 
        flex: 1,
        overflowY: 'auto',
        px: 2,
        pb: 2
      }}>
        {comments.length === 0 ? (
          <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
            No comments yet. Be the first to add one!
          </Typography>
        ) : (
          <List disablePadding>
            {comments.map((comment) => (
              <ListItem 
                key={comment.id} 
                className={classes.commentItem}
                sx={{ 
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  mb: 2
                }}
              >
                {editingCommentId === comment.id ? (
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      value={editedCommentText}
                      onChange={(e) => setEditedCommentText(e.target.value)}
                      disabled={isDisabled}
                      sx={{ mb: 1 }}
                    />
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                      <Button
                        size="small"
                        startIcon={<SaveIcon />}
                        onClick={() => handleSaveEdit(comment.id, comment.user_id)}
                        disabled={isDisabled || !editedCommentText.trim()}
                        color="primary"
                        variant="contained"
                      >
                        Save
                      </Button>
                      <Button
                        size="small"
                        startIcon={<CancelIcon />}
                        onClick={handleCancelEdit}
                        disabled={isDisabled}
                        color="inherit"
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <ListItemText
                      primary={comment.comment}
                      secondary={
                        <Typography variant="caption" component="div" sx={{ mt: 0.5 }}>
                          {commentAuthors[comment.user_id]?.full_name || 
                          commentAuthors[comment.user_id]?.email || 
                          "Unknown User"} • {new Date(comment.utc_created).toLocaleString()}
                        </Typography>
                      }
                      sx={{
                        '& .MuiTypography-root': {
                          wordBreak: 'break-word'
                        }
                      }}
                    />
                    {comment.user_id === currentUserId && (
                      <Box sx={{ 
                        display: 'flex', 
                        gap: 1,
                        alignSelf: 'flex-end',
                        mt: 1
                      }}>
                        <IconButton 
                          size="small"
                          onClick={() => handleStartEdit(comment)}
                          disabled={isDisabled}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton 
                          size="small"
                          onClick={() => handleDelete(comment.id, comment.user_id)}
                          disabled={isDisabled}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </>
                )}
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      {/* Add Comment Form */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          position: 'sticky',
          bottom: 0,
          bgcolor: 'background.paper',
          p: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
          boxShadow: '0 -4px 12px rgba(0, 0, 0, 0.05)'
        }}
      >
        <TextField
          label="New comment"
          fullWidth
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          disabled={isDisabled}
          multiline
          rows={2}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
            },
            mb: 1
          }}
        />
        <Button 
          type="submit" 
          variant="contained"
          color="primary" 
          disabled={isDisabled || !newComment.trim()}
          fullWidth
          sx={{ 
            height: 42,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600
          }}
        >
          {isDisabled ? 'Saving...' : 'Add Comment'}
        </Button>
      </Box>
    </Box>
  );
});

CommentSection.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      comment: PropTypes.string.isRequired,
      user_id: PropTypes.string.isRequired,
      utc_created: PropTypes.number.isRequired,
    })
  ).isRequired,
  commentAuthors: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  onAddComment: PropTypes.func.isRequired,
  onUpdateComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

CommentSection.displayName = 'CommentSection';

export default CommentSection;
