export const groupTodoLists = (todolists = [], excludeIds = []) => {
  if (!Array.isArray(todolists) || !Array.isArray(excludeIds)) {
    throw new TypeError('todolists and excludeIds must be arrays');
  }

  const now = new Date();
  
  // Set up date boundaries - using date only (no time) for comparison
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);
  
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const groupedLists = {
    today: [],
    yesterday: [],
    previous7Days: [],
    previous30Days: [],
    byMonth: {},
    archived: [],
  };

  todolists.forEach((list) => {
    if (!list || typeof list !== 'object') {
      console.warn('Invalid todo list item:', list);
      return;
    }

    if (excludeIds.includes(list.id)) return;

    // Handle archived lists separately
    if (list.archived) {
      groupedLists.archived.push(list);
      return;
    }

    // Get both created and updated dates
    const createdDate = new Date(list.created_at);
    const updatedDate = new Date(list.updated_at);
    
    if (isNaN(createdDate.getTime()) || isNaN(updatedDate.getTime())) {
      console.warn('Invalid date for todo list:', list);
      return;
    }

    // Convert to date-only for comparison (no time)
    const createdDateOnly = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());
    const updatedDateOnly = new Date(updatedDate.getFullYear(), updatedDate.getMonth(), updatedDate.getDate());
    
    // Use the most recent date between created and updated for grouping
    const mostRecentDate = new Date(Math.max(createdDate.getTime(), updatedDate.getTime()));
    const mostRecentDateOnly = new Date(mostRecentDate.getFullYear(), mostRecentDate.getMonth(), mostRecentDate.getDate());
    
    // Group into appropriate category following the SQL query logic
    
    // Today: Lists created or updated today
    if (createdDateOnly.getTime() === today.getTime() || updatedDateOnly.getTime() === today.getTime()) {
      groupedLists.today.push(list);
    } 
    // Yesterday: Lists created or updated yesterday
    else if (createdDateOnly.getTime() === yesterday.getTime() || updatedDateOnly.getTime() === yesterday.getTime()) {
      groupedLists.yesterday.push(list);
    } 
    // Previous 7 Days: Lists from the last week (excluding today and yesterday)
    else if ((createdDate >= sevenDaysAgo || updatedDate >= sevenDaysAgo) && 
             (createdDateOnly < yesterday && updatedDateOnly < yesterday)) {
      groupedLists.previous7Days.push(list);
    } 
    // Previous 30 Days: Lists from the last 30 days (excluding the first three groups)
    else if ((createdDate >= thirtyDaysAgo || updatedDate >= thirtyDaysAgo) && 
             (createdDateOnly < sevenDaysAgo && updatedDateOnly < sevenDaysAgo)) {
      groupedLists.previous30Days.push(list);
    } 
    // Monthly Groups: Lists older than 30 days, grouped by month
    else {
      // Use created_at for the month grouping as per the SQL query
      const monthYear = createdDate.toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!groupedLists.byMonth[monthYear]) {
        groupedLists.byMonth[monthYear] = [];
      }
      groupedLists.byMonth[monthYear].push(list);
    }
  });

  return groupedLists;
};

export const getMostRecentLists = (todolists = [], limit = 5) => {
  if (!Array.isArray(todolists)) {
    throw new TypeError('todolists must be an array');
  }

  if (typeof limit !== 'number' || limit < 0) {
    throw new TypeError('limit must be a positive number');
  }

  return [...todolists]
    .filter(list => list && !list.archived)
    .sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        return 0;
      }
      return dateB - dateA;
    })
    .slice(0, limit);
};
