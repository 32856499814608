import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, Stack } from '@mui/material';
import ee from 'event-emitter';

// Event emitter instance
const emitter = new ee();

/**
 * Trigger a notification.
 * @param {string} msg - The message to display.
 * @param {string} type - The type of notification (e.g., 'success', 'error', 'info').
 * @param {boolean} persist - Whether the notification should persist (default: false).
 */
export const notify = (msg, type = 'info', persist = false) => {
  emitter.emit('notification', { msg, type, persist });
};

const Toast = () => {
  const [notifications, setNotifications] = useState([]);
  const timeoutsRef = React.useRef(new Map());

  useEffect(() => {
    // Subscribe to notification events
    const handleNotification = (data) => {
      addNotification(data.msg, data.type, data.persist);
    };

    emitter.on('notification', handleNotification);

    // Cleanup on unmount
    return () => {
      emitter.off('notification', handleNotification);
      timeoutsRef.current.forEach((timeout) => clearTimeout(timeout));
      timeoutsRef.current.clear();
    };
  }, []);

  /**
   * Add a notification to the state.
   * @param {string} msg - The message to display.
   * @param {string} type - The type of notification.
   * @param {boolean} persist - Whether the notification should persist.
   */
  const addNotification = (msg, type, persist = false) => {
    const id = Date.now(); // Unique ID for each notification
    setNotifications((prev) => [...prev, { id, msg, type, persist }]);

    // If the notification is not persistent, remove it after 3 seconds
    if (!persist) {
      const timeout = setTimeout(() => {
        removeNotification(id);
      }, 3000);
      timeoutsRef.current.set(id, timeout);
    }
  };

  /**
   * Remove a notification from the state.
   * @param {number} id - The ID of the notification to remove.
   */
  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((note) => note.id !== id));
    if (timeoutsRef.current.has(id)) {
      clearTimeout(timeoutsRef.current.get(id));
      timeoutsRef.current.delete(id);
    }
  };

  // Convert the notification type to Material-UI's severity
  const getSeverity = (type) => {
    switch (type) {
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      default:
        return 'info';
    }
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={true}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{
            position: 'fixed',
            // Adjust top position for stacking
            top: (index) => `${16 + index * 60}px`,
            right: 16,
            zIndex: 999,
          }}
        >
          <Alert
            severity={getSeverity(notification.type)}
            variant="filled"
            sx={{ width: '100%' }}
            onClose={notification.persist ? () => removeNotification(notification.id) : undefined}
          >
            {notification.msg}
          </Alert>
        </Snackbar>
      ))}
    </Stack>
  );
};

export default Toast;
