import { supabase } from '../lib/supabase';

const CACHE_KEYS = {
  REAL_ESTATE: 'cached_real_estate_data',
  BUILDING_PERMITS: 'cached_building_permits_data',
};

const CACHE_CONFIG = {
  EXPIRATION_TIME: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
  MAX_CACHE_SIZE: 50 * 1024 * 1024, // 50MB max cache size
  CHUNK_SIZE: 1000, // Number of records per chunk
};

// Check if device is mobile
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Get available storage space (approximate)
const getAvailableStorage = async () => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const estimate = await navigator.storage.estimate();
    return {
      quota: estimate.quota,
      usage: estimate.usage,
      available: estimate.quota - estimate.usage
    };
  }
  return null;
};

// Check if we can safely store data
const canStoreData = async (dataSize) => {
  const storage = await getAvailableStorage();
  if (!storage) return true; // If we can't check, assume it's ok
  
  // For mobile devices, be more conservative with storage
  const safetyFactor = isMobileDevice() ? 0.5 : 0.8;
  return dataSize < Math.min(storage.available * safetyFactor, CACHE_CONFIG.MAX_CACHE_SIZE);
};

// Optimize GeoJSON for storage
const optimizeGeoJSON = (features) => {
  return features.map(feature => ({
    t: 'F', // type: Feature
    g: { // geometry
      t: 'P', // type: Point
      c: feature.geometry.coordinates // coordinates
    },
    p: feature.properties // properties
  }));
};

// Restore optimized GeoJSON to full format
const restoreGeoJSON = (optimizedFeatures) => {
  return {
    type: 'FeatureCollection',
    features: optimizedFeatures.map(opt => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: opt.g.c
      },
      properties: opt.p
    }))
  };
};

export const fetchAndCachePropertyData = async (userId) => {
  try {
    // Check cache first
    const cachedRealEstate = localStorage.getItem(`${CACHE_KEYS.REAL_ESTATE}_${userId}`);
    const cachedPermits = localStorage.getItem(`${CACHE_KEYS.BUILDING_PERMITS}_${userId}`);
    const cacheTimestamp = localStorage.getItem(`cache_timestamp_${userId}`);

    // If we have valid cached data that hasn't expired, return it
    if (cachedRealEstate && cachedPermits && cacheTimestamp) {
      const cacheAge = Date.now() - parseInt(cacheTimestamp);
      if (cacheAge < CACHE_CONFIG.EXPIRATION_TIME) {
        return {
          realEstate: restoreGeoJSON(JSON.parse(cachedRealEstate)),
          buildingPermits: restoreGeoJSON(JSON.parse(cachedPermits))
        };
      }
    }

    // Fetch fresh data from Supabase
    const [realEstateResponse, permitsResponse] = await Promise.all([
      supabase
        .from('real_estate_transactions')
        .select('seller_name,buyer_name,property_value,property_address,property_city,property_state,property_zip_code,property_description,homeowner_type,longitude,latitude,recording_date')
        .not('longitude', 'is', null)
        .not('latitude', 'is', null)
        .gte('recording_date', '2024-07-01'),
        //.lte('recording_date', '2025-01-31'),
      supabase
        .from('building_permits')
        .select('permit_type,contractor_name,use_type,property_description,site_address,site_city,site_state,site_zip_code,property_value,longitude,latitude,square_feet,additional_information,recording_date')
        .not('longitude', 'is', null)
        .not('latitude', 'is', null)
        .gte('recording_date', '2024-07-01')
        //.lte('recording_date', '2025-01-31')
    ]);

    if (realEstateResponse.error) throw realEstateResponse.error;
    if (permitsResponse.error) throw permitsResponse.error;

    // Transform data to GeoJSON format
    const realEstateGeoJson = {
      type: 'FeatureCollection',
      features: realEstateResponse.data.map(property => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [property.longitude, property.latitude]
        },
        properties: {
          'Seller Name': property.seller_name,
          'Buyer Name': property.buyer_name,
          'Property Value': property.property_value,
          'Property Address': property.property_address,
          'Property City': property.property_city,
          'Property State': property.property_state,
          'Property ZIP Code': property.property_zip_code,
          'Property Description': property.property_description,
          'Homeowner Type': property.homeowner_type === 'C' ? 'Commercial' : property.homeowner_type === 'R' ? 'Residential' : 'NA',
          'Recording Date': property.recording_date
        }
      }))
    };

    const buildingPermitsGeoJson = {
      type: 'FeatureCollection',
      features: permitsResponse.data.map(permit => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [permit.longitude, permit.latitude]
        },
        properties: {
          'Property Value': permit.property_value,
          'Site Address': permit.site_address,
          'Site City': permit.site_city,
          'Site State': permit.site_state,
          'Site ZIP Code': permit.site_zip_code,
          'Permit Type': permit.permit_type === 'C' ? 'Commercial' : permit.permit_type === 'R' ? 'Residential' : 'NA',
          'Use Type': permit.use_type,
          'Property Description': permit.property_description,
          'Contractor Name': permit.contractor_name,
          'Recording Date': permit.recording_date,
          'Square Feet': permit.square_feet,
          'Additional Information': permit.additional_information
        }
      }))
    };

    // Optimize the GeoJSON data
    const optimizedRealEstate = optimizeGeoJSON(realEstateGeoJson.features);
    const optimizedPermits = optimizeGeoJSON(buildingPermitsGeoJson.features);

    // Calculate approximate data size
    const dataSize = new Blob([
      JSON.stringify(optimizedRealEstate),
      JSON.stringify(optimizedPermits)
    ]).size;

    // Check if we can safely store the data
    const canStore = await canStoreData(dataSize);

    if (canStore) {
      // Store full dataset
      localStorage.setItem(`${CACHE_KEYS.REAL_ESTATE}_${userId}`, 
        JSON.stringify(optimizedRealEstate)
      );
      localStorage.setItem(`${CACHE_KEYS.BUILDING_PERMITS}_${userId}`, 
        JSON.stringify(optimizedPermits)
      );
      localStorage.setItem(`cache_timestamp_${userId}`, Date.now().toString());
    } else if (isMobileDevice()) {
      // For mobile devices with limited storage, store a subset of the data
      const limitedRealEstate = optimizedRealEstate.slice(0, CACHE_CONFIG.CHUNK_SIZE);
      const limitedPermits = optimizedPermits.slice(0, CACHE_CONFIG.CHUNK_SIZE);
      
      localStorage.setItem(`${CACHE_KEYS.REAL_ESTATE}_${userId}`, 
        JSON.stringify(limitedRealEstate)
      );
      localStorage.setItem(`${CACHE_KEYS.BUILDING_PERMITS}_${userId}`, 
        JSON.stringify(limitedPermits)
      );
      localStorage.setItem(`cache_timestamp_${userId}`, Date.now().toString());
    }

    return {
      realEstate: realEstateGeoJson,
      buildingPermits: buildingPermitsGeoJson
    };
  } catch (error) {
    console.error('Error fetching property data:', error);
    
    // If there's an error but we have cached data, return it
    const cachedRealEstate = localStorage.getItem(`${CACHE_KEYS.REAL_ESTATE}_${userId}`);
    const cachedPermits = localStorage.getItem(`${CACHE_KEYS.BUILDING_PERMITS}_${userId}`);
    
    if (cachedRealEstate && cachedPermits) {
      return {
        realEstate: JSON.parse(cachedRealEstate),
        buildingPermits: JSON.parse(cachedPermits)
      };
    }
    
    throw error;
  }
};

export const clearPropertyDataCache = (userId) => {
  localStorage.removeItem(`${CACHE_KEYS.REAL_ESTATE}_${userId}`);
  localStorage.removeItem(`${CACHE_KEYS.BUILDING_PERMITS}_${userId}`);
  localStorage.removeItem(`cache_timestamp_${userId}`);
};

// Get cache status information
export const getCacheStatus = async (userId) => {
  const cacheTimestamp = localStorage.getItem(`cache_timestamp_${userId}`);
  const realEstate = localStorage.getItem(`${CACHE_KEYS.REAL_ESTATE}_${userId}`);
  const permits = localStorage.getItem(`${CACHE_KEYS.BUILDING_PERMITS}_${userId}`);
  
  const storage = await getAvailableStorage();
  
  return {
    hasCache: !!cacheTimestamp && !!realEstate && !!permits,
    cacheAge: cacheTimestamp ? Date.now() - parseInt(cacheTimestamp) : null,
    isExpired: cacheTimestamp ? (Date.now() - parseInt(cacheTimestamp)) > CACHE_CONFIG.EXPIRATION_TIME : true,
    storageQuota: storage?.quota,
    storageUsage: storage?.usage,
    isMobile: isMobileDevice(),
    dataSize: realEstate && permits ? 
      new Blob([realEstate, permits]).size : 0
  };
};
