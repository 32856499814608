import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line
} from 'recharts';

// Colors for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57'];

const PageViewsReport = ({ data }) => {
  if (!data) return null;

  // Format data for page views bar chart
  const pageViewsData = data.mostViewedPages.map((item, index) => ({
    name: item.page.length > 20 ? item.page.substring(0, 20) + '...' : item.page,
    count: item.count,
    fullName: item.page
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Page Views Analysis
      </Typography>
      
      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Total Page Views
              </Typography>
              <Typography variant="h3">
                {data.totalPageViews.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Unique Pages Viewed
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.pageViewsByPage).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Users Who Viewed Pages
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.pageViewsByUser).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Charts */}
      <Grid container spacing={3}>
        {/* Most Viewed Pages Bar Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Most Viewed Pages
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={pageViewsData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip 
                  formatter={(value, name, props) => [value.toLocaleString(), 'Views']}
                  labelFormatter={(value, props) => props.payload && props.payload.length > 0 ? props.payload[0].fullName : value}
                />
                <Legend />
                <Bar dataKey="count" fill="#0088FE" name="Page Views" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Page Views Over Time Line Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Page Views Over Time
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <LineChart
                data={data.pageViewsByDayArray}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="count" 
                  stroke="#0088FE" 
                  name="Page Views" 
                  activeDot={{ r: 8 }} 
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Most Viewed Pages List */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Most Viewed Pages
            </Typography>
            <List>
              {data.mostViewedPages.map((page, index) => (
                <React.Fragment key={page.page}>
                  <ListItem>
                    <ListItemText 
                      primary={page.page} 
                      secondary={`${page.count.toLocaleString()} views`} 
                    />
                  </ListItem>
                  {index < data.mostViewedPages.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        
        {/* Page View Insights */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Page View Insights
            </Typography>
            <Typography variant="body1" paragraph>
              Page views represent when users navigate to different pages in the application. 
              This data helps understand which parts of the application are most frequently used.
            </Typography>
            <Typography variant="body1" paragraph>
              High page view counts may indicate popular features, while low counts might suggest 
              areas that need improvement or better visibility.
            </Typography>
            <Typography variant="body1">
              Consider the following insights:
            </Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="User Journey Analysis" 
                  secondary="Analyze the sequence of page views to understand common user journeys." 
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText 
                  primary="Conversion Optimization" 
                  secondary="Identify pages with high drop-off rates that might need improvement." 
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText 
                  primary="Feature Prioritization" 
                  secondary="Use page view data to prioritize development of popular features." 
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageViewsReport;
