import React, { useState, useEffect, useContext } from 'react';
import { useTimeTrackContext } from '../TimeTrack/context/TimeTrackContext';
import { Alert, Snackbar, Button, Box } from '@mui/material';
import { StoreContext } from '../Hooks/store';
import { useAuth } from '../Hooks/useAuth';
import { 
  getActiveBreakNotifications, 
  updateBreakNotification,
  checkBreakDue,
  createBreakNotification
} from '../../api/timetrack';

/**
 * AlertNotification component that displays break notifications
 * This component works independently of the TimeTrack component
 * and will show notifications even when TimeTrack is not mounted
 */
const AlertNotification = () => {
  const [notification, setNotification] = useState(null);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const userId = user?.id;
  
  // Get TimeTrack mounted state and clock state from store
  const storeContextValue = useContext(StoreContext);
  const { 
    isTimeTrackMounted: [isTimeTrackMounted],
    globalClockState: [globalClockState]
  } = storeContextValue;

  // Check for active break notifications
  useEffect(() => {
    if (!userId || !globalClockState.clockedIn) return;

    // Function to check for active notifications
    const checkForActiveNotifications = async () => {
      try {
        // First check for active notifications in the database
        const activeNotifications = await getActiveBreakNotifications(userId);
        
        if (activeNotifications && activeNotifications.length > 0) {
          // Show the most recent notification
          const latestNotification = activeNotifications[0];
          setNotification({
            id: latestNotification.id,
            breakType: latestNotification.break_type,
            scheduledBreakTime: new Date(latestNotification.scheduled_break_time),
            message: `${latestNotification.break_type === 'meal' ? 'Meal' : 'Rest'} break is due`,
            severity: 'warning'
          });
          setOpen(true);
          return;
        }
        
        // If no active notifications, check if a break is due
        const userState = 'WA'; // Default to Washington state
        const breakDue = await checkBreakDue(userId, userState);
        
        if (breakDue) {
          // Create a new notification
          const newNotification = await createBreakNotification({
            userId,
            breakType: breakDue.breakType,
            scheduledBreakTime: breakDue.scheduledBreakTime,
            state: userState
          });
          
          if (newNotification) {
            setNotification({
              id: newNotification.id,
              breakType: breakDue.breakType,
              scheduledBreakTime: new Date(breakDue.scheduledBreakTime),
              message: `${breakDue.breakType === 'meal' ? 'Meal' : 'Rest'} break is due`,
              severity: 'warning'
            });
            setOpen(true);
          }
        }
      } catch (error) {
        console.error('Error checking for break notifications:', error);
      }
    };

    // Check immediately
    checkForActiveNotifications();
    
    // Set up interval to check every minute
    const intervalId = setInterval(checkForActiveNotifications, 60000);
    
    return () => clearInterval(intervalId);
  }, [userId, globalClockState.clockedIn]);

  // Get device ID from store context
  const { thisdeviceid: [deviceId] } = storeContextValue;
  
  const getDeviceId = () => {
    if (deviceId && typeof deviceId === 'object' && deviceId.dvc_hash) {
      return deviceId.dvc_hash;
    }
    return null;
  };

  // Handle dismissing the notification
  const handleDismiss = async () => {
    if (!notification) return;
    
    try {
      const deviceId = getDeviceId();
      await updateBreakNotification(notification.id, { 
        dismissed: true,
        device_id: deviceId
      });
      setOpen(false);
      setNotification(null);
    } catch (error) {
      console.error('Error dismissing notification:', error);
    }
  };
  
  // Handle dismissing the notification permanently (don't show again)
  const handleDismissPermanently = async () => {
    if (!notification) return;
    
    try {
      const deviceId = getDeviceId();
      await updateBreakNotification(notification.id, { 
        dismissed: true, 
        dismissedPermanently: true,
        device_id: deviceId
      });
      setOpen(false);
      setNotification(null);
      
      console.log(`Permanently dismissed ${notification.breakType} break notifications`);
    } catch (error) {
      console.error('Error permanently dismissing notification:', error);
    }
  };

  // Handle taking a break
  const timeTrackContext = useTimeTrackContext();
  const handleBreak = timeTrackContext?.handleBreak || null;

  const handleTakeBreak = async () => {
    if (!notification) return;
    
    try {
      const deviceId = getDeviceId();
      await updateBreakNotification(notification.id, { 
        breakTaken: true,
        device_id: deviceId
      });
      
      // If TimeTrack is mounted, handle the break through its context
      if (handleBreak) {
        await handleBreak({
          breakType: notification.breakType,
          durationMinutes: notification.durationMinutes || 30, // Default to 30 mins if not specified
          isPaid: false // Default to unpaid unless we have this info
        });
      } else if (!isTimeTrackMounted) {
        // Fallback to custom event if TimeTrack isn't mounted
        const breakEvent = new CustomEvent('timetrack:takeBreak', {
          detail: { breakType: notification.breakType }
        });
        window.dispatchEvent(breakEvent);
      }
      
      setOpen(false);
      setNotification(null);
    } catch (error) {
      console.error('Error taking break:', error);
    }
  };

  if (!notification) return null;

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ 
        zIndex: 9999, // Ensure it's above other elements
        width: { xs: '100%', sm: '80%', md: '60%' }
      }}
    >
      <Alert 
        severity={notification.severity}
        sx={{ width: '100%' }}
        action={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button 
              color="primary" 
              size="small"
              variant="contained"
              onClick={handleTakeBreak}
            >
              Take Break
            </Button>
            <Button 
              color="inherit" 
              size="small"
              onClick={handleDismiss}
            >
              Dismiss
            </Button>
            <Button 
              color="error" 
              size="small"
              onClick={handleDismissPermanently}
            >
              Don't Show Again
            </Button>
          </Box>
        }
      >
        {notification.message} - {notification.scheduledBreakTime.toLocaleTimeString()}
      </Alert>
    </Snackbar>
  );
};

export default AlertNotification;
