import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";
import TimeCardHeader from './TimeCardHeader';
import TimeCardStatus from './TimeCardStatus';
import TimeCardActions from './TimeCardActions';
import TimeCardEntries from './TimeCardEntries';

export default function TimeCardDialog({
  open,
  onClose,
  tcdate,
  statuscolorTimecard,
  status,
  clockbtnlabel,
  workbreak,
  showingHistory,
  clockedin,
  handleClockin,
  handleBreak,
  timecards,
  oneDayBack,
  oneDayForward,
  fwdnavdisabled,
  isCurrentDate,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '11pt',
          backgroundColor: '#00416e',
          color: '#fff',
          textAlign: 'center',
          position: 'relative',
          padding: '12px 16px',
        }}
      >
        <TimeCardStatus statuscolorTimecard={statuscolorTimecard} status={status} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <TimeCardHeader
              tcdate={tcdate}
              oneDayBack={oneDayBack}
              oneDayForward={oneDayForward}
              fwdnavdisabled={fwdnavdisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <TimeCardActions
              clockbtnlabel={clockbtnlabel}
              workbreak={workbreak}
              showingHistory={showingHistory || !isCurrentDate}
              clockedin={clockedin}
              handleClockin={handleClockin}
              handleBreak={handleBreak}
              isCurrentDate={isCurrentDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TimeCardEntries timecards={timecards} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
