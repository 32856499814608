import { supabase } from '../lib/supabase';
import { hashCode } from '../components/Utils/hashCode';

/**
 * Save user interaction data to Supabase
 * @param {Object} interactionData - The interaction data to save
 * @param {string} userId - The user ID (UUID from auth.users)
 * @param {string|number} sessionHash - The session hash
 * @returns {Promise} - A promise that resolves when the interaction data is saved
 */
export async function saveUserInteractionData(interactionData, userId, sessionHash) {
  try {
    // Ensure we have interactions to save
    if (!interactionData || !interactionData.interactions || !Array.isArray(interactionData.interactions)) {
      console.warn('No valid interaction data to save');
      return;
    }

    // Check if userId is available
    if (!userId) {
      console.warn('User ID is not available. Using anonymous session.');
      // Use a default user ID for anonymous sessions
      userId = '00000000-0000-0000-0000-000000000000';
    } 
    // Validate UUID format for userId if it exists
    else if (typeof userId !== 'string' || !userId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) {
      console.warn('Invalid user ID format. Using anonymous session.');
      // Use a default user ID for anonymous sessions
      userId = '00000000-0000-0000-0000-000000000000';
    }

    // Ensure sessionHash is a string
    const sessionHashStr = String(sessionHash);

    // Create an array of interaction records to insert
    const interactionRecords = interactionData.interactions.map(interaction => {
      const interactionHash = hashCode(JSON.stringify(interaction)); // Calculate hash
      
      return {
        user_id: userId, // Already a UUID, no conversion needed
        session_hash: sessionHashStr,
        interaction_hash: String(interactionHash), // Convert to string
        interaction_data: interaction, // Supabase will automatically convert this to JSONB
        added_at: Date.now()
      };
    });

    // Insert the interaction records into Supabase
    const { data, error } = await supabase
      .from('user_interaction')
      .insert(interactionRecords);

    if (error) throw error;
    
    //console.log('User interaction data saved to Supabase successfully');
    return data;
  } catch (error) {
    console.error('Error saving user interaction data to Supabase:', error);
    throw error;
  }
}

/**
 * Save user session data to Supabase
 * @param {Object} sessionData - The session data to save
 * @param {string} userId - The user ID (UUID from auth.users)
 * @param {string|number} sessionHash - The session hash
 * @returns {Promise} - A promise that resolves when the session data is saved
 */
export async function saveUserSessionData(sessionData, userId, sessionHash) {
  try {
    // Check if userId is available
    if (!userId) {
      console.warn('User ID is not available. Using anonymous session.');
      // Use a default user ID for anonymous sessions
      userId = '00000000-0000-0000-0000-000000000000';
    } 
    // Validate UUID format for userId if it exists
    else if (typeof userId !== 'string' || !userId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) {
      console.warn('Invalid user ID format. Using anonymous session.');
      // Use a default user ID for anonymous sessions
      userId = '00000000-0000-0000-0000-000000000000';
    }
    
    // Ensure sessionHash is a string
    const sessionHashStr = String(sessionHash);
    
    // Insert the session data into Supabase
    const { data, error } = await supabase
      .from('user_session')
      .insert([{
        user_id: userId, // Already a UUID, no conversion needed
        session_hash: sessionHashStr,
        session_data: sessionData, // Supabase will automatically convert this to JSONB
        added_at: Date.now()
      }]);

    if (error) throw error;
    
    console.log('User session data saved to Supabase successfully');
    return data;
  } catch (error) {
    console.error('Error saving user session data to Supabase:', error);
    throw error;
  }
}
