import React from 'react';
import { 
  Box, 
  Typography, 
  Divider, 
  Button, 
  CircularProgress,
  Alert,
  Collapse,
  Paper
} from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import SyncIcon from '@mui/icons-material/Sync';
import FlagIcon from '@mui/icons-material/Flag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimeEntryItem from './TimeEntryItem';
import { useTimeTrackContext } from '../context/TimeTrackContext';
import '../styles.css';

const TimeEntriesList = () => {
  const { 
    entries, 
    syncStatus,
    currentDate,
    loading,
    error,
    syncOfflineEntries,
    formatDate,
    formatTime,
    getStatusColor,
    isCurrentDateToday,
    online
  } = useTimeTrackContext();

  const handleSync = async () => {
    try {
      await syncOfflineEntries();
    } catch (error) {
      console.error('Sync failed:', error);
    }
  };

  // Format address for display
  const formatAddress = (address) => {
    if (!address) return ''; // Handle empty or undefined addresses
    const addressParts = address.split(',').map(part => part.trim());
    return `${addressParts[0]} ${addressParts[1] || ''}`; // Combine street number and name
  };

  return (
    <Box className="time-entries">
      {error && (
        <Collapse in={!!error}>
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        </Collapse>
      )}

      <Box className="section-header-container" sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 1
      }}>
        <Typography variant="h6">
          {isCurrentDateToday() ? 
            "TODAY'S ACTIVITY" : 
            `ACTIVITY FOR ${formatDate(currentDate)}`
          }
        </Typography>
        
        {entries.some(entry => !entry.synced) && online && syncStatus !== 'success' && (
          <Button
            onClick={handleSync}
            disabled={syncStatus === 'syncing'}
            startIcon={syncStatus === 'syncing' ? <CircularProgress size={16} /> : <WifiIcon />}
            size="small"
            variant="outlined"
            color="primary"
            sx={{ minWidth: 100 }}
          >
            {syncStatus === 'idle' && 'Sync'}
            {syncStatus === 'syncing' && 'Syncing...'}
            {syncStatus === 'error' && 'Retry Sync'}
          </Button>
        )}
        
        {/* Success message shown temporarily after successful sync */}
        {syncStatus === 'success' && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            color: 'success.main',
            fontSize: '0.875rem'
          }}>
            <WifiIcon sx={{ fontSize: 16, mr: 0.5 }} />
            <span>Sync Complete</span>
          </Box>
        )}
      </Box>

      <Divider sx={{ mb: 2 }} />

      {loading.entries ? (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          minHeight: 200 
        }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {entries.length === 0 ? (
            <Typography 
              variant="body2" 
              color="textSecondary" 
              sx={{ textAlign: 'center', mt: 2 }}
            >
              No entries for {isCurrentDateToday() ? 'today' : formatDate(currentDate)}
            </Typography>
          ) : (
            entries.map((entry, index) => {
              // Find previous entry to calculate duration
              const prevEntry = entries[index + 1];
              
              // Calculate duration between entries if both exist
              const durationMs = prevEntry ? entry.timestamp - prevEntry.timestamp : 0;
              const durationHours = Math.floor(durationMs / (60 * 60 * 1000));
              const durationMinutes = Math.floor((durationMs % (60 * 60 * 1000)) / (60 * 1000));
              
              // Format duration string
              let durationStr = '';
              if (durationMs > 0) {
                if (durationHours > 0) {
                  durationStr = `${durationHours}h ${durationMinutes}m`;
                } else {
                  durationStr = `${durationMinutes}m`;
                }
              }
              
              return (
                <Paper 
                  key={entry.id || entry.local_id || index}
                  elevation={1}
                  sx={{ 
                    mb: 1.5, 
                    p: 1.5,
                    borderLeft: `4px solid ${getStatusColor(entry.status)}`,
                    bgcolor: entry.flag_note ? 'rgba(244, 67, 54, 0.05)' : 'background.paper'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Box>
                      <Typography variant="subtitle1">
                        {formatTime(entry.timestamp || entry.time_entry)}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="textSecondary"
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          color: getStatusColor(entry.status),
                          fontWeight: 500
                        }}
                      >
                        {(() => {
                          if (entry.status === 'clock-in') {
                            return 'Clocked In';
                          } else if (entry.status === 'clock-out') {
                            // Check if this is an auto-clockout by looking at the description
                            const isAuto = entry.description && entry.description.includes('auto-clock-out');
                            return isAuto ? 'Auto Clocked Out' : 'Clocked Out';
                          } else if (entry.status === 'break') {
                            return `${entry.break_type === 'meal' ? 'Meal' : 'Rest'} Break (${entry.duration} mins)`;
                          } else if (entry.status === 'break-end') {
                            return `Returned from ${entry.break_type === 'meal' ? 'Meal' : 'Rest'} Break`;
                          } else {
                            return entry.status;
                          }
                        })()}
                      </Typography>
                      
                      {durationStr && (
                        <Typography variant="caption" color="textSecondary">
                          {entry.status === 'clock-out' ?
                            `Worked for ${durationStr}` : 
                            `${durationStr} since previous activity`}
                        </Typography>
                      )}
                      
                      {entry.address && (
                        <Typography 
                          variant="caption" 
                          color="textSecondary"
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            mt: 0.5
                          }}
                        >
                          <LocationOnIcon sx={{ fontSize: 14, mr: 0.5 }} />
                          {formatAddress(entry.address)}
                        </Typography>
                      )}
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {!entry.synced && (
                        <WifiOffIcon 
                          sx={{ 
                            fontSize: 16, 
                            color: 'warning.main',
                            opacity: 0.8,
                            mr: 0.5
                          }} 
                          titleAccess="Not synced to server"
                        />
                      )}
                      
                      {entry.flag_note && (
                        <FlagIcon 
                          sx={{ 
                            fontSize: 16, 
                            color: 'error.main',
                            opacity: 0.8
                          }} 
                          titleAccess="Entry flagged for review"
                        />
                      )}
                    </Box>
                  </Box>
                  
                  {entry.early_end_info && (
                    <Typography 
                      variant="caption" 
                      color="textSecondary"
                      sx={{ display: 'block', mt: 0.5 }}
                    >
                      {(() => {
                        try {
                          const earlyEndInfo = JSON.parse(entry.early_end_info);
                          return earlyEndInfo.reason === 'User ended break early' 
                            ? `Ended early with ${earlyEndInfo.minutesRemaining}m remaining` 
                            : earlyEndInfo.reason;
                        } catch (e) {
                          return entry.early_end_info;
                        }
                      })()}
                    </Typography>
                  )}
                </Paper>
              );
            })
          )}
        </>
      )}
    </Box>
  );
};

export default TimeEntriesList;
