import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useTimeTrack } from '../../Hooks/useTimeTrack';
import { useAuth } from '../../Hooks/useAuth';
import { StoreContext } from '../../Hooks/store';
import { useBreakNotifications } from '../../Hooks/useBreakNotifications';
import { 
  isOnline, 
  getAddressFromCoordinates, 
  getBreakRules, 
  getTimeTrackEntries, 
  getMostRecentClockInTimestamp,
  shouldAutoClockout,
  performAutoClockout,
  getAutoClockoutSettings,
  checkBreakDue,
  createBreakNotification,
  updateBreakNotification,
  getActiveBreakNotifications
} from '../../../api/timetrack';
import { publishStatusChange, STATUS } from '../utils/timeTrackEvents';
import { useDeviceTracking } from './hooks/useDeviceTracking';

export const TimeTrackContext = createContext();

export function TimeTrackProvider({ children }) {
  
  // Get auth state first to ensure hooks are called in the same order
  const { user } = useAuth();
  const userId = user?.id;
  
  // Then get store context
  const storeContextValue = useContext(StoreContext);
  const { 
    askGeolocation: [requestGeolocationFunc],
    geolocation: [sharedGeolocation],
    globalClockState: [globalClockState, setGlobalClockState]
  } = storeContextValue;
  
  // Get device ID if available
  const deviceid = storeContextValue.deviceid || {};
  
  // Use device tracking hook
  const { saveDeviceInfo } = useDeviceTracking({ userId, deviceid });

  // Use the original useTimeTrack hook
  const {
    entries,
    setEntries,
    loading,
    error,
    syncStatus,
    currentDate,
    clockState,
    setClockState,
    clockIn: originalClockIn,
    clockOut: originalClockOut,
    takeBreak: originalTakeBreak,
    updateShiftDuration,
    navigateDate,
    syncOfflineEntries,
    refreshEntries,
    setCurrentDate
  } = useTimeTrack(userId);

  // UI state
  const [waitingForGeolocation, setWaitingForGeolocation] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [locationStatus, setLocationStatus] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [showBreakSchedule, setShowBreakSchedule] = useState(false);
  const [selectedState, setSelectedState] = useState('WA');
  
  const [userState, setUserState] = useState('WA');
  const [breakRules, setBreakRules] = useState([]);
  
  // Function to load break rules for a specific state
  const loadBreakRules = useCallback(async (state) => {
    if (!userId) return;
    
    try {
      console.log('Loading break rules for state:', state);
      const rules = await getBreakRules(state);
      setBreakRules(rules);
      console.log('Loaded break rules:', rules);
    } catch (error) {
      console.error('Error loading break rules:', error);
    }
  }, [userId]);
  const [showBreakAlert, setShowBreakAlert] = useState(false);
  const [showAutoClockOutAlert, setShowAutoClockOutAlert] = useState(false);
  const [showMealReminderAlert, setShowMealReminderAlert] = useState(false);
  const [showRestReminderAlert, setShowRestReminderAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [online, setOnline] = useState(isOnline());
  const [breakNotification, setBreakNotification] = useState(null);
  const [showBreakNotification, setShowBreakNotification] = useState(false);
  const [missedBreaks, setMissedBreaks] = useState([]);
  const [lastActiveTime, setLastActiveTime] = useState(Date.now());
  const [deviceWasAsleep, setDeviceWasAsleep] = useState(false);
  const [processedMissedBreaks, setProcessedMissedBreaks] = useState(new Set());
  
  // Track processed break-end entries to avoid duplicates
  const [processedBreakEnds, setProcessedBreakEnds] = useState(new Set());
  
  // Refs for timers
  const breakTimerRef = useRef(null);
  const breakCheckIntervalRef = useRef(null);

  // Update current time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    
    return () => clearInterval(intervalId);
  }, []);
  
  // Auto-clockout check
  const [autoClockoutSettings, setAutoClockoutSettings] = useState({
    max_work_hours: 24,
    check_interval_minutes: 1,
    enabled: true
  });
  const [lastAutoClockoutCheck, setLastAutoClockoutCheck] = useState(Date.now());
  const autoClockoutIntervalRef = useRef(null);
  
  // Load auto-clockout settings
  useEffect(() => {
    if (!userId) return;
    
    const loadAutoClockoutSettings = async () => {
      try {
        // Get the current project ID (if any)
        const projectId = null; // TODO: Get from current context if needed
        
        // Get the auto-clockout settings
        const settings = await getAutoClockoutSettings(projectId);
        setAutoClockoutSettings(settings);
        
        console.log('Loaded auto-clockout settings:', settings);
      } catch (error) {
        console.error('Error loading auto-clockout settings:', error);
      }
    };
    
    loadAutoClockoutSettings();
  }, [userId]);
  
  // Set up auto-clockout check interval
  useEffect(() => {
    if (!userId || !clockState.clockedIn || !autoClockoutSettings.enabled) {
      // Clear any existing interval
      if (autoClockoutIntervalRef.current) {
        clearInterval(autoClockoutIntervalRef.current);
        autoClockoutIntervalRef.current = null;
      }
      return;
    }
    
    // Convert check interval from minutes to milliseconds
    const checkIntervalMs = autoClockoutSettings.check_interval_minutes * 60 * 1000;
    
    // Set up interval to check for auto-clockout
    const checkForAutoClockout = async () => {
      // Skip if we've checked recently
      const now = Date.now();
      if (now - lastAutoClockoutCheck < checkIntervalMs) {
        return;
      }
      
      setLastAutoClockoutCheck(now);
      
      try {
        // Check if the user should be clocked out
        const shouldClockOut = await shouldAutoClockout(userId);
        
        if (shouldClockOut) {
          console.log('Auto-clockout condition met, clocking out user');
          
          // Perform the auto-clockout
          const clockOutEntry = await performAutoClockout(userId);
          
          if (clockOutEntry) {
            console.log('Auto-clockout successful:', clockOutEntry);
            
            // Update the UI to reflect the clock-out
            setClockState(prev => ({
              ...prev,
              clockedIn: false
            }));
            
            // Show a notification to the user
            setShowAutoClockOutAlert(true);
            
            // Add the clock-out entry to the entries list
            setEntries(prev => [clockOutEntry, ...prev]);
            
            // Clear the interval since we've clocked out
            if (autoClockoutIntervalRef.current) {
              clearInterval(autoClockoutIntervalRef.current);
              autoClockoutIntervalRef.current = null;
            }
          }
        }
      } catch (error) {
        console.error('Error in auto-clockout check:', error);
      }
    };
    
    // Check immediately
    checkForAutoClockout();
    
    // Set up interval for future checks
    autoClockoutIntervalRef.current = setInterval(checkForAutoClockout, checkIntervalMs);
    
    // Cleanup function
    return () => {
      if (autoClockoutIntervalRef.current) {
        clearInterval(autoClockoutIntervalRef.current);
        autoClockoutIntervalRef.current = null;
      }
    };
  }, [userId, clockState.clockedIn, autoClockoutSettings, lastAutoClockoutCheck, setClockState, setEntries]);

  // Update global clock state when clock state changes
  useEffect(() => {
    setGlobalClockState({
      clockedIn: clockState.clockedIn,
      clockInTime: clockState.clockInTime
    });
  }, [clockState.clockedIn, clockState.clockInTime, setGlobalClockState]);

  // Set up online/offline event listeners
  useEffect(() => {
    const handleOnlineStatus = () => {
      const currentStatus = navigator.onLine;
      if (currentStatus !== online) {
        console.log(`Network status changed: ${currentStatus ? 'online' : 'offline'}`);
        setOnline(currentStatus);
        
        // If coming back online, sync any offline entries
        if (currentStatus && !online) {
          syncOfflineEntries();
        }
      }
    };
    
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);
    
    const intervalId = setInterval(handleOnlineStatus, 5 * 60 * 1000);
    
    handleOnlineStatus();
    
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
      clearInterval(intervalId);
    };
  }, [online, syncOfflineEntries]);

  // Load break rules
  useEffect(() => {
    if (!userId) return;
    
    const loadBreakRules = async () => {
      try {
        const rules = await getBreakRules(selectedState);
        setBreakRules(rules);
      } catch (error) {
        console.error('Error loading break rules:', error);
      }
    };
    
    loadBreakRules();
  }, [userId, selectedState]);

  // Watch for geolocation changes
  useEffect(() => {
    if (waitingForGeolocation && sharedGeolocation.lat !== 0 && sharedGeolocation.lon !== 0) {
      setWaitingForGeolocation(false);
      
      // Get address from coordinates
      const fetchAddress = async () => {
        const address = await getAddressFromCoordinates(sharedGeolocation.lat, sharedGeolocation.lon);
        setLocationAddress(address);
        setLocationStatus(`Location captured at: ${address} on ${new Date().toLocaleString()}`);
        
        // Execute the pending action with the location
        if (pendingAction) {
          const { action, params } = pendingAction;
          
          switch (action) {
            case 'clockIn':
              originalClockIn({
                latitude: sharedGeolocation.lat,
                longitude: sharedGeolocation.lon,
                address
              });
              break;
            case 'clockOut':
              originalClockOut(params.isAuto, {
                latitude: sharedGeolocation.lat,
                longitude: sharedGeolocation.lon,
                address
              });
              break;
            case 'takeBreak':
              originalTakeBreak(
                params.breakType, 
                params.duration, 
                {
                  latitude: sharedGeolocation.lat,
                  longitude: sharedGeolocation.lon,
                  address
                },
                params.state,
                params.projectId
              );
              break;
            case 'endBreak':
              // Special case for ending a break - we need to create a break-end entry
              // This is a workaround since the original takeBreak function doesn't handle ending breaks properly
              console.log('Ending break with location:', {
                latitude: sharedGeolocation.lat,
                longitude: sharedGeolocation.lon,
                address
              });
              
              // Create a new break-end entry
              const newEntry = {
                id: crypto.randomUUID(),
                user_id: userId,
                status: 'break-end',
                timestamp: Date.now(),
                time_entry: new Date().toLocaleTimeString('en-US', { 
                  hour: 'numeric', 
                  minute: '2-digit', 
                  hour12: true 
                }),
                break_type: params.breakType,
                break_id: params.breakId, // Add the break ID to link this end entry to the start entry
                latitude: sharedGeolocation.lat,
                longitude: sharedGeolocation.lon,
                address,
                synced: false,
                local_id: crypto.randomUUID(),
                state: params.state,
                project_id: params.projectId
              };
              
              // Add the new entry to the entries array
              setEntries(prev => [newEntry, ...prev]);
              
              // Add this break ID to the processed set to avoid duplicate processing
              setProcessedBreakEnds(prev => new Set([...prev, params.breakId]));
              
              // Force a refresh of entries to ensure the clock state is updated
              setTimeout(() => {
                refreshEntries();
              }, 500);
              
              break;
            default:
              console.error('Unknown action:', action);
          }
          
          setPendingAction(null);
        }
      };
      
      fetchAddress();
    }
  }, [sharedGeolocation, waitingForGeolocation, pendingAction, originalClockIn, originalClockOut, originalTakeBreak, setEntries, userId, refreshEntries]);

  // Publish status changes to the event system
  useEffect(() => {
    let status = STATUS.DEFAULT;
    
    if (clockState.clockedIn) {
      const activeBreakType = getActiveBreakType();
      if (activeBreakType) {
        status = STATUS.ON_BREAK;
      } else {
        status = STATUS.CLOCKED_IN;
      }
    } else {
      status = STATUS.CLOCKED_OUT;
    }
    
    publishStatusChange(status);
  }, [clockState.clockedIn, entries]);

  // Track active breaks that have timers set
  const [activeBreakTimers, setActiveBreakTimers] = useState(new Set());
  
  // Automatic break ending
  useEffect(() => {
    // Skip if we're offline to prevent excessive API calls
    if (!navigator.onLine) {
      return;
    }
    
    // Check for active breaks
    const activeBreak = getActiveBreak();
    
    if (activeBreak) {
      // Skip if we've already processed this break
      if (processedBreakEnds.has(activeBreak.id)) {
        return;
      }
      
      // Skip if we already have a timer for this break
      if (activeBreakTimers.has(activeBreak.id)) {
        return;
      }
      
      console.log('Active break detected:', activeBreak);
      
      // Calculate time remaining for the break
      const breakStartTime = new Date(activeBreak.timestamp);
      const breakDuration = activeBreak.duration || 
        (activeBreak.break_type === 'meal' ? 30 : 10); // Default durations
      
      const breakEndTime = new Date(breakStartTime.getTime() + breakDuration * 60 * 1000);
      const now = new Date();
      const timeRemaining = breakEndTime.getTime() - now.getTime();
      
      if (timeRemaining <= 0) {
        // Break has already ended, end it immediately
        console.log('Break has already ended, ending now');
        endBreakAutomatically(activeBreak);
      } else {
        // Mark this break as having a timer
        setActiveBreakTimers(prev => new Set([...prev, activeBreak.id]));
        
        // Clear any existing timers
        if (breakTimerRef.current) {
          clearTimeout(breakTimerRef.current);
          breakTimerRef.current = null;
        }
        
        if (breakCheckIntervalRef.current) {
          clearInterval(breakCheckIntervalRef.current);
          breakCheckIntervalRef.current = null;
        }
        
        // Set a timer to end the break when it's time
        console.log(`Setting timer to end break in ${timeRemaining}ms`);
        breakTimerRef.current = setTimeout(() => {
          console.log('Break timer triggered, ending break');
          endBreakAutomatically(activeBreak);
          // Remove this break from active timers
          setActiveBreakTimers(prev => {
            const newSet = new Set(prev);
            newSet.delete(activeBreak.id);
            return newSet;
          });
        }, timeRemaining);
        
        // Also set an interval to check every minute in case the timer fails
        // But only do this once per break
        breakCheckIntervalRef.current = setInterval(() => {
          const now = new Date();
          
          if (now >= breakEndTime) {
            console.log('Break check interval detected ended break, ending now');
            endBreakAutomatically(activeBreak);
            
            // Clear the interval since we've ended the break
            if (breakCheckIntervalRef.current) {
              clearInterval(breakCheckIntervalRef.current);
              breakCheckIntervalRef.current = null;
            }
            
            // Remove this break from active timers
            setActiveBreakTimers(prev => {
              const newSet = new Set(prev);
              newSet.delete(activeBreak.id);
              return newSet;
            });
          }
        }, 60000); // Check every minute
      }
    }
    
    // Cleanup function
    return () => {
      if (breakTimerRef.current) {
        clearTimeout(breakTimerRef.current);
        breakTimerRef.current = null;
      }
      if (breakCheckIntervalRef.current) {
        clearInterval(breakCheckIntervalRef.current);
        breakCheckIntervalRef.current = null;
      }
    };
  }, [entries, processedBreakEnds, activeBreakTimers]);

  // Helper function to get the active break
  const getActiveBreak = useCallback(() => {
    if (!entries?.length) return null;

    // Find the most recent break entry
    const lastBreakEntry = [...entries]
      .sort((a, b) => b.timestamp - a.timestamp)
      .find(entry => entry.status === 'break');

    if (!lastBreakEntry) return null;
    
    // Skip if we've already processed this break
    if (processedBreakEnds.has(lastBreakEntry.id)) {
      return null;
    }

    // Check if there's a corresponding break-end entry
    const hasBreakEndEntry = entries.some(entry => 
      (entry.status === 'break-end' && entry.timestamp > lastBreakEntry.timestamp) ||
      (entry.status === 'break-end' && entry.break_id === lastBreakEntry.id)
    );

    if (hasBreakEndEntry) return null;

    return lastBreakEntry;
  }, [entries, processedBreakEnds]);

  // Function to end a break automatically
  const endBreakAutomatically = useCallback((breakEntry) => {
    console.log('Ending break automatically:', breakEntry);
    
    // Skip if we've already processed this break
    if (processedBreakEnds.has(breakEntry.id)) {
      console.log('Break already processed, skipping:', breakEntry.id);
      return;
    }
    
    // Add this break ID to the processed set to avoid duplicate processing
    setProcessedBreakEnds(prev => new Set([...prev, breakEntry.id]));
    
    // If we're offline or don't have geolocation, create a break-end entry immediately
    if (!navigator.onLine) {
      console.log('Device is offline, creating break-end entry without location');
      
      // Create a new break-end entry
      const newEntry = {
        id: crypto.randomUUID(),
        user_id: userId,
        status: 'break-end',
        timestamp: Date.now(),
        time_entry: new Date().toLocaleTimeString('en-US', { 
          hour: 'numeric', 
          minute: '2-digit', 
          hour12: true 
        }),
        break_type: breakEntry.break_type,
        break_id: breakEntry.id,
        address: 'Break ended automatically',
        synced: false,
        local_id: crypto.randomUUID(),
        state: userState,
        project_id: breakEntry.project_id
      };
      
      // Add the new entry to the entries array
      setEntries(prev => [newEntry, ...prev]);
      
      // Force a refresh of entries to ensure the UI is updated
      setTimeout(() => {
        refreshEntries();
      }, 500);
      
      return;
    }
    
    // Request geolocation for the break end
    setWaitingForGeolocation(true);
    setLocationStatus('Requesting location for automatic break end...');
    setPendingAction({ 
      action: 'endBreak', // Use the special endBreak action
      params: { 
        breakType: breakEntry.break_type, 
        duration: breakEntry.duration || (breakEntry.break_type === 'meal' ? 30 : 10),
        state: userState,
        projectId: breakEntry.project_id,
        breakId: breakEntry.id // Pass the break ID to link the end entry to the start entry
      } 
    });
    requestGeolocationFunc();
  }, [requestGeolocationFunc, userState, processedBreakEnds, userId, setEntries, refreshEntries]);

  // Format date for display
  const formatDate = useCallback((date) => {
    if (!date) return '';
    const formattedDate = new Date(date).toLocaleDateString('en-US', { 
      weekday: 'short', 
      month: 'numeric', 
      day: 'numeric', 
      year: '2-digit'
    });
  
    return formattedDate.replace(/, /, ' ');
  }, []);

  // Format time for display
  const formatTime = useCallback((date) => {
    if (!date) return '';
    return new Date(date).toLocaleTimeString('en-US', { 
      hour: 'numeric', 
      minute: '2-digit', 
      hour12: true 
    });
  }, []);
  
  // Format duration in minutes to a readable string
  const formatDuration = useCallback((durationMs) => {
    if (!durationMs) return '';
    
    const minutes = Math.floor(durationMs / (60 * 1000));
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours > 0) {
      return `${hours}h ${remainingMinutes}m`;
    } else {
      return `${minutes}m`;
    }
  }, []);

  // Get color for status display
  const getStatusColor = useCallback((type) => {
    switch(type) {
      case 'clock-in':
        return STATUS.CLOCKED_IN;
      case 'clock-out':
      case 'auto-clock-out': // Keep for backward compatibility
        return STATUS.CLOCKED_OUT;
      case 'break':
        return STATUS.ON_BREAK;
      case 'break-end':
        return '#0080BB';
      default:
        return '#666666';
    }
  }, []);
  
  // Calculate time between activities
  const calculateTimeBetweenActivities = useCallback((entries) => {
    if (!entries || entries.length < 2) return entries;
    
    // Sort entries by timestamp in descending order (newest first)
    const sortedEntries = [...entries].sort((a, b) => b.timestamp - a.timestamp);
    
    // Create a new array with time between activities
    const entriesWithTimeBetween = sortedEntries.map((entry, index) => {
      // Skip the last entry (oldest) as there's no previous activity
      if (index === sortedEntries.length - 1) {
        return { ...entry, timeSincePrevious: null };
      }
      
      // Calculate time since previous activity
      const currentTimestamp = entry.timestamp;
      const previousTimestamp = sortedEntries[index + 1].timestamp;
      const timeSincePrevious = currentTimestamp - previousTimestamp;
      
      return { 
        ...entry, 
        timeSincePrevious,
        previousActivityType: sortedEntries[index + 1].status
      };
    });
    
    return entriesWithTimeBetween;
  }, []);

  // Check if current date is today
  const isCurrentDateToday = useCallback(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const dateToCheck = new Date(currentDate);
    dateToCheck.setHours(0, 0, 0, 0);
    
    return dateToCheck.getTime() === today.getTime();
  }, [currentDate]);

  // Check if current date is today or in the future
  const isCurrentDateTodayOrFuture = useCallback(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const dateToCheck = new Date(currentDate);
    dateToCheck.setHours(0, 0, 0, 0);
    
    return dateToCheck >= today;
  }, [currentDate]);

  // Get isTimeTrackMounted from global store
  const { isTimeTrackMounted: [isTimeTrackMounted, setIsTimeTrackMounted] } = storeContextValue;
  
  // Set isTimeTrackMounted to true when component mounts
  useEffect(() => {
    console.log('TimeTrack component mounted, setting isTimeTrackMounted to true');
    setIsTimeTrackMounted(true);
    return () => {
      console.log('TimeTrack component unmounted, setting isTimeTrackMounted to false');
      setIsTimeTrackMounted(false);
    };
  }, [setIsTimeTrackMounted]);

  // Navigate to today's date and refresh clock state
  const navigateToToday = useCallback(() => {
    const today = new Date();
    setCurrentDate(today);
    
    // Only refresh entries if we're online and not already loading
    if (navigator.onLine && !loading.entries) {
      // Use a debounce mechanism to prevent multiple rapid calls
      if (window._todayNavigationTimeout) {
        clearTimeout(window._todayNavigationTimeout);
      }
      
      window._todayNavigationTimeout = setTimeout(async () => {
        try {
          // First refresh entries to get the latest data
          await refreshEntries();
          
          // Then update the clock state if the user is clocked in, but only if we're still online
          if (clockState && clockState.clockedIn && navigator.onLine && isTimeTrackMounted) {
            try {
              // Use cached timestamp if available to reduce API calls
              const cachedClockInData = localStorage.getItem(`timetrack_clock_in_timestamp_${userId}`);
              let timestamp = null;
              
              if (cachedClockInData) {
                const parsedData = JSON.parse(cachedClockInData);
                // Only use cache if it's less than 5 minutes old
                if (Date.now() - parsedData.cachedAt < 5 * 60 * 1000) {
                  timestamp = parsedData.timestamp;
                  console.log('Using cached clock-in timestamp:', new Date(timestamp).toLocaleString());
                }
              }
              
          // If no valid cache, fetch from API only if TimeTrack is mounted
          if (!timestamp && navigator.onLine && userId && isTimeTrackMounted) {
            try {
              timestamp = await getMostRecentClockInTimestamp(userId);
              // Cache the result
              if (timestamp) {
                localStorage.setItem(`timetrack_clock_in_timestamp_${userId}`, JSON.stringify({
                  timestamp,
                  cachedAt: Date.now()
                }));
              }
            } catch (error) {
              console.error('Error fetching most recent clock-in timestamp:', error);
            }
          }
            
              if (timestamp) {
                // Update the clock state with the correct clock-in time
                if (typeof setClockState === 'function') {
                  setClockState(prev => ({
                    ...prev,
                    clockInTime: timestamp
                  }));
                }
              }
            } catch (error) {
              console.error('Error updating clock state:', error);
            }
          }
        } catch (error) {
          console.error('Error in navigateToToday:', error);
        }
      }, 500); // 500 ms debounce
    }
  }, [setCurrentDate, refreshEntries, clockState, userId, setClockState, loading.entries]);

  // Wrapper for clock in that gets location first
  const handleClockIn = useCallback(() => {
    if (clockState.clockedIn) return;
    
    // Save device information
    if (userId && deviceid && deviceid.dvc_hash) {
      saveDeviceInfo().catch(error => {
        console.error('Error saving device information during clock in:', error);
      });
    }
    
    setWaitingForGeolocation(true);
    setLocationStatus('Requesting location...');
    setPendingAction({ action: 'clockIn' });
    requestGeolocationFunc();
  }, [clockState.clockedIn, requestGeolocationFunc, userId, deviceid, saveDeviceInfo]);
  
  // Wrapper for clock out that gets location first
  const handleClockOut = useCallback((isAuto = false) => {
    if (!clockState.clockedIn) return;
    
    // Save device information
    if (userId && deviceid && deviceid.dvc_hash) {
      saveDeviceInfo().catch(error => {
        console.error('Error saving device information during clock out:', error);
      });
    }
    
    setWaitingForGeolocation(true);
    setLocationStatus('Requesting location...');
    setPendingAction({ action: 'clockOut', params: { isAuto } });
    requestGeolocationFunc();
  }, [clockState.clockedIn, requestGeolocationFunc, userId, deviceid, saveDeviceInfo]);
  
  // Wrapper for take break that gets location first
  const handleBreak = useCallback((breakType) => {
    if (!clockState.clockedIn) return;
    
    // Save device information
    if (userId && deviceid && deviceid.dvc_hash) {
      saveDeviceInfo().catch(error => {
        console.error('Error saving device information during break:', error);
      });
    }
    
    // Find the active break entry if any
    const activeBreak = getActiveBreak();
    
    // If already on a break, end it
    if (activeBreak) {
      console.log('Already on a break, ending it:', activeBreak);
      endBreakAutomatically(activeBreak);
      return;
    }
    
    // Not on a break, start a new one
    let duration = 15; // Default duration
    
    // Find the break rule for this type
    const breakRule = breakRules.find(rule => rule.rule_type === breakType);
    
    if (breakRule) {
      duration = breakRule.duration_minutes;
    } else {
      // Fallback durations based on Washington state laws
      if (breakType === 'meal' || breakType === 'lunch') {
        duration = 30; // 30 minutes for meals
      } else if (breakType === 'rest') {
        duration = 10; // 10 minutes for rest periods
      }
    }
    
    setWaitingForGeolocation(true);
    setLocationStatus('Requesting location...');
    setPendingAction({ 
      action: 'takeBreak', 
      params: { 
        breakType, 
        duration,
        state: userState,
        projectId: null
      } 
    });
    requestGeolocationFunc();
  }, [clockState.clockedIn, breakRules, userState, requestGeolocationFunc, getActiveBreak, endBreakAutomatically, userId, deviceid, saveDeviceInfo]);

  // Toggle break schedule
  const toggleBreakSchedule = useCallback(() => {
    setShowBreakSchedule(prev => !prev);
  }, []);

  // Get break time left
  const getBreakTimeLeft = useCallback(() => {
    if (!entries?.length) return null;

    const lastBreakEntry = [...entries]
      .sort((a, b) => b.timestamp - a.timestamp)
      .find(entry => entry.status === 'break');

    if (!lastBreakEntry) return null;
    
    // Skip if we've already processed this break
    if (processedBreakEnds.has(lastBreakEntry.id)) {
      return null;
    }

    // Check if there's a corresponding break-end entry
    const hasBreakEndEntry = entries.some(entry => 
      (entry.status === 'break-end' && entry.timestamp > lastBreakEntry.timestamp) ||
      (entry.status === 'break-end' && entry.break_id === lastBreakEntry.id)
    );

    if (hasBreakEndEntry) return null;

    const breakEndTime = new Date(lastBreakEntry.timestamp);
    breakEndTime.setMinutes(
      breakEndTime.getMinutes() + (lastBreakEntry.break_type === 'meal' ? 30 : 10)
    );

    const timeLeft = breakEndTime.getTime() - Date.now();
    return timeLeft > 0 ? timeLeft : 0;
  }, [entries, processedBreakEnds]);

  // Get active break type
  const getActiveBreakType = useCallback(() => {
    if (!entries?.length) return null;

    const lastBreakEntry = [...entries]
      .sort((a, b) => b.timestamp - a.timestamp)
      .find(entry => entry.status === 'break');

    if (!lastBreakEntry) return null;
    
    // Skip if we've already processed this break
    if (processedBreakEnds.has(lastBreakEntry.id)) {
      return null;
    }

    // Check if there's a corresponding break-end entry
    const hasBreakEndEntry = entries.some(entry => 
      (entry.status === 'break-end' && entry.timestamp > lastBreakEntry.timestamp) ||
      (entry.status === 'break-end' && entry.break_id === lastBreakEntry.id)
    );

    if (hasBreakEndEntry) return null;

    return lastBreakEntry.break_type || null;
  }, [entries, processedBreakEnds]);

  // Determine if a break is paid based on break type and state rules
  const isBreakPaid = useCallback((breakType) => {
    // Default rules if we can't determine from state rules
    if (breakType === 'rest') return true; // Rest breaks are typically paid
    if (breakType === 'meal' || breakType === 'lunch') return false; // Meal breaks are typically unpaid
    
    // Try to find the rule in the break rules
    const rule = breakRules.find(r => r.rule_type === breakType);
    if (rule) {
      return rule.paid;
    }
    
    // Fallback to default rules based on state
    switch (userState) {
      case 'WA':
        // Washington state: rest breaks are paid, meal breaks are unpaid
        return breakType === 'rest';
      case 'CA':
        // California: rest breaks are paid, meal breaks are unpaid
        return breakType === 'rest';
      case 'OR':
        // Oregon: rest breaks are paid, meal breaks are unpaid
        return breakType === 'rest';
      case 'NV':
        // Nevada: rest breaks are paid, meal breaks are unpaid
        return breakType === 'rest';
      default:
        // Default federal rules: short breaks (5-20 min) are paid, meal periods (30+ min) are unpaid
        return breakType === 'rest';
    }
  }, [breakRules, userState]);

  // Calculate work time accounting for paid vs unpaid breaks
  const calculateWorkTime = useCallback((clockInTime, currentTime, entriesToUse = null) => {
    if (!clockInTime) return 0;
    
    //console.log(`Calculating work time from ${new Date(clockInTime).toLocaleString()} to ${new Date(currentTime).toLocaleString()}`);
    
    // Calculate total elapsed time since clock-in
    const totalElapsedMs = currentTime - clockInTime;
    //console.log(`Total elapsed time: ${Math.floor(totalElapsedMs / (60 * 1000))} minutes (${Math.floor(totalElapsedMs / (60 * 60 * 1000))} hours)`);
    
    // Find all break periods
    const breakPeriods = [];
    
    // Use provided entries or fall back to the entries from state
    const entriesForCalculation = entriesToUse || entries;
    
    // First, sort entries by timestamp to ensure proper order
    const sortedEntries = [...entriesForCalculation].sort((a, b) => a.timestamp - b.timestamp);
    
    // Filter entries to only include those after clock-in
    const entriesAfterClockIn = sortedEntries.filter(entry => entry.timestamp >= clockInTime);
    //console.log(`Found ${entriesAfterClockIn.length} entries after clock-in`);
    
    // Track active breaks
    let activeBreaks = [];
    
    // Process entries in chronological order
    for (let i = 0; i < entriesAfterClockIn.length; i++) {
      const entry = entriesAfterClockIn[i];
      
      if (entry.status === 'break') {
        //console.log(`Found break start: ${entry.break_type} at ${new Date(entry.timestamp).toLocaleString()}`);
        
        // Start a new break
        activeBreaks.push({
          id: entry.id || entry.local_id,
          start: entry.timestamp,
          type: entry.break_type
        });
      } else if (entry.status === 'break-end') {
        //console.log(`Found break end: ${entry.break_type} at ${new Date(entry.timestamp).toLocaleString()}`);
        
        // Find the corresponding break start
        // First try to match by break_id if available
        let matchingBreakIndex = -1;
        if (entry.break_id) {
          matchingBreakIndex = activeBreaks.findIndex(b => b.id === entry.break_id);
        }
        
        // If no match by ID, try to match by type
        if (matchingBreakIndex === -1) {
          matchingBreakIndex = activeBreaks.findIndex(b => b.type === entry.break_type);
        }
        
        if (matchingBreakIndex !== -1) {
          const matchingBreak = activeBreaks[matchingBreakIndex];
          
          // Calculate break duration
          const breakDuration = entry.timestamp - matchingBreak.start;
          const breakDurationMinutes = Math.floor(breakDuration / (60 * 1000));
          
          //console.log(`Matched break: ${matchingBreak.type}, duration: ${breakDurationMinutes} minutes`);
          
          // Determine if break is paid
          const isPaid = isBreakPaid(matchingBreak.type);
          //console.log(`Break is ${isPaid ? 'paid' : 'unpaid'}`);
          
          // Add to break periods
          breakPeriods.push({
            start: matchingBreak.start,
            end: entry.timestamp,
            duration: breakDuration,
            type: matchingBreak.type,
            paid: isPaid
          });
          
          // Remove this break from active breaks
          activeBreaks.splice(matchingBreakIndex, 1);
        } else {
          // Don't log the warning to avoid console spam
          // console.log(`Warning: Found break-end without matching break start for type: ${entry.break_type}`);
        }
      }
    }
    
    // Handle any still-active breaks
    for (const activeBreak of activeBreaks) {
      const activeDuration = currentTime - activeBreak.start;
      const activeDurationMinutes = Math.floor(activeDuration / (60 * 1000));
      
      //console.log(`Active break: ${activeBreak.type}, current duration: ${activeDurationMinutes} minutes`);
      
      // Determine if break is paid
      const isPaid = isBreakPaid(activeBreak.type);
      //console.log(`Active break is ${isPaid ? 'paid' : 'unpaid'}`);
      
      breakPeriods.push({
        start: activeBreak.start,
        end: currentTime,
        duration: activeDuration,
        type: activeBreak.type,
        active: true,
        paid: isPaid
      });
    }
    
    // Calculate total unpaid break time (only subtract unpaid breaks)
    const totalUnpaidBreakTimeMs = breakPeriods
      .filter(period => !period.paid) // Only include unpaid breaks
      .reduce((total, period) => total + period.duration, 0);
    
    //console.log(`Total unpaid break time: ${Math.floor(totalUnpaidBreakTimeMs / (60 * 1000))} minutes (${Math.floor(totalUnpaidBreakTimeMs / (60 * 60 * 1000))} hours)`);
    // console.log(`Break periods:`, breakPeriods.map(p => ({
    //   type: p.type,
    //   start: new Date(p.start).toLocaleTimeString(),
    //   end: new Date(p.end).toLocaleTimeString(),
    //   duration: Math.floor(p.duration / (60 * 1000)) + ' minutes',
    //   paid: p.paid,
    //   active: p.active || false
    // })));
    
    // Calculate work time (total elapsed time minus unpaid break time)
    const workTimeMs = totalElapsedMs - totalUnpaidBreakTimeMs;
    
    //console.log(`Work time: ${Math.floor(workTimeMs / (60 * 1000))} minutes (${Math.floor(workTimeMs / (60 * 60 * 1000))} hours)`);
    
    return Math.max(0, workTimeMs); // Ensure non-negative duration
  }, [entries, isBreakPaid]);

  // Check for due breaks and show notifications
  const [breakCheckIntervalMs, setBreakCheckIntervalMs] = useState(60000); // Check every minute by default
  const [lastBreakCheck, setLastBreakCheck] = useState(Date.now());
  const breakCheckTimerRef = useRef(null);
  
  // Function to handle dismissing a break notification
  const handleDismissBreakNotification = useCallback(async (notification) => {
    try {
      // Mark the notification as dismissed in the database
      await updateBreakNotification(notification.id, { dismissed: true });
      
      // Hide the notification
      setShowBreakNotification(false);
      setBreakNotification(null);
    } catch (error) {
      console.error('Error dismissing break notification:', error);
    }
  }, []);
  
  // Function to handle taking a break from a notification
  const handleTakeBreakFromNotification = useCallback(async (notification) => {
    try {
      // Mark the notification as break taken in the database
      await updateBreakNotification(notification.id, { breakTaken: true });
      
      // Hide the notification
      setShowBreakNotification(false);
      setBreakNotification(null);
      
      // Start the break
      handleBreak(notification.breakType);
    } catch (error) {
      console.error('Error taking break from notification:', error);
    }
  }, [handleBreak]);
  
  // Set up break check interval
  useEffect(() => {
    if (!userId || !clockState.clockedIn) {
      // Clear any existing timer
      if (breakCheckTimerRef.current) {
        clearTimeout(breakCheckTimerRef.current);
        breakCheckTimerRef.current = null;
      }
      return;
    }
    
  // Function to check for due breaks
  const checkForDueBreaks = async () => {
    try {
      // Skip if we've checked recently
      const now = Date.now();
      // console.log('[DEBUG] Checking for due breaks at', new Date(now).toLocaleString());
      // console.log('[DEBUG] Last check was at', new Date(lastBreakCheck).toLocaleString());
      // console.log('[DEBUG] Check interval is', breakCheckIntervalMs / 1000, 'seconds');
      
      if (now - lastBreakCheck < breakCheckIntervalMs) {
        //console.log('[DEBUG] Skipping check - too soon since last check');
        return;
      }
      
      setLastBreakCheck(now);
      
      // Skip if we're already showing a notification
      if (showBreakNotification || breakNotification) {
        console.log('[DEBUG] Skipping check - already showing a notification');
        return;
      }
      
      // Skip if we're already on a break
      const activeBreakType = getActiveBreakType();
      if (activeBreakType) {
        console.log('[DEBUG] Skipping check - already on a break of type:', activeBreakType);
        return;
      }
      
      console.log('[DEBUG] Checking if break is due for user:', userId, 'in state:', userState);
      
      // Check if a break is due
      const breakDue = await checkBreakDue(userId, userState);
      console.log('[DEBUG] Break due check result:', breakDue);
        
        if (breakDue) {
          console.log('Break due:', breakDue);
          
          // Create a notification in the database
          const notification = await createBreakNotification({
            userId,
            breakType: breakDue.breakType,
            scheduledBreakTime: breakDue.scheduledBreakTime,
            state: userState,
            projectId: null // TODO: Get from current context if needed
          });
          
          if (notification) {
            // Show the notification
            setBreakNotification(breakDue);
            setShowBreakNotification(true);
          }
        }
      } catch (error) {
        console.error('Error checking for due breaks:', error);
      }
      
      // Schedule the next check
      breakCheckTimerRef.current = setTimeout(checkForDueBreaks, breakCheckIntervalMs);
    };
    
    // Check immediately
    checkForDueBreaks();
    
    // Cleanup function
    return () => {
      if (breakCheckTimerRef.current) {
        clearTimeout(breakCheckTimerRef.current);
        breakCheckTimerRef.current = null;
      }
    };
  }, [
    userId, 
    clockState.clockedIn, 
    breakCheckIntervalMs, 
    lastBreakCheck, 
    showBreakNotification, 
    breakNotification, 
    userState, 
    getActiveBreakType
  ]);
  
  // Update shift duration in real-time when clocked in
  useEffect(() => {
    if (!clockState || !clockState.clockedIn) return;
    
    // Update shift duration immediately
    const updateDuration = async () => {
      // Skip if updateShiftDuration is not available
      if (!updateShiftDuration || typeof updateShiftDuration !== 'function') {
        console.error('updateShiftDuration is not available or not a function:', updateShiftDuration);
        return;
      }

      // Store a reference to setClockState at the beginning of the function
      // This ensures we're using the current value throughout the function
      const currentSetClockState = setClockState;
      // First, check if we need to refresh the clock-in time
      // This is especially important when viewing today's date
      if (isCurrentDateToday() && isTimeTrackMounted) {
        try {
          // Use the simplified function that just returns the timestamp
          // Wrap in try/catch to handle any errors with the function call
          let timestamp = null;
          try {
            // Only call getMostRecentClockInTimestamp if userId is defined and component is mounted
            if (userId && isTimeTrackMounted) {
              timestamp = await getMostRecentClockInTimestamp(userId);
            }
          } catch (error) {
            console.error('Error calling getMostRecentClockInTimestamp in updateDuration:', error);
            // Continue with null timestamp
          }
          
          if (timestamp && timestamp !== clockState.clockInTime) {
            console.log(`Found updated clock-in timestamp: ${new Date(timestamp).toLocaleString()}`);
            console.log(`Current clock-in time: ${new Date(clockState.clockInTime).toLocaleString()}`);
            
            // Update the clock state with the correct clock-in time
            // Double-check that currentSetClockState is available and is a function
            if (currentSetClockState && typeof currentSetClockState === 'function') {
              try {
                // Use a try-catch to handle any errors during the update
                currentSetClockState(prev => {
                  // Make sure prev is an object before trying to spread it
                  if (prev && typeof prev === 'object') {
                    return {
                      ...prev,
                      clockInTime: timestamp
                    };
                  } else {
                    // If prev is not an object, create a new state object
                    console.warn('Previous clock state is not an object:', prev);
                    return {
                      clockedIn: true,
                      clockInTime: timestamp,
                      lastBreakTime: null,
                      lastMealTime: null,
                      shiftDuration: 0,
                      workSessions: []
                    };
                  }
                });
              } catch (error) {
                console.error('Error updating clock state in updateDuration:', error);
              }
            } else {
              console.error('setClockState is not available or not a function:', setClockState);
            }
            
            // Use the updated clock-in time for calculations
            return;
          }
        } catch (error) {
          console.error('Error checking for updated clock-in timestamp:', error);
        }
      }
      
      if (!clockState.clockInTime) return;
      
      // Always use the clockState.clockInTime for the current session
      // This ensures we're using the correct clock-in time regardless of which date we're viewing
      const clockInTime = clockState.clockInTime;
      const now = Date.now(); // Use Date.now() to ensure we're using the current time
      
      // Simple calculation for shift duration in minutes
      // This is a direct calculation that doesn't rely on API calls
      let shiftDurationMinutes = 0;
      
      try {
        // Calculate the basic duration in minutes
        const basicDurationMs = now - clockInTime;
        shiftDurationMinutes = Math.floor(basicDurationMs / (60 * 1000));
        
        // Only make API calls if we're online and viewing today
        if (navigator.onLine && isCurrentDateToday()) {
          // Get all entries from all dates for the current user
          // This ensures we have all break entries, even if we're viewing a different date
          // Calculate start date (from clock-in time) and end date (now)
          const startDate = new Date(clockInTime);
          startDate.setHours(0, 0, 0, 0);
          
          const endDate = new Date(now);
          endDate.setHours(23, 59, 59, 999);
          
          // Only call getTimeTrackEntries if userId is defined
          let allEntries = [];
          if (userId) {
            allEntries = await getTimeTrackEntries(userId, {
              startDate: startDate.getTime(),
              endDate: endDate.getTime()
            });
          } else {
            console.log('Skipping getTimeTrackEntries call because userId is undefined');
          }
          
          // Calculate work time accounting for paid vs unpaid breaks
          const workTimeMs = calculateWorkTime(clockInTime, now, allEntries);
          
          // Convert to minutes for the updateShiftDuration function
          shiftDurationMinutes = Math.floor(workTimeMs / (60 * 1000));
        }
        
        // Update the shift duration
        updateShiftDuration(Math.max(0, shiftDurationMinutes)); // Ensure non-negative duration
        
        // Store the work time in localStorage as a backup
        try {
          localStorage.setItem(`timetrack_work_time_${userId}`, JSON.stringify({
            clockInTime,
            shiftDurationMinutes,
            lastUpdated: now
          }));
        } catch (error) {
          console.error('Error storing work time in localStorage:', error);
        }
      } catch (error) {
        console.error('Error calculating shift duration:', error);
        
        // Fallback to a simple calculation
        const basicDurationMs = now - clockInTime;
        shiftDurationMinutes = Math.floor(basicDurationMs / (60 * 1000));
        
        // Update the shift duration with the fallback calculation
        updateShiftDuration(Math.max(0, shiftDurationMinutes)); // Ensure non-negative duration
      }
    };
    
    // Update immediately
    updateDuration();
    
    // Set up interval to update every second
    const intervalId = setInterval(updateDuration, 1000);
    
    return () => clearInterval(intervalId);
  }, [clockState.clockedIn, clockState.clockInTime, updateShiftDuration, calculateWorkTime, userId, isCurrentDateToday, setClockState]);

  // Process entries to add time between activities
  useEffect(() => {
    if (entries && entries.length >= 2 && !loading) {
      const entriesWithTimeBetween = calculateTimeBetweenActivities(entries);
      setEntries(entriesWithTimeBetween);
    }
  }, [loading, calculateTimeBetweenActivities]);

  // Memoize the context value to prevent unnecessary re-renders
  const value = React.useMemo(() => ({
    // Original hook state and functions
    entries,
    setEntries,
    loading,
    error,
    syncStatus,
    currentDate,
    clockState,
    setClockState,
    updateShiftDuration,
    navigateDate,
    syncOfflineEntries,
    refreshEntries,
    setCurrentDate,
    
    // UI state
    waitingForGeolocation,
    locationStatus,
    locationAddress,
    showBreakSchedule,
    setShowBreakSchedule,
    selectedState,
    setSelectedState,
    userState,
    setUserState,
    breakRules,
    showBreakAlert,
    setShowBreakAlert,
    showAutoClockOutAlert,
    setShowAutoClockOutAlert,
    showMealReminderAlert,
    setShowMealReminderAlert,
    showRestReminderAlert,
    setShowRestReminderAlert,
    alertType,
    setAlertType,
    currentTime,
    online,
    breakNotification,
    setBreakNotification,
    showBreakNotification,
    setShowBreakNotification,
    missedBreaks,
    setMissedBreaks,
    
    // Wrapped functions
    handleClockIn,
    handleClockOut,
    handleBreak,
    toggleBreakSchedule,
    loadBreakRules,
    
    // Break notification functions
    handleDismissBreakNotification,
    handleTakeBreakFromNotification,
    
    // Utility functions
    formatDate,
    formatTime,
    formatDuration,
    getStatusColor,
    isCurrentDateToday,
    isCurrentDateTodayOrFuture,
    navigateToToday,
    getBreakTimeLeft,
    getActiveBreakType,
    getActiveBreak,
    endBreakAutomatically,
    isBreakPaid,
    calculateWorkTime,
    calculateTimeBetweenActivities,
    
    // Pass userId for convenience
    userId
  }), [
    entries, loading, error, syncStatus, currentDate, clockState,
    waitingForGeolocation, locationStatus, locationAddress,
    showBreakSchedule, selectedState, userState, breakRules,
    showBreakAlert, showAutoClockOutAlert, showMealReminderAlert,
    showRestReminderAlert, alertType, currentTime, online,
    breakNotification, showBreakNotification, missedBreaks, userId
  ]);

  return (
    <TimeTrackContext.Provider value={value}>
      {children}
    </TimeTrackContext.Provider>
  );
}

export const useTimeTrackContext = () => {
  const context = useContext(TimeTrackContext);
  if (!context) {
    throw new Error('useTimeTrackContext must be used within a TimeTrackProvider');
  }
  return context;
}
