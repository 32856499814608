import React, { useEffect, useState } from 'react';
import { notify } from "../Toast";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; // Import admin icon
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Import time icon for displaying accrued time
import { AppBar, Badge, IconButton, Menu, MenuItem, Typography, Toolbar, Tooltip, Box, Divider } from "@mui/material";
import GeocoderSearch from './GeocoderSearch';
import { LocationOn } from '@mui/icons-material'; // Import the LocationOn icon
import MoreIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { withRouter } from '../withRouter';
import TimeCard from '../TimeCard';
import TodoList from '../TodoList';
import NotificationsDialog from '../Notifications/NotificationsDialog';
import { StoreContext } from '../Hooks/store';
import { StatusIcon } from '../Shared/StatusIcon';
import { makeStyles } from '@mui/styles'; // Updated to use makeStyles
import { useTimeTrackContext } from '../TimeTrack/context/TimeTrackContext'; // Import TimeTrackContext
import { getDeviceInfo } from '../Utils/getDeviceInfo';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker'; // Import the hook
import { hashCode } from '../Utils/hashCode';
import sqlitedb from '../sqlitedb';
import { saveUserInteractionData, saveUserSessionData } from '../../api/userInteractions';
import { isUserAdmin } from '../../api/trackingConfig'; // Import isUserAdmin function
import AssignmentDialog from '../Assignment/AssignmentDialog';
import { useAuth } from '../Hooks/useAuth';
import { supabase } from '../../lib/supabase';
import { subscribeToStatusChange, STATUS } from '../TimeTrack/utils/timeTrackEvents';
import { useDeviceTracking } from '../TimeTrack/context/hooks/useDeviceTracking';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  customizeToolbar: {
    minHeight: '48px', // Adjust the toolbar height if needed
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  statusIconSpan: {
    marginLeft: theme.spacing(1),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenuItem: {
    color: '#000', // Set text color to black
    backgroundColor: '#fff', // Set background color to white
  },
  adminIcon: {
    color: '#FFD700', // Gold color for admin icon
  }
}));

function PrimarySearchAppBar(props) {
  const { user, logout } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  
  const classes = useStyles(); // Apply styles
  const {
    timecard,
    thisdeviceid,
    thissessionid,
    thisuserid,
    thislocationid,
    modaldialog,
    mysessiondata,
    mytodolistnames,
    myinteractions,
    askGeolocation,
    geolocation
  } = React.useContext(StoreContext);
  const [requestGeolocationFunc] = askGeolocation;
  const [sharedGeolocation] = geolocation; // Access the shared geolocation state

  // Determine if the location has been obtained
  const isLocationObtained = sharedGeolocation.lat !== 0 && sharedGeolocation.lon !== 0;

  const [interactiondata] = myinteractions;
  const [statuscolorTimecard, setStatuscolorTimecard] = timecard;
  const [deviceid, setDeviceid] = thisdeviceid;
  const [sessionid, setSessionid] = thissessionid;
  const [userid, setUserid] = thisuserid;
  const [locationid, setLocationid] = thislocationid;
  const [sessiondata, setsessiondata] = mysessiondata;
  const [kvtodolistnames, setKvtodolistnames] = mytodolistnames;
  const [loadedmodal, setLoadedmodal] = modaldialog;

  const prps = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const menuId = 'primary-search-account-menu';

  const navigate = useNavigate();
  
  // Subscribe to TimeTrack status changes
  useEffect(() => {
    // Set default status color
    setStatuscolorTimecard(STATUS.DEFAULT);
    
    // Subscribe to status changes
    const unsubscribe = subscribeToStatusChange((status) => {
      setStatuscolorTimecard(status);
    });
    
    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
    };
  }, [setStatuscolorTimecard]);
  
  // Check if user is an admin
  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        //try {
          //console.log(`SimpleAppBar: Checking if user ${user.id} is an admin`);
          
          // Force admin to true for testing
          //setIsAdmin(true);
          //console.log('SimpleAppBar: Forcing admin to true for testing');
          
          // Uncomment this to use the actual admin check
          /*
          const admin = await isUserAdmin(user.id);
          console.log(`SimpleAppBar: User ${user.id} is admin: ${admin}`);
          setIsAdmin(admin);
          
          // Debug: Check if the user is in the user_roles table
          const { data, error } = await supabase
            .from('user_roles')
            .select('*')
            .eq('user_id', user.id);
            
          if (error) {
            console.error('Error querying user_roles:', error);
          } else {
            console.log('User roles data:', data);
          }
          */
      try {
        console.log(`AdminPage: Checking if user ${user.id} is an admin`);

        // Force admin to true for testing
        //setIsAdmin(true);
        //console.log('AdminPage: Forcing admin to true for testing');
        
        // Uncomment this to use the actual admin check
        const admin = await isUserAdmin(user.id);
        console.log(`AdminPage: User ${user.id} is admin: ${admin}`);
        setIsAdmin(admin); // temporarily set to true for testing
      
      } catch (error) {
          console.error('Error checking admin status:', error);
          // Force admin to true for testing
          setIsAdmin(true);
        }
      } else {
        console.log('SimpleAppBar: No user logged in');
        setIsAdmin(false);
      }
    };

    checkAdmin();
  }, [user]);

  const handleAssignment = () => {
    setLoadedmodal('AssignmentDialog');
    handleMenuClose();
  };

  const handleTimecard = () => {
    setLoadedmodal('TimeCard');
    handleMenuClose();
  };

  // Handle admin page navigation
  const handleAdminPage = () => {
    console.log('Navigating to admin page');
    // Make sure we're using the correct path
    navigate('/admin', { replace: true });
    handleMenuClose();
  };

  const saveSessionDataToDatabase = () => {
    // Check if sessiondata exists
    if (!sessiondata) {
      console.warn('No session data available to save');
      return;
    }

    const updatedSessionData = {
      ...sessiondata,
      end_at: Date.now()
    };

    // Check if device property exists before destructuring
    if (updatedSessionData.device) {
      const { dvc_hash, dvc_json } = updatedSessionData.device;
      //console.log('to save from session');
      //console.log('dvc_hash:', dvc_hash);
      //console.log('dvc_json:', dvc_json);
      
      saveSessionToDb(updatedSessionData);
      saveUserInteractionToDb();
    } else {
      console.warn('Device information is missing in session data');
      // Still try to save what we have
      saveSessionToDb(updatedSessionData);
      saveUserInteractionToDb();
    }
  };

  // Initialize the user interaction tracker
  const { trackInteraction, clearSessionData } = useUserInteractionTracker();

  async function saveSessionToDb(sessionJson) {
    trackInteraction('form_submit', { formName: 'TopNav.Menu' }); // Use the hook
    try {
      // Check if userid and sessionid are defined
      if (!userid || !sessionid) {
        console.warn('User ID or Session ID is missing, cannot save session data');
        return;
      }
      
      //console.dir(sessionJson, { depth: null });
      notify('Saving...');
      
      // Save to both SQLite (for backward compatibility) and Supabase
      try {
        await sqlitedb.saveUserSessionData(sessionJson, userid, sessionid);
      } catch (sqliteError) {
        console.warn('SQLite save failed, continuing with Supabase:', sqliteError);
      }
      
      // Save to Supabase
      await saveUserSessionData(sessionJson, userid, sessionid);
    } catch (error) {
      console.error('Error saving session data:', error);
      notify('Error saving user session data.');
    }
  }

  async function saveUserInteractionToDb() {
    trackInteraction('form_submit', { formName: 'Save.Interactions' }); // Use the hook
    try {
      // Check if userid and sessionid are defined
      if (!userid || !sessionid) {
        console.warn('User ID or Session ID is missing, cannot save interaction data');
        return;
      }
      
      // Check if interactiondata exists
      if (!interactiondata) {
        console.warn('No interaction data available to save');
        return;
      }
      
      //console.log(interactiondata);
      //notify('Saving...');
      
      // Save to both SQLite (for backward compatibility) and Supabase
      // try {
      //   await sqlitedb.saveUserInteractionData(interactiondata, userid, sessionid);
      // } catch (sqliteError) {
      //   console.warn('SQLite save failed, continuing with Supabase:', sqliteError);
      // }
      
      // Save to Supabase
      await saveUserInteractionData(interactiondata, userid, sessionid);
    } catch (error) {
      console.error('Error saving interaction data:', error);
      notify('Error saving user interactions.');
    }
  }

  const handleLogin = () => {
    initializeState('handleLogin');
    trackInteraction('login', { // Use the hook
      sessionHash: sessionid,
      utcTime: new Date().toUTCString(),
      localTime: new Date().toLocaleTimeString(),
      userId: user?.id // Include the user ID
    });
  };

  const handleLogout = async () => {
    try {
      // Track the logout interaction
      trackInteraction('logout', {
        sessionHash: sessionid,
        utcTime: new Date().toUTCString(),
        localTime: new Date().toLocaleTimeString(),
        userId: user?.id // Include the user ID
      });
      
      // Save session data to database
      saveSessionDataToDatabase();
      
      // Clear session data
      clearSessionData();
      
      // Log out of Supabase
      await logout();
      
      notify('Successfully logged out');
      navigate('/', { replace: true }); // Redirect to the homepage
    } catch (error) {
      console.error('Logout failed:', error);
      notify('Logout failed. Please try again.');
    }
  };

  // Function to clear all localStorage data
  const handleClearOfflineData = () => {
    try {
      // First notify the user to sync any pending data
      notify('Please sync any pending offline data before clearing');
      
      // Ask for confirmation
      const confirmClear = window.confirm(
        'Are you sure you want to clear all offline data? ' +
        'This will delete any pending syncs and locally stored information. ' +
        'This action cannot be undone.'
      );
      
      // Only proceed if the user confirms
      if (confirmClear) {
        // Track the interaction
        trackInteraction('clear_offline_data', {
          sessionHash: sessionid,
          utcTime: new Date().toUTCString(),
          localTime: new Date().toLocaleTimeString(),
          userId: user?.id
        });
        
        // Clear all localStorage
        localStorage.clear();
        
        // Notify the user
        notify('All offline data cleared successfully');
      } else {
        // User canceled the operation
        notify('Operation canceled. Your offline data is safe.');
        trackInteraction('clear_offline_data_canceled', {
          sessionHash: sessionid,
          utcTime: new Date().toUTCString(),
          localTime: new Date().toLocaleTimeString(),
          userId: user?.id
        });
      }
      
      handleMenuClose();
    } catch (error) {
      console.error('Failed to clear offline data:', error);
      notify('Failed to clear offline data. Please try again.');
    }
  };

  useEffect(() => {
    // Clear session data
    clearSessionData();
    
    // Initialize state
    initializeState('useEffect');
    
    // Track page view
    trackInteraction('page_view', { 
      sessionHash: sessionid, 
      pageName: 'HomePage',
      userId: user?.id, // Include the user ID
      url: window.location.href, // Include the URL
      timestamp: Date.now() // Include the timestamp
    });
    
    // Initialize the interaction tracker with user ID and session hash
    if (user && sessionid) {
      // Save initial session data
      const initialSessionData = {
        user_id: user.id,
        session_hash: sessionid,
        start_time: Date.now(),
        user_agent: navigator.userAgent,
        device_info: getDeviceInfo()
      };
      
      // Save session data to Supabase
      saveUserSessionData(initialSessionData, user.id, sessionid)
        .catch(error => console.error('Error saving initial session data:', error));
    }
  }, [user, sessionid]); // Depend on user and sessionid

  // Use device tracking hook
  const { saveDeviceInfo } = useDeviceTracking({ userid, deviceid });

  const initializeState = (calledBy = '') => {
    const rtnow = Date.now();
    //console.log(`in initializeState-- caller ${calledBy}: rtnow=${rtnow}`);

    if (user) {
      // Use the authenticated user's UUID directly instead of hashing the email
      setUserid(user.id);
    }

    const obj = JSON.stringify(getDeviceInfo());
    const dvc_obj = {
      dvc_hash: hashCode(obj),
      dvc_json: obj,
    };
    setDeviceid(dvc_obj);

    
    // Save device information
    if (userid && deviceid && deviceid.dvc_hash) {
      console.log('Saving device info during app launch');
      console.dir(deviceid, { depth: null });
      saveDeviceInfo().catch(error => {
        console.error('Error saving device information during clock in:', error);
      });
    }

    if (!sessionid || sessionid === '') {
      const newSessionHash = hashCode([sessionid, userid, deviceid, Date.now()].join(","));
      setSessionid(newSessionHash);

      const session_obj = {
        session_hash: newSessionHash,
        device: dvc_obj,
        start_at: rtnow,
        end_at: rtnow,
        session_locations: []
      };

      //console.dir(session_obj, { depth: null });
      setsessiondata(session_obj);
    } else {
      //console.log('session already set: ', sessionid);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCustomMenuOpen = () => {
    handleMenuClose();
  };

  const handleTimecardMenuOpen = () => {
    setLoadedmodal('TimeCard');
    handleMenuClose();
  };

  const handleTodoMenuOpen = () => {
    setLoadedmodal('Todolist');
    handleMenuClose();
  };

  const handleTodoNotesMenuOpen = () => {
    setLoadedmodal('Todonotes');
    handleMenuClose();
  };


  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleStatusUpdate = (assignmentId, status) => {
    if (props.onStatusUpdate) {
      props.onStatusUpdate(assignmentId, status);
    }
    handleMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      
      {isAdmin && (
        <MenuItem onClick={handleAdminPage}>
          Admin Panel
        </MenuItem>
      )}
      <Divider />
      <MenuItem onClick={handleClearOfflineData}>Clear All Offline Data</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem> {/* Updated to use handleLogout */}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleTodoMenuOpen} className={classes.mobileMenuItem}>
        <IconButton onClick={handleTodoMenuOpen} aria-label="show 4 new mails" color="inherit" size="large">
          <Badge badgeContent={42} color="secondary">
            <MailIcon onClick={handleTodoMenuOpen} />
          </Badge>
        </IconButton>
        <TodoList rendercolor='primary' />
      </MenuItem>
      <MenuItem className={classes.mobileMenuItem}>
        <IconButton aria-label="show 11 new notifications" color="inherit" size="large">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon onClick={handleTodoNotesMenuOpen} />
          </Badge>
        </IconButton>
        <NotificationsDialog rendercolor="primary" />
      </MenuItem>
      <MenuItem onClick={handleTimecardMenuOpen} className={classes.mobileMenuItem}>
        <TimeCard rendercolor='primary' />
      </MenuItem>

      <MenuItem onClick={handleAssignment} className={classes.mobileMenuItem}>
        <AssignmentDialog rendercolor='primary' />
      </MenuItem>
]
      {/* Always show the admin menu item for debugging */}
      <MenuItem onClick={handleAdminPage} className={classes.mobileMenuItem}>
        <IconButton
          aria-label="Admin Panel"
          color="inherit"
          size="large"
          style={{ color: isAdmin ? '#FFD700' : 'inherit' }}
        >
          <AdminPanelSettingsIcon />
          {isAdmin && <span style={{ fontSize: '8px', position: 'absolute', bottom: '2px', right: '2px', color: 'black' }}>✓</span>}
        </IconButton>
        <p>Admin Panel {isAdmin ? '(Admin)' : '(No Access)'}</p>
      </MenuItem>
      <MenuItem onClick={handleClearOfflineData} className={classes.mobileMenuItem}>
        <IconButton
          aria-label="Clear offline data"
          color="inherit"
          size="large">
          <Badge color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Clear All Offline Data</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen} className={classes.mobileMenuItem}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.customizeToolbar}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography className={classes.title} variant="h6" noWrap>
              geowork.app
            </Typography>
            <span className={classes.statusIconSpan}>
              <StatusIcon cls1={statuscolorTimecard} />
            </span>
            <GeocoderSearch />
          </Box>
          <Tooltip title={isLocationObtained ? "Location obtained" : "Get My Location"}>
            <IconButton
              color="inherit"
              onClick={requestGeolocationFunc} // This triggers the geolocation request
              aria-label="Get My Location"
            >
              {/* Change the icon color based on whether the location is obtained */}
              <LocationOn style={{ color: isLocationObtained ? 'rgb(140, 198, 63)' : 'inherit' }} />
            </IconButton>
          </Tooltip>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="Todos"
              onClick={handleTodoMenuOpen}
              color="inherit"
              size="large">
              <Badge badgeContent={44} color="secondary">
                <MailIcon />
              </Badge>
              <TodoList rendercolor='#fff' />
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit" size="large">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
              <NotificationsDialog rendercolor="primary" />
            </IconButton>
            <IconButton
              aria-label="Time Card"
              onClick={handleTimecardMenuOpen}
              color="inherit"
              size="large">
              <TimeCard rendercolor='#fff' />
            </IconButton>

            <IconButton onClick={handleAssignment}>
              <AssignmentDialog rendercolor='#fff' />
            </IconButton>
            {/* Always show the admin icon for debugging */}
            <Tooltip title="Admin Panel">
              <IconButton
                aria-label="Admin Panel"
                onClick={handleAdminPage}
                style={{ color: isAdmin ? '#FFD700' : 'rgba(255,255,255,0.5)' }}
                size="large"
              >
                <AdminPanelSettingsIcon />
                {isAdmin && <span style={{ fontSize: '8px', position: 'absolute', bottom: '2px', right: '2px', color: 'white' }}>✓</span>}
              </IconButton>
            </Tooltip>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large">
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="large">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default withRouter(PrimarySearchAppBar);
