import React, { useState, useEffect } from 'react';
import { 
  DialogTitle, 
  IconButton, 
  Tooltip, 
  Box,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsIcon from '@mui/icons-material/Settings';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useTimeTrackContext } from '../context/TimeTrackContext';
import { STATUS } from '../utils/timeTrackEvents';
import '../styles.css';

const TimeTrackHeader = ({ onClose }) => {
  const { 
    toggleBreakSchedule,
    online,
    clockState,
    getActiveBreakType
  } = useTimeTrackContext();

  const [statusColor, setStatusColor] = useState(STATUS.DEFAULT);

  // Update status color based on current status
  useEffect(() => {
    if (clockState.clockedIn) {
      const activeBreakType = getActiveBreakType();
      if (activeBreakType) {
        setStatusColor(STATUS.ON_BREAK); // Orange for break
      } else {
        setStatusColor(STATUS.CLOCKED_IN); // Green for clocked in
      }
    } else {
      setStatusColor(STATUS.CLOCKED_OUT); // Red for clocked out
    }
  }, [clockState.clockedIn, getActiveBreakType]);

  return (
    <DialogTitle 
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        bgcolor: '#00416e',
        color: 'white',
        p: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <AccessTimeIcon sx={{ color: statusColor }} />
        <Typography variant="h6" component="span">
          Time Track
        </Typography>
        <Box className="connection-status" sx={{ ml: 1 }}>
          {online ? (
            <Tooltip title="Online">
              <WifiIcon sx={{ color: '#8CC63F', fontSize: 18 }} />
            </Tooltip>
          ) : (
            <Tooltip title="Offline">
              <WifiOffIcon sx={{ color: '#B3B3B3', fontSize: 18 }} />
            </Tooltip>
          )}
        </Box>
      </Box>
      
      <Box>
        <Tooltip title="Break Schedule">
          <IconButton
            onClick={toggleBreakSchedule}
            sx={{ 
              color: 'white',
              mr: 1,
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.08)'
              }
            }}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Close">
          <IconButton
            onClick={onClose}
            sx={{ 
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.08)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </DialogTitle>
  );
};

export default TimeTrackHeader;
