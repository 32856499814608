import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { supabase } from '../../lib/supabase';
import { addUserAsAdmin, removeUserAsAdmin, isUserAdmin } from '../../api/trackingConfig';
import { useAuth } from '../Hooks/useAuth';

const UserRoleManagement = () => {
  const { user } = useAuth(); // Get user from useAuth hook
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Load users and admin roles
  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);
        
        // Try to get admin users from the users table
        try {
          // First try using the RPC function if available
          const { data: adminUsers, error: adminError } = await supabase
            .from('users')
            .select('id, email')
            .eq('is_admin', true);
          
          if (!adminError && adminUsers) {
            // Set admins
            setAdmins(adminUsers.map(admin => admin.id));
            
            // Set users with emails if available
            setUsers(adminUsers.map(admin => ({ 
              id: admin.id, 
              email: admin.email || `User ID: ${admin.id.substring(0, 8)}...` 
            })));
            
            return;
          }
        } catch (directError) {
          console.error('Error fetching admin users directly:', directError);
        }
        
        // Fallback: Try to get current user if they're an admin
        const { data: { user: currentUser } } = await supabase.auth.getUser();
        
        if (currentUser) {
          const isAdmin = await isUserAdmin(currentUser.id);
          
          if (isAdmin) {
            setAdmins([currentUser.id]);
            setUsers([{ 
              id: currentUser.id, 
              email: currentUser.email || `User ID: ${currentUser.id.substring(0, 8)}...` 
            }]);
          }
        }
      } catch (error) {
        console.error('Error loading users and roles:', error);
        setSnackbar({
          open: true,
          message: 'Error loading users and roles',
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };
    
    loadUsers();
  }, []);

  // Handle adding a new admin
  const handleAddAdmin = async () => {
    try {
      // Since we can't query by email, we'll just use the ID directly
      const userId = newAdminEmail.trim();
      
      if (!userId || !userId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) {
        setSnackbar({
          open: true,
          message: `Please enter a valid user ID (UUID format)`,
          severity: 'error'
        });
        return;
      }
      
      // Add user as admin
      const success = await addUserAsAdmin(userId, user);
      
      if (success) {
        // Update admins list
        setAdmins([...admins, userId]);
        
        // Update users list
        setUsers([...users, { 
          id: userId, 
          email: `User ID: ${userId.substring(0, 8)}...` 
        }]);
        
        setSnackbar({
          open: true,
          message: `User ${userId} added as admin`,
          severity: 'success'
        });
        
        setOpenDialog(false);
        setNewAdminEmail('');
      } else {
        throw new Error('Failed to add user as admin');
      }
    } catch (error) {
      console.error('Error adding admin:', error);
      setSnackbar({
        open: true,
        message: 'Error adding admin',
        severity: 'error'
      });
    }
  };

  // Handle removing an admin
  const handleRemoveAdmin = async (userId) => {
    try {
      // Don't allow removing yourself
      if (userId === user?.id) {
        setSnackbar({
          open: true,
          message: 'You cannot remove yourself as admin',
          severity: 'error'
        });
        return;
      }
      
      // Remove user as admin
      const success = await removeUserAsAdmin(userId, user);
      
      if (success) {
        // Update admins list
        setAdmins(admins.filter(id => id !== userId));
        
        setSnackbar({
          open: true,
          message: 'Admin role removed successfully',
          severity: 'success'
        });
      } else {
        throw new Error('Failed to remove admin role');
      }
    } catch (error) {
      console.error('Error removing admin:', error);
      setSnackbar({
        open: true,
        message: 'Error removing admin role',
        severity: 'error'
      });
    }
  };

  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        User Role Management
      </Typography>

      {/* Admin Users Table */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5">Admin Users</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PersonAddIcon />}
            onClick={() => setOpenDialog(true)}
          >
            Add Admin
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .filter(user => admins.includes(user.id))
                .map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveAdmin(user.id)}
                        disabled={user.id === user?.id}
                        title={user.id === user?.id ? "You cannot remove yourself" : "Remove admin role"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {admins.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No admin users found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Add Admin Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Admin User</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, minWidth: 400 }}>
            <TextField
              fullWidth
              label="User ID"
              value={newAdminEmail}
              onChange={(e) => setNewAdminEmail(e.target.value)}
              placeholder="Enter user ID (UUID format)"
              helperText="Enter the UUID of the user you want to add as admin"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleAddAdmin} 
            variant="contained" 
            color="primary"
            disabled={!newAdminEmail}
          >
            Add Admin
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserRoleManagement;
