import { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';

export function useTimeCards(userId) {
  const [timecards, setTimecards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTimeCards = async () => {
      try {
        // Ensure userId is a valid UUID
        if (!userId || typeof userId !== 'string' || !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(userId)) {
          throw new Error('Invalid user ID. Expected a valid UUID.');
        }

        const { data, error: fetchError } = await supabase
          .from('time_card') // Use the correct table name
          .select('*')
          .eq('user_id', userId);

        if (fetchError) throw fetchError;
        setTimecards(data || []);
      } catch (error) {
        console.error('Error fetching time cards:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchTimeCards();
    }
  }, [userId]);

  const createTimeCard = async (timecardData) => {
    try {
      // Ensure userId is a valid UUID
      if (!userId || typeof userId !== 'string' || !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(userId)) {
        throw new Error('Invalid user ID. Expected a valid UUID.');
      }

      // Include all fields that exist in the database
      const timeCardEntry = {
        user_id: userId,
        event_name: timecardData.event_name,
        event_date: timecardData.event_date,
        utc_start: timecardData.utc_start,
        utc_end: timecardData.utc_end,
        event_duration_ms: timecardData.event_duration_ms,
        latitude: timecardData.latitude,
        longitude: timecardData.longitude,
        location_permission: timecardData.location_permission,
        utc_saved: Date.now(),
        needs_review: !timecardData.latitude || !timecardData.longitude || 
                      timecardData.location_permission === 'denied' || 
                      timecardData.location_permission === 'unsupported',
        // New fields from time_card_update.sql
        work_time_before_break: timecardData.work_time_before_break || null,
        total_work_time: timecardData.total_work_time || null,
        work_sessions: timecardData.work_sessions ? JSON.stringify(timecardData.work_sessions) : JSON.stringify([]),
        is_break: timecardData.is_break || false,
        address: timecardData.address || null
      };

      // Save work time data to local storage
      try {
        const workTimeKey = `workTime_${userId}_${Date.now()}`;
        const workTimeData = {
          userId,
          eventName: timecardData.event_name,
          timestamp: Date.now(),
          totalWorkTime: timecardData.total_work_time || 0,
          workTimeBeforeBreak: timecardData.work_time_before_break || 0,
          workSessions: timecardData.work_sessions || JSON.stringify([]),
          isBreak: timecardData.is_break || false,
          address: timecardData.address || ''
        };
        localStorage.setItem(workTimeKey, JSON.stringify(workTimeData));
        console.log('Work time data saved to local storage:', workTimeData);
      } catch (error) {
        console.error('Error saving work time data to local storage:', error);
      }

      // Insert the time card entry with all fields
      const { data, error: insertError } = await supabase
        .from('time_card')
        .insert([timeCardEntry])
        .single();

      if (insertError) throw insertError;
      
      // Update the local state with the new timecard
      const newTimecard = data || timeCardEntry;
      setTimecards((prev) => [...prev, newTimecard]);
      
      return newTimecard;
    } catch (error) {
      console.error('Error creating time card:', error);
      setError(error);
      throw error;
    }
  };

  return { timecards, loading, error, createTimeCard };
}
