import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';

/**
 * Create a new time track entry.
 * @param {Object} timeTrackData - The time track data.
 * @param {string} timeTrackData.userId - The ID of the user (UUID from auth.users).
 * @param {string} timeTrackData.status - The status of the time entry (clock-in, clock-out, break, lunch).
 * @param {number} timeTrackData.timestamp - The timestamp of the entry (milliseconds).
 * @param {string} timeTrackData.timeEntry - The time in 12-hour format (e.g., '7:15 AM').
 * @param {number} [timeTrackData.duration] - The duration in minutes (for breaks).
 * @param {string} [timeTrackData.breakType] - The type of break (break, lunch, rest, meal).
 * @param {number} [timeTrackData.workTimeBeforeBreak] - The time worked before taking a break (milliseconds).
 * @param {number} [timeTrackData.totalWorkTime] - The total work time excluding breaks (milliseconds).
 * @param {Array} [timeTrackData.workSessions] - The work sessions with start, end, and duration.
 * @param {number} [timeTrackData.latitude] - The latitude of the location.
 * @param {number} [timeTrackData.longitude] - The longitude of the location.
 * @param {string} [timeTrackData.address] - The address of the location.
 * @param {string} [timeTrackData.early_end_info] - JSON string containing information about early break endings.
 * @param {string} [timeTrackData.description] - Additional information about the entry (e.g., auto-clockout reason).
 * @param {boolean} [timeTrackData.synced=true] - Whether the entry has been synced with the server.
 * @param {string} [timeTrackData.localId] - The local ID for offline entries.
 * @param {string} [timeTrackData.state='WA'] - Two-letter state code (e.g., 'WA' for Washington) indicating which state's labor laws apply.
 * @param {string} [timeTrackData.projectId] - Optional project ID to link this time entry to a project.
 * @param {string} [timeTrackData.device_id] - The device ID hash for tracking which device was used.
 * @returns {Promise<Object>} - A promise that resolves with the created time track entry.
 */
export async function createTimeTrackEntry(timeTrackData) {
  const {
    userId,
    status,
    timestamp,
    timeEntry,
    duration,
    breakType,
    workTimeBeforeBreak,
    totalWorkTime,
    workSessions,
    latitude,
    longitude,
    address, // Now storing the address in the database
    early_end_info, // Information about early break endings
    synced = true,
    localId,
    state = 'WA', // Default to Washington state
    projectId,
    device_id, // Device ID for tracking which device was used
    description // Description field for additional information (e.g., auto-clockout reason)
  } = timeTrackData;

  const entry = {
    user_id: userId,
    status,
    timestamp,
    time_entry: timeEntry,
    duration,
    break_type: breakType,
    work_time_before_break: workTimeBeforeBreak,
    total_work_time: totalWorkTime,
    work_sessions: workSessions ? JSON.stringify(workSessions) : null,
    latitude,
    longitude,
    address, // Now storing the address in the database
    early_end_info, // Store early break end information
    description, // Store description (e.g., auto-clockout reason)
    synced,
    last_sync_timestamp: synced ? Date.now() : null,
    local_id: localId || uuidv4(),
    state, // Store the state for break rule compliance
    project_id: projectId, // Store the project ID if provided
    device_id // Store the device ID for tracking which device was used
  };

  // Check if we're online before attempting to save to Supabase
  if (!isOnline()) {
    console.log('Device is offline, storing entry locally');
    storeOfflineTimeTrackEntry(entry);
    return { ...entry, id: entry.local_id, address }; // Return the entry with the local ID as the ID and include address for display
  }

  try {
    const { data, error } = await supabase
      .from('time_track')
      .insert([entry])
      .select()
      .single();

    if (error) {
      // If the error is related to the table not existing, we can still use the component in offline mode
      if (error.message && (
          error.message.includes('relation "time_track" does not exist') || 
          error.message.includes('does not exist in schema')
        )) {
        console.log('time_track table does not exist, storing entry locally');
        storeOfflineTimeTrackEntry(entry);
        return { ...entry, id: entry.local_id, address }; // Return the entry with the local ID as the ID and include full address for storage and display
      }
      
      throw error;
    }
    
    return { ...data, address }; // Include full address for storage and display
  } catch (error) {
    console.error('Error creating time track entry:', error);
    // Store in local storage for offline support
    storeOfflineTimeTrackEntry(entry);
    
    // Return the entry with the local ID as the ID so the UI can still update
    return { ...entry, id: entry.local_id, address }; // Include full address for storage and display
  }
}

// Cache for time track entries to reduce API calls
const entriesCache = new Map();
// Rate limiting - track last query time for each date range
const lastQueryTimes = new Map();
// Minimum time between queries for the same date range (in milliseconds)
const MIN_QUERY_INTERVAL = 2000; // 2 seconds

/**
 * Get time track entries for a user.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @param {Object} [options] - Options for filtering the entries.
 * @param {number} [options.startDate] - The start date for filtering entries (milliseconds).
 * @param {number} [options.endDate] - The end date for filtering entries (milliseconds).
 * @param {string} [options.status] - The status for filtering entries.
 * @returns {Promise<Array>} - A promise that resolves with the time track entries.
 */
export async function getTimeTrackEntries(userId, options = {}) {
  const { startDate, endDate, status } = options;

  // Check if userId is undefined or null
  if (!userId) {
    console.error('Error getting time track entries: userId is undefined or null');
    return [];
  }

  // Create a cache key based on the query parameters
  const cacheKey = `${userId}-${startDate || 0}-${endDate || 0}-${status || ''}`;
  
  // Check if we're offline before attempting to fetch from Supabase
  if (!isOnline()) {
    const offlineEntries = getOfflineTimeTrackEntries(userId, { startDate, endDate });
    return offlineEntries;
  }

  // Check if we have a cached result that's less than 30 seconds old
  if (entriesCache.has(cacheKey)) {
    const cachedData = entriesCache.get(cacheKey);
    const now = Date.now();
      // Use cache if it's less than 30 seconds old
      if (now - cachedData.timestamp < 30000) {
        // Only log this once every 10 seconds to reduce console spam
        if (now - (window._lastEntriesCacheLogTime || 0) > 10000) {
          console.log('Using cached time track entries');
          window._lastEntriesCacheLogTime = now;
        }
        return cachedData.entries;
      }
  }
  
  // Check rate limiting
  const now = Date.now();
  if (lastQueryTimes.has(cacheKey)) {
    const lastQueryTime = lastQueryTimes.get(cacheKey);
    const timeSinceLastQuery = now - lastQueryTime;
    
    if (timeSinceLastQuery < MIN_QUERY_INTERVAL) {
      // Only log this once every 10 seconds to reduce console spam
      if (now - (window._lastEntriesRateLimitLogTime || 0) > 10000) {
        //console.log(`Rate limiting: Too many requests for the same date range. Using cached data.`);
        window._lastEntriesRateLimitLogTime = now;
      }
      
      // If we have cached data, return it
      if (entriesCache.has(cacheKey)) {
        return entriesCache.get(cacheKey).entries;
      }
      
      // If no cached data, return offline entries
      return getOfflineTimeTrackEntries(userId, { startDate, endDate });
    }
  }
  
  // Update last query time
  lastQueryTimes.set(cacheKey, now);

  try {
    let query = supabase
      .from('time_track')
      .select('*')
      .eq('user_id', userId)
      .order('timestamp', { ascending: false });

    if (startDate) {
      query = query.gte('timestamp', startDate);
    }

    if (endDate) {
      query = query.lte('timestamp', endDate);
    }

    if (status) {
      query = query.eq('status', status);
    }

    const { data, error } = await query;

    if (error) {
      console.error('Supabase query error:', error);
      throw error;
    }
    
    // Merge with offline entries
    const offlineEntries = getOfflineTimeTrackEntries(userId, { startDate, endDate });
    const mergedEntries = [...(data || []), ...offlineEntries];
    
    // Sort by timestamp in descending order
    const sortedEntries = mergedEntries.sort((a, b) => b.timestamp - a.timestamp);
    
    // Cache the result
    entriesCache.set(cacheKey, {
      entries: sortedEntries,
      timestamp: now
    });
    
    return sortedEntries;
  } catch (error) {
    console.error('Error getting time track entries:', error);
    
    // Return offline entries if online fetch fails
    const offlineEntries = getOfflineTimeTrackEntries(userId, { startDate, endDate });
    return offlineEntries;
  }
}

/**
 * Update a time track entry.
 * @param {string} entryId - The ID of the time track entry.
 * @param {Object} updateData - The data to update.
 * @returns {Promise<Object>} - A promise that resolves with the updated time track entry.
 */
export async function updateTimeTrackEntry(entryId, updateData) {
  try {
    const { data, error } = await supabase
      .from('time_track')
      .update(updateData)
      .eq('id', entryId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating time track entry:', error);
    
    // Update in local storage for offline support
    updateOfflineTimeTrackEntry(entryId, updateData);
    throw error;
  }
}

/**
 * Get break rules for a specific state.
 * @param {string} state - Two-letter state code.
 * @returns {Promise<Array>} - A promise that resolves with the break rules.
 */
export async function getBreakRules(state) {
  try {
    if (!isOnline()) {
      console.log('Device is offline, cannot fetch break rules');
      return [];
    }
    
    // Use a more direct query approach
    const query = supabase
      .from('break_rules')
      .select('*')
      .eq('state', state)
      .order('min_work_hours', { ascending: true });
    
    const { data, error } = await query;
    
    if (error) {
      console.error('Error fetching break rules:', error);
      
      // Only return hardcoded WA break rules as fallback if there's an actual error
      if (state === 'WA') {
        return [
          {
            id: "9a35513d-a372-4793-9b16-638d809c2c61",
            rule_type: "meal",
            min_work_hours: 2.00,
            max_work_hours: 5.00,
            duration_minutes: 30,
            paid: false,
            description: "Meal period of at least 30 minutes which commences no less than 2 hours nor more than 5 hours from the beginning of the shift.",
            created_at: "2025-03-04 05:26:34.591855+00",
            updated_at: "2025-03-12 18:28:37.203345+00",
            state: "WA"
          },
          {
            id: "dc11a2b5-0239-4e7a-b935-a9da95128aa0",
            rule_type: "overtime_meal",
            min_work_hours: 3.00,
            max_work_hours: null,
            duration_minutes: 30,
            paid: false,
            description: "Employees working 3 or more hours longer than a normal work day shall be allowed at least one 30-minute meal period prior to or during the overtime period.",
            created_at: "2025-03-04 05:26:34.591855+00",
            updated_at: "2025-03-12 18:28:37.203345+00",
            state: "WA"
          },
          {
            id: "791afd08-0b5f-4d6e-acfe-bd8373f53db6",
            rule_type: "rest",
            min_work_hours: 4.00,
            max_work_hours: null,
            duration_minutes: 10,
            paid: true,
            description: "Rest period of not less than 10 minutes for each 4 hours of working time, scheduled as near as possible to the midpoint of the work period.",
            created_at: "2025-03-04 05:26:34.591855+00",
            updated_at: "2025-03-12 18:28:37.203345+00",
            state: "WA"
          }
        ];
      } else {
        // For non-WA states with errors, return an empty array
        console.log(`No fallback rules available for state: ${state}`);
        return [];
      }
    }
    
    if (!data || data.length === 0) {
      console.log(`No break rules found for state: ${state}`);
      
      // Only use hardcoded WA break rules as fallback for WA state
      if (state === 'WA') {
        console.log('Using hardcoded WA break rules as fallback for WA state');
        return [
          {
            id: "9a35513d-a372-4793-9b16-638d809c2c61",
            rule_type: "meal",
            min_work_hours: 2.00,
            max_work_hours: 5.00,
            duration_minutes: 30,
            paid: false,
            description: "Meal period of at least 30 minutes which commences no less than 2 hours nor more than 5 hours from the beginning of the shift.",
            created_at: "2025-03-04 05:26:34.591855+00",
            updated_at: "2025-03-12 18:28:37.203345+00",
            state: "WA"
          },
          {
            id: "dc11a2b5-0239-4e7a-b935-a9da95128aa0",
            rule_type: "overtime_meal",
            min_work_hours: 3.00,
            max_work_hours: null,
            duration_minutes: 30,
            paid: false,
            description: "Employees working 3 or more hours longer than a normal work day shall be allowed at least one 30-minute meal period prior to or during the overtime period.",
            created_at: "2025-03-04 05:26:34.591855+00",
            updated_at: "2025-03-12 18:28:37.203345+00",
            state: "WA"
          },
          {
            id: "791afd08-0b5f-4d6e-acfe-bd8373f53db6",
            rule_type: "rest",
            min_work_hours: 4.00,
            max_work_hours: null,
            duration_minutes: 10,
            paid: true,
            description: "Rest period of not less than 10 minutes for each 4 hours of working time, scheduled as near as possible to the midpoint of the work period.",
            created_at: "2025-03-04 05:26:34.591855+00",
            updated_at: "2025-03-12 18:28:37.203345+00",
            state: "WA"
          }
        ];
      } else {
        // For non-WA states with no data, return an empty array
        console.log(`No break rules found for state: ${state}, returning empty array`);
        return [];
      }
    }
    
    console.log(`Found ${data.length} break rules for state: ${state}`);
    
    // Convert string min_work_hours and max_work_hours to numbers
    const processedData = data.map(rule => ({
      ...rule,
      min_work_hours: typeof rule.min_work_hours === 'string' ? parseFloat(rule.min_work_hours) : rule.min_work_hours,
      max_work_hours: typeof rule.max_work_hours === 'string' ? parseFloat(rule.max_work_hours) : rule.max_work_hours
    }));
    
    return processedData;
  } catch (error) {
    console.error('Error in getBreakRules:', error);
    
    // Only use hardcoded WA break rules as fallback for WA state
    if (state === 'WA') {
      console.log('Using hardcoded WA break rules as fallback due to error for WA state');
      return [
        {
          id: "9a35513d-a372-4793-9b16-638d809c2c61",
          rule_type: "meal",
          min_work_hours: 2.00,
          max_work_hours: 5.00,
          duration_minutes: 30,
          paid: false,
          description: "Meal period of at least 30 minutes which commences no less than 2 hours nor more than 5 hours from the beginning of the shift.",
          created_at: "2025-03-04 05:26:34.591855+00",
          updated_at: "2025-03-12 18:28:37.203345+00",
          state: "WA"
        },
        {
          id: "dc11a2b5-0239-4e7a-b935-a9da95128aa0",
          rule_type: "overtime_meal",
          min_work_hours: 3.00,
          max_work_hours: null,
          duration_minutes: 30,
          paid: false,
          description: "Employees working 3 or more hours longer than a normal work day shall be allowed at least one 30-minute meal period prior to or during the overtime period.",
          created_at: "2025-03-04 05:26:34.591855+00",
          updated_at: "2025-03-12 18:28:37.203345+00",
          state: "WA"
        },
        {
          id: "791afd08-0b5f-4d6e-acfe-bd8373f53db6",
          rule_type: "rest",
          min_work_hours: 4.00,
          max_work_hours: null,
          duration_minutes: 10,
          paid: true,
          description: "Rest period of not less than 10 minutes for each 4 hours of working time, scheduled as near as possible to the midpoint of the work period.",
          created_at: "2025-03-04 05:26:34.591855+00",
          updated_at: "2025-03-12 18:28:37.203345+00",
          state: "WA"
        }
      ];
    } else {
      // For non-WA states with errors, return an empty array
      console.log(`No fallback rules available for state: ${state}, returning empty array`);
      return [];
    }
  }
}
/**
 * Sync offline time track entries with the server.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<Array>} - A promise that resolves with the synced entries.
 */
export async function syncOfflineTimeTrackEntries(userId) {
  try {
    // Get offline entries for the user
    const offlineEntries = getOfflineTimeTrackEntries(userId);
    
    if (!offlineEntries.length) {
      console.log('No offline entries to sync');
      return [];
    }
    
    console.log(`Syncing ${offlineEntries.length} offline entries for user ${userId}`);
    
    const syncedEntries = [];
    const failedEntries = [];
    
    for (const entry of offlineEntries) {
      try {
        // Skip null or undefined entries
        if (!entry) {
          console.warn('Skipping null or undefined entry during sync');
          continue;
        }
        
        if (entry._deleted) {
          // If the entry was deleted offline, delete it from the server
          if (entry.id) {
            await supabase
              .from('time_track')
              .delete()
              .eq('id', entry.id);
          }
          // Consider this entry synced even if it was just deleted locally
          syncedEntries.push({ id: entry.id, local_id: entry.local_id });
        } else if (entry.id) {
          // If the entry has an ID, update it
          // Remove any properties that aren't part of the database schema
          const { _fromBackup, ...cleanEntry } = entry;
          
          const { data, error } = await supabase
            .from('time_track')
            .update({
              ...cleanEntry,
              synced: true,
              last_sync_timestamp: Date.now()
            })
            .eq('id', entry.id)
            .select()
            .single();
            
          if (error) {
            throw error;
          }
          
          if (data) {
            syncedEntries.push(data);
          }
        } else {
          // If the entry doesn't have an ID, create it
          // Remove any properties that aren't part of the database schema
          const { _fromBackup, break_id, ...cleanEntry } = entry;
          
          const { data, error } = await supabase
            .from('time_track')
            .insert([{
              ...cleanEntry,
              synced: true,
              last_sync_timestamp: Date.now()
            }])
            .select()
            .single();
            
          if (error) {
            throw error;
          }
          
          if (data) {
            syncedEntries.push(data);
          }
        }
      } catch (error) {
        console.error('Error syncing offline time track entry:', error);
        failedEntries.push(entry);
      }
    }
    
    // If we successfully synced all entries, clear all offline entries for this user
    if (syncedEntries.length > 0 && failedEntries.length === 0) {
      // Clear all offline entries for this user
      clearAllOfflineTimeTrackEntries(userId);
    } else {
      // Only clear the synced entries
      clearSyncedOfflineTimeTrackEntries(userId, syncedEntries);
    }
    
    return syncedEntries;
  } catch (error) {
    console.error('Error in syncOfflineTimeTrackEntries:', error);
    return [];
  }
}

/**
 * Get time track settings for a user.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @returns {Promise<Object>} - A promise that resolves with the time track settings.
 */
export async function getTimeTrackSettings(userId) {
  try {
    const { data, error } = await supabase
      .from('time_track_settings')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (error) {
      // If no settings found, create default settings
      if (error.code === 'PGRST116') {
        return createTimeTrackSettings(userId);
      }
      throw error;
    }
    
    return data;
  } catch (error) {
    console.error('Error getting time track settings:', error);
    
    // Return default settings if online fetch fails
    return {
      user_id: userId,
      default_start_time: '07:00:00',
      default_end_time: '15:30:00',
      regular_work_hours: 8.0,
      auto_clock_out: true
    };
  }
}

/**
 * Create time track settings for a user.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @param {Object} [settings] - The settings to create.
 * @returns {Promise<Object>} - A promise that resolves with the created time track settings.
 */
export async function createTimeTrackSettings(userId, settings = {}) {
  const defaultSettings = {
    user_id: userId,
    default_start_time: '07:00:00',
    default_end_time: '15:30:00',
    regular_work_hours: 8.0,
    auto_clock_out: true,
    ...settings
  };

  try {
    const { data, error } = await supabase
      .from('time_track_settings')
      .insert([defaultSettings])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating time track settings:', error);
    return defaultSettings;
  }
}

/**
 * Update time track settings for a user.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @param {Object} settings - The settings to update.
 * @returns {Promise<Object>} - A promise that resolves with the updated time track settings.
 */
export async function updateTimeTrackSettings(userId, settings) {
  try {
    const { data, error } = await supabase
      .from('time_track_settings')
      .update(settings)
      .eq('user_id', userId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating time track settings:', error);
    throw error;
  }
}

// The getBreakRules function is already defined above

// Cache for most recent clock-in timestamps
const clockInTimestampCache = new Map();
// Rate limiting for clock-in timestamp queries
const lastClockInTimestampQueryTimes = new Map();
// Minimum time between clock-in timestamp queries (in milliseconds)
const MIN_CLOCK_IN_QUERY_INTERVAL = 5000; // 5 seconds

/**
 * Get the most recent clock-in timestamp for a user.
 * This is a simplified version that just returns the timestamp.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<number|null>} - A promise that resolves with the timestamp of the most recent clock-in or null if not found.
 */
export async function getMostRecentClockInTimestamp(userId) {
  try {
    // Check if userId is undefined or null
    if (!userId) {
      console.error('Error getting most recent clock-in: userId is undefined or null');
      return null;
    }
    
    if (!isOnline()) {
      console.log('Device is offline, cannot fetch most recent clock-in timestamp');
      return null;
    }

    // Check if we have a cached timestamp that's less than 1 minute old
    if (clockInTimestampCache.has(userId)) {
      const cachedData = clockInTimestampCache.get(userId);
      const now = Date.now();
      // Use cache if it's less than 1 minute old
      if (now - cachedData.timestamp < 60000) {
        // Only log this once every 10 seconds to reduce console spam
        if (now - (window._lastTimestampCacheLogTime || 0) > 10000) {
          console.log('Using cached clock-in timestamp');
          window._lastTimestampCacheLogTime = now;
        }
        return cachedData.value;
      }
    }
    
    // Check rate limiting
    const now = Date.now();
    if (lastClockInTimestampQueryTimes.has(userId)) {
      const lastQueryTime = lastClockInTimestampQueryTimes.get(userId);
      const timeSinceLastQuery = now - lastQueryTime;
      
      if (timeSinceLastQuery < MIN_CLOCK_IN_QUERY_INTERVAL) {
        // Only log this once every 10 seconds to reduce console spam
        if (now - (window._lastTimestampRateLimitLogTime || 0) > 10000) {
          console.log(`Rate limiting: Too many clock-in timestamp requests. Using cached data.`);
          window._lastTimestampRateLimitLogTime = now;
        }
        
        // If we have cached data, return it
        if (clockInTimestampCache.has(userId)) {
          return clockInTimestampCache.get(userId).value;
        }
        
        // If no cached data, return null
        return null;
      }
    }
    
    // Update last query time
    lastClockInTimestampQueryTimes.set(userId, now);
    
    // This query finds the most recent clock-in entry
    const { data, error } = await supabase
      .from('time_track')
      .select('timestamp')
      .eq('user_id', userId)
      .eq('status', 'clock-in')
      .order('timestamp', { ascending: false })
      .limit(1);

    if (error) {
      console.error('Error getting most recent clock-in:', error);
      return null;
    }

    if (!data || data.length === 0) {
      console.log('No clock-in entry found');
      return null;
    }

    const clockInTimestamp = data[0].timestamp;
    
    // Cache the result
    clockInTimestampCache.set(userId, {
      value: clockInTimestamp,
      timestamp: now
    });
    
    return clockInTimestamp;
  } catch (error) {
    console.error('Error in getMostRecentClockInTimestamp:', error);
    
    // If we have cached data, return it as a fallback
    if (clockInTimestampCache.has(userId)) {
      return clockInTimestampCache.get(userId).value;
    }
    
    return null;
  }
}

/**
 * Get address from coordinates using OpenStreetMap Nominatim API.
 * @param {number} latitude - The latitude.
 * @param {number} longitude - The longitude.
 * @returns {Promise<string>} - A promise that resolves with the address.
 */
export async function getAddressFromCoordinates(latitude, longitude) {
  try {
    // Use OpenStreetMap Nominatim API to get address
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`);
    const data = await response.json();
    
    // Format the address
    const address = data.display_name || 'Unknown location';
    return address;
  } catch (error) {
    console.error('Error getting address from coordinates:', error);
    return 'Unknown location';
  }
}

// Helper functions for offline support

/**
 * Store a time track entry in local storage for offline support.
 * @param {Object} entry - The time track entry to store.
 */
function storeOfflineTimeTrackEntry(entry) {
  const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  offlineEntries.push({
    ...entry,
    synced: false
  });
  localStorage.setItem('offlineTimeTrackEntries', JSON.stringify(offlineEntries));
}

/**
 * Get offline time track entries for a user.
 * @param {string} userId - The ID of the user.
 * @param {Object} [options] - Options for filtering the entries.
 * @param {number} [options.startDate] - The start date for filtering entries (milliseconds).
 * @param {number} [options.endDate] - The end date for filtering entries (milliseconds).
 * @returns {Array} - The offline time track entries.
 */
function getOfflineTimeTrackEntries(userId, options = {}) {
  const { startDate, endDate } = options;
  
  // Get entries from both storage locations
  const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  
  // We'll skip the automatic creation of clock-in entries from backup state
  // This was causing duplicate entries when the app loads
  // Instead, we'll rely on the useTimeTrack hook to handle the clock state properly
  
  return offlineEntries.filter(entry => {
    // Filter by user ID and not deleted
    const userMatch = entry.user_id === userId && !entry._deleted;
    
    // If no date filters, just return user matches
    if (!startDate && !endDate) {
      return userMatch;
    }
    
    // Apply date filters if provided
    let dateMatch = true;
    if (startDate && entry.timestamp < startDate) {
      dateMatch = false;
    }
    if (endDate && entry.timestamp > endDate) {
      dateMatch = false;
    }
    
    return userMatch && dateMatch;
  });
}

/**
 * Update an offline time track entry.
 * @param {string} entryId - The ID of the time track entry.
 * @param {Object} updateData - The data to update.
 */
function updateOfflineTimeTrackEntry(entryId, updateData) {
  const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  const index = offlineEntries.findIndex(entry => entry.id === entryId || entry.local_id === entryId);
  
  if (index !== -1) {
    offlineEntries[index] = {
      ...offlineEntries[index],
      ...updateData,
      synced: false
    };
    localStorage.setItem('offlineTimeTrackEntries', JSON.stringify(offlineEntries));
  }
}

/**
 * Mark an offline time track entry as deleted.
 * @param {string} entryId - The ID of the time track entry.
 */
function markOfflineTimeTrackEntryAsDeleted(entryId) {
  const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  const index = offlineEntries.findIndex(entry => entry.id === entryId || entry.local_id === entryId);
  
  if (index !== -1) {
    offlineEntries[index] = {
      ...offlineEntries[index],
      _deleted: true,
      synced: false
    };
    localStorage.setItem('offlineTimeTrackEntries', JSON.stringify(offlineEntries));
  }
}

/**
 * Clear synced offline time track entries.
 * @param {string} userId - The ID of the user.
 * @param {Array} syncedEntries - The synced entries.
 */
function clearSyncedOfflineTimeTrackEntries(userId, syncedEntries) {
  const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  
  // Filter out any null or undefined entries before mapping
  const validSyncedEntries = syncedEntries.filter(entry => entry != null);
  const syncedIds = validSyncedEntries.map(entry => entry.id || entry.local_id);
  
  const remainingEntries = offlineEntries.filter(entry => {
    // Keep entries that are not synced or belong to other users
    return entry.user_id !== userId || !syncedIds.includes(entry.id || entry.local_id);
  });
  
  localStorage.setItem('offlineTimeTrackEntries', JSON.stringify(remainingEntries));
}

/**
 * Clear all offline time track entries for a user.
 * @param {string} userId - The ID of the user.
 */
function clearAllOfflineTimeTrackEntries(userId) {
  const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  
  const remainingEntries = offlineEntries.filter(entry => {
    // Keep entries that belong to other users
    return entry.user_id !== userId;
  });
  
  localStorage.setItem('offlineTimeTrackEntries', JSON.stringify(remainingEntries));
}

/**
 * Check if the device is online.
 * @returns {boolean} - Whether the device is online.
 */
export function isOnline() {
  return navigator.onLine;
}

// Cache for most recent clock-in entries
const clockInEntryCache = new Map();
// Rate limiting for clock-in entry queries
const lastClockInEntryQueryTimes = new Map();
// Minimum time between clock-in entry queries (in milliseconds)
const MIN_CLOCK_IN_ENTRY_QUERY_INTERVAL = 5000; // 5 seconds

/**
 * Get the most recent clock-in entry that doesn't have a corresponding clock-out.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<Object|null>} - A promise that resolves with the most recent clock-in entry or null if not found.
 */
export async function getMostRecentClockIn(userId) {
  try {
    if (!isOnline()) {
      return null;
    }

    // Check if we have a cached entry that's less than 1 minute old
    if (clockInEntryCache.has(userId)) {
      const cachedData = clockInEntryCache.get(userId);
      const now = Date.now();
      // Use cache if it's less than 1 minute old
      if (now - cachedData.timestamp < 60000) {
        // Only log this once every 10 seconds to reduce console spam
        if (now - (window._lastEntryCacheLogTime || 0) > 10000) {
          console.log('Using cached clock-in entry');
          window._lastEntryCacheLogTime = now;
        }
        return cachedData.value;
      }
    }
    
    // Check rate limiting
    const now = Date.now();
    if (lastClockInEntryQueryTimes.has(userId)) {
      const lastQueryTime = lastClockInEntryQueryTimes.get(userId);
      const timeSinceLastQuery = now - lastQueryTime;
      
      if (timeSinceLastQuery < MIN_CLOCK_IN_ENTRY_QUERY_INTERVAL) {
        // Only log this once every 10 seconds to reduce console spam
        if (now - (window._lastEntryRateLimitLogTime || 0) > 10000) {
          console.log(`Rate limiting: Too many clock-in entry requests. Using cached data.`);
          window._lastEntryRateLimitLogTime = now;
        }
        
        // If we have cached data, return it
        if (clockInEntryCache.has(userId)) {
          return clockInEntryCache.get(userId).value;
        }
        
        // If no cached data, return null
        return null;
      }
    }
    
    // Update last query time
    lastClockInEntryQueryTimes.set(userId, now);
    
    // Use the timestamp function which is already cached and rate-limited
    const timestamp = await getMostRecentClockInTimestamp(userId);
    
    // If no timestamp, no clock-in entry
    if (!timestamp) {
      return null;
    }
    
    // This query finds the most recent clock-in entry
    const { data, error } = await supabase
      .from('time_track')
      .select('*')
      .eq('user_id', userId)
      .eq('status', 'clock-in')
      .eq('timestamp', timestamp)
      .single();

    if (error) {
      console.error('Error fetching most recent clock-in:', error);
      return null;
    }

    if (!data) {
      return null;
    }

    // Cache the result
    clockInEntryCache.set(userId, {
      value: data,
      timestamp: now
    });
    
    return data;
  } catch (error) {
    console.error('Error in getMostRecentClockIn:', error);
    
    // If we have cached data, return it as a fallback
    if (clockInEntryCache.has(userId)) {
      return clockInEntryCache.get(userId).value;
    }
    
    return null;
  }
}

// This function is now defined above, so we're removing the duplicate

/**
 * Create a break-end entry in local storage.
 * This is used when a break automatically ends while the app is offline.
 * @param {string} userId - The ID of the user.
 * @param {string} breakType - The type of break (break, lunch, rest, meal).
 * @param {Object} locationData - The location data.
 * @param {string} [earlyEndInfo] - Information about early break endings.
 * @param {string} [breakId] - The ID of the break entry to link this end entry to.
 * @returns {Object} - The created break-end entry.
 */
export function createOfflineBreakEndEntry(userId, breakType, locationData = null, earlyEndInfo = null, breakId = null) {
  const now = new Date();
  const timeEntry = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
  
  const offlineEndEntry = {
    local_id: `local_${Date.now()}`,
    user_id: userId,
    status: 'break-end',
    timestamp: now.getTime(),
    time_entry: timeEntry,
    break_type: breakType,
    latitude: locationData?.latitude,
    longitude: locationData?.longitude,
    address: locationData?.address, // Include address for storage in the database
    early_end_info: earlyEndInfo, // Include early end info if provided
    synced: false
  };
  
  // Store in local storage
  const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  offlineEntries.push(offlineEndEntry);
  localStorage.setItem('offlineTimeTrackEntries', JSON.stringify(offlineEntries));
  
  return offlineEndEntry;
}

/**
 * Get auto-clockout settings for a project.
 * @param {string} [projectId] - The ID of the project. If null, returns global default settings.
 * @returns {Promise<Object>} - A promise that resolves with the auto-clockout settings.
 */
export async function getAutoClockoutSettings(projectId = null) {
  try {
    if (!isOnline()) {
      //console.log('Device is offline, using default auto-clockout settings');
      return {
        max_work_hours: 24,
        max_work_minutes: 24 * 60, // 24 hours in minutes
        check_interval_minutes: 1,
        enabled: true
      };
    }

    //console.log(`Fetching auto-clockout settings for project: ${projectId || 'global default'}`);
    
    let query = supabase
      .from('project_auto_clockout_settings')
      .select('*');
    
    if (projectId) {
      // Try to get project-specific settings first
      const { data: projectSettings, error: projectError } = await query
        .eq('project_id', projectId)
        .single();
      
      if (!projectError && projectSettings) {
        //console.log('Found project-specific auto-clockout settings:', projectSettings);
        
        // If max_work_minutes is not set, calculate it from max_work_hours
        if (projectSettings.max_work_minutes === null || projectSettings.max_work_minutes === undefined) {
          projectSettings.max_work_minutes = projectSettings.max_work_hours * 60;
        }
        
        return projectSettings;
      }
    }
    
    // Fall back to global default settings (null project_id)
    const { data: globalSettings, error: globalError } = await query
      .is('project_id', null)
      .single();
    
    if (globalError) {
      console.error('Error fetching global auto-clockout settings:', globalError);
      // Return default settings if there's an error
      return {
        max_work_hours: 24,
        max_work_minutes: 24 * 60, // 24 hours in minutes
        check_interval_minutes: 1,
        enabled: true
      };
    }
    
    // If max_work_minutes is not set, calculate it from max_work_hours
    if (globalSettings.max_work_minutes === null || globalSettings.max_work_minutes === undefined) {
      globalSettings.max_work_minutes = globalSettings.max_work_hours * 60;
    }
    
    //console.log('Using global default auto-clockout settings:', globalSettings);
    return globalSettings;
  } catch (error) {
    console.error('Error in getAutoClockoutSettings:', error);
    // Return default settings if there's an error
    return {
      max_work_hours: 24,
      max_work_minutes: 24 * 60, // 24 hours in minutes
      check_interval_minutes: 1,
      enabled: true
    };
  }
}

/**
 * Check if a user should be automatically clocked out based on their clock-in time.
 * @param {string} userId - The ID of the user.
 * @param {string} [projectId] - The ID of the project. If null, uses global default settings.
 * @returns {Promise<boolean>} - A promise that resolves with true if the user should be clocked out.
 */
export async function shouldAutoClockout(userId, projectId = null) {
  try {
    // Get the most recent clock-in timestamp
    const clockInTimestamp = await getMostRecentClockInTimestamp(userId);
    
    if (!clockInTimestamp) {
      console.log('No active clock-in found, no need for auto-clockout');
      return false;
    }
    
    // Check if there's already been an auto-clockout since the last clock-in
    // Get all entries since the last clock-in
    const entries = await getTimeTrackEntries(userId, {
      startDate: clockInTimestamp,
      endDate: Date.now()
    });
    
    // Look for any clock-out entries with auto-clockout in the description
    const hasAutoClockout = entries.some(entry => 
      entry.status === 'clock-out' && 
      entry.description && 
      entry.description.includes('auto-clock-out')
    );
    
    if (hasAutoClockout) {
      console.log('Already performed auto-clockout since last clock-in, skipping');
      return false;
    }
    
    // Get the auto-clockout settings
    const settings = await getAutoClockoutSettings(projectId);
    
    if (!settings.enabled) {
      console.log('Auto-clockout is disabled for this project');
      return false;
    }
    
    // Calculate how long the user has been clocked in (in minutes)
    const now = Date.now();
    const clockInDurationMinutes = (now - clockInTimestamp) / (1000 * 60);
    
    // Use max_work_minutes if available, otherwise fall back to max_work_hours * 60
    const maxWorkMinutes = settings.max_work_minutes || (settings.max_work_hours * 60);
    
    console.log(`User has been clocked in for ${clockInDurationMinutes.toFixed(2)} minutes`);
    console.log(`Auto-clockout threshold: ${maxWorkMinutes} minutes`);
    
    // Check if the user has exceeded the maximum work minutes
    if (clockInDurationMinutes >= maxWorkMinutes) {
      console.log(`User has exceeded maximum work time (${maxWorkMinutes} minutes), should auto-clockout`);
      return true;
    }
    
    return false;
  } catch (error) {
    console.error('Error in shouldAutoClockout:', error);
    return false;
  }
}

/**
 * Perform an automatic clock-out for a user.
 * @param {string} userId - The ID of the user.
 * @param {string} [projectId] - The ID of the project.
 * @param {Object} [locationData] - Optional location data.
 * @returns {Promise<Object|null>} - A promise that resolves with the clock-out entry or null if failed.
 */
export async function performAutoClockout(userId, projectId = null, locationData = null) {
  try {
    console.log(`Performing auto-clockout for user: ${userId}`);
    
    // Get the auto-clockout settings to include in the description
    const settings = await getAutoClockoutSettings(projectId);
    const maxWorkMinutes = settings.max_work_minutes || (settings.max_work_hours * 60);
    
    // Format maxWorkMinutes in a human-readable manner
    const maxWorkHours = Math.floor(maxWorkMinutes / 60);
    const remainingMinutes = maxWorkMinutes % 60;
    
    // Create a human-readable string for the max work time
    let maxWorkTimeStr = '';
    if (maxWorkHours > 0) {
      maxWorkTimeStr += `${maxWorkHours} hour${maxWorkHours !== 1 ? 's' : ''}`;
      if (remainingMinutes > 0) {
        maxWorkTimeStr += ` ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
      }
    } else {
      maxWorkTimeStr = `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
    }
    
    const now = new Date();
    const timeEntry = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    
    // Get the most recent clock-in timestamp to calculate total work time
    const clockInTimestamp = await getMostRecentClockInTimestamp(userId);
    
    // Calculate total work time based on the last clock-in timestamp
    let totalWorkTime = null;
    if (clockInTimestamp) {
      totalWorkTime = now.getTime() - clockInTimestamp;
    }
    
    // Create the auto-clockout entry with status 'clock-out' but description indicating auto-clockout
    // Include the maxWorkMinutes in the description in a human-readable format
    const clockOutEntry = {
      user_id: userId,
      status: 'clock-out',
      timestamp: now.getTime(),
      time_entry: timeEntry,
      description: `auto-clock-out - system detected extended work period (max: ${maxWorkTimeStr})`,
      total_work_time: totalWorkTime,
      latitude: locationData?.latitude,
      longitude: locationData?.longitude,
      address: locationData?.address || 'Auto-clockout - no location data',
      synced: isOnline(),
      last_sync_timestamp: isOnline() ? Date.now() : null,
      local_id: `local_${Date.now()}`,
      state: 'WA', // Default state
      project_id: projectId
    };
    
    if (isOnline()) {
      // If online, save to Supabase
      const { data, error } = await supabase
        .from('time_track')
        .insert([clockOutEntry])
        .select()
        .single();
      
      if (error) {
        console.error('Error creating auto-clockout entry in Supabase:', error);
        // Fall back to storing locally
        storeOfflineTimeTrackEntry(clockOutEntry);
        return clockOutEntry;
      }
      
      console.log('Auto-clockout entry created in Supabase:', data);
      return data;
    } else {
      // If offline, store locally
      console.log('Device is offline, storing auto-clockout entry locally');
      storeOfflineTimeTrackEntry(clockOutEntry);
      return clockOutEntry;
    }
  } catch (error) {
    console.error('Error in performAutoClockout:', error);
    return null;
  }
}


/**
 * Create a break notification for a user.
 * @param {Object} notificationData - The notification data.
 * @param {string} notificationData.userId - The ID of the user.
 * @param {string} notificationData.breakType - The type of break (rest, meal, etc.).
 * @param {Date|number} notificationData.scheduledBreakTime - The scheduled time for the break.
 * @param {number} [notificationData.durationMinutes] - The duration of the break in minutes.
 * @param {string} [notificationData.state='WA'] - Two-letter state code.
 * @param {string} [notificationData.projectId] - The ID of the project.
 * @returns {Promise<Object|null>} - A promise that resolves with the created notification or null if failed.
 */
export async function createBreakNotification(notificationData) {
  try {
    const {
      userId,
      breakType,
      scheduledBreakTime,
      durationMinutes,
      state = 'WA',
      projectId
    } = notificationData;
    
    // Get break rules to determine the actual duration minutes and if this break type is paid
    const breakRules = await getBreakRules(state);
    const breakRule = breakRules.find(rule => rule.rule_type === breakType);
    const actualDurationMinutes = breakRule?.duration_minutes || durationMinutes;
    const isPaid = breakRule?.paid || false;

    const now = new Date();
    
    // Get the most recent clock-in timestamp for the session hash
    const clockInTime = await getMostRecentClockInTimestamp(userId);
    
    if (!clockInTime) {
      console.log('No active clock-in found, cannot create break notification');
      return null;
    }

    // Generate session hash matching BreakSchedule implementation
    // IMPORTANT: Order must match exactly with BreakSchedule.js
    const hashInput = `${userId}-${breakType.toLowerCase()}-${actualDurationMinutes}-${isPaid ? 'paid' : 'unpaid'}-${breakRule?.min_work_hours}-${breakRule?.state || state}`;
    const sessionHash = hashInput.split('').reduce((a,b) => {
      a = ((a << 5) - a) + b.charCodeAt(0);
      return a & a;
    }, 0).toString(16).substring(0, 8);
    
    console.log(`[DEBUG] Hash components: userId=${userId}, breakType=${breakType.toLowerCase()}, durationMinutes=${actualDurationMinutes}, isPaid=${isPaid ? "paid" : "unpaid"}, min_work_hours=${breakRule?.min_work_hours}, state=${breakRule?.state || state}`);
    console.log(`[DEBUG] Break hash for ${breakType} (${actualDurationMinutes} min): Input=${hashInput}, Hash=${sessionHash}`);
    
    // Check if a notification with this hash already exists and has been dismissed
    if (isOnline()) {
      const { data: existingNotifications, error } = await supabase
        .from('break_notifications')
        .select('*')
        .eq('user_id', userId)
        .eq('session_hash', sessionHash)
        .eq('dismissed', true);
      
      if (!error && existingNotifications && existingNotifications.length > 0) {
        console.log(`Notification with hash ${sessionHash} already exists and was dismissed, skipping`);
        return null;
      }
    }
    
    const notification = {
      user_id: userId,
      break_type: breakType,
      notification_time: now.toISOString(),
      scheduled_break_time: new Date(scheduledBreakTime).toISOString(),
      dismissed: false,
      break_taken: false,
      session_hash: sessionHash,
      state,
      project_id: projectId,
      is_paid: isPaid
    };
    
    if (isOnline()) {
      // If online, save to Supabase
      const { data, error } = await supabase
        .from('break_notifications')
        .insert([notification])
        .select()
        .single();
      
      if (error) {
        console.error('Error creating break notification in Supabase:', error);
        return null;
      }
      
      console.log('Break notification created in Supabase:', data);
      return data;
    } else {
      // If offline, store locally for now
      // In a real implementation, we would have a more robust offline storage mechanism
      console.log('Device is offline, cannot create break notification');
      return { ...notification, id: `local_${Date.now()}` };
    }
  } catch (error) {
    console.error('Error in createBreakNotification:', error);
    return null;
  }
}

/**
 * Update a break notification (mark as dismissed or break taken).
 * @param {string} notificationId - The ID of the notification.
 * @param {Object} updateData - The data to update.
 * @param {boolean} [updateData.dismissed] - Whether the notification was dismissed.
 * @param {boolean} [updateData.breakTaken] - Whether the break was taken.
 * @param {boolean} [updateData.dismissedPermanently] - Whether the notification was permanently dismissed.
 * @param {string} [updateData.device_id] - The device ID hash of the device used to dismiss or take the break.
 * @returns {Promise<Object|null>} - A promise that resolves with the updated notification or null if failed.
 */
export async function updateBreakNotification(notificationId, updateData) {
  try {
    const now = new Date();
    const updates = {};
    
    if (updateData.dismissed !== undefined) {
      updates.dismissed = updateData.dismissed;
      if (updateData.dismissed) {
        updates.dismissed_time = now.toISOString();
      }
    }
    
    if (updateData.breakTaken !== undefined) {
      updates.break_taken = updateData.breakTaken;
      if (updateData.breakTaken) {
        updates.break_taken_time = now.toISOString();
      }
    }
    
    if (updateData.dismissedPermanently !== undefined) {
      updates.dismissed_permanently = updateData.dismissedPermanently;
    }
    
    // Add device_id if provided
    if (updateData.device_id) {
      updates.device_id = updateData.device_id;
      console.log(`Logging device ID ${updateData.device_id} for notification action`);
    }
    
    if (Object.keys(updates).length === 0) {
      console.log('No updates provided for break notification');
      return null;
    }
    
    if (isOnline()) {
      // If online, update in Supabase
      const { data, error } = await supabase
        .from('break_notifications')
        .update(updates)
        .eq('id', notificationId)
        .select()
        .single();
      
      if (error) {
        console.error('Error updating break notification in Supabase:', error);
        return null;
      }
      
      console.log('Break notification updated in Supabase:', data);
      return data;
    } else {
      // If offline, store locally for now
      console.log('Device is offline, cannot update break notification');
      return null;
    }
  } catch (error) {
    console.error('Error in updateBreakNotification:', error);
    return null;
  }
}

/**
 * Check if a break notification with a specific hash has been dismissed or taken.
 * @param {string} userId - The ID of the user.
 * @param {string} breakType - The type of break (rest, meal, etc.).
 * @param {number} durationMinutes - The duration of the break in minutes.
 * @returns {Promise<Object|null>} - A promise that resolves with the notification status or null if not found.
 */
export async function checkBreakNotificationStatus(userId, breakType, durationMinutes) {
  try {
    if (!isOnline()) {
      console.log('Device is offline, cannot check break notification status');
      return null;
    }
    
    // Get break rules to determine if this break type is paid
    const breakRules = await getBreakRules('WA'); // Default to WA if state not available
    const breakRule = breakRules.find(rule => rule.rule_type === breakType);
    const isPaid = breakRule?.paid || false;
    
    // Use the actual duration minutes from the break rule if available
    const actualDurationMinutes = breakRule?.duration_minutes || durationMinutes;

    // Generate session hash matching BreakSchedule implementation
    // IMPORTANT: Order must match exactly with BreakSchedule.js
    const hashInput = `${userId}-${breakType.toLowerCase()}-${actualDurationMinutes}-${isPaid ? 'paid' : 'unpaid'}-${breakRule?.min_work_hours}-${breakRule?.state || 'WA'}`;
    const sessionHash = hashInput.split('').reduce((a,b) => {
      a = ((a << 5) - a) + b.charCodeAt(0);
      return a & a;
    }, 0).toString(16).substring(0, 8);
    
    console.log(`[DEBUG] Hash components: userId=${userId}, breakType=${breakType.toLowerCase()}, durationMinutes=${actualDurationMinutes}, isPaid=${isPaid ? "paid" : "unpaid"}, min_work_hours=${breakRule?.min_work_hours}, state=${breakRule?.state || "WA"}`);
    console.log(`[DEBUG] Break hash for ${breakType} (${actualDurationMinutes} min): Input=${hashInput}, Hash=${sessionHash}`);
    
    // Check if a notification with this hash exists
    const { data, error } = await supabase
      .from('break_notifications')
      .select('*')
      .eq('user_id', userId)
      .eq('session_hash', sessionHash)
      .order('created_at', { ascending: false })
      .limit(1);
    
    if (error) {
      console.error('Error checking break notification status:', error);
      return null;
    }
    
    if (!data || data.length === 0) {
      return null;
    }
    
    const notification = data[0];
    
    // Return the notification status
    return {
      dismissed: notification.dismissed,
      dismissedTime: notification.dismissed_time,
      breakTaken: notification.break_taken,
      breakTakenTime: notification.break_taken_time,
      dismissedPermanently: notification.dismissed_permanently,
      deviceId: notification.device_id
    };
  } catch (error) {
    console.error('Error in checkBreakNotificationStatus:', error);
    return null;
  }
}

/**
 * Get active break notifications for a user.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<Array>} - A promise that resolves with the active break notifications.
 */
export async function getActiveBreakNotifications(userId) {
  try {
    if (!isOnline()) {
      console.log('Device is offline, cannot fetch break notifications');
      return [];
    }
    
    // Get notifications that are not dismissed, not taken, and not permanently dismissed
    const { data, error } = await supabase
      .from('break_notifications')
      .select('*')
      .eq('user_id', userId)
      .eq('dismissed', false)
      .eq('break_taken', false)
      .eq('dismissed_permanently', false)
      .order('scheduled_break_time', { ascending: true });
    
    if (error) {
      console.error('Error fetching break notifications:', error);
      return [];
    }
    
    return data || [];
  } catch (error) {
    console.error('Error in getActiveBreakNotifications:', error);
    return [];
  }
}

/**
 * Check if a break is due based on work duration and break rules.
 * @param {string} userId - The ID of the user.
 * @param {string} [state='WA'] - Two-letter state code.
 * @param {string} [projectId] - The ID of the project.
 * @returns {Promise<Object|null>} - A promise that resolves with the break info or null if no break is due.
 */
export async function checkBreakDue(userId, state = 'WA', projectId = null) {
  try {
    // Get the most recent clock-in timestamp
    const clockInTimestamp = await getMostRecentClockInTimestamp(userId);
    
    if (!clockInTimestamp) {
      console.log('No active clock-in found, no breaks due');
      return null;
    }
    
    // Get the break rules for the state
    const breakRules = await getBreakRules(state);
    
    if (!breakRules || breakRules.length === 0) {
      console.log('No break rules found for state:', state);
      return null;
    }
    
    // Get all time track entries since clock-in
    const now = Date.now();
    const entries = await getTimeTrackEntries(userId, {
      startDate: clockInTimestamp,
      endDate: now
    });
    
    // Calculate work time since clock-in
    const workTimeMs = calculateWorkTimeSinceClockIn(clockInTimestamp, now, entries);
    const workTimeHours = workTimeMs / (1000 * 60 * 60);
    
    console.log(`User has been working for ${workTimeHours.toFixed(2)} hours`);
    
    // Check each break rule to see if a break is due
    for (const rule of breakRules) {
      // Skip rules that don't have min_work_hours
      if (!rule.min_work_hours) continue;
      
      // Check if work time exceeds the minimum for this rule
      if (workTimeHours >= rule.min_work_hours) {
        // Check if there's a maximum work hours and if we've exceeded it
        if (rule.max_work_hours && workTimeHours > rule.max_work_hours) {
          continue; // Skip this rule if we've exceeded the maximum
        }
        
        // Check if we've already taken this type of break since clock-in
        const breakTaken = entries.some(entry => 
          entry.status === 'break' && 
          entry.break_type === rule.rule_type
        );
        
        if (breakTaken) {
          console.log(`Already taken a ${rule.rule_type} break`);
          continue;
        }
        
        // Check if we already have an active notification for this break type
        const activeNotifications = await getActiveBreakNotifications(userId);
        const hasActiveNotification = activeNotifications.some(
          notification => notification.break_type === rule.rule_type
        );
        
        if (hasActiveNotification) {
          console.log(`Already have an active notification for ${rule.rule_type} break`);
          continue;
        }
        
        // Check if this break type has been permanently dismissed
        const { data: permanentlyDismissedNotifications, error: permError } = await supabase
          .from('break_notifications')
          .select('*')
          .eq('user_id', userId)
          .eq('break_type', rule.rule_type)
          .eq('dismissed_permanently', true)
          .limit(1);
        
        if (permError) {
          console.error('Error checking for permanently dismissed notifications:', permError);
        } else if (permanentlyDismissedNotifications && permanentlyDismissedNotifications.length > 0) {
          console.log(`Break type ${rule.rule_type} has been permanently dismissed`);
          continue;
        }
        
        // Generate session hash matching BreakSchedule implementation
        // IMPORTANT: Order must match exactly with BreakSchedule.js
        const hashInput = `${userId}-${rule.rule_type.toLowerCase()}-${rule.duration_minutes}-${rule.paid ? 'paid' : 'unpaid'}-${rule.min_work_hours}-${rule?.state || 'WA'}`;
        const sessionHash = hashInput.split('').reduce((a,b) => {
          a = ((a << 5) - a) + b.charCodeAt(0);
          return a & a;
        }, 0).toString(16).substring(0, 8);
        
        console.log(`[DEBUG] Hash components: userId=${userId}, breakType=${rule.rule_type.toLowerCase()}, durationMinutes=${rule.duration_minutes}, isPaid=${rule.paid ? "paid" : "unpaid"}, min_work_hours=${rule.min_work_hours}, state=${rule?.state || "WA"}`);
        console.log(`[DEBUG] Break hash for ${rule.rule_type} (${rule.duration_minutes} min): Input=${hashInput}, Hash=${sessionHash}`);
        
        // Check if a notification with this hash has already been dismissed in this session
        const { data: dismissedNotifications, error: hashError } = await supabase
          .from('break_notifications')
          .select('*')
          .eq('user_id', userId)
          .eq('session_hash', sessionHash)
          .eq('dismissed', true)
          .limit(1);
        
        if (hashError) {
          console.error('Error checking for dismissed notifications with hash:', hashError);
        } else if (dismissedNotifications && dismissedNotifications.length > 0) {
          console.log(`Notification with hash ${sessionHash} already dismissed in this session, skipping`);
          continue;
        }
        
        // Break is due
        console.log(`${rule.rule_type} break is due`);
        return {
          breakType: rule.rule_type,
          durationMinutes: rule.duration_minutes,
          scheduledBreakTime: new Date(now).toISOString(),
          state,
          projectId
        };
      }
    }
    
    return null; // No break is due
  } catch (error) {
    console.error('Error in checkBreakDue:', error);
    return null;
  }
}

/**
 * Calculate work time since clock-in, accounting for breaks.
 * This is a simplified version of calculateWorkTime that only considers
 * the time since clock-in.
 * @param {number} clockInTime - The clock-in timestamp.
 * @param {number} currentTime - The current timestamp.
 * @param {Array} entries - The time track entries.
 * @returns {number} - The work time in milliseconds.
 */
function calculateWorkTimeSinceClockIn(clockInTime, currentTime, entries) {
  if (!clockInTime) return 0;
  
  // Calculate total elapsed time since clock-in
  const totalElapsedMs = currentTime - clockInTime;
  
  // Find all break periods
  const breakPeriods = [];
  
  // First, sort entries by timestamp to ensure proper order
  const sortedEntries = [...entries].sort((a, b) => a.timestamp - b.timestamp);
  
  // Track active breaks
  let activeBreaks = [];
  
  // Process entries in chronological order
  for (let i = 0; i < sortedEntries.length; i++) {
    const entry = sortedEntries[i];
    
    if (entry.status === 'break') {
      // Start a new break
      activeBreaks.push({
        id: entry.id || entry.local_id,
        start: entry.timestamp,
        type: entry.break_type
      });
    } else if (entry.status === 'break-end') {
      // Find the corresponding break start
      // First try to match by break_id if available
      let matchingBreakIndex = -1;
      if (entry.break_id) {
        matchingBreakIndex = activeBreaks.findIndex(b => b.id === entry.break_id);
      }
      
      // If no match by ID, try to match by type
      if (matchingBreakIndex === -1) {
        matchingBreakIndex = activeBreaks.findIndex(b => b.type === entry.break_type);
      }
      
      if (matchingBreakIndex !== -1) {
        const matchingBreak = activeBreaks[matchingBreakIndex];
        
        // Calculate break duration
        const breakDuration = entry.timestamp - matchingBreak.start;
        
        // Determine if break is paid (simplified logic)
        const isPaid = matchingBreak.type === 'rest';
        
        // Add to break periods
        breakPeriods.push({
          start: matchingBreak.start,
          end: entry.timestamp,
          duration: breakDuration,
          type: matchingBreak.type,
          paid: isPaid
        });
        
        // Remove this break from active breaks
        activeBreaks.splice(matchingBreakIndex, 1);
      }
    }
  }
  
  // Handle any still-active breaks
  for (const activeBreak of activeBreaks) {
    const activeDuration = currentTime - activeBreak.start;
    
    // Determine if break is paid (simplified logic)
    const isPaid = activeBreak.type === 'rest';
    
    breakPeriods.push({
      start: activeBreak.start,
      end: currentTime,
      duration: activeDuration,
      type: activeBreak.type,
      active: true,
      paid: isPaid
    });
  }
  
  // Calculate total unpaid break time (only subtract unpaid breaks)
  const totalUnpaidBreakTimeMs = breakPeriods
    .filter(period => !period.paid) // Only include unpaid breaks
    .reduce((total, period) => total + period.duration, 0);
  
  // Calculate work time (total elapsed time minus unpaid break time)
  const workTimeMs = totalElapsedMs - totalUnpaidBreakTimeMs;
  
  return Math.max(0, workTimeMs); // Ensure non-negative duration
}
