import { useState, useEffect, useCallback } from 'react';
import { getTodoItems } from '../../../api/todo';
import { groupTodoLists, getMostRecentLists } from '../utils/todoListUtils';

export const useTodoListState = (todolists) => {
  const [todoListCounts, setTodoListCounts] = useState({});
  const [mostRecentLists, setMostRecentLists] = useState([]);
  const [groupedLists, setGroupedLists] = useState({
    today: [],
    yesterday: [],
    previous7Days: [],
    previous30Days: [],
    byMonth: {},
    archived: [],
  });

  const fetchTodoListCounts = useCallback(async () => {
    const counts = {};
    for (const list of todolists) {
      try {
        const todoItems = await getTodoItems(list.id);
        const total = todoItems.length;
        const completed = todoItems.filter((item) => item.completed).length;
        counts[list.id] = { total, completed };
      } catch (error) {
        console.error(`Error fetching todo items for list ${list.id}:`, error);
        counts[list.id] = { total: 0, completed: 0 };
      }
    }
    setTodoListCounts(counts);
  }, [todolists]);

  useEffect(() => {
    if (todolists.length > 0) {
      fetchTodoListCounts();
    }
  }, [fetchTodoListCounts]);

  useEffect(() => {
    // We're not displaying most recent lists anymore, so we don't need to exclude them from groups
    // Just set an empty array for mostRecentLists
    setMostRecentLists([]);
    
    // Pass an empty array for excludeIds to show all todo lists in their respective groups
    const updatedGroupedLists = groupTodoLists(todolists, []);
    setGroupedLists(updatedGroupedLists);
  }, [todolists]);

  return {
    todoListCounts,
    mostRecentLists,
    groupedLists,
  };
};
