import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line
} from 'recharts';

// Colors for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57'];

const ButtonClicksReport = ({ data }) => {
  if (!data) return null;

  // Format data for button clicks bar chart
  const buttonClicksData = data.mostClickedButtons.map((item, index) => ({
    name: item.buttonId.length > 20 ? item.buttonId.substring(0, 20) + '...' : item.buttonId,
    count: item.count,
    fullName: item.buttonId
  }));

  // Format data for component pie chart
  const componentData = Object.entries(data.buttonClicksByComponent)
    .map(([component, count], index) => ({
      name: component,
      value: count,
      color: COLORS[index % COLORS.length]
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 10);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Button Clicks Analysis
      </Typography>
      
      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Total Button Clicks
              </Typography>
              <Typography variant="h3">
                {data.totalButtonClicks.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Unique Buttons Clicked
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.buttonClicksByButton).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Components with Button Clicks
              </Typography>
              <Typography variant="h3">
                {Object.keys(data.buttonClicksByComponent).length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Charts */}
      <Grid container spacing={3}>
        {/* Most Clicked Buttons Bar Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Most Clicked Buttons
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={buttonClicksData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip 
                  formatter={(value, name, props) => [value.toLocaleString(), 'Clicks']}
                  labelFormatter={(value, props) => props.payload && props.payload.length > 0 ? props.payload[0].fullName : value}
                />
                <Legend />
                <Bar dataKey="count" fill="#00C49F" name="Button Clicks" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Button Clicks by Component Pie Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Button Clicks by Component
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <PieChart>
                <Pie
                  data={componentData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {componentData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Button Clicks Over Time Line Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Button Clicks Over Time
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
              <LineChart
                data={data.buttonClicksByDayArray}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="count" 
                  stroke="#00C49F" 
                  name="Button Clicks" 
                  activeDot={{ r: 8 }} 
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        
        {/* Most Clicked Buttons List */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Most Clicked Buttons
            </Typography>
            <List>
              {data.mostClickedButtons.map((button, index) => (
                <React.Fragment key={button.buttonId}>
                  <ListItem>
                    <ListItemText 
                      primary={button.buttonId} 
                      secondary={`${button.count.toLocaleString()} clicks`} 
                    />
                  </ListItem>
                  {index < data.mostClickedButtons.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        
        {/* Button Click Insights */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Button Click Insights
            </Typography>
            <Typography variant="body1" paragraph>
              Button clicks represent direct user interactions with actionable elements in the application.
              This data helps understand which actions users are taking most frequently.
            </Typography>
            <Typography variant="body1" paragraph>
              High click counts on specific buttons may indicate popular features or common user workflows,
              while low counts might suggest buttons that are less discoverable or less useful.
            </Typography>
            <Typography variant="body1">
              Consider the following insights:
            </Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="UI Optimization" 
                  secondary="Ensure frequently used buttons are easily accessible and prominently displayed." 
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText 
                  primary="Feature Usage" 
                  secondary="Identify which features are most actively used based on associated button clicks." 
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText 
                  primary="User Flow Analysis" 
                  secondary="Analyze sequences of button clicks to understand common user workflows." 
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ButtonClicksReport;
