import { useEffect } from 'react';
import { useTrackInteractions } from '../../utils/trackInteractions';

/**
 * A component that adds interaction tracking to a Leaflet map
 * @param {Object} map - The Leaflet map instance
 * @param {string} userId - The user ID
 * @returns {null} - This component doesn't render anything
 */
const TrackMapInteractions = ({ map, userId }) => {
  const { 
    trackMapInteraction, 
    trackMapLayerToggle, 
    trackComponentView 
  } = useTrackInteractions();

  useEffect(() => {
    if (!map) return;

    // Track component view
    // trackComponentView('Map', {
    //   userId,
    //   timestamp: Date.now()
    // });

    // Track map clicks
    const handleMapClick = (e) => {
      trackMapInteraction('click', {
        lat: e.latlng.lat,
        lng: e.latlng.lng
      }, {
        userId,
        zoom: map.getZoom(),
        timestamp: Date.now()
      });
    };

    // Track map moves
    const handleMapMove = (e) => {
      const center = map.getCenter();
      trackMapInteraction('move', {
        lat: center.lat,
        lng: center.lng
      }, {
        userId,
        zoom: map.getZoom(),
        timestamp: Date.now()
      });
    };

    // Track map zooms
    const handleMapZoom = (e) => {
      const center = map.getCenter();
      trackMapInteraction('zoom', {
        lat: center.lat,
        lng: center.lng,
        zoom: map.getZoom()
      }, {
        userId,
        timestamp: Date.now()
      });
    };

    // Track layer changes
    const handleBaseLayerChange = (e) => {
      trackMapLayerToggle(e.name, true, {
        userId,
        layerType: 'baseLayer',
        timestamp: Date.now()
      });
    };

    const handleOverlayAdd = (e) => {
      trackMapLayerToggle(e.name, true, {
        userId,
        layerType: 'overlay',
        timestamp: Date.now()
      });
    };

    const handleOverlayRemove = (e) => {
      trackMapLayerToggle(e.name, false, {
        userId,
        layerType: 'overlay',
        timestamp: Date.now()
      });
    };

    // Add event listeners
    map.on('click', handleMapClick);
    map.on('moveend', handleMapMove);
    map.on('zoomend', handleMapZoom);
    map.on('baselayerchange', handleBaseLayerChange);
    map.on('overlayadd', handleOverlayAdd);
    map.on('overlayremove', handleOverlayRemove);

    // Remove event listeners on cleanup
    return () => {
      map.off('click', handleMapClick);
      map.off('moveend', handleMapMove);
      map.off('zoomend', handleMapZoom);
      map.off('baselayerchange', handleBaseLayerChange);
      map.off('overlayadd', handleOverlayAdd);
      map.off('overlayremove', handleOverlayRemove);
    };
  }, [map, userId, trackMapInteraction, trackMapLayerToggle, trackComponentView]);

  return null;
};

export default TrackMapInteractions;
