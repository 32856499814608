import { useEffect, useState } from "react";

export default function useStickyState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    try {
      return stickyValue !== null ? JSON?.parse(stickyValue) : defaultValue;
    } catch
    (error) {
      console.error(`Error parsing local storage with key=${key}:`, error);
      return defaultValue;
    }
  });


  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error saving to local storage: ", error);
    }
  }, [key, value]);

  return [value, setValue];
}
