import React from 'react';
import { Grid, Box, Typography } from "@mui/material";
import ClockinButton from './ClockinButton';
import BreakButton from './BreakButton';

export default function TimeCardActions({
  clockbtnlabel,
  workbreak,
  showingHistory,
  clockedin,
  handleClockin,
  handleBreak,
  isCurrentDate,
}) {
  return (
    <Box sx={{ 
      padding: '16px 0',
      borderRadius: '4px',
      marginBottom: '16px'
    }}>
      {!isCurrentDate && (
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#666', 
            textAlign: 'center', 
            marginBottom: '10px',
            fontStyle: 'italic'
          }}
        >
          Clock in/out actions are only available for the current date
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <ClockinButton 
            disabled={showingHistory || !isCurrentDate} 
            onClick={handleClockin}
            fullWidth
          >
            {clockbtnlabel}
          </ClockinButton>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <BreakButton 
            disabled={!clockedin || showingHistory || !isCurrentDate} 
            onClick={handleBreak}
            fullWidth
          >
            {workbreak}
          </BreakButton>
        </Grid>
      </Grid>
    </Box>
  );
}
