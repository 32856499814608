// Map of action prefixes to their corresponding action verbs
// This compressed format saves space and improves performance by eliminating runtime grammar rules
export const actionPrefixMap = {
  // Tech/Development
  "test": "Testing",
  "check": "Checking",
  "review": "Reviewing",
  "write": "Writing",
  "code": "Coding",
  "create": "Creating",
  "build": "Building",
  "implement": "Implementing",
  "fix": "Fixing",
  "update": "Updating",
  "add": "Adding",
  "remove": "Removing",
  "delete": "Deleting",
  "design": "Designing",
  "develop": "Developing",
  "research": "Researching",
  "analyze": "Analyzing",
  "debug": "Debugging",
  "deploy": "Deploying",
  "configure": "Configuring",
  "install": "Installing",
  "setup": "Setting up",
  "refactor": "Refactoring",
  "optimize": "Optimizing",
  "document": "Documenting",
  "integrate": "Integrating",
  "migrate": "Migrating",
  "automate": "Automating",
  "compile": "Compiling",
  "merge": "Merging",
  "commit": "Committing",
  "push": "Pushing",
  "pull": "Pulling",
  "branch": "Branching",
  "clone": "Cloning",
  "fork": "Forking",
  
  // Communication/Collaboration
  "call": "Calling",
  "email": "Emailing",
  "contact": "Contacting",
  "meet": "Meeting with",
  "discuss": "Discussing",
  "plan": "Planning",
  "schedule": "Scheduling",
  "present": "Presenting",
  "share": "Sharing",
  "collaborate": "Collaborating",
  "coordinate": "Coordinating",
  "negotiate": "Negotiating",
  "interview": "Interviewing",
  "moderate": "Moderating",
  "facilitate": "Facilitating",
  "mediate": "Mediating",
  
  // Management/Organization
  "manage": "Managing",
  "lead": "Leading",
  "delegate": "Delegating",
  "assign": "Assigning",
  "track": "Tracking",
  "monitor": "Monitoring",
  "evaluate": "Evaluating",
  "assess": "Assessing",
  "report": "Reporting",
  "organize": "Organizing",
  "prioritize": "Prioritizing",
  "allocate": "Allocating",
  "budget": "Budgeting",
  "forecast": "Forecasting",
  "approve": "Approving",
  "authorize": "Authorizing",
  "supervise": "Supervising",
  "oversee": "Overseeing",
  
  // Learning/Education
  "study": "Studying",
  "learn": "Learning",
  "teach": "Teaching",
  "train": "Training",
  "mentor": "Mentoring",
  "coach": "Coaching",
  "tutor": "Tutoring",
  "grade": "Grading",
  "research": "Researching",
  "read": "Reading",
  "summarize": "Summarizing",
  "outline": "Outlining",
  "draft": "Drafting",
  
  // Project/Product Management
  "scope": "Scoping",
  "define": "Defining",
  "specify": "Specifying",
  "prototype": "Prototyping",
  "iterate": "Iterating",
  "launch": "Launching",
  "release": "Releasing",
  "measure": "Measuring",
  "improve": "Improving",
  "backlog": "Backlogging",
  "groom": "Grooming",
  "sprint": "Sprinting",
  "demo": "Demoing",
  "retrospect": "Retrospecting",
  "roadmap": "Roadmapping",
  
  // General Work Tasks
  "prepare": "Preparing",
  "clean": "Cleaning",
  "file": "Filing",
  "archive": "Archiving",
  "backup": "Backing up",
  "restore": "Restoring",
  "print": "Printing",
  "scan": "Scanning",
  "ship": "Shipping",
  "deliver": "Delivering",
  "order": "Ordering",
  "purchase": "Purchasing",
  "buy": "Buying",
  "sell": "Selling",
  "invoice": "Invoicing",
  "pay": "Paying",
  "process": "Processing",
  "submit": "Submitting",
  "apply": "Applying",
  "register": "Registering",
  "book": "Booking",
  "reserve": "Reserving",
  
  // Common verbs with special conjugation
  "be": "Being",
  "see": "Seeing",
  "go": "Going",
  "do": "Doing",
  "have": "Having",
  "run": "Running",
  "sit": "Sitting",
  "get": "Getting",
  "put": "Putting",
  "cut": "Cutting",
  "let": "Letting",
  "hit": "Hitting",
  "set": "Setting",
  "quit": "Quitting",
  "swim": "Swimming",
  "begin": "Beginning",
  "drink": "Drinking",
  "sing": "Singing",
  "ring": "Ringing",
  "speak": "Speaking",
  "drive": "Driving",
  "fly": "Flying",
  "draw": "Drawing",
  "know": "Knowing",
  "grow": "Growing",
  "throw": "Throwing",
  "show": "Showing",
  "blow": "Blowing",
  "flow": "Flowing"
};

// Get the list of action prefixes from the map keys for faster lookup
export const actionPrefixes = Object.keys(actionPrefixMap);

// Infer action verb from todo description
export const inferActionVerb = (description) => {
  if (!description) return "Working";
  
  // Convert to lowercase for easier matching
  const lowerDesc = description.toLowerCase();
  
  // Check if the description contains any of the action prefixes
  for (const prefix of actionPrefixes) {
    if (lowerDesc.startsWith(prefix) || 
        lowerDesc.includes(` ${prefix} `) || 
        lowerDesc.includes(` ${prefix}`)) {
      return actionPrefixMap[prefix];
    }
  }
  
  // If no specific action is found, try to infer from the first word if it's a verb
  const firstWord = lowerDesc.split(' ')[0];
  if (firstWord.endsWith('ing')) {
    // If the first word already ends with 'ing', capitalize it
    return firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
  } else if (firstWord.length > 3 && actionPrefixMap[firstWord]) {
    // If the first word is in our map, use that
    return actionPrefixMap[firstWord];
  } else if (firstWord.length > 3) {
    // For other potential verbs, make a best guess
    if (firstWord.endsWith('e') && !firstWord.endsWith('ee')) {
      return firstWord.charAt(0).toUpperCase() + firstWord.slice(1, -1) + "ing";
    } else {
      return firstWord.charAt(0).toUpperCase() + firstWord.slice(1) + "ing";
    }
  }
  
  // Default fallback
  return "Working on";
};
