import React, { useState, useEffect } from 'react';
import { useAuth } from '../Hooks/useAuth';
import { 
  Paper, 
  Typography, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  Divider, 
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Alert,
  AlertTitle,
  Snackbar,
  CircularProgress,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  FormControlLabel
} from '@mui/material';
import { 
  CalendarToday, 
  AccessTime, 
  CheckCircle, 
  Warning, 
  Sync, 
  SyncProblem,
  Notes,
  Close,
  Settings,
  Timer as TimerIcon,
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import { 
  getTimeTrackEntriesForWeek, 
  calculateTotalHoursForWeek, 
  checkTimesheetExists,
  submitTimesheet,
  syncOfflineTimesheets,
  getSubmittedTimesheets,
  getTimesheetDetails,
  isDeviceOnline,
  getTimesheetSettings,
  updateTimesheetSettings,
  getWeekNumber
} from '../../api/timesheet';
import './styles.css';

const Timesheet = ({ open = false, onClose = () => {} }) => {
  const { user } = useAuth();
  const userId = user?.id;

  // State for current week
  const [currentWeek, setCurrentWeek] = useState({
    startDate: null,
    endDate: null,
    entries: [],
    totalHours: 0,
    overtimeHours: 0,
    regularHours: 0,
    timeTrackIds: []
  });
  
  // State for week navigation
  const [selectedWeekOffset, setSelectedWeekOffset] = useState(0); // 0 = current week, -1 = previous week, etc.

  // State for timesheet submission
  const [notes, setNotes] = useState('');
  const [projectId, setProjectId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [timesheetExists, setTimesheetExists] = useState(false);
  const [submittedTimesheets, setSubmittedTimesheets] = useState([]);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [timesheetDetailsOpen, setTimesheetDetailsOpen] = useState(false);
  const [syncStatus, setSyncStatus] = useState('idle');
  const [hasOfflineData, setHasOfflineData] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  
  // Settings state
  const [settings, setSettings] = useState({
    workweek_start: 0, // 0 = Sunday, 1 = Monday, etc.
    workweek_length: 5, // 5-day workweek by default
    reminder_day: 5, // Friday (5) is the default reminder day
    hours_per_day: 8 // Default expected hours per day
  });
  const [showSettings, setShowSettings] = useState(false);
  const [settingsChanged, setSettingsChanged] = useState(false);

  // Initialize the current week data and load settings
  useEffect(() => {
    if (userId) {
      loadSettings();
      loadSubmittedTimesheets();
      checkOfflineData();
    }
  }, [userId]);
  
  // Regenerate week data when week offset changes
  useEffect(() => {
    if (userId) {
      generateWeekData();
    }
  }, [selectedWeekOffset, userId]);
  
  // Load timesheet settings
  const loadSettings = async () => {
    try {
      setLoading(true);
      const userSettings = await getTimesheetSettings(userId);
      setSettings(userSettings);
      setLoading(false);
      
      // Generate week data after loading settings
      generateWeekData(userSettings);
    } catch (error) {
      console.error('Error loading timesheet settings:', error);
      setLoading(false);
      
      // Generate week data with default settings
      generateWeekData(settings);
    }
  };
  
  // Save timesheet settings
  const saveSettings = async () => {
    try {
      setLoading(true);
      await updateTimesheetSettings(userId, settings);
      setSettingsChanged(false);
      setShowSettings(false);
      setLoading(false);
      
      // Regenerate week data with new settings
      generateWeekData(settings);
      
      // Show success message
      setSnackbarMessage('Settings saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving timesheet settings:', error);
      setLoading(false);
      
      // Show error message
      setSnackbarMessage('Failed to save settings. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Check for offline data
  const checkOfflineData = () => {
    const offlineTimesheets = JSON.parse(localStorage.getItem('offlineTimesheets') || '[]');
    const hasOffline = offlineTimesheets.some(timesheet => 
      timesheet.user_id === userId && !timesheet.synced && !timesheet._deleted
    );
    setHasOfflineData(hasOffline);
  };

  // Generate week data based on selected week offset and settings
  const generateWeekData = async (userSettings = settings) => {
    try {
      setLoading(true);
      setError(null);

      const today = new Date();
      const dayOfWeek = today.getDay();
      
      // For debugging
      console.log('Generating week data with settings:', userSettings);
      console.log('Today:', today, 'Day of week:', dayOfWeek, 'Week offset:', selectedWeekOffset);
      
      // Calculate the start date of the current week based on workweek_start setting
      // For now, use Sunday as the start day to ensure compatibility with existing data
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - dayOfWeek); // Always use Sunday as start
      
      // Apply the week offset
      startDate.setDate(startDate.getDate() + (selectedWeekOffset * 7));
      startDate.setHours(0, 0, 0, 0);
      
      // Calculate the end date (always use Saturday as end date for now)
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6); // Always use 7-day week for now
      endDate.setHours(23, 59, 59, 999);
      
      console.log('Week range:', startDate, 'to', endDate);
      
      // Get time track entries for the week
      const entries = await getTimeTrackEntriesForWeek(userId, startDate, endDate);
      
      // Get the regular work hours from time_track_settings (if available)
      // This is the setting that determines what's considered regular vs. overtime hours
      const regularWorkHours = userSettings?.regular_work_hours || 8.0;
      
      // Calculate total hours and overtime based on the regular work hours setting
      const { totalHours, overtimeHours, regularHours } = calculateTotalHoursForWeek(entries, regularWorkHours);
      
      // Get time track IDs
      const timeTrackIds = entries.map(entry => entry.id || entry.local_id);
      
      // Check if timesheet already exists for this week
      const exists = await checkTimesheetExists(userId, startDate, endDate);
      setTimesheetExists(exists);
      
      setCurrentWeek({
        startDate,
        endDate,
        entries,
        totalHours,
        overtimeHours,
        regularHours,
        timeTrackIds
      });
      
      setLoading(false);
    } catch (error) {
      console.error('Error generating week data:', error);
      setError('Failed to load timesheet data. Please try again.');
      setLoading(false);
    }
  };

  // Load submitted timesheets
  const loadSubmittedTimesheets = async () => {
    try {
      const timesheets = await getSubmittedTimesheets(userId);
      setSubmittedTimesheets(timesheets);
    } catch (error) {
      console.error('Error loading submitted timesheets:', error);
    }
  };

  // Format date as MM/DD/YYYY using local time
  const formatDate = (date) => {
    if (!date) return '';
    // If date is a timestamp (number), convert it to a Date object
    const d = typeof date === 'number' ? new Date(date) : new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  };

  const formatDateInTable = (date) => {
    if (!date) return '';
  
    // If date is a timestamp (number), convert it to a Date object
    const d = typeof date === 'number' ? new Date(date) : new Date(date);
  
    // Format the date to include weekday, month, day, and 2-digit year
    const formattedDate = d.toLocaleDateString('en-US', {
      weekday: 'short', // Short weekday (e.g., 'Sun')
      month: 'numeric', // Numeric month (e.g., '3')
      day: 'numeric',   // Numeric day (e.g., '2')
      year: '2-digit'   // 2-digit year (e.g., '25')
    });
  
    // Remove the comma and replace it with a space
    return formattedDate.replace(',', '');
  };
  
  // Check if today is the reminder day
  const isReminderDay = () => {
    const today = new Date();
    return today.getDay() === settings.reminder_day;
  };
  
  // Handle settings change
  const handleSettingsChange = (field, value) => {
    setSettings({
      ...settings,
      [field]: typeof value === 'string' ? parseInt(value, 10) : value
    });
    setSettingsChanged(true);
  };
  
  // Get day name for the dropdown
  const getDayName = (dayNum) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[dayNum];
  };

  // Format hours as decimal value with 2 decimal places
  const formatHours = (hours) => {
    if (hours === 0) return '0.00';
    return hours.toFixed(2);
  };

  // Handle timesheet submission
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Check if timesheet already exists
      if (timesheetExists) {
        setError('A timesheet has already been submitted for this week.');
        setLoading(false);
        return;
      }
      
      // Check if there are entries to submit
      if (currentWeek.entries.length === 0) {
        setError('No time entries found for this week.');
        setLoading(false);
        return;
      }
      
      // Submit timesheet
      const timesheetData = {
        userId,
        startDate: currentWeek.startDate,
        endDate: currentWeek.endDate,
        totalHours: currentWeek.totalHours,
        overtimeHours: currentWeek.overtimeHours,
        regularHours: currentWeek.regularHours,
        timeTrackIds: currentWeek.timeTrackIds,
        notes,
        projectId: projectId || null
      };
      
      await submitTimesheet(timesheetData);
      
      // Show success message
      setSuccess(true);
      setSnackbarMessage('Timesheet submitted successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      
      // Reset form
      setNotes('');
      setProjectId('');
      
      // Reload data
      generateWeekData();
      loadSubmittedTimesheets();
      
      setLoading(false);
    } catch (error) {
      console.error('Error submitting timesheet:', error);
      setError('Failed to submit timesheet. Please try again.');
      setSnackbarMessage('Failed to submit timesheet. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  // Handle sync of offline timesheets
  const handleSync = async () => {
    try {
      setSyncStatus('syncing');
      
      // Check if online
      if (!isDeviceOnline()) {
        setSyncStatus('error');
        setSnackbarMessage('Cannot sync while offline. Please try again when online.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
      
      // Sync offline timesheets
      const syncedTimesheets = await syncOfflineTimesheets(userId);
      
      if (syncedTimesheets.length > 0) {
        setSyncStatus('success');
        setSnackbarMessage(`Successfully synced ${syncedTimesheets.length} timesheet(s).`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        
        // Reload data
        generateWeekData();
        loadSubmittedTimesheets();
        checkOfflineData();
      } else {
        setSyncStatus('idle');
        setSnackbarMessage('No timesheets to sync.');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error syncing timesheets:', error);
      setSyncStatus('error');
      setSnackbarMessage('Failed to sync timesheets. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // View timesheet details
  const viewTimesheetDetails = async (timesheetId) => {
    try {
      setLoading(true);
      
      const details = await getTimesheetDetails(timesheetId);
      setSelectedTimesheet(details);
      setTimesheetDetailsOpen(true);
      
      setLoading(false);
    } catch (error) {
      console.error('Error getting timesheet details:', error);
      setSnackbarMessage('Failed to load timesheet details. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  // Close timesheet details dialog
  const closeTimesheetDetails = () => {
    setTimesheetDetailsOpen(false);
    setSelectedTimesheet(null);
  };

  // Handle snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Group entries by day
  const getEntriesByDay = () => {
    if (!currentWeek.entries.length) return [];
    
    const days = {};
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    // Get the regular work hours from settings (either from time_track_settings or timesheet_settings)
    // Default to 8 hours if not available
    const regularWorkHours = settings?.regular_work_hours || 8.0;
    
    // Log the settings and regular work hours for debugging
    console.log('Current settings:', settings);
    console.log('Regular work hours used for calculation:', regularWorkHours);
    
    // Initialize days
    for (let i = 0; i < 7; i++) {
      const date = new Date(currentWeek.startDate);
      date.setDate(currentWeek.startDate.getDate() + i);
      
      days[date.toDateString()] = {
        date,
        dayName: dayNames[date.getDay()],
        entries: [],
        totalHours: 0,
        regularHours: 0,
        overtimeHours: 0
      };
    }
    
    // Group entries by day
    currentWeek.entries.forEach(entry => {
      const entryDate = new Date(entry.timestamp);
      const dateString = entryDate.toDateString();
      
      if (days[dateString]) {
        days[dateString].entries.push(entry);
      }
    });
    
    // Calculate total hours for each day
    Object.keys(days).forEach(dateString => {
      // Get entries for this day
      const dayEntries = days[dateString].entries;
      
      // Skip if no entries
      if (dayEntries.length === 0) {
        return;
      }
      
      // Pass the regularWorkHours setting to calculateTotalHoursForWeek
      const { totalHours, overtimeHours, regularHours } = calculateTotalHoursForWeek(
        dayEntries,
        regularWorkHours
      );
      
      // Log the calculation results for debugging
      console.log(`Day ${dateString} calculation:`, {
        entries: dayEntries.length,
        totalHours,
        regularWorkHours,
        regularHours,
        overtimeHours
      });
      
      days[dateString].totalHours = totalHours;
      days[dateString].regularHours = regularHours;
      days[dateString].overtimeHours = overtimeHours;
    });
    
    return Object.values(days);
  };

  // Get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return '#FFA726'; // Orange
      case 'approved':
        return '#66BB6A'; // Green
      case 'rejected':
        return '#EF5350'; // Red
      default:
        return '#9E9E9E'; // Grey
    }
  };

  // Get status label
  const getStatusLabel = (status) => {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'approved':
        return 'Approved';
      case 'rejected':
        return 'Rejected';
      default:
        return 'Unknown';
    }
  };

  // Format overtime minutes
  const formatOvertimeMinutes = (minutes) => {
    if (!minutes || minutes === 0) return '';
    
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    
    if (hours > 0 && mins > 0) {
      return `${hours}h ${mins}m`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else {
      return `${mins}m`;
    }
  };

  // If user is not logged in, show a message
  if (!userId) {
    return (
      <Paper className="timesheet-container">
        <Typography variant="h5" align="center">
          Please log in to view your timesheet.
        </Typography>
      </Paper>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="timesheet-dialog-title"
    >
      <DialogTitle id="timesheet-dialog-title">
        {showSettings ? 'Timesheet Settings' : 'Timesheet Submission'}
        <Box sx={{ position: 'absolute', right: 48, top: 8 }}>
          <IconButton
            aria-label="settings"
            onClick={() => setShowSettings(!showSettings)}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <Settings />
          </IconButton>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="timesheet-container">
          <Paper elevation={3} className="timesheet-paper">
            {showSettings ? (
              /* Settings Panel */
              <>
                <Typography variant="h4" component="h1" gutterBottom>
                  Timesheet Settings
                </Typography>
                
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="workweek-start-label">Workweek Start Day</InputLabel>
                      <Select
                        labelId="workweek-start-label"
                        value={settings.workweek_start}
                        onChange={(e) => handleSettingsChange('workweek_start', e.target.value)}
                        label="Workweek Start Day"
                      >
                        {[0, 1, 2, 3, 4, 5, 6].map(day => (
                          <MenuItem key={day} value={day}>{getDayName(day)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="workweek-length-label">Workweek Length (days)</InputLabel>
                      <Select
                        labelId="workweek-length-label"
                        value={settings.workweek_length}
                        onChange={(e) => handleSettingsChange('workweek_length', e.target.value)}
                        label="Workweek Length (days)"
                      >
                        {[1, 2, 3, 4, 5, 6, 7].map(len => (
                          <MenuItem key={len} value={len}>{len} days</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="reminder-day-label">Reminder Day</InputLabel>
                      <Select
                        labelId="reminder-day-label"
                        value={settings.reminder_day}
                        onChange={(e) => handleSettingsChange('reminder_day', e.target.value)}
                        label="Reminder Day"
                      >
                        {[0, 1, 2, 3, 4, 5, 6].map(day => (
                          <MenuItem key={day} value={day}>{getDayName(day)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography variant="caption" color="textSecondary">
                      This is the day when the timesheet submission reminder will appear
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Expected Hours Per Day"
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={settings.hours_per_day}
                      onChange={(e) => handleSettingsChange('hours_per_day', e.target.value)}
                      inputProps={{ min: 1, max: 24 }}
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Regular Work Hours Per Day"
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={settings.regular_work_hours || 8.0}
                      onChange={(e) => handleSettingsChange('regular_work_hours', parseFloat(e.target.value))}
                      inputProps={{ min: 1, max: 24, step: 0.5 }}
                      helperText="Hours beyond this will be counted as overtime"
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Box mt={2} display="flex" justifyContent="space-between">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setShowSettings(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={saveSettings}
                        disabled={!settingsChanged || loading}
                      >
                        Save Settings
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              /* Timesheet Submission */
              <>
                <Typography variant="h4" component="h1" gutterBottom>
                  Timesheet Submission
                </Typography>
                
                {/* Reminder badge */}
                {isReminderDay() && (
                  <Alert severity="info" className="alert-message" icon={<CalendarToday />}>
                    Today is your configured reminder day for timesheet submission.
                  </Alert>
                )}
            
                {/* Week Navigation */}
                <Box mb={3}>
                  {/* Week Navigation Buttons */}
                  <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setSelectedWeekOffset(selectedWeekOffset - 1);
                        generateWeekData();
                      }}
                      startIcon={<ChevronLeft />}
                      disabled={loading}
                    >
                      Previous Week
                    </Button>
                    
                    {selectedWeekOffset !== 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setSelectedWeekOffset(0);
                          generateWeekData();
                        }}
                        disabled={loading}
                        style={{ 
                          backgroundColor: '#0080BB', 
                          color: 'white',
                          textTransform: 'uppercase'
                        }}
                      >
                        This Week
                      </Button>
                    )}
                    
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setSelectedWeekOffset(selectedWeekOffset + 1);
                        generateWeekData();
                      }}
                      endIcon={<ChevronRight />}
                      disabled={loading || selectedWeekOffset >= 0}
                    >
                      Next Week
                    </Button>
                  </Box>
                  
                  {/* Week Status Indicators */}
                  <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center"
                    mb={2}
                    sx={{
                      position: 'relative',
                      overflowX: { xs: 'hidden', md: 'visible' }
                    }}
                  >
                    {/* Mobile Previous Weeks Button */}
                    <Box 
                      sx={{ 
                        display: { xs: 'flex', md: 'none' },
                        position: 'absolute',
                        left: 0,
                        zIndex: 2,
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        borderRadius: '50%',
                        width: '32px',
                        height: '32px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        boxShadow: 1
                      }}
                      onClick={() => {
                        // Scroll to previous weeks on mobile
                        const container = document.getElementById('week-indicators-container');
                        if (container) {
                          container.scrollBy({ left: -200, behavior: 'smooth' });
                        }
                      }}
                    >
                      <ChevronLeft fontSize="small" />
                    </Box>
                    
                    {/* Week Indicators Container */}
                    <Box 
                      id="week-indicators-container"
                      sx={{
                        display: 'flex',
                        overflowX: { xs: 'auto', md: 'visible' },
                        scrollbarWidth: 'none', // Hide scrollbar for Firefox
                        '&::-webkit-scrollbar': { // Hide scrollbar for Chrome/Safari
                          display: 'none'
                        },
                        px: { xs: 4, md: 0 }, // Add padding on mobile for the navigation buttons
                        scrollSnapType: { xs: 'x mandatory', md: 'none' },
                        width: '100%',
                        justifyContent: 'center'
                      }}
                    >
                      {[-4, -3, -2, -1, 0].map((offset) => {
                        // Calculate the date for this week
                        const weekDate = new Date();
                        weekDate.setDate(weekDate.getDate() + (offset * 7));
                        
                        // Calculate the start and end dates for this week
                        const weekStart = new Date(weekDate);
                        weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Sunday
                        weekStart.setHours(0, 0, 0, 0);
                        
                        const weekEnd = new Date(weekStart);
                        weekEnd.setDate(weekStart.getDate() + 6); // Saturday
                        weekEnd.setHours(23, 59, 59, 999);
                        
                        const weekNumber = getWeekNumber(weekStart);
                        
                        // Find matching timesheet
                        const matchingTimesheet = submittedTimesheets.find(ts => 
                          new Date(ts.start_date).toDateString() === weekStart.toDateString() &&
                          new Date(ts.end_date).toDateString() === weekEnd.toDateString()
                        );
                        
                        // Determine status color and text
                        let statusColor = '#9E9E9E'; // Default gray
                        let statusText = 'Not Submitted';
                        
                        if (matchingTimesheet) {
                          // Timesheet exists, show its status
                          if (matchingTimesheet.status === 'approved') {
                            statusColor = '#66BB6A'; // Green
                            statusText = 'Approved';
                          } else if (matchingTimesheet.status === 'rejected') {
                            statusColor = '#EF5350'; // Red
                            statusText = 'Rejected';
                          } else {
                            statusColor = '#FFA726'; // Orange
                            statusText = 'Pending';
                          }
                        } else if (offset < 0) {
                          // Past week
                          if (offset === selectedWeekOffset && currentWeek.entries.length === 0) {
                            // Currently selected past week with no entries
                            statusColor = '#9E9E9E'; // Gray
                            statusText = 'No Entries';
                          } else {
                            // Past week that's either not selected or has entries
                            statusColor = '#EF5350'; // Red
                            statusText = 'Past Due';
                          }
                        }
                        
                        // Determine if this is the selected week
                        const isSelected = offset === selectedWeekOffset;
                        
                        return (
                          <Box 
                            key={offset}
                            onClick={() => {
                              setSelectedWeekOffset(offset);
                              generateWeekData();
                            }}
                            sx={{
                              cursor: 'pointer',
                              mx: 1,
                              p: 1,
                              borderRadius: 1,
                              border: isSelected ? '2px solid #0080BB' : '1px solid #e0e0e0',
                              backgroundColor: isSelected ? '#e3f2fd' : 'white',
                              minWidth: '80px',
                              textAlign: 'center'
                            }}
                          >
                            <Typography variant="caption" display="block" gutterBottom>
                              Week {weekNumber}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                              {formatDate(weekStart)} - {formatDate(weekEnd)}
                            </Typography>
                            <Chip 
                              size="small" 
                              label={statusText}
                              style={{ 
                                backgroundColor: statusColor,
                                color: 'white',
                                fontSize: '0.7rem',
                                height: '20px'
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                    
                    {/* Mobile Next Weeks Button */}
                    <Box 
                      sx={{ 
                        display: { xs: 'flex', md: 'none' },
                        position: 'absolute',
                        right: 0,
                        zIndex: 2,
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        borderRadius: '50%',
                        width: '32px',
                        height: '32px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        boxShadow: 1
                      }}
                      onClick={() => {
                        // Scroll to next weeks on mobile
                        const container = document.getElementById('week-indicators-container');
                        if (container) {
                          container.scrollBy({ left: 200, behavior: 'smooth' });
                        }
                      }}
                    >
                      <ChevronRight fontSize="small" />
                    </Box>
                  </Box>
                </Box>
                
                {/* Week Information */}
                <Card className="week-info-card">
                  <CardContent>
                    <Grid container spacing={2}>
                      {/* Total Hours */}
                      <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <AccessTime color="primary" />
                          <Typography variant="h6">
                            Total Hours: {formatHours(currentWeek.totalHours)}
                          </Typography>
                        </Box>
                        {currentWeek.overtimeHours > 0 && (
                          <Typography variant="body2" color="warning.main">
                            Including {formatHours(currentWeek.overtimeHours)} overtime
                          </Typography>
                        )}
                      </Grid>

                      {/* Week Range */}
                      <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <CalendarToday color="primary" />
                          <Typography>
                            {formatDate(currentWeek.startDate)} - {formatDate(currentWeek.endDate)}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Status */}
                      <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="center" gap={1}>
                          {timesheetExists ? (
                            <CheckCircle color="success" />
                          ) : (
                            <Warning color="warning" />
                          )}
                          <Typography>
                            {timesheetExists ? 'Submitted' : 'Not Submitted'}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Daily Entries Table */}
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell align="right">Regular</TableCell>
                                <TableCell align="right">Overtime</TableCell>
                                <TableCell align="right">Total</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {getEntriesByDay().map((day) => (
                                <TableRow key={day.date.toDateString()}>
                                  <TableCell>{formatDateInTable(day.date)}</TableCell>
                                  <TableCell align="right">{formatHours(day.regularHours)}</TableCell>
                                  <TableCell align="right">
                                    {day.overtimeHours > 0 ? formatHours(day.overtimeHours) : '-'}
                                  </TableCell>
                                  <TableCell align="right">{formatHours(day.totalHours)}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      {/* Notes Field */}
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Notes"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          disabled={loading || timesheetExists}
                        />
                      </Grid>

                      {/* Submit Button */}
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          {hasOfflineData && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleSync}
                              startIcon={syncStatus === 'error' ? <SyncProblem /> : <Sync />}
                              disabled={loading || syncStatus === 'syncing'}
                            >
                              {syncStatus === 'syncing' ? 'Syncing...' : 'Sync Offline Data'}
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={loading || timesheetExists || currentWeek.entries.length === 0}
                            endIcon={<Notes />}
                          >
                            Submit Timesheet
                          </Button>
                        </Box>
                      </Grid>

                      {/* Error Message */}
                      {error && (
                        <Grid item xs={12}>
                          <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {error}
                          </Alert>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </>
            )}
          </Paper>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Timesheet;
