import { supabase } from '../lib/supabase';

/**
 * Check if tracking is enabled for a specific component and event type
 * @param {string} componentName - The name of the component
 * @param {string} eventType - The type of event
 * @returns {Promise<boolean>} - Whether tracking is enabled
 */
export async function isTrackingEnabled(componentName, eventType) {
  try {
    // First check if tracking is globally enabled
    const { data: globalConfig, error: globalError } = await supabase
      .from('global_tracking_config')
      .select('is_enabled')
      .limit(1)
      .single();

    if (globalError) {
      console.error('Error fetching global tracking config:', globalError);
      return true; // Default to enabled if there's an error
    }

    if (!globalConfig.is_enabled) {
      return false; // If global tracking is disabled, return false immediately
    }

    // Check component-specific config
    const { data: componentConfig, error: componentError } = await supabase
      .from('tracking_config')
      .select('is_enabled')
      .or('component_name.eq.' + componentName + ',component_name.eq.*')
      .order('component_name', { ascending: false }) // Specific component first, then wildcard
      .limit(1)
      .single();

    if (componentError) {
      console.error('Error fetching component tracking config:', componentError);
      return true; // Default to enabled if there's an error
    }

    if (!componentConfig.is_enabled) {
      return false; // If component tracking is disabled, return false
    }

    // Check event-specific config
    try {
      const { data: eventConfig, error: eventError } = await supabase
        .from('tracking_config')
        .select('is_enabled')
        .eq('component_name', componentName)
        .or('event_type.eq.' + eventType + ',event_type.eq.*')
        .order('event_type', { ascending: false }) // Specific event first, then wildcard
        .limit(1);

      if (eventError) {
        console.error('Error fetching event tracking config:', eventError);
        return true; // Default to enabled if there's an error
      }

      if (!eventConfig || eventConfig.length === 0) {
        console.log(`No specific config found for ${componentName}:${eventType}, defaulting to enabled`);
        return true; // Default to enabled if no config found
      }

      return eventConfig[0].is_enabled;
    } catch (innerError) {
      console.error('Exception in event tracking config check:', innerError);
      return true; // Default to enabled if there's an exception
    }
  } catch (error) {
    console.error('Error checking if tracking is enabled:', error);
    return true; // Default to enabled if there's an error
  }
}

/**
 * Get all tracking configurations
 * @returns {Promise<Array>} - Array of tracking configurations
 */
export async function getAllTrackingConfigs() {
  try {
    const { data: globalConfig, error: globalError } = await supabase
      .from('global_tracking_config')
      .select('*')
      .limit(1)
      .single();

    if (globalError) {
      console.error('Error fetching global tracking config:', globalError);
      throw globalError;
    }

    const { data: componentConfigs, error: componentError } = await supabase
      .from('tracking_config')
      .select('*')
      .order('component_name', { ascending: true })
      .order('event_type', { ascending: true });

    if (componentError) {
      console.error('Error fetching component tracking configs:', componentError);
      throw componentError;
    }

    return {
      globalConfig,
      componentConfigs
    };
  } catch (error) {
    console.error('Error getting all tracking configs:', error);
    throw error;
  }
}

/**
 * Update global tracking configuration
 * @param {boolean} isEnabled - Whether tracking is globally enabled
 * @returns {Promise<Object>} - Updated global config
 */
export async function updateGlobalTrackingConfig(isEnabled) {
  try {
    // Get the ID of the global config
    const { data: existingConfig, error: fetchError } = await supabase
      .from('global_tracking_config')
      .select('id')
      .limit(1)
      .single();

    if (fetchError) {
      console.error('Error fetching global tracking config:', fetchError);
      throw fetchError;
    }

    // Update the global config
    const { data, error } = await supabase
      .from('global_tracking_config')
      .update({
        is_enabled: isEnabled,
        updated_at: new Date(),
        updated_by: (await supabase.auth.getUser())?.data?.user?.id || 'unknown'
      })
      .eq('id', existingConfig.id)
      .single();

    if (error) {
      console.error('Error updating global tracking config:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error updating global tracking config:', error);
    throw error;
  }
}

/**
 * Update component tracking configuration
 * @param {string} componentName - The name of the component
 * @param {string} eventType - The type of event
 * @param {boolean} isEnabled - Whether tracking is enabled
 * @returns {Promise<Object>} - Updated component config
 */
export async function updateComponentTrackingConfig(componentName, eventType, isEnabled) {
  try {
    // Check if config exists
    const { data: existingConfig, error: fetchError } = await supabase
      .from('tracking_config')
      .select('id')
      .eq('component_name', componentName)
      .eq('event_type', eventType);

    if (fetchError) {
      console.error('Error fetching component tracking config:', fetchError);
      throw fetchError;
    }

    let data;
    let error;

    if (existingConfig && existingConfig.length > 0) {
      // Update existing config
      const result = await supabase
        .from('tracking_config')
        .update({
          is_enabled: isEnabled,
          updated_at: new Date(),
          updated_by: (await supabase.auth.getUser())?.data?.user?.id || 'unknown'
        })
        .eq('id', existingConfig[0].id);
      
      data = result.data;
      error = result.error;
    } else {
      // Insert new config
      const result = await supabase
        .from('tracking_config')
        .insert({
          component_name: componentName,
          event_type: eventType,
          is_enabled: isEnabled,
          created_by: (await supabase.auth.getUser())?.data?.user?.id || 'unknown',
          updated_by: (await supabase.auth.getUser())?.data?.user?.id || 'unknown'
        });
      
      data = result.data;
      error = result.error;
    }

    if (error) {
      console.error('Error updating component tracking config:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error updating component tracking config:', error);
    throw error;
  }
}

/**
 * Delete a tracking configuration
 * @param {string} id - The ID of the configuration to delete
 * @returns {Promise<void>}
 */
export async function deleteTrackingConfig(id) {
  try {
    const { error } = await supabase
      .from('tracking_config')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting tracking config:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error deleting tracking config:', error);
    throw error;
  }
}

/**
 * Get cached tracking configuration
 * Uses localStorage to cache tracking config and reduce API calls
 * @returns {Object|null} - Cached tracking config or null if not cached
 */
export function getCachedTrackingConfig() {
  try {
    const cachedConfig = localStorage.getItem('trackingConfig');
    if (cachedConfig) {
      const { timestamp, config } = JSON.parse(cachedConfig);
      
      // Check if cache is still valid (less than 5 minutes old)
      if (Date.now() - timestamp < 5 * 60 * 1000) {
        return config;
      }
    }
    return null;
  } catch (error) {
    console.error('Error getting cached tracking config:', error);
    return null;
  }
}

/**
 * Set cached tracking configuration
 * @param {Object} config - The tracking configuration to cache
 */
export function setCachedTrackingConfig(config) {
  try {
    localStorage.setItem('trackingConfig', JSON.stringify({
      timestamp: Date.now(),
      config
    }));
  } catch (error) {
    console.error('Error setting cached tracking config:', error);
  }
}

/**
 * Clear cached tracking configuration
 */
export function clearCachedTrackingConfig() {
  try {
    localStorage.removeItem('trackingConfig');
  } catch (error) {
    console.error('Error clearing cached tracking config:', error);
  }
}

/**
 * Check if a user is an admin
 * @param {string} userId - The user ID to check
 * @returns {Promise<boolean>} - Whether the user is an admin
 */
export async function isUserAdmin(userId) {
  console.log(`Checking if user ${userId} is an admin`);
  
  if (!userId) {
    console.log('No userId provided, returning false');
    return false;
  }
  
  // Special case for the first admin user
  if (userId === '00000000-0000-0000-0000-000000000000') {
    console.log('User is the first admin user, returning true');
    return true;
  }
  
  try {
    console.log(`Checking is_admin column for user ${userId}`);
    
    // First try using the RPC function if available
    try {
      const { data: rpcData, error: rpcError } = await supabase.rpc('is_user_admin', {
        user_id: userId
      });
      
      if (!rpcError) {
        console.log(`RPC result for user ${userId} is admin: ${rpcData}`);
        return rpcData;
      }
      
      console.log('RPC function not available, falling back to direct query');
    } catch (rpcError) {
      console.log('Error using RPC function:', rpcError);
      console.log('Falling back to direct query');
    }
    
    // Direct query as fallback
    const { data, error } = await supabase
      .from('users')
      .select('is_admin')
      .eq('id', userId)
      .maybeSingle();

    if (error) {
      console.error('Error checking if user is admin:', error);
      
      // Try one more approach - auth.users might not be directly accessible
      try {
        // Use a custom function or endpoint to check admin status
        const { data: authData, error: authError } = await supabase.auth.getUser();
        
        if (authError) {
          console.error('Error getting user:', authError);
          return false;
        }
        
        // If we're checking the current user, we can use the user metadata
        if (authData?.user?.id === userId) {
          const isAdmin = authData?.user?.user_metadata?.is_admin === true;
          console.log(`User ${userId} is admin (from metadata): ${isAdmin}`);
          return isAdmin;
        }
      } catch (authError) {
        console.error('Error checking auth user:', authError);
      }
      
      console.log('Returning false due to query error');
      return false;
    }

    const isAdmin = data?.is_admin === true;
    console.log(`User ${userId} is admin: ${isAdmin}`);
    return isAdmin;
  } catch (error) {
    console.error('Exception checking if user is admin:', error);
    console.log('Returning false due to exception');
    return false;
  }
}

/**
 * Add a user to the admin role
 * @param {string} userId - The user ID to add as admin
 * @param {Object} currentUser - The current user object (optional)
 * @returns {Promise<boolean>} - Whether the operation was successful
 */
export async function addUserAsAdmin(userId, currentUser = null) {
  if (!userId) return false;
  
  try {
    // Get the current user if not provided
    let user = currentUser;
    if (!user) {
      const { data } = await supabase.auth.getUser();
      user = data?.user;
    }
    
    // Check if the current user is an admin
    const isAdmin = await isUserAdmin(user?.id);
    if (!isAdmin) {
      console.error('Only admins can add other admins');
      return false;
    }
    
    // First try using the RPC function if available
    try {
      const { data: rpcData, error: rpcError } = await supabase.rpc('set_user_as_admin', {
        user_id: userId,
        is_admin_value: true
      });
      
      if (!rpcError) {
        console.log(`RPC result for setting user ${userId} as admin: ${rpcData}`);
        return rpcData;
      }
      
      console.log('RPC function not available, falling back to direct update');
    } catch (rpcError) {
      console.log('Error using RPC function:', rpcError);
      console.log('Falling back to direct update');
    }
    
    // Direct update as fallback
    const { data, error } = await supabase
      .from('users')
      .update({ is_admin: true })
      .eq('id', userId);

    if (error) {
      console.error('Error adding user as admin:', error);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error adding user as admin:', error);
    return false;
  }
}

/**
 * Remove a user from the admin role
 * @param {string} userId - The user ID to remove from admin
 * @param {Object} currentUser - The current user object (optional)
 * @returns {Promise<boolean>} - Whether the operation was successful
 */
export async function removeUserAsAdmin(userId, currentUser = null) {
  if (!userId) return false;
  
  try {
    // Get the current user if not provided
    let user = currentUser;
    if (!user) {
      const { data } = await supabase.auth.getUser();
      user = data?.user;
    }
    
    // Check if the current user is an admin
    const isAdmin = await isUserAdmin(user?.id);
    if (!isAdmin) {
      console.error('Only admins can remove other admins');
      return false;
    }
    
    // Don't allow removing the last admin
    // Count admins by querying users with is_admin = true
    const { data: adminUsers, error: countError } = await supabase
      .from('users')
      .select('id')
      .eq('is_admin', true);
      
    if (countError) {
      console.error('Error counting admins:', countError);
      return false;
    }
    
    if (!adminUsers || adminUsers.length <= 1) {
      console.error('Cannot remove the last admin');
      return false;
    }
    
    // First try using the RPC function if available
    try {
      const { data: rpcData, error: rpcError } = await supabase.rpc('set_user_as_admin', {
        user_id: userId,
        is_admin_value: false
      });
      
      if (!rpcError) {
        console.log(`RPC result for removing admin from user ${userId}: ${rpcData}`);
        return rpcData;
      }
      
      console.log('RPC function not available, falling back to direct update');
    } catch (rpcError) {
      console.log('Error using RPC function:', rpcError);
      console.log('Falling back to direct update');
    }
    
    // Direct update as fallback
    const { error } = await supabase
      .from('users')
      .update({ is_admin: false })
      .eq('id', userId);

    if (error) {
      console.error('Error removing user as admin:', error);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error removing user as admin:', error);
    return false;
  }
}
