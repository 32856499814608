import React, { useState, useEffect } from 'react';
import { StoreContext } from '../Hooks/store';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import makeStyles from '@mui/styles/makeStyles';
import Todos from './Todos';
import ArchivedTodolists from './ArchivedTodolists';
import { getTodoLists } from '../../api/todo';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker'; // Import the hook

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  iconwidth: {
    minWidth: '35px !important',
  },
  menuwidth: {
    width: 'inherit',
  },
  menuDiv: { marginLeft: '10px' },
  activitiesMenu: {
    fontSize: '12pt',
    verticalAlign: 'middle',
    marginBottom: '5px',
    display: 'inline-block',
  },
  tctitle: {
    fontFamily: 'Montserrat',
    fontSize: '11pt',
    backgroundColor: '#00416e',
    color: '#fff',
    textAlign: 'center',
  },
  b1: {
    backgroundColor: '#fff',
    minWidth: '320px',
    marginLeft: '-5px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function TodoList({ open, onClose }) {
  const {
    modaldialog,
    todolistheader,
    todolistheaderid,
    todolistsaved,
    thisuserid,
    showarchiveflag,
    tododirtycheck,
    thisdeviceid,
    thislocationid,
    thissessionid,
    mytodolists,
  } = React.useContext(StoreContext);

  // Initialize the user interaction tracker
  const { trackInteraction } = useUserInteractionTracker();

  const [, setLoadedmodal] = modaldialog;
  const [dirtytodos, setdirtytodos] = tododirtycheck;
  const [todoHeader, setTodoHeader] = todolistheader;
  const [todolistid, settodolistid] = todolistheaderid;
  const [todos, setTodos] = todolistsaved;
  const [userid, setUserid] = thisuserid;
  const [showarchived, setShowarchived] = showarchiveflag;
  const [deviceid] = thisdeviceid;
  const [locationid] = thislocationid;
  const [sessionid, setSessionid] = thissessionid;
  const [todolists, settodolists] = mytodolists;

  const classes = useStyles();

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  /**
   * Fetch todo lists for the user.
   */
  const fetchTodoLists = async () => {
    try {
      const todoLists = await getTodoLists(userid);
      settodolists(todoLists || []);
    } catch (error) {
      console.error('Error fetching todo lists:', error);
      showSnackbar('Error fetching todo lists', 'error');
    }
  };

  /**
   * Handle creating a new todo list.
   * @param {object} newList - The newly created todo list.
   */
  const handleCreateTodoList = async (newList) => {
    try {
      await fetchTodoLists();
      showSnackbar('New todo list created!', 'success');
    } catch (error) {
      console.error('Error creating todo list:', error);
      showSnackbar('Error creating todo list', 'error');
    }
  };

  /**
   * Handle selecting a todo list.
   * @param {string} listId - The ID of the selected todo list.
   * @param {string} listName - The name of the selected todo list.
   */
  const handleSelectTodoList = (listId, listName) => {
    console.clear(); // Debugging
    //console.log('1. Selected listId:', listId, 'listName:', listName); // Debugging
    settodolistid(listId);
    setTodoHeader(listName);
    setShowarchived(false);
  };

  /**
   * Handle closing the dialog.
   */
  const handleClose = () => {
    onClose();
  };

  /**
   * Toggle the visibility of archived lists.
   */
  const toggleShowArchiveFlag = () => {
    setShowarchived(!showarchived);
  };

  // Fetch todo lists when the component mounts or the todolistid changes
  useEffect(() => {
    if (open) {
      // Track page view
      trackInteraction('page_view', { 
        sessionHash: sessionid, 
        pageName: 'TodoList',
        userId: userid,
        url: window.location.href, // Include the URL
        timestamp: Date.now() // Include the timestamp
      });

      fetchTodoLists();


    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.tctitle} onClose={handleClose} disableTypography>
        <MenuOutlinedIcon
          onClick={toggleShowArchiveFlag}
          style={{ position: 'absolute', left: '15px', marginRight: '8px' }}
        />
        {showarchived ? 'All Lists' : todoHeader}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.b1}>
        {showarchived ? (
          <ArchivedTodolists
            onSelectTodoList={handleSelectTodoList}
            onCreateTodoList={handleCreateTodoList}
          />
        ) : (
          <Todos todolistid={todolistid} />
        )}
      </DialogContent>
    </Dialog>
  );
}