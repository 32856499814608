import React from 'react';
import { 
  Box, 
  IconButton, 
  Button, 
  CircularProgress,
  Tooltip,
  Typography
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TodayIcon from '@mui/icons-material/Today';
import { useTimeTrackContext } from '../context/TimeTrackContext';
import '../styles.css';

const DateNavigation = () => {
  const { 
    currentDate,
    loading,
    navigateDate,
    formatDate,
    isCurrentDateToday,
    isCurrentDateTodayOrFuture,
    navigateToToday
  } = useTimeTrackContext();

  // Handle date navigation with explicit loading state management
  const handleNavigateBack = () => {
    if (loading.dateNav) return;
    console.log('Navigating back');
    // Use a local variable to prevent stale closures
    const direction = 'back';
    navigateDate(direction);
  };

  const handleNavigateForward = () => {
    if (loading.dateNav || isCurrentDateTodayOrFuture()) return;
    console.log('Navigating forward');
    // Use a local variable to prevent stale closures
    const direction = 'forward';
    navigateDate(direction);
  };

  return (
    <Box className="date-navigation-container" sx={{ mb: 3 }}>
      <Box className="date-navigation" sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        gap: 2
      }}>
        <IconButton 
          size="small" 
          onClick={handleNavigateBack}
          className="nav-button"
          disabled={loading.dateNav}
          sx={{ 
            bgcolor: 'background.paper',
            boxShadow: 1,
            '&:hover': {
              bgcolor: 'action.hover'
            }
          }}
        >
          {loading.dateNav ? (
            <CircularProgress size={20} />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
        
        <Typography 
          variant="h6" 
          component="div"
          sx={{ 
            fontWeight: 500,
            minWidth: 120,
            textAlign: 'center'
          }}
        >
          {formatDate(currentDate)}
        </Typography>
        
        <IconButton 
          size="small" 
          onClick={handleNavigateForward}
          disabled={isCurrentDateTodayOrFuture() || loading.dateNav}
          className="nav-button"
          sx={{ 
            bgcolor: 'background.paper',
            boxShadow: 1,
            '&:hover': {
              bgcolor: 'action.hover'
            },
            '&.Mui-disabled': { 
              opacity: 0.3 
            }
          }}
        >
          {loading.dateNav ? (
            <CircularProgress size={20} />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </Box>

      {!isCurrentDateToday() && (
        <Box className="today-button-container" sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          mt: 2
        }}>
          <Button
            variant="outlined"
            onClick={navigateToToday}
            className="today-button"
            size="small"
            startIcon={loading.dateNav ? <CircularProgress size={16} /> : <TodayIcon />}
            disabled={loading.dateNav}
          >
            Today
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DateNavigation;
