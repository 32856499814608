import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';
import { isOnline } from './timetrack';

/**
 * Get timesheet settings for a user
 * @param {string} userId - The user ID
 * @returns {Promise<Object>} - The timesheet settings
 */
export async function getTimesheetSettings(userId) {
  try {
    console.log('Getting timesheet settings for user:', userId);
    
    if (!isOnline()) {
      // Get from local storage if offline
      return getOfflineTimesheetSettings(userId);
    }

    // First, get timesheet settings
    const { data, error } = await supabase
      .from('timesheet_settings')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (error) {
      console.log('Error getting settings:', error);
      // If no settings found, create default settings
      if (error.code === 'PGRST116') {
        console.log('No settings found, creating default settings');
        return createTimesheetSettings(userId);
      }
      throw error;
    }
    
    // If timesheet settings don't have regular_work_hours, try to get it from time_track_settings
    if (data && (data.regular_work_hours === undefined || data.regular_work_hours === null)) {
      try {
        const { data: timeTrackSettings, error: timeTrackError } = await supabase
          .from('time_track_settings')
          .select('regular_work_hours')
          .eq('user_id', userId)
          .single();
        
        if (!timeTrackError && timeTrackSettings && timeTrackSettings.regular_work_hours) {
          // Add regular_work_hours from time_track_settings to timesheet settings
          data.regular_work_hours = timeTrackSettings.regular_work_hours;
          
          // Update timesheet settings with regular_work_hours
          await updateTimesheetSettings(userId, { regular_work_hours: timeTrackSettings.regular_work_hours });
        }
      } catch (timeTrackError) {
        console.error('Error getting time track settings:', timeTrackError);
        // If error, just continue with existing timesheet settings
      }
    }
    
    console.log('Settings found:', data);
    return data;
  } catch (error) {
    console.error('Error getting timesheet settings:', error);
    
    // Return default settings if online fetch fails
    const defaultSettings = {
      user_id: userId,
      workweek_start: 0, // Sunday
      workweek_length: 7, // 7-day workweek (full week)
      reminder_day: 5, // Friday
      hours_per_day: 8,
      regular_work_hours: 8.0 // Default regular work hours
    };
    console.log('Returning default settings:', defaultSettings);
    return defaultSettings;
  }
}

/**
 * Create timesheet settings for a user
 * @param {string} userId - The user ID
 * @param {Object} [settings] - The settings to create
 * @returns {Promise<Object>} - The created timesheet settings
 */
export async function createTimesheetSettings(userId, settings = {}) {
  // Try to get regular_work_hours from time_track_settings
  let regularWorkHours = 8.0; // Default value
  
  if (isOnline()) {
    try {
      const { data: timeTrackSettings, error: timeTrackError } = await supabase
        .from('time_track_settings')
        .select('regular_work_hours')
        .eq('user_id', userId)
        .single();
      
      if (!timeTrackError && timeTrackSettings && timeTrackSettings.regular_work_hours) {
        regularWorkHours = timeTrackSettings.regular_work_hours;
      }
    } catch (error) {
      console.error('Error getting time track settings:', error);
      // Continue with default value if error
    }
  }
  
  const defaultSettings = {
    user_id: userId,
    workweek_start: 0, // Sunday
    workweek_length: 7, // 7-day workweek (full week)
    reminder_day: 5, // Friday
    hours_per_day: 8,
    regular_work_hours: regularWorkHours, // Use value from time_track_settings or default
    ...settings
  };

  try {
    const { data, error } = await supabase
      .from('timesheet_settings')
      .insert([defaultSettings])
      .select()
      .single();

    if (error) throw error;
    
    // Store in local storage for offline use
    storeOfflineTimesheetSettings(data);
    
    return data;
  } catch (error) {
    console.error('Error creating timesheet settings:', error);
    
    // Store in local storage if online creation fails
    storeOfflineTimesheetSettings(defaultSettings);
    
    return defaultSettings;
  }
}

/**
 * Update timesheet settings for a user
 * @param {string} userId - The user ID
 * @param {Object} settings - The settings to update
 * @returns {Promise<Object>} - The updated timesheet settings
 */
export async function updateTimesheetSettings(userId, settings) {
  try {
    if (!isOnline()) {
      // Store in local storage if offline
      return updateOfflineTimesheetSettings(userId, settings);
    }

    const { data, error } = await supabase
      .from('timesheet_settings')
      .update(settings)
      .eq('user_id', userId)
      .select()
      .single();

    if (error) throw error;
    
    // Store in local storage for offline use
    storeOfflineTimesheetSettings(data);
    
    return data;
  } catch (error) {
    console.error('Error updating timesheet settings:', error);
    
    // Store in local storage if online update fails
    return updateOfflineTimesheetSettings(userId, settings);
  }
}

/**
 * Get offline timesheet settings for a user
 * @param {string} userId - The user ID
 * @returns {Object} - The offline timesheet settings
 */
function getOfflineTimesheetSettings(userId) {
  const offlineSettings = JSON.parse(localStorage.getItem('offlineTimesheetSettings') || '{}');
  
  if (offlineSettings[userId]) {
    return offlineSettings[userId];
  }
  
  // Return default settings if no offline settings found
  const defaultSettings = {
    user_id: userId,
    workweek_start: 0, // Sunday
    workweek_length: 5, // 5-day workweek
    reminder_day: 5, // Friday
    hours_per_day: 8
  };
  
  // Store default settings in local storage
  storeOfflineTimesheetSettings(defaultSettings);
  
  return defaultSettings;
}

/**
 * Store timesheet settings in local storage
 * @param {Object} settings - The settings to store
 */
function storeOfflineTimesheetSettings(settings) {
  const offlineSettings = JSON.parse(localStorage.getItem('offlineTimesheetSettings') || '{}');
  
  offlineSettings[settings.user_id] = settings;
  
  localStorage.setItem('offlineTimesheetSettings', JSON.stringify(offlineSettings));
}

/**
 * Update offline timesheet settings for a user
 * @param {string} userId - The user ID
 * @param {Object} settings - The settings to update
 * @returns {Object} - The updated offline timesheet settings
 */
function updateOfflineTimesheetSettings(userId, settings) {
  const offlineSettings = JSON.parse(localStorage.getItem('offlineTimesheetSettings') || '{}');
  
  const updatedSettings = {
    ...offlineSettings[userId],
    ...settings,
    updated_at: new Date().toISOString()
  };
  
  offlineSettings[userId] = updatedSettings;
  
  localStorage.setItem('offlineTimesheetSettings', JSON.stringify(offlineSettings));
  
  return updatedSettings;
}

/**
 * Get the week number for a given date
 * @param {Date} date - The date to get the week number for
 * @returns {number} - The week number (1-53)
 */
export function getWeekNumber(date) {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7)); // Set to nearest Thursday
  const yearStart = new Date(d.getFullYear(), 0, 1);
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}

/**
 * Get time track entries for a specific week
 * @param {string} userId - The user ID
 * @param {Date} startDate - The start date of the week
 * @param {Date} endDate - The end date of the week
 * @returns {Promise<Array>} - The time track entries for the week
 */
export async function getTimeTrackEntriesForWeek(userId, startDate, endDate) {
  try {
    if (!isOnline()) {
      // Get from local storage if offline
      return getOfflineTimeTrackEntriesForWeek(userId, startDate, endDate);
    }

    const { data, error } = await supabase
      .from('time_track')
      .select('*')
      .eq('user_id', userId)
      .gte('timestamp', startDate.getTime())
      .lte('timestamp', endDate.getTime())
      .order('timestamp', { ascending: true });

    if (error) throw error;

    // Merge with offline entries
    const offlineEntries = getOfflineTimeTrackEntriesForWeek(userId, startDate, endDate);
    const mergedEntries = [...(data || []), ...offlineEntries];
    
    // Sort by timestamp
    return mergedEntries.sort((a, b) => a.timestamp - b.timestamp);
  } catch (error) {
    console.error('Error getting time track entries for week:', error);
    return getOfflineTimeTrackEntriesForWeek(userId, startDate, endDate);
  }
}

/**
 * Get offline time track entries for a specific week
 * @param {string} userId - The user ID
 * @param {Date} startDate - The start date of the week
 * @param {Date} endDate - The end date of the week
 * @returns {Array} - The offline time track entries for the week
 */
function getOfflineTimeTrackEntriesForWeek(userId, startDate, endDate) {
  const offlineEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  
  return offlineEntries.filter(entry => {
    return (
      entry.user_id === userId &&
      !entry._deleted &&
      entry.timestamp >= startDate.getTime() &&
      entry.timestamp <= endDate.getTime()
    );
  });
}

/**
 * Calculate total hours worked for a week based on time track entries
 * @param {Array} entries - The time track entries
 * @param {number} [regularWorkHours=8.0] - The regular work hours per day (from settings)
 * @returns {Object} - Object containing total hours, regular hours, and overtime hours
 */
export function calculateTotalHoursForWeek(entries, regularWorkHours = 8.0) {
  // First check if we have total_work_time available in the entries
  let totalWorkTimeMs = 0;
  let totalOvertimeMinutes = 0;
  let hasValidTotalWorkTime = false;
  
  // Check clock-out entries for total_work_time
  const clockOutEntries = entries.filter(entry => 
    (entry.status === 'clock-out' || entry.status === 'auto-clock-out') && 
    entry.total_work_time !== null && 
    entry.total_work_time !== undefined
  );
  
  if (clockOutEntries.length > 0) {
    // Sum up total_work_time from all clock-out entries
    clockOutEntries.forEach(entry => {
      totalWorkTimeMs += entry.total_work_time || 0;
      
      // If entry has overtime_minutes, use it
      // Otherwise, calculate overtime based on regularWorkHours
      if (entry.overtime_minutes !== undefined && entry.overtime_minutes !== null) {
        totalOvertimeMinutes += entry.overtime_minutes;
      } else {
        // Calculate work time in hours for this entry
        const entryHours = (entry.total_work_time || 0) / (60 * 60 * 1000);
        
        // If entry hours exceed regular work hours, add the difference as overtime
        if (entryHours > regularWorkHours) {
          const overtimeHours = entryHours - regularWorkHours;
          totalOvertimeMinutes += overtimeHours * 60; // Convert hours to minutes
        }
      }
    });
    hasValidTotalWorkTime = true;
  }
  
  // If we have valid total_work_time, convert to hours and return
  if (hasValidTotalWorkTime) {
    // Convert milliseconds to hours
    const totalHours = parseFloat((totalWorkTimeMs / (60 * 60 * 1000)).toFixed(2));
    
    // Calculate regular and overtime hours based on regularWorkHours
    // Regular hours should be capped at regularWorkHours
    const regularHours = Math.min(totalHours, regularWorkHours);
    const overtimeHours = Math.max(0, totalHours - regularWorkHours);
    
    console.log('Total work time calculation:', {
      totalWorkTimeMs,
      totalHours,
      regularWorkHours,
      regularHours,
      overtimeHours
    });
    
    return {
      totalHours,
      overtimeHours,
      regularHours
    };
  }
  
  // Fallback to manual calculation if total_work_time is not available
  let totalMinutes = 0;
  let totalOvertimeMinutesManual = 0;
  let clockInTime = null;
  let onBreak = false;
  let breakStartTime = null;
  
  // Group entries by day to calculate overtime per day
  const entriesByDay = {};
  
  // Sort entries by timestamp
  const sortedEntries = [...entries].sort((a, b) => a.timestamp - b.timestamp);
  
  // First pass: calculate total work time per day
  for (const entry of sortedEntries) {
    const entryDate = new Date(entry.timestamp);
    const dateString = entryDate.toDateString();
    
    if (!entriesByDay[dateString]) {
      entriesByDay[dateString] = {
        entries: [],
        totalMinutes: 0,
        overtimeMinutes: 0
      };
    }
    
    entriesByDay[dateString].entries.push(entry);
  }
  
  // Log the regularWorkHours parameter for debugging
  console.log('calculateTotalHoursForWeek regularWorkHours:', regularWorkHours);
  
  // Second pass: calculate work time for each day
  Object.keys(entriesByDay).forEach(dateString => {
    const dayEntries = entriesByDay[dateString].entries;
    let dayTotalMinutes = 0;
    let clockInTime = null;
    let onBreak = false;
    let breakStartTime = null;
    
    for (const entry of dayEntries) {
      switch (entry.status) {
        case 'clock-in':
          clockInTime = entry.timestamp;
          break;
        case 'clock-out':
        case 'auto-clock-out':
          if (clockInTime) {
            // Calculate time worked
            let workTime = entry.timestamp - clockInTime;
            
            // Convert to minutes
            dayTotalMinutes += Math.floor(workTime / (60 * 1000));
            
            // Add overtime minutes if available
            if (entry.overtime_minutes) {
              totalOvertimeMinutesManual += entry.overtime_minutes;
            }
            
            // Reset clock in time
            clockInTime = null;
          }
          break;
        case 'break':
          if (clockInTime && !onBreak) {
            // Calculate time worked until break
            let workTime = entry.timestamp - clockInTime;
            
            // Convert to minutes
            dayTotalMinutes += Math.floor(workTime / (60 * 1000));
            
            // Set break state
            onBreak = true;
            breakStartTime = entry.timestamp;
            
            // Update clock in time for after break
            clockInTime = entry.timestamp;
          }
          break;
        case 'break-end':
          if (onBreak && breakStartTime) {
            // Reset break state
            onBreak = false;
            breakStartTime = null;
            
            // Update clock in time
            clockInTime = entry.timestamp;
          }
          break;
      }
    }
    
    // Store total minutes for this day
    entriesByDay[dateString].totalMinutes = dayTotalMinutes;
    
    // Calculate overtime for this day based on regularWorkHours
    const regularWorkMinutes = regularWorkHours * 60;
    const dayOvertimeMinutes = Math.max(0, dayTotalMinutes - regularWorkMinutes);
    entriesByDay[dateString].overtimeMinutes = dayOvertimeMinutes;
    
    // Log the calculation for this day
    console.log(`Day ${dateString} overtime calculation:`, {
      dayTotalMinutes,
      regularWorkMinutes,
      dayOvertimeMinutes
    });
    
    // Add to total counters
    totalMinutes += dayTotalMinutes;
    totalOvertimeMinutesManual += dayOvertimeMinutes;
  });

  // Convert minutes to hours
  const totalHours = parseFloat((totalMinutes / 60).toFixed(2));
  
  // Calculate regular and overtime hours based on regularWorkHours
  // Regular hours should be capped at regularWorkHours
  const regularHours = Math.min(totalHours, regularWorkHours);
  const overtimeHours = Math.max(0, totalHours - regularWorkHours);
  
  // Log the final calculation results
  console.log('Final calculation results:', {
    totalMinutes,
    totalHours,
    totalOvertimeMinutesManual,
    overtimeHours,
    regularHours,
    regularWorkHours
  });
  
  return {
    totalHours,
    overtimeHours,
    regularHours
  };
}

/**
 * Check if a timesheet already exists for a specific week
 * @param {string} userId - The user ID
 * @param {Date} startDate - The start date of the week
 * @param {Date} endDate - The end date of the week
 * @returns {Promise<boolean>} - Whether a timesheet exists
 */
export async function checkTimesheetExists(userId, startDate, endDate) {
  try {
    if (!isOnline()) {
      // Check in local storage if offline
      return checkOfflineTimesheetExists(userId, startDate, endDate);
    }

    const { data, error } = await supabase
      .from('timesheets')
      .select('id')
      .eq('user_id', userId)
      .eq('start_date', startDate.toISOString())
      .eq('end_date', endDate.toISOString())
      .single();

    if (error && error.code === 'PGRST116') {
      // No timesheet found
      return false;
    }

    if (error) throw error;

    // Timesheet exists
    return !!data;
  } catch (error) {
    console.error('Error checking if timesheet exists:', error);
    return checkOfflineTimesheetExists(userId, startDate, endDate);
  }
}

/**
 * Check if an offline timesheet exists for a specific week
 * @param {string} userId - The user ID
 * @param {Date} startDate - The start date of the week
 * @param {Date} endDate - The end date of the week
 * @returns {boolean} - Whether an offline timesheet exists
 */
function checkOfflineTimesheetExists(userId, startDate, endDate) {
  const offlineTimesheets = JSON.parse(localStorage.getItem('offlineTimesheets') || '[]');
  
  return offlineTimesheets.some(timesheet => {
    return (
      timesheet.user_id === userId &&
      new Date(timesheet.start_date).getTime() === startDate.getTime() &&
      new Date(timesheet.end_date).getTime() === endDate.getTime() &&
      !timesheet._deleted
    );
  });
}

/**
 * Submit a timesheet
 * @param {Object} timesheetData - The timesheet data
 * @param {string} timesheetData.userId - The user ID
 * @param {Date} timesheetData.startDate - The start date of the week
 * @param {Date} timesheetData.endDate - The end date of the week
 * @param {number} timesheetData.totalHours - The total hours worked
 * @param {Array} timesheetData.timeTrackIds - The IDs of time track entries
 * @param {string} [timesheetData.notes] - Notes for the timesheet
 * @param {string} [timesheetData.projectId] - The project ID (for future implementation)
 * @returns {Promise<Object>} - The submitted timesheet
 */
export async function submitTimesheet(timesheetData) {
  const {
    userId,
    startDate,
    endDate,
    totalHours,
    overtimeHours = 0,
    regularHours = totalHours,
    timeTrackIds,
    notes,
    projectId
  } = timesheetData;

  // Calculate week number
  const weekNumber = getWeekNumber(startDate);

  // Create timesheet object
  const timesheet = {
    user_id: userId,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    week_number: weekNumber,
    total_hours: totalHours,
    overtime_hours: overtimeHours,
    regular_hours: regularHours,
    status: 'pending',
    notes,
    project_id: projectId,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  };

  try {
    if (!isOnline()) {
      // Store in local storage if offline
      return storeOfflineTimesheet(timesheet, timeTrackIds);
    }

    // Insert timesheet
    const { data: timesheetData, error: timesheetError } = await supabase
      .from('timesheets')
      .insert([timesheet])
      .select()
      .single();

    if (timesheetError) throw timesheetError;

    // Insert timesheet entries
    const timesheetEntries = timeTrackIds.map(timeTrackId => ({
      timesheet_id: timesheetData.id,
      time_track_id: timeTrackId
    }));

    const { error: entriesError } = await supabase
      .from('timesheet_entries')
      .insert(timesheetEntries);

    if (entriesError) throw entriesError;

    // Mark time track entries as submitted
    const { error: updateError } = await supabase
      .from('time_track')
      .update({ submitted_for_timesheet: true })
      .in('id', timeTrackIds);

    if (updateError) throw updateError;

    return timesheetData;
  } catch (error) {
    console.error('Error submitting timesheet:', error);
    
    // Store in local storage if online submission fails
    return storeOfflineTimesheet(timesheet, timeTrackIds);
  }
}

/**
 * Store a timesheet in local storage for offline support
 * @param {Object} timesheet - The timesheet data
 * @param {Array} timeTrackIds - The IDs of time track entries
 * @returns {Object} - The stored timesheet
 */
function storeOfflineTimesheet(timesheet, timeTrackIds) {
  // Generate a local ID
  const localId = uuidv4();
  
  // Add local ID to timesheet
  const timesheetWithId = {
    ...timesheet,
    id: localId,
    synced: false
  };
  
  // Store timesheet in local storage
  const offlineTimesheets = JSON.parse(localStorage.getItem('offlineTimesheets') || '[]');
  offlineTimesheets.push(timesheetWithId);
  localStorage.setItem('offlineTimesheets', JSON.stringify(offlineTimesheets));
  
  // Store timesheet entries in local storage
  const offlineTimesheetEntries = JSON.parse(localStorage.getItem('offlineTimesheetEntries') || '[]');
  
  timeTrackIds.forEach(timeTrackId => {
    offlineTimesheetEntries.push({
      id: uuidv4(),
      timesheet_id: localId,
      time_track_id: timeTrackId,
      synced: false
    });
  });
  
  localStorage.setItem('offlineTimesheetEntries', JSON.stringify(offlineTimesheetEntries));
  
  // Mark time track entries as submitted in local storage
  const offlineTimeTrackEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  
  timeTrackIds.forEach(timeTrackId => {
    const index = offlineTimeTrackEntries.findIndex(entry => 
      entry.id === timeTrackId || entry.local_id === timeTrackId
    );
    
    if (index !== -1) {
      offlineTimeTrackEntries[index].submitted_for_timesheet = true;
    }
  });
  
  localStorage.setItem('offlineTimeTrackEntries', JSON.stringify(offlineTimeTrackEntries));
  
  return timesheetWithId;
}

/**
 * Sync offline timesheets with the server
 * @param {string} userId - The user ID
 * @returns {Promise<Array>} - The synced timesheets
 */
export async function syncOfflineTimesheets(userId) {
  const offlineTimesheets = JSON.parse(localStorage.getItem('offlineTimesheets') || '[]')
    .filter(timesheet => timesheet.user_id === userId && !timesheet.synced && !timesheet._deleted);
  
  if (offlineTimesheets.length === 0) {
    return [];
  }
  
  const syncedTimesheets = [];
  
  for (const offlineTimesheet of offlineTimesheets) {
    try {
      // Get timesheet entries for this timesheet
      const offlineTimesheetEntries = JSON.parse(localStorage.getItem('offlineTimesheetEntries') || '[]')
        .filter(entry => entry.timesheet_id === offlineTimesheet.id && !entry.synced);
      
      // Get time track IDs
      const timeTrackIds = offlineTimesheetEntries.map(entry => entry.time_track_id);
      
      // Remove local ID from timesheet
      const { id, synced, ...timesheetData } = offlineTimesheet;
      
      // Insert timesheet
      const { data: insertedTimesheet, error: timesheetError } = await supabase
        .from('timesheets')
        .insert([timesheetData])
        .select()
        .single();
      
      if (timesheetError) throw timesheetError;
      
      // Insert timesheet entries
      const timesheetEntries = timeTrackIds.map(timeTrackId => ({
        timesheet_id: insertedTimesheet.id,
        time_track_id: timeTrackId
      }));
      
      const { error: entriesError } = await supabase
        .from('timesheet_entries')
        .insert(timesheetEntries);
      
      if (entriesError) throw entriesError;
      
      // Mark time track entries as submitted
      const { error: updateError } = await supabase
        .from('time_track')
        .update({ submitted_for_timesheet: true })
        .in('id', timeTrackIds);
      
      if (updateError) throw updateError;
      
      // Mark timesheet as synced in local storage
      markOfflineTimesheetAsSynced(offlineTimesheet.id);
      
      // Mark timesheet entries as synced in local storage
      markOfflineTimesheetEntriesAsSynced(offlineTimesheet.id);
      
      syncedTimesheets.push(insertedTimesheet);
    } catch (error) {
      console.error('Error syncing offline timesheet:', error);
    }
  }
  
  return syncedTimesheets;
}

/**
 * Mark an offline timesheet as synced
 * @param {string} timesheetId - The timesheet ID
 */
function markOfflineTimesheetAsSynced(timesheetId) {
  const offlineTimesheets = JSON.parse(localStorage.getItem('offlineTimesheets') || '[]');
  
  const index = offlineTimesheets.findIndex(timesheet => timesheet.id === timesheetId);
  
  if (index !== -1) {
    offlineTimesheets[index].synced = true;
    localStorage.setItem('offlineTimesheets', JSON.stringify(offlineTimesheets));
  }
}

/**
 * Mark offline timesheet entries as synced
 * @param {string} timesheetId - The timesheet ID
 */
function markOfflineTimesheetEntriesAsSynced(timesheetId) {
  const offlineTimesheetEntries = JSON.parse(localStorage.getItem('offlineTimesheetEntries') || '[]');
  
  const updatedEntries = offlineTimesheetEntries.map(entry => {
    if (entry.timesheet_id === timesheetId) {
      return { ...entry, synced: true };
    }
    return entry;
  });
  
  localStorage.setItem('offlineTimesheetEntries', JSON.stringify(updatedEntries));
}

/**
 * Get submitted timesheets for a user
 * @param {string} userId - The user ID
 * @returns {Promise<Array>} - The submitted timesheets
 */
export async function getSubmittedTimesheets(userId) {
  try {
    if (!isOnline()) {
      // Get from local storage if offline
      return getOfflineSubmittedTimesheets(userId);
    }

    const { data, error } = await supabase
      .from('timesheets')
      .select(`
        *,
        approver:approver_id(id, email)
      `)
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) throw error;

    // Merge with offline timesheets
    const offlineTimesheets = getOfflineSubmittedTimesheets(userId);
    const mergedTimesheets = [...(data || []), ...offlineTimesheets];
    
    // Sort by created_at
    return mergedTimesheets.sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  } catch (error) {
    console.error('Error getting submitted timesheets:', error);
    return getOfflineSubmittedTimesheets(userId);
  }
}

/**
 * Get offline submitted timesheets for a user
 * @param {string} userId - The user ID
 * @returns {Array} - The offline submitted timesheets
 */
function getOfflineSubmittedTimesheets(userId) {
  const offlineTimesheets = JSON.parse(localStorage.getItem('offlineTimesheets') || '[]');
  
  return offlineTimesheets.filter(timesheet => 
    timesheet.user_id === userId && !timesheet._deleted
  );
}

/**
 * Get timesheet details including time track entries
 * @param {string} timesheetId - The timesheet ID
 * @returns {Promise<Object>} - The timesheet details
 */
export async function getTimesheetDetails(timesheetId) {
  try {
    if (!isOnline()) {
      // Get from local storage if offline
      return getOfflineTimesheetDetails(timesheetId);
    }

    // Get timesheet
    const { data: timesheet, error: timesheetError } = await supabase
      .from('timesheets')
      .select(`
        *,
        approver:approver_id(id, email)
      `)
      .eq('id', timesheetId)
      .single();

    if (timesheetError) throw timesheetError;

    // Get timesheet entries
    const { data: timesheetEntries, error: entriesError } = await supabase
      .from('timesheet_entries')
      .select('time_track_id')
      .eq('timesheet_id', timesheetId);

    if (entriesError) throw entriesError;

    // Get time track entries
    const timeTrackIds = timesheetEntries.map(entry => entry.time_track_id);
    
    const { data: timeTrackEntries, error: timeTrackError } = await supabase
      .from('time_track')
      .select('*')
      .in('id', timeTrackIds)
      .order('timestamp', { ascending: true });

    if (timeTrackError) throw timeTrackError;

    return {
      ...timesheet,
      timeTrackEntries: timeTrackEntries || []
    };
  } catch (error) {
    console.error('Error getting timesheet details:', error);
    return getOfflineTimesheetDetails(timesheetId);
  }
}

/**
 * Get offline timesheet details
 * @param {string} timesheetId - The timesheet ID
 * @returns {Object} - The offline timesheet details
 */
function getOfflineTimesheetDetails(timesheetId) {
  const offlineTimesheets = JSON.parse(localStorage.getItem('offlineTimesheets') || '[]');
  const offlineTimesheetEntries = JSON.parse(localStorage.getItem('offlineTimesheetEntries') || '[]');
  const offlineTimeTrackEntries = JSON.parse(localStorage.getItem('offlineTimeTrackEntries') || '[]');
  
  // Find timesheet
  const timesheet = offlineTimesheets.find(timesheet => timesheet.id === timesheetId);
  
  if (!timesheet) {
    return null;
  }
  
  // Find timesheet entries
  const timesheetEntries = offlineTimesheetEntries.filter(entry => entry.timesheet_id === timesheetId);
  
  // Find time track entries
  const timeTrackIds = timesheetEntries.map(entry => entry.time_track_id);
  const timeTrackEntries = offlineTimeTrackEntries.filter(entry => 
    timeTrackIds.includes(entry.id) || timeTrackIds.includes(entry.local_id)
  );
  
  return {
    ...timesheet,
    timeTrackEntries: timeTrackEntries.sort((a, b) => a.timestamp - b.timestamp)
  };
}

/**
 * Check if the device is online
 * @returns {boolean} - Whether the device is online
 */
export function isDeviceOnline() {
  return isOnline();
}
