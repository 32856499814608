import React, { useState, useEffect } from 'react';
import { StoreContext } from '../Hooks/store';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';
import { formatDistanceStrict } from 'date-fns';
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  Button,
  Snackbar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { 
  getTodoItems, 
  createTodoItem, 
  updateTodoItemDescription, 
  toggleTodoItemCompletion, 
  deleteTodoItem, 
  updateTodoItemTimeSpent, 
  updateTodoItemStartTime,
  addTodoTimeSpentHistory,
  getTotalTimeSpentOnTodo
} from '../../api/todo';
import { v4 as uuidv4 } from 'uuid';
import { inferActionVerb } from './utils/actionVerbs';

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Todos({ todolistid }) {
  const {
    todolistsaved,
    todolistheader,
    thisuserid,
    modaldialog,
    tododirtycheck,
  } = React.useContext(StoreContext);

  const [, setLoadedmodal] = modaldialog;
  const [todos, setTodos] = todolistsaved;
  const [dirtytodos, setdirtytodos] = tododirtycheck;
  const [userid] = thisuserid;

  const [newTodo, setNewTodo] = useState('');
  const [isAddingTodo, setIsAddingTodo] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTodoIndex, setEditingTodoIndex] = useState(null);
  const [deletingTodoId, setDeletingTodoId] = useState(null);
  const [delTodoIdx, setDelTodoIdx] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [activeTimers, setActiveTimers] = useState({});
  const [totalTimeSpent, setTotalTimeSpent] = useState({});

  const [todoHeader] = todolistheader;
  
  // Initialize the user interaction tracker
  const { trackInteraction } = useUserInteractionTracker();

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // Show Snackbar function
  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Fetch todos for the selected todo list
  const fetchTodosForList = async () => {
    try {
      if (!todolistid) {
        throw new Error('No todo list selected');
      }

      const todoItems = await getTodoItems(todolistid); // Fetch todos for the selected list
      setTodos(todoItems || []); // Ensure todos is an array, even if API returns null
      
      // Fetch total time spent for each todo item
      const timeSpentMap = {};
      for (const todo of todoItems || []) {
        try {
          const totalMs = await getTotalTimeSpentOnTodo(todo.id);
          if (totalMs > 0) {
            timeSpentMap[todo.id] = totalMs;
          }
        } catch (error) {
          console.error(`Error fetching time spent for todo ${todo.id}:`, error);
        }
      }
      setTotalTimeSpent(timeSpentMap);
    } catch (error) {
      console.error('Error fetching todos:', error);
      showSnackbar('Error fetching todos', 'error');
    }
  };

  // Track page_view when the component mounts (only once)
  useEffect(() => {
    if (todolistid && userid) {
      // Track page_view when the component mounts
      trackInteraction('page_view', {
        componentName: 'Todos',
        timestamp: Date.now(),
        userId: userid
      });
    }
  }, []); // Empty dependency array means this runs once on mount

  // Fetch todos when the component opens or the selected todo list changes
  useEffect(() => {
    if (todolistid) {
      fetchTodosForList();
    }
  }, [todolistid]); // Only depend on todolistid

  // Load active timers from localStorage on component mount
  useEffect(() => {
    const storedTimers = localStorage.getItem(`todo_timers_${todolistid}`);
    if (storedTimers) {
      setActiveTimers(JSON.parse(storedTimers));
    }
  }, [todolistid]);

  // Set up interval to update active timers display
  useEffect(() => {
    // Only set up the interval if there are active timers
    if (Object.keys(activeTimers).length === 0) return;
    
    // Update the component every second to show the current elapsed time
    const intervalId = setInterval(() => {
      // Force a re-render to update the timer display
      setTodos([...todos]);
    }, 1000);
    
    // Clean up the interval when the component unmounts or timers change
    return () => clearInterval(intervalId);
  }, [activeTimers, todos]);

  // Handle adding a new todo
  const handleAddedTodo = async () => {
    if (newTodo.trim() === '') {
      showSnackbar('Todo cannot be empty', 'error');
      return;
    }
  
    // Optimistically update the local state
    const tempTodo = {
      id: uuidv4(), // Temporary ID (will be replaced by the API response)
      user_id: userid,
      list_id: todolistid,
      description: newTodo.trim(),
      completed: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
  
    // Add the temporary todo to the list
    setTodos((prevTodos) => [...(prevTodos || []), tempTodo]);
  
    // Clear the input field
    setNewTodo('');
    setIsAddingTodo(false);
  
    try {
      // Call the API to create the todo item
      const newTodoItem = await createTodoItem(userid, todolistid, newTodo.trim(), false);
  
      // Replace the temporary todo with the actual todo from the API response
      setTodos((prevTodos) =>
        prevTodos.map((todo) => (todo.id === tempTodo.id ? newTodoItem : todo))
      );
  
      showSnackbar('Todo added!', 'success');
    } catch (error) {
      console.error('Error adding todo:', error);
      showSnackbar(`Error adding todo: ${error.message}`, 'error');
  
      // Rollback the optimistic update if the API call fails
      setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== tempTodo.id));
    }
  };

  // Handle toggling todo completion
  const handleToggleTodo = async (index) => {
    const updatedTodos = [...(todos || [])]; // Ensure todos is an array
    const todo = updatedTodos[index];
    if (!todo) return; // Skip if todo is null or undefined

    try {
      // If the todo is being completed and has an active timer, stop the timer and mark as completed
      if (!todo.completed && activeTimers[todo.id]) {
        await handleStopTimer(todo.id, true); // Pass true to indicate we want to complete the todo
        return; // handleStopTimer will update the todo status
      }
      
      // Track the completion action if the todo is being completed (not uncompleted)
      if (!todo.completed) {
        trackInteraction('todo_complete', {
          componentName: 'Todos',
          timestamp: Date.now(),
          userId: userid
        });
      }

      // If the todo is being completed, calculate the time between start and completion
      let timeSpentText = null;
      let timeSpentMs = 0;
      let startTimeISO = null;
      
      if (!todo.completed) {
        // This is the case where a todo is being completed
        const completionTime = new Date();
        const endTimeISO = completionTime.toISOString();
        let startTime;
        
        // Use started_at if available, otherwise fall back to created_at
        if (todo.started_at) {
          startTime = new Date(todo.started_at);
          startTimeISO = todo.started_at;
          timeSpentMs = completionTime - startTime;
          timeSpentText = `Completed in: ${formatTimeSpent(timeSpentMs)}`;
        } else if (todo.created_at) {
          startTime = new Date(todo.created_at);
          startTimeISO = todo.created_at;
          timeSpentMs = completionTime - startTime;
          timeSpentText = `Completed in: ${formatTimeSpent(timeSpentMs)}`;
        }
        
        if (timeSpentText) {
          todo.time_spent_text = timeSpentText;
          todo.ended_at = endTimeISO;
          
          // Only add a record to the time spent history table if the todo doesn't already have time spent
          // This prevents double-counting when completing a todo after stopping the timer
          if (timeSpentMs > 0 && startTimeISO && !todo.time_spent) {
            try {
              await addTodoTimeSpentHistory(todo.id, userid, timeSpentMs, startTimeISO, endTimeISO);
              
              // Update the total time spent for this todo
              const totalMs = await getTotalTimeSpentOnTodo(todo.id);
              if (totalMs > 0) {
                setTotalTimeSpent(prev => ({
                  ...prev,
                  [todo.id]: totalMs
                }));
              }
            } catch (error) {
              console.error('Error adding time spent history:', error);
            }
          }
        }
      } else {
        // This is the case where a todo is being uncompleted
        // We don't need to do anything special here, just toggle the completion status
      }

      await toggleTodoItemCompletion(todo.id, !todo.completed, timeSpentText); // Update completion status in the database
      updatedTodos[index].completed = !todo.completed; // Update completion status in the local state
      setTodos(updatedTodos);
      showSnackbar(todo.completed ? 'Todo marked as incomplete' : 'Todo completed!', 'success');
    } catch (error) {
      console.error('Error toggling todo:', error);
      showSnackbar('Error toggling todo', 'error');
    }
  };

  // State for confirmation dialog when starting timer on completed todo
  const [confirmStartDialogOpen, setConfirmStartDialogOpen] = useState(false);
  const [todoToStart, setTodoToStart] = useState(null);

  // Start timer for a todo item
  const handleStartTimer = async (todoId) => {
    // Find the todo item
    const todo = todos.find(todo => todo.id === todoId);
    
    // If the todo is completed, show confirmation dialog
    if (todo && todo.completed) {
      setTodoToStart(todo);
      setConfirmStartDialogOpen(true);
      return;
    }
    
    // Otherwise, start the timer normally
    await startTimerForTodo(todoId);
  };
  
  // Function to actually start the timer after confirmation if needed
  const startTimerForTodo = async (todoId) => {
    const startTime = new Date().toISOString();
    const updatedTimers = {
      ...activeTimers,
      [todoId]: startTime
    };
    
    // Track the start action
    trackInteraction('todo_start', {
      componentName: 'Todos',
      timestamp: Date.now(),
      userId: userid
    });
    
    // Find the todo in the list
    const updatedTodos = [...todos];
    const todoIndex = updatedTodos.findIndex(todo => todo.id === todoId);
    
    if (todoIndex === -1) return; // Skip if todo not found
    
    // If the todo was completed, mark it as not completed
    if (updatedTodos[todoIndex].completed) {
      updatedTodos[todoIndex].completed = false;
      
      // Update the completion status in the database
      try {
        await toggleTodoItemCompletion(todoId, false);
      } catch (error) {
        console.error('Error marking todo as not completed:', error);
        showSnackbar('Error marking todo as not completed', 'error');
        return; // Exit if we can't mark as not completed
      }
    }
    
    // Save the start time to the database
    try {
      await updateTodoItemStartTime(todoId, startTime);
      
      // Update the active timers state
      setActiveTimers(updatedTimers);
      localStorage.setItem(`todo_timers_${todolistid}`, JSON.stringify(updatedTimers));
      
      // Update the todos state to trigger a re-render
      updatedTodos[todoIndex].started_at = startTime; // Add the start time to the todo item
      setTodos([...updatedTodos]); // Use a new array reference to ensure re-render
      
      showSnackbar('Timer started!', 'info');
    } catch (error) {
      console.error('Error saving start time:', error);
      showSnackbar('Timer started, but failed to save start time', 'warning');
    }
  };
  
  // Handle confirming start timer on completed todo
  const handleConfirmStartTimer = async () => {
    if (!todoToStart) return;
    
    await startTimerForTodo(todoToStart.id);
    setConfirmStartDialogOpen(false);
    setTodoToStart(null);
  };
  
  // Handle canceling start timer on completed todo
  const handleCancelStartTimer = () => {
    setConfirmStartDialogOpen(false);
    setTodoToStart(null);
  };

  // Stop timer for a todo item
  const handleStopTimer = async (todoId, completeAfterStop = false) => {
    if (!activeTimers[todoId]) return;

    const updatedTodos = [...(todos || [])];
    const todoIndex = updatedTodos.findIndex(todo => todo.id === todoId);
    
    if (todoIndex === -1) return;

    const todo = updatedTodos[todoIndex];
    
    // Track the stop action
    trackInteraction('todo_stop', {
      componentName: 'Todos',
      timestamp: Date.now(),
      userId: userid
    });
    const startTime = new Date(activeTimers[todoId]);
    const startTimeISO = startTime.toISOString();
    const endTime = new Date();
    const endTimeISO = endTime.toISOString();
    
    // Calculate time spent
    const timeSpentMs = endTime - startTime;
    const timeSpentText = completeAfterStop 
      ? `Completed in: ${formatTimeSpent(timeSpentMs)}`
      : `Time spent: ${formatTimeSpent(timeSpentMs)}`;
    
    // Update the todo with time tracking information
    todo.time_spent = timeSpentMs;
    todo.time_start = startTimeISO;
    todo.time_end = endTimeISO;
    todo.ended_at = endTimeISO;
    todo.time_spent_text = timeSpentText;
    
    // If we're completing the todo after stopping the timer, update the completed status
    if (completeAfterStop) {
      todo.completed = true;
    }
    
    setTodos(updatedTodos);
    
    // Remove the timer from active timers
    const newActiveTimers = { ...activeTimers };
    delete newActiveTimers[todoId];
    
    setActiveTimers(newActiveTimers);
    localStorage.setItem(`todo_timers_${todolistid}`, JSON.stringify(newActiveTimers));
    
    try {
      // Save the time spent text to the todo item
      await updateTodoItemTimeSpent(todoId, timeSpentText, endTimeISO);
      
      // Add a record to the time spent history table
      await addTodoTimeSpentHistory(todoId, userid, timeSpentMs, startTimeISO, endTimeISO);
      
      // Update the total time spent for this todo
      const totalMs = await getTotalTimeSpentOnTodo(todoId);
      if (totalMs > 0) {
        setTotalTimeSpent(prev => ({
          ...prev,
          [todoId]: totalMs
        }));
      }
      
      // If we're completing the todo after stopping the timer, update the completion status in the database
      if (completeAfterStop) {
        await toggleTodoItemCompletion(todoId, true, timeSpentText);
        showSnackbar('Timer stopped and todo completed!', 'success');
      } else {
        showSnackbar('Timer stopped and time recorded!', 'success');
      }
    } catch (error) {
      console.error('Error saving time spent:', error);
      showSnackbar('Timer stopped, but failed to save time data', 'warning');
    }
  };

  // Format time spent in a human-readable format
  const formatTimeSpent = (timeSpent) => {
    const seconds = Math.floor(timeSpent / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    
    if (hours > 0) {
      return `${hours}h ${minutes % 60}m`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds % 60}s`;
    } else {
      return `${seconds}s`;
    }
  };

  // Calculate and format ongoing time
  const calculateOngoingTime = (startTimeStr) => {
    const startTime = new Date(startTimeStr);
    const now = new Date();
    const timeSpent = now - startTime;
    return formatTimeSpent(timeSpent);
  };

  // Handle saving a todo (updating description)
  const handleSaveTodo = async (index) => {
    const updatedTodos = [...(todos || [])]; // Ensure todos is an array
    const todo = updatedTodos[index];
    if (!todo) return; // Skip if todo is null or undefined

    try {
      await updateTodoItemDescription(todo.id, todo.description); // Save the updated description to the database
      setEditingTodoIndex(null);
      setIsEditing(false);
      showSnackbar('Todo saved!', 'success');
    } catch (error) {
      console.error('Error saving todo:', error);
      showSnackbar('Error saving todo', 'error');
    }
  };

  // Handle deleting a todo
  const handleDeleteTodo = (index) => {
    const todo = todos?.[index]; // Ensure todos is an array
    if (!todo) return; // Skip if todo is null or undefined

    setDeletingTodoId(todo.id);
    setDelTodoIdx(index);
    setIsAlertOpen(true);
  };

  // Handle confirming todo deletion
  const handleConfirmDeleteTodo = async () => {
    try {
      await deleteTodoItem(deletingTodoId); // Delete the todo from the database
      const updatedTodos = (todos || []).filter((_, i) => i !== delTodoIdx); // Ensure todos is an array
      setTodos(updatedTodos);
      setIsAlertOpen(false);
      setDeletingTodoId(null);
      setDelTodoIdx(null);
      showSnackbar('Todo deleted!', 'success');
    } catch (error) {
      console.error('Error deleting todo:', error);
      showSnackbar('Error deleting todo', 'error');
    }
  };

  // Handle canceling todo deletion
  const handleCancelDeleteTodo = () => {
    setIsAlertOpen(false);
    setDeletingTodoId(null);
    setDelTodoIdx(null);
  };

  // Handle updating a todo item (local state only)
  const updateTodoitem = (index, e) => {
    const updatedTodos = [...(todos || [])]; // Ensure todos is an array
    const todo = updatedTodos[index];
    if (!todo) return; // Skip if todo is null or undefined

    todo.description = e.target.value;
    setTodos(updatedTodos);
  };

  // Handle editing a todo item
  const handleEditingTodo = (index) => {
    setEditingTodoIndex(index);
    setIsEditing(true);
  };

  return (
    <>
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isAlertOpen}
        onClose={handleCancelDeleteTodo}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Todo Item</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this todo item? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteTodo} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeleteTodo} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Start Timer Confirmation Dialog */}
      <Dialog
        open={confirmStartDialogOpen}
        onClose={handleCancelStartTimer}
        aria-labelledby="start-timer-dialog-title"
        aria-describedby="start-timer-dialog-description"
      >
        <DialogTitle id="start-timer-dialog-title">Continue Working on Completed Item</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This item is marked as completed. Do you want to continue working on it? 
            This will mark the item as not completed and start the timer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelStartTimer} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmStartTimer} color="primary">
            Continue Working
          </Button>
        </DialogActions>
      </Dialog>

      {/* Main Todo List UI */}
      <div style={{ width: '100%', visibility: isAlertOpen ? 'hidden' : 'visible' }}>
        {/* Todo List Header */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            {todoHeader}
          </Typography>
        </div>

        {/* Add New Todo Item */}
        <div>
          {isAddingTodo ? (
            <>
              <TextField
                autoFocus
                multiline
                style={{ width: '100%' }}
                value={newTodo}
                onChange={(e) => setNewTodo(e.target.value)}
                onBlur={() => {
                  if (newTodo.trim() === '') {
                    setIsAddingTodo(false);
                  }
                  if (newTodo.trim() !== '') {
                    handleAddedTodo();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddedTodo();
                  }
                }}
              />
              <div>
                <SaveOutlinedIcon onClick={handleAddedTodo} />
              </div>
            </>
          ) : (
            <Button variant="contained" color="primary" onClick={() => setIsAddingTodo(true)}>
              <AddOutlinedIcon />
            </Button>
          )}
        </div>

        {/* Todo List Items */}
        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
          <List>
            {(todos || [])
              .sort((a, b) => {
                // First sort by updated_at (newest first)
                if (a.updated_at && b.updated_at) {
                  return new Date(b.updated_at) - new Date(a.updated_at);
                }
                // If updated_at is not available or equal, sort by created_at (newest first)
                return new Date(b.created_at) - new Date(a.created_at);
              })
              .map((todo, index) => (
                <ListItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox 
                    checked={todo?.completed} 
                    onChange={() => handleToggleTodo(index)}
                    disabled={activeTimers[todo?.id] ? true : false} // Disable checkbox if timer is running
                  />
                  {editingTodoIndex === index ? (
                    <>
                      <TextField
                        autoFocus
                        multiline
                        style={{ width: '100%' }}
                        value={todo?.description || ''}
                        onChange={(e) => updateTodoitem(index, e)}
                        onBlur={() => handleSaveTodo(index)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSaveTodo(index);
                          }
                        }}
                      />
                      <SaveOutlinedIcon onClick={() => handleSaveTodo(index)} />
                    </>
                  ) : (
                    <>
                      <ListItemText 
                        primary={todo?.description} 
                        onClick={() => handleEditingTodo(index)} 
                        secondary={
                          todo?.time_spent_text ? (
                            <span style={{ 
                              fontStyle: 'italic', 
                              fontSize: '0.8rem', 
                              color: '#666' 
                            }}>
                              {todo.time_spent_text}
                              {totalTimeSpent[todo.id] && totalTimeSpent[todo.id] > 0 ? 
                                ` (Total: ${formatTimeSpent(totalTimeSpent[todo.id])})` : ''}
                            </span>
                          ) : todo?.completed && todo?.ended_at ? (
                            <span style={{ 
                              fontStyle: 'italic', 
                              fontSize: '0.8rem', 
                              color: '#666' 
                            }}>
                              Completed in: {formatDistanceStrict(
                                new Date(todo.ended_at), 
                                new Date(todo.started_at || todo.created_at)
                              )}
                              {totalTimeSpent[todo.id] && totalTimeSpent[todo.id] > 0 ? 
                                ` (Total: ${formatTimeSpent(totalTimeSpent[todo.id])})` : ''}
                            </span>
                          ) : todo?.time_spent ? (
                            <span style={{ 
                              fontStyle: 'italic', 
                              fontSize: '0.8rem', 
                              color: '#666' 
                            }}>
                              Time spent: {formatTimeSpent(todo.time_spent)}
                              {totalTimeSpent[todo.id] && totalTimeSpent[todo.id] > 0 ? 
                                ` (Total: ${formatTimeSpent(totalTimeSpent[todo.id])})` : ''}
                            </span>
                          ) : activeTimers[todo?.id] ? (
                            <span style={{ 
                              fontStyle: 'italic', 
                              fontSize: '0.8rem', 
                              color: '#2196f3' 
                            }}>
                              {todoHeader && (todoHeader.toLowerCase().includes('gym') || todoHeader.toLowerCase().includes('exercise') || todoHeader.toLowerCase().includes('workout')) 
                                ? `Doing: ${todo.description.length > 20 ? todo.description.slice(0, 20) + '...' : todo.description}`
                                : `${inferActionVerb(todo.description.length > 20 ? todo.description.slice(0, 20) + '...' : todo.description)}`}: {calculateOngoingTime(activeTimers[todo.id])}
                              {totalTimeSpent[todo.id] && totalTimeSpent[todo.id] > 0 ? 
                                ` (Total: ${formatTimeSpent(totalTimeSpent[todo.id])})` : ''}
                            </span>
                          ) : totalTimeSpent[todo.id] && totalTimeSpent[todo.id] > 0 ? (
                            <span style={{ 
                              fontStyle: 'italic', 
                              fontSize: '0.8rem', 
                              color: '#666' 
                            }}>
                              Total time: {formatTimeSpent(totalTimeSpent[todo.id])}
                            </span>
                          ) : null
                        }
                      />
                      <IconButton 
                        aria-label={activeTimers[todo?.id] ? "Stop timer" : "Start timer"} 
                        onClick={(e) => {
                          e.stopPropagation();
                          activeTimers[todo?.id] 
                            ? handleStopTimer(todo.id, true) // Pass true to mark as completed when stopping
                            : handleStartTimer(todo.id);
                        }}
                        color={activeTimers[todo?.id] ? "error" : "primary"}
                        disabled={todo?.completed} // Disable start button for completed items
                      >
                        {activeTimers[todo?.id] ? <StopIcon /> : <PlayArrowIcon />}
                      </IconButton>
                      <IconButton 
                        aria-label="Edit this item" 
                        onClick={() => handleEditingTodo(index)}
                        disabled={activeTimers[todo?.id] || todo?.completed} // Disable edit when timer running or completed
                      >
                        <EditNoteOutlinedIcon />
                      </IconButton>
                      <IconButton 
                        aria-label="Remove this item" 
                        onClick={() => handleDeleteTodo(index)}
                        disabled={activeTimers[todo?.id]} // Disable delete when timer running
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
          </List>
        </div>
      </div>
    </>
  );
}
