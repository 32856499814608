
import MailIcon from '@mui/icons-material/Mail';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Icon for Time Card
import TimerIcon from '@mui/icons-material/Timer'; // Icon for TimeTrack
import AssignmentIcon from '@mui/icons-material/Assignment'; // Icon for Homework
import DescriptionIcon from '@mui/icons-material/Description'; // Icon for Timesheet
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'; // Icon for Clear Offline Data

/**
 * Function to generate menu items for the custom menu control.
 * @param {function} setOpenDialog - Function to set the currently open dialog.
 * @param {function} handleProfileMenuOpen - Function to handle profile menu opening.
 * @returns {Array} - Array of menu items.
 */
const getMenuItems = (setOpenDialog, handleProfileMenuOpen) => [
  {
    label: "Todos",
    icon: <MailIcon />,
    onClick: () => setOpenDialog('Todolist'), // Open TodoList dialog
  },
  {
    label: "Time Track",
    icon: <TimerIcon />, // Use a timer icon
    onClick: () => setOpenDialog('TimeTrack'), // Open TimeTrack dialog
  },
  {
    label: "Timesheet",
    icon: <DescriptionIcon />, // Use a description icon
    onClick: () => setOpenDialog('Timesheet'), // Open Timesheet dialog
  },
  {
    label: "Time Card",
    icon: <AccessTimeIcon />, // Use a simple icon
    onClick: () => setOpenDialog('TimeCard'), // Open TimeCard dialog
  },
  {
    label: "Assignment",
    icon: <AssignmentIcon />, // Use a simple icon
    onClick: () => setOpenDialog('AssignmentDialog'), // Open AssignmentDialog
  },
  {
    label: "Clear All Offline Data",
    icon: <DeleteSweepIcon />,
    onClick: () => {
      try {
        // First alert the user to sync any pending data
        alert('Please sync any pending offline data before clearing');
        
        // Ask for confirmation
        const confirmClear = window.confirm(
          'Are you sure you want to clear all offline data? ' +
          'This will delete any pending syncs and locally stored information. ' +
          'This action cannot be undone.'
        );
        
        // Only proceed if the user confirms
        if (confirmClear) {
          // Clear all localStorage
          localStorage.clear();
          
          // Log success message
          console.log('All offline data cleared successfully');
          
          // Show success message if possible
          if (typeof alert !== 'undefined') {
            alert('All offline data cleared successfully');
          }
        } else {
          // User canceled the operation
          console.log('Clear offline data operation canceled');
          
          // Show canceled message if possible
          if (typeof alert !== 'undefined') {
            alert('Operation canceled. Your offline data is safe.');
          }
        }
      } catch (error) {
        console.error('Failed to clear offline data:', error);
        
        // Show error message if possible
        if (typeof alert !== 'undefined') {
          alert('Failed to clear offline data. Please try again.');
        }
      }
    },
  },
];

export default getMenuItems;
