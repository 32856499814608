import { useEffect } from 'react';
import { saveUserDevice } from '../../../../api/userDevice';

/**
 * Hook for tracking device information
 * @param {Object} params - Parameters
 * @param {string} params.userId - User ID
 * @param {Object} params.deviceid - Device ID object with dvc_hash and dvc_json
 * @returns {Object} Device tracking state and functions
 */
export function useDeviceTracking({ userId, deviceid }) {
  // Save device information when user is authenticated and when the component mounts
  useEffect(() => {
    if (userId && deviceid && deviceid.dvc_hash) {
      // Save device information to the database
      saveUserDevice(userId, deviceid)
        .then(savedDevice => {
          console.log('Device information saved on component mount:', savedDevice);
        })
        .catch(error => {
          console.error('Error saving device information:', error);
        });
    }
  }, [userId, deviceid]);

  /**
   * Save device information to the database
   * @returns {Promise<Object>} Saved device information
   */
  const saveDeviceInfo = async () => {
    if (!userId || !deviceid || !deviceid.dvc_hash) {
      console.warn('Cannot save device info: missing userId or deviceid');
      return null;
    }

    try {
      const savedDevice = await saveUserDevice(userId, deviceid);
      console.log('Device information saved:', savedDevice);
      return savedDevice;
    } catch (error) {
      console.error('Error saving device information:', error);
      return null;
    }
  };

  return {
    saveDeviceInfo
  };
}
