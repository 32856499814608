import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { StatusIcon } from '../Shared/StatusIcon';
import { StoreContext } from '../Hooks/store';
import { useTimeTrackContext } from '../TimeTrack/context/TimeTrackContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Create a custom hook to safely use the TimeTrackContext
const useTimeTrackContextSafely = () => {
  try {
    return useTimeTrackContext();
  } catch (error) {
    console.log('TimeTrackContext not available:', error);
    return null;
  }
};

export default function TimeCardStatus({ statuscolorTimecard, status }) {
  const [shiftDuration, setShiftDuration] = useState('0h 0m 0s');
  const store = useContext(StoreContext) || {};
  const [user] = store?.user || [null];
  const userId = user?.id;
  
  // Get the TimeTrack context to use its functions
  const timeTrackContext = useTimeTrackContextSafely();
  
  // Get the clock state and calculate work time function from context if available
  const clockState = timeTrackContext?.clockState;
  const calculateWorkTime = timeTrackContext?.calculateWorkTime;
  const entries = timeTrackContext?.entries;
  
  // Update shift duration in real-time when clocked in
  useEffect(() => {
    if (!clockState?.clockedIn) {
      setShiftDuration('0h 0m 0s');
      return;
    }
    
    const updateDuration = () => {
      if (!clockState.clockInTime) return;
      
      // Find the most recent clock-in entry
      const clockInEntry = entries?.find(entry => entry.status === 'clock-in');
      
      // Use the clock-in entry timestamp if available, otherwise use clockState.clockInTime
      const clockInTime = clockInEntry ? clockInEntry.timestamp : clockState.clockInTime;
      const now = new Date().getTime(); // Use current time
      
      // Calculate work time accounting for paid vs unpaid breaks
      let workTimeMs;
      
      if (calculateWorkTime) {
        // Use the TimeTrackContext's calculateWorkTime function if available
        workTimeMs = calculateWorkTime(clockInTime, now);
      } else {
        // Fallback calculation if TimeTrackContext is not available
        workTimeMs = now - clockInTime;
        console.log('Using fallback calculation for work time');
        console.log('ClockInTime:', clockInTime);
      }
      
      // Convert to hours, minutes, seconds for display
      const hours = Math.floor(workTimeMs / (60 * 60 * 1000));
      const minutes = Math.floor((workTimeMs % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((workTimeMs % (60 * 1000)) / 1000);
      
      // Format the display
      setShiftDuration(`${hours}h ${minutes}m ${seconds}s`);
    };
    
    // Update immediately
    updateDuration();
    
    // Set up interval to update every second
    const intervalId = setInterval(updateDuration, 1000);
    
    return () => clearInterval(intervalId);
  }, [clockState, calculateWorkTime, entries]);
  
  // Determine if we should show the timer
  const showTimer = status === 'Clocked In' || status === 'On Break';
  
  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    }}>
      <StatusIcon cls1={statuscolorTimecard} />
      <Box sx={{ 
        marginLeft: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
          {status}
        </Typography>
        
        {showTimer && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            mt: 0.5,
          }}>
            <AccessTimeIcon sx={{ fontSize: 16, color: 'white', mr: 0.5 }} />
            <Typography variant="caption" sx={{ color: 'white' }}>
              {shiftDuration}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
