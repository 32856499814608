import L from 'leaflet';
import { DomEvent } from 'leaflet'; // Explicitly import DomEvent
import React from 'react';
import ReactDOM from 'react-dom';
import './CustomMenuControl.css';

const CustomMenuControl = L.Control.extend({
  options: {
    position: 'topleft', // Position of the control on the map
  },

  initialize: function (options) {
    L.Util.setOptions(this, options);
    this.menuItems = options.menuItems || []; // Store menu items
  },

  onAdd: function (map) {
    // Create a container for the control
    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control custom-menu-control');

    // Create a toggle button to open/close the menu
    const toggleButton = L.DomUtil.create('button', 'menu-toggle-button', container);
    toggleButton.innerHTML = '☰'; // Hamburger icon
    toggleButton.title = 'Toggle Menu'; // Tooltip

    // Create a container for the menu items
    const menuContainer = L.DomUtil.create('div', 'menu-items-container', container);
    menuContainer.style.display = 'none'; // Initially hidden

    // Add menu items to the container
    this.menuItems.forEach((item) => {
      const button = L.DomUtil.create('button', 'menu-item', menuContainer);
      button.title = item.label; // Add a tooltip

      // Create a div to hold the icon and label
      const content = L.DomUtil.create('div', 'menu-item-content', button);

      // Render the icon using ReactDOM
      const iconContainer = L.DomUtil.create('span', 'menu-item-icon', content);
      ReactDOM.render(item.icon, iconContainer);

      // Add the label
      const label = L.DomUtil.create('span', 'menu-item-label', content);
      label.textContent = item.label;

      // Attach the onClick handler
      button.onclick = (e) => {
        DomEvent.stopPropagation(e); // Stop event propagation
        item.onClick(e); // Call the provided onClick handler
        menuContainer.style.display = 'none'; // Close the menu after clicking an item
      };

      // Prevent map clicks from propagating when interacting with the button
      DomEvent.disableClickPropagation(button);
      DomEvent.disableScrollPropagation(button);
    });

    // Toggle menu visibility when the toggle button is clicked
    toggleButton.onclick = (e) => {
      DomEvent.stopPropagation(e); // Stop event propagation
      if (menuContainer.style.display === 'none') {
        menuContainer.style.display = 'block'; // Show menu
      } else {
        menuContainer.style.display = 'none'; // Hide menu
      }
    };

    // Close the menu when clicking outside of it
    const handleClickOutside = (event) => {
      if (!container.contains(event.target)) {
        menuContainer.style.display = 'none'; // Hide menu
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the control is removed
    this.onRemove = function () {
      document.removeEventListener('click', handleClickOutside);
    };

    return container;
  },
});

export default CustomMenuControl;