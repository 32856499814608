import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Box, 
  Grid, 
  CircularProgress,
  Alert,
  Collapse,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { 
  PlayArrow as StartIcon, 
  Stop as StopIcon,
  Coffee as RestIcon,
  Restaurant as MealIcon,
  AccessTime as ClockIcon
} from '@mui/icons-material';
import { useTimeTrackContext } from '../context/TimeTrackContext';
import { getTimeTrackEntries, checkBreakDue, createBreakNotification } from '../../../api/timetrack';
import '../styles.css';
import BreakSchedule from './BreakSchedule';

const TimeTrackControls = () => {
  const { 
    entries,
    clockState,
    setClockState,
    error,
    loading,
    waitingForGeolocation,
    handleClockIn,
    handleClockOut,
    handleBreak,
    getBreakTimeLeft,
    getActiveBreakType,
    isCurrentDateToday,
    showBreakSchedule,
    toggleBreakSchedule,
    currentTime,
    calculateWorkTime,
    userId,
    showAutoClockOutAlert,
    setShowAutoClockOutAlert,
    navigateToToday
  } = useTimeTrackContext();

  const [breakTimeLeft, setBreakTimeLeft] = useState(null);
  const [activeBreakType, setActiveBreakType] = useState(null);
  const [showError, setShowError] = useState(false);
  const [shiftDuration, setShiftDuration] = useState('0h 0m 0s');
  const [testingBreakAlert, setTestingBreakAlert] = useState(false);

  // Update break timer
  useEffect(() => {
    const timeLeft = getBreakTimeLeft();
    const breakType = getActiveBreakType();
    
    setBreakTimeLeft(timeLeft);
    setActiveBreakType(breakType);

    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setBreakTimeLeft(prev => {
          const newTime = prev - 1000;
          return newTime > 0 ? newTime : 0;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [entries, getBreakTimeLeft, getActiveBreakType]);

  // Show error alert when error state changes
  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => setShowError(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  // Fetch the most recent clock-in entry when the component mounts or when the date changes
  useEffect(() => {
    if (!clockState.clockedIn) return;
    
    // Use navigateToToday which will handle fetching the most recent clock-in timestamp
    if (isCurrentDateToday()) {
      //console.log('Refreshing clock state using navigateToToday...');
      navigateToToday();
    }
  }, [clockState.clockedIn, isCurrentDateToday, navigateToToday]);

  // Update shift duration in real-time when clocked in
  useEffect(() => {
    if (!clockState.clockedIn) {
      setShiftDuration('0h 0m 0s');
      return;
    }
    
    const updateDuration = () => {
      if (!clockState.clockInTime) return;
      
      // Always use the clockState.clockInTime for the current session
      // This ensures we're using the correct clock-in time regardless of which date we're viewing
      const clockInTime = clockState.clockInTime;
      const now = Date.now(); // Use Date.now() for consistency
      
      // Simple direct calculation for elapsed time
      const elapsedMs = now - clockInTime;
      
      // Convert to hours, minutes, seconds for display
      const hours = Math.floor(elapsedMs / (60 * 60 * 1000));
      const minutes = Math.floor((elapsedMs % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((elapsedMs % (60 * 1000)) / 1000);
      
      // Format the display
      setShiftDuration(`${hours}h ${minutes}m ${seconds}s`);
    };
    
    // Update immediately
    updateDuration();
    
    // Set up interval to update every second
    const intervalId = setInterval(updateDuration, 1000);
    
    return () => clearInterval(intervalId);
  }, [clockState.clockedIn, clockState.clockInTime]);

  const formatBreakTime = (ms) => {
    if (!ms) return '0';
    const minutes = Math.ceil(ms / 60000);
    return `${minutes}`;
  };

  const getBreakButtonText = (type) => {
    if (activeBreakType === type) {
      const timeLeft = formatBreakTime(breakTimeLeft);
      return `End ${type === 'rest' ? 'Rest' : 'Meal'} Break Early (${timeLeft}m)`;
    }
    return type === 'rest' ? 'Rest Break (10m)' : 'Meal Break (30m)';
  };

  return (
    <>
      <Collapse in={showError}>
        <Alert 
          severity="error" 
          onClose={() => setShowError(false)}
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      </Collapse>

      {/* Status Section */}
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2,
          p: 2,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box 
            sx={{ 
              width: 12, 
              height: 12, 
              borderRadius: '50%', 
              mr: 1,
              bgcolor: clockState.clockedIn ? 
                (activeBreakType ? '#FBB03B' : '#8CC63F') : 
                '#FF1D25'
            }} 
          />
          <Typography variant="body1" fontWeight="medium">
            {clockState.clockedIn ? 
              (activeBreakType ? 'On Break' : 'Clocked In') : 
              'Clocked Out'}
          </Typography>
        </Box>
        
        {clockState.clockedIn && isCurrentDateToday() && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ClockIcon sx={{ mr: 0.5, fontSize: 18, color: 'text.secondary' }} />
            <Typography variant="body2" color="text.secondary">
              {shiftDuration}
            </Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" justifyContent="center" mb={2}>
        {clockState.clockedIn ? (
          <Button
            variant="contained"
            color="error"
            startIcon={loading.clockOut ? <CircularProgress size={20} /> : <StopIcon />}
            onClick={() => handleClockOut()}
            size="large"
            disabled={!isCurrentDateToday() || waitingForGeolocation || loading.clockOut}
            fullWidth
          >
            {loading.clockOut ? 'Clocking Out...' : 'Clock Out'}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={loading.clockIn ? <CircularProgress size={20} /> : <StartIcon />}
            onClick={handleClockIn}
            size="large"
            disabled={!isCurrentDateToday() || waitingForGeolocation || loading.clockIn}
            fullWidth
          >
            {loading.clockIn ? 'Clocking In...' : 'Clock In'}
          </Button>
        )}
      </Box>
      
      {clockState.clockedIn && isCurrentDateToday() && (
        <>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="warning"
                startIcon={loading.break ? <CircularProgress size={20} /> : <RestIcon />}
                onClick={() => handleBreak('rest')}
                fullWidth
                disabled={waitingForGeolocation || loading.break || (activeBreakType && activeBreakType !== 'rest')}
                sx={{ 
                  bgcolor: '#FBB03B',
                  '&:hover': {
                    bgcolor: '#e69c35'
                  },
                  '&:disabled': {
                    bgcolor: 'rgba(251, 176, 59, 0.5)'
                  }
                }}
              >
                {loading.break && activeBreakType === 'rest' ? 'Ending Break...' :
                 loading.break ? 'Starting Break...' :
                 getBreakButtonText('rest')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="info"
                startIcon={loading.break ? <CircularProgress size={20} /> : <MealIcon />}
                onClick={() => handleBreak('meal')}
                fullWidth
                disabled={waitingForGeolocation || loading.break || (activeBreakType && activeBreakType !== 'meal')}
                sx={{ 
                  bgcolor: '#0080BB',
                  '&:hover': {
                    bgcolor: '#006da1'
                  },
                  '&:disabled': {
                    bgcolor: 'rgba(0, 128, 187, 0.5)'
                  }
                }}
              >
                {loading.break && activeBreakType === 'meal' ? 'Ending Break...' :
                 loading.break ? 'Starting Break...' :
                 getBreakButtonText('meal')}
              </Button>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" mt={2} gap={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleBreakSchedule}
              size="small"
              sx={{ textTransform: 'none' }}
            >
              View Break Schedule
            </Button>
            
            <Button
              variant="outlined"
              color="secondary"
              onClick={async () => {
                setTestingBreakAlert(true);
                try {
                  console.log('[DEBUG] Testing break alert notification');
                  // Create a test break notification
                  const now = new Date();
                  const breakType = Math.random() > 0.5 ? 'rest' : 'meal';
                  
                  const testBreakDue = {
                    breakType,
                    durationMinutes: breakType === 'meal' ? 30 : 10,
                    scheduledBreakTime: now.toISOString(),
                    state: 'WA'
                  };
                  
                  console.log('[DEBUG] Creating test break notification:', testBreakDue);
                  
                  // Create the notification in the database
                  const notification = await createBreakNotification({
                    userId,
                    breakType: testBreakDue.breakType,
                    scheduledBreakTime: testBreakDue.scheduledBreakTime,
                    state: 'WA'
                  });
                  
                  console.log('[DEBUG] Test break notification created:', notification);
                  
                  // Show an alert to confirm
                  alert(`Test ${breakType} break notification created. You should see it appear shortly.`);
                } catch (error) {
                  console.error('Error creating test break notification:', error);
                  alert('Error creating test break notification: ' + error.message);
                } finally {
                  setTestingBreakAlert(false);
                }
              }}
              size="small"
              disabled={testingBreakAlert || !clockState.clockedIn}
              sx={{ textTransform: 'none' }}
            >
              {testingBreakAlert ? 'Creating Test Alert...' : 'Test Break Alerts'}
            </Button>
          </Box>
        </>
      )}

      <BreakSchedule 
        open={showBreakSchedule}
        onClose={toggleBreakSchedule}
      />

      {/* Auto-Clockout Alert Dialog */}
      <Dialog
        open={showAutoClockOutAlert}
        onClose={() => setShowAutoClockOutAlert(false)}
        aria-labelledby="auto-clockout-dialog-title"
        aria-describedby="auto-clockout-dialog-description"
      >
        <DialogTitle id="auto-clockout-dialog-title">
          Automatic Clock-Out
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="auto-clockout-dialog-description">
            You have been automatically clocked out because you exceeded the maximum work hours (24 hours).
            For your health and safety, the system does not allow continuous work for more than 24 hours.
            Please take a break and clock in again when you're ready to continue working.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAutoClockOutAlert(false)} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TimeTrackControls;
