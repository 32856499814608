// React and core dependencies
import React, { useEffect, useRef, useState, useContext, useCallback } from "react";
import styled from "styled-components";
import TrackMapInteractions from "./TrackMapInteractions";

// Leaflet and map dependencies
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

// Hooks and utilities
import { StoreContext } from '../Hooks/store';
import { useAuth } from '../Hooks/useAuth';
import { useSaveLocation } from '../Hooks/useSaveLocation';
import { 
  getAllSavedLocations, 
  createSavedLocation, 
  softDeleteLocation,
  updateSavedLocation,
  getCommentCount 
} from '../../api/savedLocations';
import { fetchAndCachePropertyData, clearPropertyDataCache } from '../../api/propertyData';
import { reverseGeocode } from '../../utils/geocoding';
import { notify } from '../Toast';
import { iconMap, createCustomIcon, createPropertyLayer, createLassoControl, createRefreshControl, createDateRangeSliderControl } from './mapUtils';
 
// Components
// PropertyControlsGroup is no longer needed
import CustomizedDialogs from '../LocationPopup';
import LocationPopupDialog from './LocationPopupDialog';
import LocationManagementDialog from './LocationManagementDialog';
import CustomMenuControl from './CustomMenuControl';
import MarkerLayer from './components/MarkerLayer';
import TodoList from '../TodoList';
import TimeCard from '../TimeCard';
import AssignmentDialog from '../Assignment/AssignmentDialog';
import TimeTrack from '../TimeTrack';
import Timesheet from '../Timesheet';

// UI Elements
import MyLocation from '@mui/icons-material/MyLocation';
import RefreshIcon from '@mui/icons-material/Refresh';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Styles
import './Map.css';

// Data
import markersData from '../../data/markers.json';
import getMenuItems from './menuItems';

const Wrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function Map({ width = "100vw", height = "95vh" }) {
  const { user } = useAuth();
  const storeContextValue = useContext(StoreContext);
  const { 
    currentuserlocation: [userlocation, setuserlocation],
    geolocation: [sharedGeolocation],
    mapdialog: [dialogContent, setDialogContent],
    mapdialogopen: [dialogOpen, setDialogOpen],
    askGeolocation: [requestGeolocationFunc],
    mapInstance: [_, setMapInstance]
  } = storeContextValue;
  
  // Expose the StoreContext to the window object for use in mapUtils.js
  useEffect(() => {
    // Make sure the root element exists
    const rootElement = document.getElementById('root');
    if (rootElement) {
      // Expose the setDialogContent and setDialogOpen functions to the window object
      rootElement.__STORE_CONTEXT__ = {
        setDialogContent,
        setDialogOpen
      };
    }
    
    return () => {
      // Clean up when component unmounts
      const rootElement = document.getElementById('root');
      if (rootElement) {
        delete rootElement.__STORE_CONTEXT__;
      }
    };
  }, [setDialogContent, setDialogOpen]);
  
  const { isSaving, handleSaveLocation } = useSaveLocation(user?.id);

  const mapRef = useRef(null);
  const userMarkerRef = useRef(null);
  const savedLocationsLayerRef = useRef(L.layerGroup());
  const markersLayerRef = useRef(L.layerGroup());
  const legendRef = useRef(null);
  const [propertyData, setPropertyData] = useState(null);

  const [savedLocations, setSavedLocations] = useState([]);
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [locationDialogData, setLocationDialogData] = useState({
    coordinates: { lat: 0, lng: 0 },
    name: "",
    address: "",
    description: "",
    id: null,
  });
  const [mode, setMode] = useState("save");
  const [openDialog, setOpenDialog] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationPopupOpen, setLocationPopupOpen] = useState(false);
  const [selectedLocationComments, setSelectedLocationComments] = useState(0);
  const [dateFilter, setDateFilter] = useState(null);
  const [dateRangeControl, setDateRangeControl] = useState(null);
  // showPropertyControls is no longer needed as the control is managed by Leaflet
  // const [showPropertyControls, setShowPropertyControls] = useState(false); 
  const [isLassoActive, setIsLassoActive] = useState(false); // Keep for lasso state indication if needed elsewhere
  const [propertyLayersVisible, setPropertyLayersVisible] = useState(false);

  // Load property data when user logs in
  useEffect(() => {
    if (user) {
      fetchAndCachePropertyData(user.id)
        .then(data => {
          setPropertyData(data);
        })
        .catch(error => {
          notify("Failed to load property data. Some features may be unavailable offline.", "error");
          console.error("Error loading property data:", error);
        });
    }
  }, [user]);

  const handleProfileMenuOpen = () => {
    console.log("Profile menu opened");
  };

  const menuItems = getMenuItems(setOpenDialog, handleProfileMenuOpen);

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const fetchSavedLocations = useCallback(async () => {
    if (!user) return;

    try {
      const locations = await getAllSavedLocations();
      setSavedLocations(locations);
    } catch (error) {
      console.error("Error fetching saved locations:", error);
      notify("Failed to fetch saved locations.", "error", true);
    }
  }, [user]);

  const handleEditLocation = useCallback((locationId) => {
    const location = savedLocations.find(loc => loc.id === locationId);
    if (location) {
      setLocationDialogData({
        coordinates: { lat: location.coords.lat, lng: location.coords.lng },
        name: location.name,
        address: location.address,
        description: location.description,
        id: location.id,
      });
      setMode("edit");
      setLocationDialogOpen(true);
    }
  }, [savedLocations]);
  
  const handleDeleteLocation = useCallback((locationId) => {
    const location = savedLocations.find(loc => loc.id === locationId);
    if (location) {
      setLocationDialogData({
        coordinates: { lat: location.coords.lat, lng: location.coords.lng },
        name: location.name,
        address: location.address,
        description: location.description,
        id: location.id,
      });
      setMode("delete");
      setLocationDialogOpen(true);
    }
  }, [savedLocations]);

  useEffect(() => {
    window.handleEditLocation = handleEditLocation;
    window.handleDeleteLocation = handleDeleteLocation;
    return () => {
      delete window.handleEditLocation;
      delete window.handleDeleteLocation;
    };
  }, [handleEditLocation, handleDeleteLocation]);

  useEffect(() => {
    if (mapRef.current && sharedGeolocation.lat !== 0 && sharedGeolocation.lon !== 0) {
      const { lat, lon } = sharedGeolocation;
      mapRef.current.setView([lat, lon], 17);
      notify('Map center updated to: ' + lat + ', ' + lon);

      if (userMarkerRef.current) {
        userMarkerRef.current.setLatLng([lat, lon]);
      } else {
        userMarkerRef.current = L.marker([lat, lon], {
          icon: createCustomIcon(MyLocation, '#FF0000'),
        })
          .addTo(mapRef.current)
          .bindPopup("You are here!");
      }
    }
  }, [sharedGeolocation]);

  const updateSavedLocationsLayer = useCallback(async () => {
    savedLocationsLayerRef.current.clearLayers();

    for (const location of savedLocations) {
      const IconComponent = iconMap[location.icon] || iconMap.PlaceIcon;
      const customIcon = createCustomIcon(IconComponent, location.color || '#3f51b5');
      const marker = L.marker([location.coords.lat, location.coords.lng], { icon: customIcon });

      marker.on('click', async () => {
        try {
          const commentCount = await getCommentCount(location.id);
          setSelectedLocationComments(commentCount);
          setSelectedLocation(location);
          setLocationPopupOpen(true);
          mapRef.current?.setView([location.coords.lat, location.coords.lng], 17);
        } catch (error) {
          console.error("Error fetching comment count for location:", location.id, error);
          notify("Failed to fetch comment count.", "error");
        }
      });

      savedLocationsLayerRef.current.addLayer(marker);
    }
  }, [savedLocations, mapRef]);

  const handleSaveLocationCallback = useCallback(
    async (name, address, description, coordinates) => {
      try {
        console.log('Saving location with data:', { name, address, description, coordinates });
  
        const savedLocation = await createSavedLocation(
          user.id,
          name,
          address,
          description,
          'PlaceIcon',
          [coordinates.lat, coordinates.lng]
        );
  
        console.log('Saved location response:', savedLocation);
  
        if (!savedLocation || !savedLocation.id) {
          throw new Error('Failed to save location: No ID returned');
        }
  
        notify('Location saved successfully!', 'success');
  
        setLocationDialogData((prevData) => ({
          ...prevData,
          id: savedLocation.id,
        }));
  
        fetchSavedLocations();
      } catch (error) {
        console.error('Error saving location:', error);
        notify(`Failed to save location: ${error.message}`, 'error');
      }
    },
    [user, fetchSavedLocations]
  );

  const handleUpdateLocation = useCallback(async (name, address, description) => {
    try {
      const updatedData = { name, address, description };
      await updateSavedLocation(locationDialogData.id, updatedData);
      notify("Location updated successfully!", "success", false);

      const updatedLocations = savedLocations.map(loc =>
        loc.id === locationDialogData.id
          ? { ...loc, name, address, description }
          : loc
      );
      setSavedLocations(updatedLocations);
      
      // Update selected location if it's currently being viewed
      if (selectedLocation && selectedLocation.id === locationDialogData.id) {
        setSelectedLocation({
          ...selectedLocation,
          name,
          address,
          description
        });
      }
    } catch (error) {
      console.error("Error updating location:", error);
      notify("Failed to update location.", "error", true);
    }
  }, [locationDialogData.id, savedLocations, selectedLocation]);

  const handleDeleteLocationCallback = useCallback(async () => {
    try {
      await softDeleteLocation(locationDialogData.id, user?.id, "User requested deletion");
      notify("Location deleted successfully!", "success", false);
      
      // Close the popup if the deleted location is currently being viewed
      if (selectedLocation && selectedLocation.id === locationDialogData.id) {
        setLocationPopupOpen(false);
        setSelectedLocation(null);
      }
      
      fetchSavedLocations();
    } catch (error) {
      console.error("Error deleting location:", error);
      notify("Failed to delete location.", "error", true);
    }
  }, [locationDialogData.id, fetchSavedLocations, selectedLocation]);

  // Refresh location data
  const refreshLocationData = useCallback(async () => {
    if (selectedLocation) {
      try {
        const locations = await getAllSavedLocations();
        const updatedLocation = locations.find(loc => loc.id === selectedLocation.id);
        if (updatedLocation) {
          setSelectedLocation(updatedLocation);
        }
      } catch (error) {
        console.error("Error refreshing location data:", error);
      }
    }
  }, [selectedLocation]);

  const handleMarkerClick = useCallback((location) => {
    setDialogContent(location);
    setDialogOpen(true);
    mapRef.current?.setView([location.coords.lat, location.coords.lng], 17);
  }, []);

  useEffect(() => {
    if (!mapRef.current) {
      // Load saved map state or use defaults with user-specific key
      const mapStateKey = user ? `mapState_${user.id}` : 'mapState_guest';
      const savedMapState = JSON.parse(localStorage.getItem(mapStateKey)) || {
        center: [47.66, -122.33],
        zoom: 10
      };

      mapRef.current = L.map("map", {
        center: savedMapState.center,
        zoom: savedMapState.zoom,
        layers: [
          L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            detectRetina: true,
            maxZoom: 20,
            maxNativeZoom: 17,
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
          }),
        ],
        zoomControl: true,
      });

      L.control.scale().addTo(mapRef.current);

      // Initialize custom markers layer
      markersLayerRef.current.clearLayers();
      markersData.forEach((m) => {
        const IconComponent = iconMap[m.defaultIcon] || iconMap.PlaceIcon;
        const customIcon = createCustomIcon(IconComponent, m.color || 'red');
        const marker = L.marker(m.coords, { icon: customIcon });

        marker.properties = {
          name: m.name,
          address: m.address,
          description: m.description,
        };

        marker.on('click', (e) => {
          setDialogContent(e.target.properties);
          setDialogOpen(true);
          mapRef.current.setView(e.latlng, 17);
        });

        markersLayerRef.current.addLayer(marker);
      });

      const baseLayers = {
        "OpenStreetMap": L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          detectRetina: true,
          maxZoom: 20,
          maxNativeZoom: 17,
          attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        }),
        "OpenTopoMap": L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: 'Map data: &copy; OpenStreetMap contributors, SRTM | Map style: &copy; OpenTopoMap (CC-BY-SA)'
        }),
        "Esri World Street Map": L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
        }),
        "Esri World Topo Map": L.tileLayer('http://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
        })
      };

      // Add lasso control first so it works with all layers
      createLassoControl(mapRef.current);

      // Create property layers only if we have the data
      let seattle_2024july_2025janLayer = null;
      let seattle_building_permits_july2024_jan2025Layer = null;

      if (propertyData) {
        seattle_2024july_2025janLayer = createPropertyLayer(propertyData.realEstate, mapRef, dateFilter);
        seattle_building_permits_july2024_jan2025Layer = createPropertyLayer(propertyData.buildingPermits, mapRef, dateFilter);
      }

      // Initialize layer states from localStorage or defaults with user-specific key
      const layerStatesKey = user ? `mapLayerStates_${user.id}` : 'mapLayerStates_guest';
      const savedLayerStates = JSON.parse(localStorage.getItem(layerStatesKey)) || {
        baseLayer: 'OpenStreetMap',
        overlays: ['Custom Markers', 'Saved Locations']
      };

      const overlays = {
        "Custom Markers": markersLayerRef.current,
        "Saved Locations": savedLocationsLayerRef.current,
        ...(propertyData && {
          "Weekly Real Est Txns July 2024 - Mar 2025": seattle_2024july_2025janLayer,
          "Building Permits July 2024 - Mar 2025": seattle_building_permits_july2024_jan2025Layer,
        })
      };

      // Create legend control
      const legend = L.control({ position: 'topright' });
      legendRef.current = legend;

      legend.onAdd = function () {
        const div = L.DomUtil.create('div', 'info legend');
        const grades = [0, 1000000, 2000000, 3000000, 4000000, 5000000];
        const colors = ['#FFD700', '#FF8C00', '#FF4500', '#FF0000', '#800080', '#4A0082'];
        
        div.style.backgroundColor = 'white';
        div.style.padding = '10px';
        div.style.borderRadius = '5px';
        div.style.boxShadow = '0 1px 5px rgba(0,0,0,0.4)';
        
        div.innerHTML = '<h4 style="margin:0 0 10px 0">Property Values</h4>';
        
        for (let i = 0; i < grades.length; i++) {
          div.innerHTML +=
            '<div style="margin-bottom:5px">' +
            '<i style="background:' + colors[i] + '; width:15px; height:15px; display:inline-block; margin-right:5px; border:1px solid #000"></i> ' +
            '$' + (grades[i] / 1000000).toFixed(1) + 'M' +
            (grades[i + 1] ? ' &ndash; $' + (grades[i + 1] / 1000000).toFixed(1) + 'M' : '+') +
            '</div>';
        }
        
        return div;
      };

      // Function to update legend visibility
      const updateLegendVisibility = (overlayNames) => {
        const propertyLayersVisible = overlayNames.some(name => 
          name === "Weekly Real Est Txns July 2024 - Mar 2025" || 
          name === "Building Permits July 2024 - Mar 2025"
        );
        
        if (propertyLayersVisible) {
          legendRef.current.addTo(mapRef.current);
        } else {
          try {
            mapRef.current.removeControl(legendRef.current);
          } catch (e) {
            // Control might not be on map
          }
        }
      };

      // Apply saved overlay states and update legend visibility
      Object.entries(overlays).forEach(([name, layer]) => {
        if (layer && savedLayerStates.overlays.includes(name)) {
          layer.addTo(mapRef.current);
        }
      });
      updateLegendVisibility(savedLayerStates.overlays);
      
      // Initialize showPropertyControls based on saved layer state
      const propertyLayersVisible = savedLayerStates.overlays.some(name => 
        name === "Weekly Real Est Txns July 2024 - Mar 2025" || 
        name === "Building Permits July 2024 - Mar 2025"
      );
      // setShowPropertyControls(propertyLayersVisible);

      // Create layers control and store reference
      layersControlRef.current = L.control.layers(baseLayers, overlays).addTo(mapRef.current);

      // Add event listeners to persist map state and layer changes
      mapRef.current.on('moveend zoomend', () => {
        const center = mapRef.current.getCenter();
        localStorage.setItem(mapStateKey, JSON.stringify({
          center: [center.lat, center.lng],
          zoom: mapRef.current.getZoom()
        }));
      });

      mapRef.current.on('baselayerchange', (e) => {
        const currentOverlays = Object.entries(overlays)
          .filter(([_, layer]) => layer && mapRef.current.hasLayer(layer))
          .map(([name]) => name);
        
        localStorage.setItem(layerStatesKey, JSON.stringify({
          baseLayer: e.name,
          overlays: currentOverlays
        }));
      });

      // Store references to the original property layers
      propertyLayersRef.current = {
        realEstate: seattle_2024july_2025janLayer,
        buildingPermits: seattle_building_permits_july2024_jan2025Layer
      };

      // Store references to all layers for easier access
      const allLayers = {
        markersLayer: markersLayerRef.current,
        savedLocationsLayer: savedLocationsLayerRef.current,
        realEstateLayer: seattle_2024july_2025janLayer,
        buildingPermitsLayer: seattle_building_permits_july2024_jan2025Layer
      };

      // Handle overlay add/remove events
      mapRef.current.on('overlayadd', (e) => {
        // Get current visible overlays
        const currentOverlays = [];
        
        // Only add the layer that was just added
        if (e.name === "Custom Markers") {
          currentOverlays.push("Custom Markers");
        }
        
        if (e.name === "Saved Locations") {
          currentOverlays.push("Saved Locations");
        }
        
        if (e.name === "Weekly Real Est Txns July 2024 - Mar 2025") {
          currentOverlays.push("Weekly Real Est Txns July 2024 - Mar 2025");
        }
        
        if (e.name === "Building Permits July 2024 - Mar 2025") {
          currentOverlays.push("Building Permits July 2024 - Mar 2025");
        }
        
        // Also add any other layers that are already visible
        // (but not the one we just added since we already did that)
        if (e.name !== "Custom Markers" && mapRef.current.hasLayer(markersLayerRef.current)) {
          currentOverlays.push("Custom Markers");
        }
        
        if (e.name !== "Saved Locations" && mapRef.current.hasLayer(savedLocationsLayerRef.current)) {
          currentOverlays.push("Saved Locations");
        }
        
        if (e.name !== "Weekly Real Est Txns July 2024 - Mar 2025" && 
            propertyLayersRef.current.realEstate && 
            mapRef.current.hasLayer(propertyLayersRef.current.realEstate)) {
          currentOverlays.push("Weekly Real Est Txns July 2024 - Mar 2025");
        }
        
        if (e.name !== "Building Permits July 2024 - Mar 2025" && 
            propertyLayersRef.current.buildingPermits && 
            mapRef.current.hasLayer(propertyLayersRef.current.buildingPermits)) {
          currentOverlays.push("Building Permits July 2024 - Mar 2025");
        }
        
        // Save to localStorage
        localStorage.setItem(layerStatesKey, JSON.stringify({
          baseLayer: Object.keys(baseLayers).find(name => 
            mapRef.current.hasLayer(baseLayers[name])),
          overlays: currentOverlays
        }));

        // Update legend visibility
        updateLegendVisibility(currentOverlays);
        
        // Check if property layers are visible to show/hide property controls
        const propertyLayersVisible = currentOverlays.some(name => 
          name === "Weekly Real Est Txns July 2024 - Mar 2025" || 
          name === "Building Permits July 2024 - Mar 2025"
        );
        // setShowPropertyControls(propertyLayersVisible);
      });
      
      mapRef.current.on('overlayremove', (e) => {
        // Get current visible overlays by checking each layer
        const currentOverlays = [];
        
        // Check each layer except the one being removed
        if (e.name !== "Custom Markers" && mapRef.current.hasLayer(markersLayerRef.current)) {
          currentOverlays.push("Custom Markers");
        }
        
        if (e.name !== "Saved Locations" && mapRef.current.hasLayer(savedLocationsLayerRef.current)) {
          currentOverlays.push("Saved Locations");
        }
        
        if (e.name !== "Weekly Real Est Txns July 2024 - Mar 2025" && 
            propertyLayersRef.current.realEstate && 
            mapRef.current.hasLayer(propertyLayersRef.current.realEstate)) {
          currentOverlays.push("Weekly Real Est Txns July 2024 - Mar 2025");
        }
        
        if (e.name !== "Building Permits July 2024 - Mar 2025" && 
            propertyLayersRef.current.buildingPermits && 
            mapRef.current.hasLayer(propertyLayersRef.current.buildingPermits)) {
          currentOverlays.push("Building Permits July 2024 - Mar 2025");
        }
        
        // Save to localStorage
        localStorage.setItem(layerStatesKey, JSON.stringify({
          baseLayer: Object.keys(baseLayers).find(name => 
            mapRef.current.hasLayer(baseLayers[name])),
          overlays: currentOverlays
        }));

        // Update legend visibility
        updateLegendVisibility(currentOverlays);
        
        // Check if property layers are visible to show/hide property controls
        const propertyLayersVisible = currentOverlays.some(name => 
          name === "Weekly Real Est Txns July 2024 - Mar 2025" || 
          name === "Building Permits July 2024 - Mar 2025"
        );
        // setShowPropertyControls(propertyLayersVisible);
      });

      // Apply saved base layer
      baseLayers[savedLayerStates.baseLayer].addTo(mapRef.current);

      const customControl1 = L.control({ position: 'topleft' });
      customControl1.onAdd = function () {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control custom-control');
        const button = L.DomUtil.create('button', '', container);
        button.innerHTML = 'Get My Location';
        button.title = 'Enable location and center the map to my location';
        button.onclick = () => requestGeolocationFunc();
        L.DomEvent.disableClickPropagation(button);
        
        tippy(button, {
          content: 'Show my current location',
          placement: 'bottom',
          arrow: true,
        });

        return container;
      };
      customControl1.addTo(mapRef.current);

      // Add refresh property data control
      createRefreshControl(mapRef.current, async () => {
        if (!user) {
          notify("You must be logged in to refresh property data.", "error", true);
          return;
        }
        try {
          clearPropertyDataCache(user.id);
          const data = await fetchAndCachePropertyData(user.id);
          setPropertyData(data);
          notify("Property data refreshed successfully!", "success");
        } catch (error) {
          console.error("Error refreshing property data:", error);
          notify("Failed to refresh property data.", "error");
        }
      });

      const customMenuControl = new CustomMenuControl({ position: "topleft", menuItems });
      customMenuControl.addTo(mapRef.current);

      mapRef.current.on('contextmenu', async (e) => {
        if (!user) {
          notify("You must be logged in to save a location.", "error", true);
          return;
        }

        const { lat, lng } = e.latlng;
        const address = await reverseGeocode(lat, lng);

        setLocationDialogData({
          coordinates: { lat, lng },
          name: "",
          address,
          description: "",
          id: null,
        });
        setMode("save");
        setLocationDialogOpen(true);
      });
    }

    // Share map instance with store
    if (mapRef.current) {
      setMapInstance(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        setMapInstance(null);
        mapRef.current.remove();
        mapRef.current = null;
      }
      if (userMarkerRef.current) {
        userMarkerRef.current.remove();
        userMarkerRef.current = null;
      }
    };
  }, [user, propertyData]); // Added propertyData dependency

  useEffect(() => {
    if (mapRef.current) {
      updateSavedLocationsLayer();
    }
  }, [savedLocations, updateSavedLocationsLayer]);

  useEffect(() => {
    fetchSavedLocations();
  }, [fetchSavedLocations]);

  // Store references to property layers and layer control
  const propertyLayersRef = useRef({
    realEstate: null,
    buildingPermits: null
  });
  const layersControlRef = useRef(null);

  // Track property layer visibility through layers control
  useEffect(() => {
    if (mapRef.current && layersControlRef.current) {
      const checkVisibility = () => {
        const visible = 
          layersControlRef.current._layers.some(layer => 
            layer.layer === propertyLayersRef.current.realEstate ||
            layer.layer === propertyLayersRef.current.buildingPermits
          );
        setPropertyLayersVisible(visible);
      };

      // Check initially
      checkVisibility();

      // Listen for layer changes
      mapRef.current.on('overlayadd overlayremove', checkVisibility);

      return () => {
        if (mapRef.current) {
          mapRef.current.off('overlayadd overlayremove', checkVisibility);
        }
      };
    }
  }, [mapRef, propertyData]);

  // Update property layers when date filter changes
  useEffect(() => {
    if (mapRef.current && propertyData) {
      // Remove existing property layers
      if (propertyLayersRef.current.realEstate) {
        mapRef.current.removeLayer(propertyLayersRef.current.realEstate);
      }
      if (propertyLayersRef.current.buildingPermits) {
        mapRef.current.removeLayer(propertyLayersRef.current.buildingPermits);
      }

      // Only create new layers if they're visible in the layers control
      if (propertyLayersVisible) {
        const realEstateLayer = createPropertyLayer(
          propertyData.realEstate, 
          mapRef,
          dateFilter
        );
        const buildingPermitsLayer = createPropertyLayer(
          propertyData.buildingPermits,
          mapRef,
          dateFilter
        );

        propertyLayersRef.current = {
          realEstate: realEstateLayer,
          buildingPermits: buildingPermitsLayer
        };

        // Add layers back to map if they're visible
        if (realEstateLayer) {
          realEstateLayer.addTo(mapRef.current);
        }
        if (buildingPermitsLayer) {
          buildingPermitsLayer.addTo(mapRef.current);
        }
      }
    }
  }, [dateFilter, propertyData, propertyLayersVisible]);

  // Add date range control
  useEffect(() => {
    if (mapRef.current) {
      if (propertyLayersVisible && !dateRangeControl) {
        const control = createDateRangeSliderControl(mapRef.current, (filter) => {
          setDateFilter(filter);
        });
        control.addTo(mapRef.current);
        setDateRangeControl(control);
      } else if (!propertyLayersVisible && dateRangeControl) {
        mapRef.current.removeControl(dateRangeControl);
        setDateRangeControl(null);
      }
    }
  }, [propertyLayersVisible, dateRangeControl]);

  return (
    <>
      <CustomizedDialogs payload={dialogContent} />
      <LocationManagementDialog
        open={locationDialogOpen}
        onClose={() => setLocationDialogOpen(false)}
        onSaveLocation={handleSaveLocationCallback}
        onUpdateLocation={handleUpdateLocation}
        onDeleteLocation={handleDeleteLocationCallback}
        isSaving={isSaving}
        initialCoordinates={locationDialogData.coordinates}
        initialLocationId={locationDialogData.id}
        initialName={locationDialogData.name}
        initialAddress={locationDialogData.address}
        initialDescription={locationDialogData.description}
        mode={mode}
        fetchSavedLocations={fetchSavedLocations}
        onDataChange={refreshLocationData}
      />

      {openDialog === 'Todolist' && (
        <TodoList open={true} onClose={handleCloseDialog} />
      )}
      {openDialog === 'TimeCard' && (
        <TimeCard open={true} onClose={handleCloseDialog} />
      )}
      
      {openDialog === 'AssignmentDialog' && (
        <AssignmentDialog open={true} onClose={handleCloseDialog} />
      )}
     
      {openDialog === 'TimeTrack' && (
        <TimeTrack open={true} onClose={handleCloseDialog} />
      )}
      {openDialog === 'Timesheet' && (
        <Timesheet open={true} onClose={handleCloseDialog} />
      )}

      {mapRef.current && (
        <>
          <MarkerLayer
            markers={savedLocations}
            map={mapRef.current}
            onMarkerClick={handleMarkerClick}
            handleEditLocation={handleEditLocation}
            handleDeleteLocation={handleDeleteLocation}
            getCommentCount={getCommentCount}
          />
          <MarkerLayer
            markers={markersData}
            map={mapRef.current}
            onMarkerClick={handleMarkerClick}
            handleEditLocation={handleEditLocation}
            handleDeleteLocation={handleDeleteLocation}
            getCommentCount={getCommentCount}
          />
        </>
      )}

      <LocationPopupDialog
        open={locationPopupOpen}
        onClose={() => setLocationPopupOpen(false)}
        location={selectedLocation}
        commentCount={selectedLocationComments}
        onEdit={handleEditLocation}
        onDelete={handleDeleteLocation}
        userId={user?.id}
        onDataChange={refreshLocationData}
      />
      <TrackMapInteractions map={mapRef.current} userId={user?.id} />
      <Wrapper width={width} height={height} id="map" />
    </>
  );
}

export default React.memo(Map);
